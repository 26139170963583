import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class AdvertisementsService extends BaseService {
  static get entity () {
    return 'advertisements'
  }

  static async getAdvertisements (params = {}, xweb) {
    try {
      const response = await this.request({ auth: true }, false).get(`${this.entity}?${this.querystring(params)}`)
      const data = {
        data: response.data.data
      }
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}

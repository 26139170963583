<template>
	<div class="login">
		<div class="wrapper">
			<UiToastList/>
			<Welcome @next="step = 'select_login'" v-show="step === 'start_screen'"></Welcome>
			<SelectLoginMethod @showPrivacyPolicyModal="showPrivacyModal = true" @next="step = 'login'" v-show="step === 'select_login'"></SelectLoginMethod>
			<AuthForm v-show="step === 'login'"></AuthForm>
			<transition name="modal">
				<PrivacyPolicyModal @close="closeModal" @accept="accept" v-show="showPrivacyModal"></PrivacyPolicyModal>
			</transition>
		</div>
	</div>
</template>
<script>
import Welcome from '../../components/login/Welcome'
import SelectLoginMethod from '../../components/login/SelectLoginMethod'
import AuthForm from '../../components/login/AuthForm'
import UiToastList from '@/components/UiToastList'
import PrivacyPolicyModal from '@/components/login/modals/PrivacyPolicy'
import { mapMutations } from 'vuex'

export default {
	data () {
		return {
			agreeTermsAndConditions: false,
			showPrivacyModal: false,
			step: 'start_screen'
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		closeModal () {
			this.showPrivacyModal = false
		},
		accept () {
			this.showPrivacyModal = false
			this.agreeTermsAndConditions = true
		},
		showPreviewError (error) {
			switch (error) {
				case 'email_not_found':
					this.toast({
						message: 'No Email found in social profile, try another social network',
						type: 'error'
					})
					break
			}
		}
	},
	components: {
		PrivacyPolicyModal,
		Welcome,
		UiToastList,
		SelectLoginMethod,
		AuthForm
	},
	mounted () {
		if (this.$route.query.error) {
			this.showPreviewError(this.$route.query.error)
		}
	}
}
</script>
<style scoped lang="scss">
	.login {
		background: url('~@/assets/login-bg.png'), radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7679446778711485) 24%);
		min-height: 100vh;
		width: 100%;

		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
		.wrapper {
			width: 575px;
			padding: 0 50px;
			background: #2F2F2F;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		}
	}

	@media screen and (max-width: 768px) {
		.login {
			padding-right: 16px;
			padding-left: 16px;
			.wrapper {

				padding-right: 24px;
				padding-left: 24px;
				max-width: 100%;
			}
		}
	}
</style>

import BaseModel from './BaseModel'

export default class UserModel extends BaseModel {
  get schema () {
    return {
      avatar: undefined,
      email: undefined,
      id: undefined,
      locale: undefined,
      name: undefined,
      nickname: undefined,
      platform: undefined,
      timezone: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

	getAvatar () {
		return this.avatar ?? require('@/assets/default-avatar.png')
	}

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

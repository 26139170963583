<template>
	<div class="ui-breadcrumbs component" v-if="breadcrumbsList.length">
		<div class="ui-breadcrumbs__item" v-for="(breadcrumb, index) in breadcrumbsList" :key="index">
			<RouterLink v-if="breadcrumb.path" :to="{ path: breadcrumb.path }">{{ breadcrumb.name }}</RouterLink>
			<span v-else>{{ breadcrumb.name }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'UiBreadcrumbs',
		data () {
			return {
				breadcrumbsList: []
			}
		},
		mounted () {
			this.$store.subscribe((mutation) => {
				if (this.$route.meta.onChangeBreadcrumb && this.$route.meta.onChangeBreadcrumb.includes(mutation.type)) {
					this.updateList()
				}
			})

			this.updateList()
		},
		watch: {
			'$route' () {
				this.updateList()
			}
		},

		methods: {
			updateList () {
				const breadcrumbs = this.$route.meta.breadcrumb || []
				breadcrumbs.forEach((item, index) => {
					if (item.isDynamic) {
						breadcrumbs[index].name = this.$store.getters[item.getter][item.property]
					}
				})

				this.breadcrumbsList = breadcrumbs
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ui-breadcrumbs {
		display: flex;
		font-size: 10px;
		line-height: 20px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: $color-gray-300;

		&__item {
			position: relative;
			a {
				text-decoration: none;
				color: #8F8F8F;
			}
		}

		> * + * {
			margin-left: 16px;

			&:before {
				content: '|';
				position: absolute;
				left: -11px;
				color: #8F8F8F;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	@media screen and (max-width: 768px) {
		.ui-breadcrumbs {
			> * + * {
				&:before {
					position: static;
					margin-right: 11px;
				}
			}
			&__item {

				span, a {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					max-width: 100%;
					font-size: 10px;
					line-height: 20px;
				}
			}
		}
	}
</style>

<template>
	<div class="round-robin__play">
		<div class="leader-board__tabs">
			<div class="leader-board__tabs--left" v-if="currentMatch">
				<button
					class="leader-board__tab" v-for="(r, index) in currentMatch.roomRoundsCount" :key="index"
					:class="{ 'is-active': r === currentMatch.round}"
					@click="currentTab = index"
					:disabled="r !== currentMatch.round"
				>
					<svg v-if="r !== currentMatch.round" width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.9375 6.875H10.2812V4.90625C10.2812 2.63672 8.39453 0.75 6.125 0.75C3.82812 0.75 1.96875 2.63672 1.96875 4.90625V6.875H1.3125C0.574219 6.875 0 7.47656 0 8.1875V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V8.1875C12.25 7.47656 11.6484 6.875 10.9375 6.875ZM8.09375 6.875H4.15625V4.90625C4.15625 3.83984 5.03125 2.9375 6.125 2.9375C7.19141 2.9375 8.09375 3.83984 8.09375 4.90625V6.875Z" fill="#8F8F8F"/>
					</svg>
					{{ $t('Round') }} {{ index + 1 }}
				</button>
			</div>
		</div>

		<div class="shell">
			<transition name="slide">
				<div class="range" v-if="currentMatch">
					<div class="cell-sm-8 md_full">
						<template>
							<MatchPlayers :match="currentMatch" :service="service"></MatchPlayers>
							<MatchProoveScore :match="currentMatch" :service="service"></MatchProoveScore>
						</template>
					</div>
					<div class="cell-sm-4 round_container">
						<SidebarContainer @nextMatch="getCurrentMatch" :rounds="6" :round="currentMatch"></SidebarContainer>
					</div>
				</div>
			</transition>
			<transition name="slide">
				<div class="loading" v-if="!currentMatch">
					<QlashLoader></QlashLoader>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import QlashLoader from '../../../components/QlashLoader'
import MatchProoveScore from '../../../components/TournamentMatch/MatchProoveScore'
import MatchPlayers from '../../../components/TournamentMatch/MatchPlayers'
import SidebarContainer from '../../../components/round_robin/components/SidebarContainer'
import { RoundRobinService } from '../../../services/round-robin.service'
import { mapGetters, mapMutations } from 'vuex'
import { prepare as matchPrepare } from '../../../model-preparing/tournaments/round-robin/match'
export default {
	data () {
		return {
			service: RoundRobinService,
			currentMatch: null,
			cacheSockets: []
		}
	},
	watch: {
		currentMatch: {
			async handler () {
				await this.subscribeMatchStatus()
			}
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		getCurrentMatch () {
			RoundRobinService.getCurrentMatch(this.tournament.id).then(response => {
				if (response.status === 200 && response.status === 200 && response.data && response.data.data) {
					this.currentMatch = matchPrepare(response.data.data)
				} else {
					return this.toast({
						type: 'error',
						message: 'An error occurred while loading rooms, please try again later'
					})
				}
			})
		},
		subscribeMatchStatus () {
			if (this.currentMatch) {
				const channel = `rr.room.${this.currentMatch.roomId}`

				if (!this.cacheSockets.includes(channel) && this.tournament.type === 7) {
					this.cacheSockets.push(channel)
					this.subscribeObserver(channel, () => {
						this.getCurrentMatch()
					})
				}

				this.subscribeObserver(`rr.tournament.${this.tournament.id}.match.${this.currentMatch.id}`, (data) => {
					if (data.data.status === 'FINISHED') {
						const currentMatch = this.currentMatch
						currentMatch.status = data.data.status
						this.$set(this, 'currentMatch', currentMatch)
					} else {
						this.getCurrentMatch()
					}
				})
			}
		},
		async init () {
			await this.getCurrentMatch()
			await this.subscribeMatchStatus()
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	mounted () {
		this.init()
	},
	components: {
		QlashLoader,
		MatchProoveScore,
		SidebarContainer,
		MatchPlayers
	}
}
</script>
<style scoped lang="scss">
.loading {
	width: 100%;
	margin: 30px 0;
	display: flex;
	justify-content: center;
}
.round-robin__play {
	.leader-board__tabs {
		display: flex;
		justify-content: space-between;
		padding-left: 40px;
		margin-bottom: 40px;

		.leader-board__tabs--left {
			overflow-x: auto;
			overflow-y: hidden;
			max-width: 100%;
			&::-webkit-scrollbar {
				height: 4px;
			}
			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #888;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}
		}

		border-bottom: 1px solid $color-gray-600;

		&--left {
			display: flex;
			flex-wrap: nowrap;
		}

		&-wrapper {
			max-width: 100%;
			overflow-x: auto;
			margin-bottom: 30px;
			padding-bottom: 10px;
		}
	}
	.leader-board__tab {
		@include navigation-item;
		outline: none;
		border: none;
		display: flex;
		align-items: center;
		svg {
			margin-right: 8px;
			margin-bottom: 2px;
			min-width: 13px;
		}
		white-space: nowrap;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		color: $color-gray-400;
		text-align: center;
		border-bottom: 2px solid transparent;

		&.total {
			text-align: center;
			min-width: 205px;
			color: $primary-color;

			&.is-active,
			&:hover {
				color: #00E7DF;
			}

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.ui-icon-base {
				margin-right: 15px;
			}
		}
		&.is-active {
			color: $primary-color;
			border-bottom: 2px solid #00E7DF;
		}
		padding-right: 22.5px;
		padding-left: 22.5px;
	}
	.round-result {
		margin-bottom: 20px;
	}
}

.slide-enter-active, .slide-leave-active {
	transition: all .15s ease-in;
	top: -10px;
}

.slide-enter, .slide-leave-to {
	opacity: 0;
}

.slide-enter-to, .slide-leave {
	opacity: 1;
	top: 0;
}

@media screen and (max-width: 1200px) {
	.round-robin__play .leader-board__tabs {
		margin-bottom: 20px;
	}
	.round_container {
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 0;
		width: 100%;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		::v-deep {
			.sidebar__container {
				.tournament-sidebar__header {
					display: none;
				}
				.tournament-sidebar__content {
					border-radius: 0;
				}
			}
		}
	}
	.md_full {
		width: 100%;
		padding-right: 16px;
		padding-left: 16px;
		padding-bottom: 200px;
		min-width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.round {
		margin-top: 16px;
		.shell {
			width: 100%;
			min-width: 100%;
		}
	}
}
</style>

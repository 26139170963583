import BaseModel from './BaseModel'

export default class TournamentItemModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      title: undefined,
      gameId: undefined,
      image: undefined,
      isJoined: undefined,
      isPrivate: undefined,
      locale: undefined,
      matchType: undefined,
			registeredParticipantCount: undefined,
      maxMember: undefined,
      participantCount: undefined,
			prizePool: undefined,
      platformId: undefined,
			participantId: undefined,
      startedAt: undefined,
      sponsorText: undefined,
      sponsorUrl: undefined,
      sponsorImage: undefined,
      status: undefined,
      teamMates: undefined,
      type: undefined
    }
  }

	getAvatar () {
		return this.image ? this.image : require('@/assets/placeholder.png')
	}

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 14">
    <g>
      <path d="M17.55 12.2475H15.3V3.10167C15.3 2.36234 14.6813 1.73254 13.95 1.73254H10.8V3.48503H13.5V14H17.55C17.775 14 18 13.8083 18 13.5619V12.6856C18 12.4666 17.775 12.2475 17.55 12.2475ZM8.775 0.0348089L3.375 1.37656C2.95313 1.48609 2.7 1.84207 2.7 2.25281V12.2475H0.45C0.196875 12.2475 0 12.4666 0 12.6856V13.5619C0 13.8083 0.196875 14 0.45 14H9.9V0.911056C9.9 0.308636 9.3375 -0.129487 8.775 0.0348089ZM7.425 7.86627C7.03125 7.86627 6.75 7.48291 6.75 6.99002C6.75 6.52452 7.03125 6.11377 7.425 6.11377C7.79063 6.11377 8.1 6.52452 8.1 6.99002C8.1 7.48291 7.79063 7.86627 7.425 7.86627Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

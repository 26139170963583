import ParticipantModel from '@/models/ParticipantModel'
import ArenaItem from '@/models/ArenaItem'
export function prepare (state, value) {
	const rounds = []
	value.data.map(item => {
		item.participants = item.participants.map(item => {
			const participant = state.participants.filter(participant => participant.id === item.id)[0]

			return new ParticipantModel({
				...participant,
				...item
			})
		}).sort((a, b) => {
			return b.points - a.points
		})
		rounds.push(new ArenaItem(item))
	})

	return rounds
}

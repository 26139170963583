<template>
	<div class="tournaments page">
		<UiPageTopBar
			:backgroundImgUrl="currentGame.topBanner ? currentGame.topBanner : currentGame.image"
			:title="currentGame.name"
			v-if="currentGame"
		/>
		<div class="shell" v-if="Object.keys(filteredTournaments).length && currentGame">
			<UiAccordionItem
				v-for='(day, key) in filteredTournaments'
				:title="`${ key === 'Past Tournaments' ? key : getFormattedDate(key) }`"
				:isOpened="key !== 'Past Tournaments'"
				:key="currentGame.id + day.date"
				:adv="getAdv(day, filteredTournaments)"
				:onOpen="key === 'Past Tournaments' ? fetchPastTournaments : () => {}"
			>
				<div class="range">
					<div class="cell-sm-6 cell-md-4" v-for="item in day.items" :key="item.id">
						<UiTournamentItem
							:id="item.id"
							:prizePool="item.prizePool"
							:title="item.title"
							:image="!item.image ? require('@/assets/placeholder.png') : item.image"
							:status="item.status.replace('_', ' ')"
							:platforms="getGamePlatforms(item.gameId, item.platformId)"
							:type="item.type"
							:matchType="item.matchType"
							:teamMates="item.teamMates"
							:participants="item.maxMember"
							:registeredParticipants="item.registeredParticipantCount"
							:startedAt="item.startedAt"
							:sponsorText="item.sponsorText"
							:sponsorUrl="item.sponsorUrl"
							:sponsorImage="item.sponsorImage"
						/>
					</div>
				</div>
			</UiAccordionItem>
		</div>
		<div class="no-tournaments" v-if="!Object.keys(tournaments).length">
			<UiBaseIcon
				width="36px"
				height="36px"
				iconName="error"
			/>
			<h3>{{ $t('Sorry, there are no tournaments right now!') }}</h3>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'
	import Moment from 'moment'
	import UiPageTopBar from '@/components/UiPageTopBar'
	import UiTournamentItem from '@/components/UiTournamentItem'
	import UiAccordionItem from '@/components/UiAccordionItem'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'
	import prepareTournamentsByDays from '@/mixins/prepareTournamentsByDays'

	export default {
		name: 'Tournaments',
		mixins: [prepareTournamentsByDays],
		components: {
			UiPageTopBar,
			UiTournamentItem,
			UiAccordionItem,
			UiBaseIcon
		},
		data () {
			return {
				locale: 'en',
				disableScrollEvent: false,
				limit: 10,
				isOpen: false
			}
		},
		mounted () {
			this.init()
		},
		computed: {
			...mapGetters({
				currentGame: 'user/getCurrentGame',
				getGames: 'user/getGames',
				tournaments: 'tournaments/getTournaments',
				tournamentsMeta: 'tournaments/getTournamentsMeta',
				advertisements: 'advertisements/getAdvertisements'
			}),
			filteredTournaments () {
				return this.prepareTournamentsByDays(this.tournaments)
			},
			currentPage () {
				return this.tournamentsMeta.currentPage
			}
		},
		watch: {
			currentGame () {
				if (!this.currentGame) {
					return this.$router.push({ name: 'games' })
				}
			}
		},
		methods: {
			...mapActions({
				getTournaments: 'tournaments/getTournaments',
				getAdvertisements: 'advertisements/getAdvertisements',
				updateTournaments: 'tournaments/updateTournaments'
			}),
			async init () {
				if (!this.currentGame) {
					return this.$router.push({ name: 'games' })
				}
				this.amplitude.logEvent('screen_view', {
					screen_name: 'Tournament List'
				})
				await this.getAdvertisements({
					games: this.currentGame.id
				})
				await this.getTournaments({
					locale: this.locale,
					country_id: this.$currentUser && this.$currentUser.country_id ? this.$currentUser.country_id : 0,
					games: this.currentGame.id,
					page: 1,
					limit: this.limit,
					from: new Moment().startOf('day').format('YYYY-MM-DD-HH-mm-ss')
				})
				this.getNextPosts()
			},
			getFormattedDate (date) {
				const current = Moment(date)
				const isToday = current.isSame(Moment(), 'day')
				const isTomorrow = current.isSame(Moment().add(1, 'days'), 'day')
				let prefix = 'dddd'

				if (isToday) prefix = '[Today]'
				if (isTomorrow) prefix = '[Tomorrow]'

				return new Moment(date).format(prefix + ' D MMMM')
			},
			getGameById (id) {
				return this.getGames.filter(item => item.id === id)[0]
			},
			getGamePlatforms (gameId, platformId) {
				const game = this.getGames.filter(item => item.id === gameId)[0]
				if (!game) return [1, 2, 3, 4]
				return game.isGlobal ? [1, 2, 3, 4] : [platformId]
			},
			getNextPosts () {
				window.onscroll = async () => {
					const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
					if (bottomOfWindow) {
						if (!this.disableScrollEvent && this.currentPage < this.tournamentsMeta.lastPage) {
							this.disableScrollEvent = true
							await this.updateTournaments({
								locale: this.locale,
								games: this.currentGame.id,
								page: this.currentPage + 1,
								limit: this.limit
							})
							this.disableScrollEvent = false
						}
					}
				}
			},
			getRandomInt (min, max) {
				min = Math.ceil(min)
				max = Math.floor(max)
				return Math.floor(Math.random() * (max - min + 1)) + min
			},
			getAdv (day, filteredTournaments) {
				var adv = null
				var dayIndex = 0
				var tournamentIndexes = []
				
				Object.keys(filteredTournaments).filter((o, i) => {
					if (filteredTournaments[o].date === day.date) {
						dayIndex = i
						return true
					}
				})

				if (Object.keys(filteredTournaments)[dayIndex] !== 'Past Tournaments') {
					filteredTournaments[Object.keys(filteredTournaments)[dayIndex]].items.forEach((t) => {
						this.tournaments.filter((o, ti) => {
							if (t.id === o.id) {
								tournamentIndexes.push(ti)
								return true
							}
						})
					})
				}
				
				tournamentIndexes.forEach((ti) => {
					if (ti % 8 === 2) {
						const advs = this.advertisements.filter((o) => {
							if (o.platforms.includes(this.currentGame.platformId)) {
								return true
							}
						})
						if (advs.length > 0) {
							adv = advs[(Math.random() * advs.length) | 0]
						}
					}
				})

				return adv
			},
			async fetchPastTournaments () {
				console.log(this.tournaments)
				// only fetch the past tournaments if you haven't already, to avoid spamming the api
				if (this.filteredTournaments['Past Tournaments'].items.length > 0) return

				await this.getTournaments({
					locale: this.locale,
					country_id: this.$currentUser && this.$currentUser.country_id ? this.$currentUser.country_id : 0,
					games: this.currentGame.id,
					page: 1,
					limit: this.limit + 20,
					from: Moment().subtract(7, 'days').format('YYYY-MM-DD-HH-mm-ss'),
					status: 'FINISHED'
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ui-accordion-item {
		margin-top: 40px;
	}

	.page {
		padding-bottom: 40px;
	}

	.no-tournaments {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 40px;
		padding: 20px;
		border: 1px dashed $color-gray-500;
		border-radius: 5px;
		height: 65vh;

		text-align: center;

		h3 {
			letter-spacing: .1px;
		}

		.ui-icon-base {
			color: $color-gray-400;
			margin-bottom: 20px;
		}
	}

	.advertisement-container {
		width:100%;
		margin-top: 10px;
		padding: 0px;
		height: 150px;
	}

	.advertisement-container img {
		height: 100%;
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		.ui-accordion-item {
			margin-top: 20px;
		}
		.no-tournaments {
			margin: 16px;
			height: 50vh;
			h3 {
				font-size: 20px;
				line-height: 26px;
			}
		}
	}
</style>

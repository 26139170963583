<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 19">
    <g>
      <path d="M15.625 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H15.625C16.6406 18.25 17.5 17.4297 17.5 16.375V2.625C17.5 1.60938 16.6406 0.75 15.625 0.75ZM4.96094 14.5391C4.72656 14.9297 4.25781 15.0469 3.86719 14.8125C3.51562 14.6172 3.35938 14.1094 3.59375 13.7578L4.14062 12.7812C4.76562 12.5859 5.3125 12.7422 5.70312 13.25L4.96094 14.5391ZM10.3516 12.4297H3.28125C2.85156 12.4297 2.5 12.0781 2.5 11.6484C2.5 11.2188 2.85156 10.8672 3.28125 10.8672H5.27344L7.8125 6.45312L6.99219 5.04688C6.79688 4.69531 6.91406 4.22656 7.30469 3.99219C7.65625 3.79688 8.16406 3.91406 8.35938 4.26562L8.71094 4.89062L9.0625 4.26562C9.25781 3.91406 9.76562 3.79688 10.1172 3.99219C10.5078 4.22656 10.625 4.69531 10.3906 5.04688L7.07031 10.8672H9.49219C10.2734 10.8672 10.7031 11.8047 10.3516 12.4297ZM14.2188 12.4297H13.0859L13.8281 13.7578C14.0625 14.1484 13.9062 14.6172 13.5547 14.8125C13.1641 15.0469 12.6953 14.8906 12.5 14.5391C11.2109 12.3125 10.2344 10.6328 9.60938 9.53906C8.94531 8.40625 9.41406 7.27344 9.88281 6.88281C10.3906 7.78125 11.1328 9.10938 12.1875 10.8672H14.2188C14.6484 10.8672 15 11.2188 15 11.6484C15 12.0781 14.6484 12.4297 14.2188 12.4297Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

<template>
  <div class="qr-login">
    <div class="main">
      <div class="logo">
        <img :src="logo" alt="qlash">
      </div>
      <div class="qr-banner">
          <h3>{{$t('In order to enter the site, log in through the applications')}}</h3>
        <ol class="qr-banner__info">
          <li>{{$t('Open the Qlash app on your phone')}}</li>
          <li>{{$t('Go to setup and select enter web')}}</li>
          <li>{{$t('Read the authorization code')}}</li>
        </ol>
        <div class="qr-banner__code">
          <span class="qr-banner__code-decorator"></span>
          <span class="qr-banner__code-decorator"></span>
          <span class="qr-banner__code-decorator"></span>
          <span class="qr-banner__code-decorator"></span>
          <img :src="qr" alt="code">
        </div>
      </div>
      <div class="app-links">
        <p>{{$t('No clash app? Download here')}}</p>
        <div class="app-links__links">
          <a href="#">
            <UiBaseIcon iconName="play-store"/>
            <span>{{$t('Google Play')}}</span>
          </a>
          <a href="#">
            <UiBaseIcon iconName="apple-brand"/>
            <span>{{$t('App store')}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiBaseIcon from '../components/icons/UiBaseIcon'

export default {
  name: 'QRLogin',
  data () {
    return {
      logo: require('../assets/logo-big.svg'),
      qr: require('../assets/qr-code.png')
    }
  },
  components: {
    UiBaseIcon
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.qr-login {
  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .qr-banner {
    position: relative;
    max-width: 575px;
    padding: 40px;
    background: $color-gray-600;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 140px 0 rgba(0, 231, 223, 0.2);
      z-index: -1;
    }

    h3 {
      letter-spacing: 1px;
    }

    ol {
      counter-reset: list;
      list-style: none;
      margin-top: 25px;
    }

    ol li {
      position: relative;
      counter-increment: list;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
      padding-left: 40px;
    }

    ol li::before {
      position: absolute;
      left: 0;
      top: 0;
      content: counter(list);
      border: 1px solid $primary-color;
      border-radius: 5px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      width: 24px;
      height: 24px;
      display: inline-block;
    }

    &__code {
      position: relative;
      max-width: 200px;
      margin-top: 45px;
      margin-left: auto;
      margin-right: auto;

      &-decorator {
        position: absolute;
        width: 12px;
        height: 12px;
        border: 1px solid #00E7DF;

        &:nth-child(1) {
          top: -6px;
          left: -6px;
          border-bottom-width: 0;
          border-right-width: 0;
        }

        &:nth-child(2) {
          right: -6px;
          top: -6px;
          border-bottom-width: 0;
          border-left-width: 0;
        }

        &:nth-child(3) {
          bottom: -6px;
          right: -6px;
          border-top-width: 0;
          border-left-width: 0;
        }

        &:nth-child(4) {
          bottom: -6px;
          left: -6px;
          border-top-width: 0;
          border-right-width: 0;
        }
      }
    }
  }

  .app-links {
    text-align: center;
    margin-bottom: 57px;

    p {
      text-align: center;
      margin-top: 72px;
      margin-bottom: 7px;
      font-size: 18px;
      line-height: 32px;
    }

    a {
      position: relative;
      display: inline-block;
      padding: 15px;
      font-weight: 500;
      color: $primary-color;
      text-decoration: none;
      margin: 13px;
      border-radius: 4px;
      transition: 0.2s linear;

      .ui-icon-base {
        margin-right: 10px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 2px solid #00E7DF;
        filter: blur(1px);
        border-radius: 4px;
      }

      &:hover {
        color: $color-white;
        background: $secondary-color-1;
      }
    }
  }
}
</style>

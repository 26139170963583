<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 36 36">
    <g>
      <path d="M18 0C8.01562 0 0 7.10156 0 15.75C0 20.7422 2.53125 25.1016 6.60938 27.9844C7.3125 28.4766 7.66406 29.25 7.59375 30.0938L6.89062 34.7344C6.82031 35.4375 7.3125 36 8.01562 36H13.5V32.0625C13.5 31.7812 13.7109 31.5 14.0625 31.5H15.1875C15.4688 31.5 15.75 31.7812 15.75 32.0625V36H20.25V32.0625C20.25 31.7812 20.4609 31.5 20.8125 31.5H21.9375C22.2188 31.5 22.5 31.7812 22.5 32.0625V36H27.9141C28.6172 36 29.1094 35.4375 29.0391 34.7344L28.4062 30.0938C28.2656 29.25 28.6172 28.4766 29.3203 27.9844C33.3984 25.1016 36 20.7422 36 15.75C36 7.10156 27.9141 0 18 0ZM11.25 22.5C8.71875 22.5 6.75 20.5312 6.75 18C6.75 15.5391 8.71875 13.5 11.25 13.5C13.7109 13.5 15.75 15.5391 15.75 18C15.75 20.5312 13.7109 22.5 11.25 22.5ZM24.75 22.5C22.2188 22.5 20.25 20.5312 20.25 18C20.25 15.5391 22.2188 13.5 24.75 13.5C27.2109 13.5 29.25 15.5391 29.25 18C29.25 20.5312 27.2109 22.5 24.75 22.5Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

<template>
	<div class="mobile__header-games">
		<div class="mobile__header-games__list">
			<div class="mobile__header-games__item" @click.prevent="onChangeGame(game.id)" :class="{ 'active': currentGame && currentGame.id === game.id }" v-for="(game,i) in games" :key="i"
			>
				<div class="mobile__header-games__item-picture">
					<UiImgLoader
						:src="game.icon"
						:animated="false"
					/>
				</div>
			</div>
		</div>
		<div @click.prevent.stop="$emit('close')" class="mobile__header-games__close">
			<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.50971 3.98865L7.77921 1.71915C8.07425 1.44681 8.07425 0.992908 7.77921 0.720567L7.27992 0.221277C7.00758 -0.0737589 6.55368 -0.0737589 6.28134 0.221277L4.01184 2.49078L1.71964 0.221277C1.4473 -0.0737589 0.993396 -0.0737589 0.721056 0.221277L0.221765 0.720567C-0.0732706 0.992908 -0.0732706 1.44681 0.221765 1.71915L2.49127 3.98865L0.221765 6.28085C-0.0732706 6.55319 -0.0732706 7.00709 0.221765 7.27943L0.721056 7.77872C0.993396 8.07376 1.4473 8.07376 1.71964 7.77872L4.01184 5.50922L6.28134 7.77872C6.55368 8.07376 7.00758 8.07376 7.27992 7.77872L7.77921 7.27943C8.07425 7.00709 8.07425 6.55319 7.77921 6.28085L5.50971 3.98865Z" fill="white"/>
			</svg>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import UiImgLoader from '../../../components/UiImgLoader'
export default {
	computed: {
		...mapGetters({
			games: 'user/getGames',
			currentGame: 'user/getCurrentGame'
		})
	},
	methods: {
		...mapMutations({
			setGame: 'user/SET_CURRENT_GAME'
		}),
		async onChangeGame (id) {
			await this.setGame(id)
			await window.scrollTo(0, 0)

			if (this.$router.currentRoute.name !== 'index') {
				this.$emit('close')
			}

			await this.getTournaments({
				games: id,
				locale: 'en',
				country_id: this.$currentUser && this.$currentUser.country_id ? this.$currentUser.country_id : 0,
				page: 1,
				limit: 10
			})
		}
	},
	components: {
		UiImgLoader
	}
}
</script>
<style scoped lang="scss">
.mobile__header-games {
	background: #232323;
	border: 1px solid #2F2F2F;
	border-top: none;
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
	width: 100%;
	display: flex;
	align-items: center;
	padding-right: 16px;
	.mobile__header-games__list {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		.mobile__header-games__item {
			transition: 0.2s;
			padding: 15px 14px;
			border-right: 1px solid #2F2F2F;
			border-bottom: 1px solid transparent;
			&.active {
				background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
				border-bottom: 1px solid #00E7DF;
				.mobile__header-games__item-picture {
					border: 1px solid #00E7DF;
					box-sizing: border-box;
					box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
				}
			}
			.mobile__header-games__item-picture {
				width: 30px;
				height: 30px;
				background: #2F2F2F;
				box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
				border-radius: 4px;
				overflow: hidden;
				padding: 3px;
				display: flex;
				align-items: center;
				justify-content: center;
				::v-deep {
					.img-container {
						width: 24px;
						border-radius: 4px;
						height: 24px;
						display: flex;
						align-items: center;
						overflow: hidden;
						img {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
	.mobile__header-games__close {
		width: 28px;
		height: 28px;
		min-width: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #2F2F2F;
		border: 1px solid #505050;
		border-radius: 4px;
	}
}
</style>

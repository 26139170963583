import AdvertisementItemModel from '@/models/AdvertisementItemModel'

export default {
	SET_ADVERTISEMENTS (state, value) {
		const advertisements = []

		value.data.map(item => {
			advertisements.push(new AdvertisementItemModel(item))
		})

		state.advertisements = advertisements
	}
}

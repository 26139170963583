import { ChatService } from '@/services/chat.service'
import { eventBus } from '../../../buses/eventBus'
export default {
	getRounds ({ commit, rootGetters, rootState }) {
		return ChatService.getChats(rootGetters['user/getCurrentUser'].id).then(rounds => commit('SET_ROUNDS', { rounds: rounds.data, rootState }))
	},
	setActiveChat ({ commit }, chat) {
		commit('SET_ACTIVE_CHAT', chat)
	},
	removeChat ({ commit }, id) {
		commit('REMOVE_CHAT', id)
	},
	closeChat ({ commit }) {
		commit('CLOSE_CHAT')
		eventBus.$emit('chat::close')
	}
}

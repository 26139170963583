import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
export class ChatService extends BaseService {
	static async getChats (userId) {
		try {
			const response = await this.request({ auth: true }).get(`users/${userId}/rounds?with_tournament=true`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getMessagesRound ({ tournamentId, roundId }) {
		try {
			const response = await this.request({ auth: true }).get(`tournaments/${tournamentId}/matches/${roundId}/messages`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getMessagesTeam ({ roomId }) {
		try {
			const response = await this.request({ auth: true }).get(`chat/${roomId}?limit=15`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async sendMessage ({ tournamentId, roundId, message }) {
		try {
			const response = await this.request({ auth: true }).post(`tournaments/${tournamentId}/matches/${roundId}/messages`, message)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

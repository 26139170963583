<template>
	<div class="ui-checkbox component">
		<input
			:id="labelId"
			:checked="checked"
			v-on="listeners"
			type="checkbox">
		<label :for="labelId">
			<span class="placeholder">
				<UiBaseIcon class="ok" width="8px" iconName="done"/>
			</span>

			{{ label }}
		</label>
	</div>
</template>

<script>
	import UiBaseIcon from '../components/icons/UiBaseIcon'

	export default {
		name: 'UiCheckbox',

		props: {
			label: {
				type: String,
				required: true
			},
			checked: {
				type: Boolean,
				required: true
			}, // v-model
			value: { type: [String, Number, Boolean] }
		},

		model: {
			prop: 'checked',
			event: 'change'
		},

		components: {
			UiBaseIcon
		},

		methods: {
			getRandomInt () {
				const min = 1
				const max = 1000000000
				return Math.floor(Math.random() * (max - min + 1) + min)
			}
		},

		computed: {
			labelId () {
				return `inputId${this.getRandomInt()}`
			},
			listeners () {
				return {
					...this.$listeners,
					change: event => this.$emit('change', event.target.checked)
				}
			}
		}

	}
</script>

<style lang="scss" scoped>
	.ui-checkbox.component {
		width: 100%;
		cursor: pointer;
		display: inline-block;
		color: white;

		input {
			display: none;
		}

		label {
			display: flex;
			align-items: center;
			cursor: pointer;
			.placeholder {
				margin-right: 10px;
				position: relative;
				width: 15px;
				min-width: 15px;
				height: 15px;
				cursor: pointer;
				display: inline-block;
				border: 1px solid #bebebe;
				border-radius: 3px;
				.ok {
					opacity: 0;
					position: absolute;
					left: 3px;
					bottom: -4px;
					pointer-events: none;
				}
			}
		}
		input:checked + label {
			.placeholder {
				border: 1px solid orange;
				background-color: orange;
				.ok {
					opacity: 1;
				}
			}
		}
	}
</style>

<template>
	<div class="ui-brackets component" v-if="brackets">
		<UiBracketsRounds :rounds="getRounds()"/>
		<div class="ui-brackets__wrapper" :class="{'with__third-match': thirdMatch}">
			<UiBracketNode :data="brackets"/>
			<UiBracketItem class="third__match" :data="thirdMatch" v-if="thirdMatch"></UiBracketItem>
		</div>
	</div>
</template>

<script>
	import UiBracketNode from './UiBracketNode'
	import UiBracketItem from './UiBracketItem'
	import UiBracketsRounds from './UiBracketsRounds'
	export default {
		components: {
			UiBracketNode,
			UiBracketsRounds,
			UiBracketItem
		},
		props: {
			matches: { type: Array }
		},
		mounted () {
		},
		computed: {
			thirdMatch () {
				return this.matches.find(item => item.isThirdMatch)
			},
			brackets () {
				if (!this.matches) return null

				const rounds = this.groupByRounds
				const allRounds = () => {
					let matches = []
					for (const item in rounds) {
						matches.push(...rounds[item].games)
					}
					matches = matches.map(item => {
						return {
							...item,
							games: []
						}
					})
					return matches
				}

				const findRecursiveMatches = (id) => {
					const result = []
					const find = allRounds().filter(item => item.nextMatchId === id)
					for (const item in find) {
						find[item].games = findRecursiveMatches(find[item].id)
						result.push(find[item])
					}
					return result
				}
				if (!rounds[rounds.length - 1]) return null
				const parentMatch = rounds[rounds.length - 1].games[0]
				parentMatch.isFinal = true
				parentMatch.games = findRecursiveMatches(parentMatch.id)
				return parentMatch ?? null
			},
			groupByRounds () {
				const matches = JSON.parse(JSON.stringify(this.matches))
				const brackets = []

				matches.sort((a, b) => a.id - b.id)

				matches.forEach((item) => {
					const roundIsSet = typeof brackets[item.round - 1] !== 'undefined'
					const newItem = Object.assign(item)

					if (!roundIsSet) {
						brackets[newItem.round - 1] = { games: [] }
					}

					if (newItem.first_participant) {
						newItem.first_participant.result = ''
					}

					if (newItem.second_participant) {
						newItem.second_participant.result = ''
					}

					brackets[newItem.round - 1].games.push(newItem)
				})

				return brackets
			}
		},
		methods: {
			getRounds () {
				const rounds = []
				const groupedRounds = this.groupByRounds

				if (!Object.keys(groupedRounds).length) return []

				Object.keys(groupedRounds).forEach(index => {
					const firstMatch = groupedRounds[index].games[0]
					rounds.push({
						round: firstMatch.round,
						startedAt: firstMatch.startedAt
					})
				})

				return rounds
			}
		}
	}
</script>

<style lang="scss">
	$items-space-between: 20px;

	.ui-brackets {
		width: 100%;
		overflow-x: auto;

		&::-webkit-scrollbar-track {
			background-color: $color-gray-600;
			-webkit-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 36px;
		}

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			background-color: $color-gray-600;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 36px;
			-webkit-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			background-color: $primary-color;
		}

		&__wrapper {
			position: relative;
			display: flex;
			&.with__third-match {
				width: fit-content;
				position: relative;
				.third__match {
					position: absolute;
					top: calc(50% + 115px);
					right: 0;
				}
			}
		}

		&-item-parent {
			position: relative;
			margin-left: $items-space-between;
			display: flex;
			align-items: center;
		}

		&-item-children {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&-item-child {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			margin-top: 10px;
			margin-bottom: 10px;
			position: relative;
		}

		&-item-parent:after {
			content: "";
			position: absolute;
			width: calc(#{$items-space-between + 12px} / 2);
			height: 1px;
			left: 5px;
			top: 50%;
			background-color: $color-gray-500;
			transform: translate(-100%, -1px);
		}

		&-item-child:before {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(100%, -1px);
			width: calc(#{$items-space-between} / 2);
			height: 1px;
			border-top: 1px solid $color-gray-500;
		}

		&-item-child {
			&:after {
				content: "";
				position: absolute;
				right: calc(-#{$items-space-between + 10px} / 2);
				height: calc(50% + 10px);
				width: 7px;
				top: 50%;
				border-right: 1px solid $color-gray-500;
				background: $color-body;
			}

			&:first-child:after {
				border-top: 1px solid $color-gray-500;
				border-top-right-radius: 5px;
				margin-top: -1px;
			}

			&:last-child:after {
				border-bottom: 1px solid $color-gray-500;
				border-bottom-right-radius: 5px;
			}
		}

		&-item-child:last-child:after {
			transform: translateY(-100%);
		}

		&-item-child:only-child:after {
			display: none;
		}
	}

	@media screen and (max-width: 768px) {
		.ui-brackets {
			padding-right: 20px;
		}
	}
</style>

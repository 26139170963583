import Centrifuge from 'centrifuge'
import { mapGetters } from 'vuex'
import { AuthService } from '../services/auth.service'
export default {
	methods: {
		subscribeObserver: function (channel, callback) {
			this.$root.centrifugeInstance.subscribe(channel, (data) => callback(data))
		},
		async reconnectToCentrifuge () {
			await AuthService.getWsToken()
			await this.connectToCentrifuge()
		},
		async connectToCentrifuge () {
			const handler = () => {
					this.$root.centrifugeInstance = new Centrifuge(process.env.VUE_APP_CENTRIFUGE_HOST, {
						refreshEndpoint: `${process.env.VUE_APP_API_URL}/users/${this.$currentUser.id}/ws-token`
					})
				this.$root.centrifugeInstance.setToken(this.wsToken)
				this.$root.centrifugeInstance.connect()
			}
			if (!this.wsToken) {
				await AuthService.getWsToken()
				handler()
				return true
			}
			handler()
			return true
		}
	},
	computed: {
		...mapGetters({
			wsToken: 'user/wsToken'
		}),
		$currentGame () {
			return this.$store.getters['user/getCurrentGame']
		}
	}
}

import { Http } from './http.init'

export class TermsService {
	static async setTermByCountry (id) {
		await this.getTermByCountry(id).then(response => {
			const data = response.data.data
			this.saveTerm(data)
		})
	}

	static async saveTerm (data) {
		const term = {
			content: data.content,
			title: data.title,
			country_id: data.country.id,
			version: data.version
		}
		localStorage.setItem('term', JSON.stringify(term))
	}

	static async getTermByCountry (id) {
		return new Http({ auth: false }).get(`${process.env.VUE_APP_API_URL}/terms/${id}/latest`).then(async (response) => {
			return response
		})
	}
}

<template>
	<div class="privacy-policy" v-if="visible">
		<div class="body" v-if="termInstance">
			<h1>{{ termInstance.title }}</h1>
			<div class="text" v-html="termInstance.content">
				{{ termInstance.content }}
			</div>
			<div class="controls">
				<div class="checkbox">
					<input id="agreeTerms" v-model="agreeTerms" type="checkbox">
					<label for="agreeTerms">
						<span class="square"></span>
						<span class="name">I have read and understood the rules</span>
					</label>
				</div>
				<div class="checkbox" style="margin-top:10px">
					<input id="agreeProfiling" v-model="agreeProfiling" type="checkbox">
					<label for="agreeProfiling">
						<span class="square"></span>
						<span class="name">I accept user profiling conditions</span>
					</label>
				</div>
				<div class="buttons">
					<UiButton type="secondary" @click="logout">reject</UiButton>
					<UiButton type="primary" @click="accept" :disabled="!agreeTerms">accept</UiButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import { TermsService } from '../../services/terms.service'
import { mapGetters } from 'vuex'
import { AuthService } from '../../services/auth.service'
import { UsersService } from '../../services/users.service'

export default {
	data () {
		return {
			agreeTerms: false,
			agreeProfiling: false,
			visible: false,
			termInstance: null
		}
	},
	components: {
		UiButton
	},
	computed: {
		...mapGetters({
			user: 'user/getCurrentUser'
		})
	},
	methods: {
		async accept () {
			await TermsService.saveTerm(this.termInstance)
			if (this.agreeProfiling) {
				await UsersService.updateUser({ accepted_profiling: true }, this.user.id)

				// set user accepted profiling to true in localstorage at "currentUser"
				const user = JSON.parse(localStorage.getItem('currentUser'))
				user.accepted_profiling = true
				localStorage.setItem('currentUser', JSON.stringify(user))
			}
			this.visible = false
		},
		async logout () {
			this.amplitude.logEvent('logout')
			await AuthService.makeLogout()
		},
		getTerm () {
			const countryId = () => {
				if (this.term) {
					return this.term.country_id
				}
				return this.user.country_id ?? null
			}
			TermsService.getTermByCountry(countryId()).then(response => {
				const term = localStorage.getItem('term') ? JSON.parse(localStorage.getItem('term')) : null
				if (!this.user.accepted_profiling || !term || response.data.data.version !== term.version) {
					this.visible = true
					this.termInstance = response.data.data
				}
			})
		}
	},
	mounted () {
		this.getTerm()
	}

}
</script>
<style scoped lang="scss">
.privacy-policy {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0,0,0, 0.6);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	.body {
		display: flex;
		background: #2F2F2F;
		width: 575px;
		border-radius: 2px;
		flex-direction: column;
		padding: 25px;
		height: 80vh;
		.text {
			padding-bottom: 20px;
			flex-grow: 1;
			overflow: hidden;
			overflow-y: auto;
			padding-top: 0;
			margin-top: 25px;
			&::-webkit-scrollbar {
				width: 4px;
			}
			p {
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #FFFFFF;
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				background-color: #00E7DF;
			}
		}
		.controls {
			padding: 25px;
			padding-top: 16px;
			margin: 0 -25px;
			border-top: 1px solid #505050;
			padding-bottom: 0;
			.checkbox {
				input {
					display: none;
				}
				label {
					cursor: pointer;
					display: flex;
					align-items: center;
					.name {
						font-size: 14px;
						line-height: 16px;
						letter-spacing: 0.4px;
						color: #E0E0E0;
					}
					.square {
						transition: 0.2s;
						width: 16px;
						display: block;
						height: 16px;
						background: #2F2F2F;
						border: 1px solid #505050;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
						border-radius: 4px;
						margin-right: 10px;
					}
				}
				input:checked + label {
					.square {
						background: #00E7DF;
					}
				}
			}
			.buttons {
				margin-top: 16px;
				display: flex;
				grid-gap: 20px;
				button {
					flex: 1;
					&.button-secondary {
						color: #B7B7B7;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}
}
</style>

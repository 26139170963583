<template>
	<div class="mobile__header">
		<div class="mobile__header-body">
			<div class="mobile__header-left">
				<a @click.prevent.stop="showMobileMenu">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="4.66667" height="4.66667" rx="2.33333" fill="#BDBDBD"/>
						<rect y="9.33301" width="4.66667" height="4.66667" rx="2.33333" fill="#BDBDBD"/>
						<rect x="9.3335" y="9.22168" width="4.66667" height="4.66667" rx="2.33333" fill="#BDBDBD"/>
						<rect x="9.3335" width="4.66667" height="4.66667" rx="2.33333" fill="#BDBDBD"/>
					</svg>
				</a>
				<a @click.prevent="showGames">
					<svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.5 0H5.5C2.44062 0 0 2.46729 0 5.48287C0 8.53271 2.44062 10.9657 5.5 10.9657C7.0125 10.9657 8.42188 10.3489 9.41875 9.32087H12.5469C13.5438 10.3489 14.9531 11 16.5 11C19.525 11 22 8.53271 22 5.51713C22 2.46729 19.525 0.0342679 16.5 0ZM8.525 5.89408C8.525 6.13396 8.31875 6.3053 8.1125 6.3053H6.325V8.08723C6.325 8.3271 6.11875 8.49844 5.9125 8.49844H5.0875C4.84688 8.49844 4.675 8.3271 4.675 8.08723V6.3053H2.8875C2.64688 6.3053 2.475 6.13396 2.475 5.89408V5.07165C2.475 4.86604 2.64688 4.66044 2.8875 4.66044H4.675V2.8785C4.675 2.6729 4.84688 2.46729 5.0875 2.46729H5.9125C6.11875 2.46729 6.325 2.6729 6.325 2.8785V4.66044H8.1125C8.31875 4.66044 8.525 4.86604 8.525 5.07165V5.89408ZM15.95 8.49844C15.1594 8.49844 14.575 7.91589 14.575 7.12773C14.575 6.37383 15.1594 5.75701 15.95 5.75701C16.7063 5.75701 17.325 6.37383 17.325 7.12773C17.325 7.91589 16.7063 8.49844 15.95 8.49844ZM18.15 5.20872C17.3594 5.20872 16.775 4.62617 16.775 3.83801C16.775 3.08411 17.3594 2.46729 18.15 2.46729C18.9062 2.46729 19.525 3.08411 19.525 3.83801C19.525 4.62617 18.9062 5.20872 18.15 5.20872Z" fill="#BDBDBD"/>
					</svg>
				</a>
			</div>
			<router-link to="/" class="mobile__header-logo">
				<img :src="logo" alt="">
			</router-link>
			<div class="mobile__header-right">
				<NotificationsDropdown></NotificationsDropdown>
				<router-link :to="{ path: '/mobile-chat' }">
					<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.6875 3.96875C14.9688 4.53125 14.0625 5.21875 10.875 7.53125C10.25 8 9.09375 9.03125 8 9.03125C6.875 9.03125 5.75 8 5.09375 7.53125C1.90625 5.21875 1 4.53125 0.28125 3.96875C0.15625 3.875 0 3.96875 0 4.125V10.5C0 11.3438 0.65625 12 1.5 12H14.5C15.3125 12 16 11.3438 16 10.5V4.125C16 3.96875 15.8125 3.875 15.6875 3.96875ZM8 8C8.71875 8.03125 9.75 7.09375 10.2812 6.71875C14.4375 3.71875 14.75 3.4375 15.6875 2.6875C15.875 2.5625 16 2.34375 16 2.09375V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V2.09375C0 2.34375 0.09375 2.5625 0.28125 2.6875C1.21875 3.4375 1.53125 3.71875 5.6875 6.71875C6.21875 7.09375 7.25 8.03125 8 8Z" fill="#BDBDBD"/>
					</svg>
				</router-link>
			</div>
		</div>
		<MobileHeaderGames @close="closeGames" v-if="openGames"></MobileHeaderGames>
		<MobileMenu @close="openMobileMenu = false" v-if="openMobileMenu"></MobileMenu>
	</div>
</template>
<script>
import NotificationsDropdown from '../../NotificationsDropdown'
import MobileHeaderGames from './MobileHeaderGames'
import MobileMenu from './MobileMenu'
export default {
	data () {
		return {
			openGames: false,
			openMobileMenu: false,
			logo: require('@/assets/logo.svg')
		}
	},
	methods: {
		showMobileMenu () {
			this.openMobileMenu = true
			this.openGames = false
		},
		showGames () {
			this.openMobileMenu = false
			this.openGames = true
		},
		closeGames () {
			this.openMobileMenu = false
			this.openGames = false
			this.$router.push({ path: '/' })
		},
		openMobileChat () {
			this.openGames = false
			this.openMobileMenu = false
		}
	},
	watch: {
		$route: function () {
			this.openGames = false
			this.openMobileMenu = false
		}
	},
	components: {
		MobileMenu,
		MobileHeaderGames,
		NotificationsDropdown
	}
}
</script>
<style scoped lang="scss">
	.mobile__header {
		width: 100%;
		.mobile__header-body {
			height: 60px;
			background: #232323;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			display: flex;
			position: relative;
			z-index: 9999;
			justify-content: space-between;
			border-bottom: 1px solid #2F2F2F;
			.mobile__header-left {
				display: flex;
			}
			a:not(.mobile__header-logo) {
				width: 60px;
				border-right: 1px solid #2F2F2F;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid transparent;
				transition: 0.2s;
				svg {
					path, rect {
						transition: 0.2s;
					}
				}
				&:hover, &:focus, &.active {
					border-bottom: 1px solid #00E7DF;
					svg {
						path, rect {
							fill: #00E7DF
						}
					}
				}
			}
			.mobile__header-logo {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.mobile__header-right {
				display: flex;
				a {
					width: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

	}
</style>

<template>
	<div class="ui-brackets-item component">
		<div :class="nodeClasses()">
			<UiBracketItem :data="data"/>
		</div>
		<div v-if="data.games[0] || data.games[1]" class="ui-brackets-item-children">
			<div class="ui-brackets-item-child" v-if="data.games[0]">

				<UiBracketNode :data="data.games[0]"/>
			</div>
			<div class="ui-brackets-item-child" v-if="data.games[1]">
				<UiBracketNode :data="data.games[1]"/>
			</div>
		</div>
	</div>
</template>

<script>
	import UiBracketItem from './UiBracketItem'

	export default {
		name: 'UiBracketNode',
		components: {
			UiBracketItem
		},
		props: {
			data: Object
		},
		methods: {
			nodeClasses () {
				const classes = []
				const node = this.data
				const isParent = node.games[0] || node.games[1]
				const hasParent = node.hasParent

				if (isParent) {
					classes.push('ui-brackets-item-parent')
					return classes.join(' ')
				}

				if (hasParent) classes.push('ui-brackets-item-child')

				return classes.join(' ')
			}
		}
	}
</script>

<template>
  <div class="ui-page-top-bar component">
    <div class="ui-page-top-bar__bg" v-if="backgroundImgUrl" :style="{ backgroundImage: `url(${backgroundImgUrl})` }"></div>
    <div class="ui-page-top-bar__back" v-if="withPrevButton && !isMobile">
      <a href="#" @click="goToPrev">
        <UiBaseIcon
          width="13px"
          height="16px"
          iconName="arrow-left"
        />
      </a>
    </div>
    <div class="ui-page-top-bar__title">
      <UiBreadcrumbs/>
      <h1>{{ title }}</h1>
    </div>
    <div class="ui-page-top-bar__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import UiBaseIcon from './icons/UiBaseIcon'
import UiBreadcrumbs from './UiBreadcrumbs'
import { mapGetters } from 'vuex'

export default {
  name: 'UiPageTopBar',
  components: {
    UiBreadcrumbs,
    UiBaseIcon
  },
  props: {
    backgroundImgUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    withPrevButton: {
      type: Boolean,
      default: false
    }
  },
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		})
	},
  methods: {
    goToPrev () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.ui-page-top-bar {
  position: relative;
  display: flex;
  border-bottom: 1px solid $color-gray-600;
	max-width: 100%;
	overflow: hidden;

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 0;
    mix-blend-mode: color-dodge;
    opacity: 0.5;
  }

  &__title {
    flex: 1 0 auto;
    padding: 20px 25px 30px 25px;
    z-index: 1;

    h1 {
      margin-top: 9px;
    }
  }

  &__back {
    z-index: 1;
    a {
      display: flex;
      align-items: center;
      padding-left: 13px;
      padding-right: 13px;
      height: 100%;
      color: $color-white;

      border-right: 1px solid $color-gray-600;

      @include base-transition;

      @include hover-state {
        color: $primary-color;
      }

      @include focus-state {
        color: $color-white;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
	.ui-page-top-bar {
		&__title {
			h1 {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.ui-page-top-bar {
		&__title {
			padding: 11px 16px;
			h1 {
				font-size: 18px;
				line-height: 28px;
				margin-top: 0;
			}
		}
	}
}
</style>

<template>
	<div class="team page">
		<UiPageTopBar :title="team.name ? team.name : 'Team'">
			<div class="team__item-controls">
				<template v-if="iCaptain">
					<button class="edit__team" @click="editMode = !editMode">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.6101 3.89225C14.13 3.37237 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.419 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.419V11.581Z" fill="#121212"/>
						</svg>
						<template v-if="!isMobile">
							<template v-if="!editMode">
								Edit Team
							</template>
							<template v-else>
								Exit
							</template>
						</template>
						<template v-else-if="editMode">Exit</template>
						<template v-else-if="!editMode">Edit</template>
					</button>
				</template>
			</div>
		</UiPageTopBar>
		<div class="shell">
			<div class="team__content" v-if="team">
				<template v-if="!isMobile">
					<div class="team__content-viewport">
						<TeamInfo
							:team="team"
							@update="getTeam"
							:editMode="editMode"
						></TeamInfo>
						<div class="team__content-viewport-list">
							<TeamPlayer v-for="(player) in getMates"
													:teamId="team.id"
													:captainId="captainId"
													:key="player.id"
													@remove="removePlayerFromTeam"
													:player="player"
													:editMode="editMode"
													@update="getTeam"
							></TeamPlayer>
						</div>
					</div>
					<div class="team__content-sidebar">
						<TeamStats
							:team="team"
							@update="getTeam"
						></TeamStats>
					</div>
				</template>
				<template v-else>
					<div class="team__content-viewport">
						<TeamInfo
							:team="team"
							@update="getTeam"
							:editMode="editMode"
						></TeamInfo>
						<UiTabs>
							<UiTab title="Team positions">
								<div class="team__content-sidebar">
									<TeamStats
										:team="team"
										@update="getTeam"
									></TeamStats>
								</div>
							</UiTab>
							<UiTab title="Members">
								<div class="team__content-viewport-list">
									<TeamPlayer v-for="(player,i) in getMates"
															:teamId="team.id"
															:captainId="captainId"
															:key="i"
															:editMode="editMode"
															@remove="removePlayerFromTeam"
															:player="player"
															@update="getTeam"
									></TeamPlayer>
								</div>
							</UiTab>
						</UiTabs>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import UiTabs from '@/components/UiTabs'
import UiTab from '@/components/UiTab'
import UiPageTopBar from '../../components/UiPageTopBar'
import TeamStats from '@/components/teams/TeamStats'
import { TeamsService } from '../../services/teams.service'
import TeamPlayer from '@/components/teams/TeamPlayer'
import TeamInfo from '@/components/teams/TeamInfo'
import { mapGetters } from 'vuex'
export default {
	props: {
		id: {
			required: true
		}
	},
	data () {
		return {
			editMode: false,
			invites: [],
			team: null
		}
	},
	components: {
		UiTabs,
		UiTab,
		TeamStats,
		UiPageTopBar,
		TeamPlayer,
		TeamInfo
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		}),
		iCaptain () {
			return this.captainId === this.$currentUser.id
		},
		captainId () {
			const find = this.getMates.find(item => item.role === 'captain')
			return find ? find.user_id : null
		},
		getMates () {
			const mates = this.team.team_mates
			if (!mates) {
				return []
			}

			return mates.sort((x, y) => {
				return (x.role === y.role) ? 0 : x ? -1 : 1
			})
		}
	},
	methods: {
		removePlayerFromTeam (id) {
			const index = this.team.team_mates.findIndex(item => item.id === id)
			if (index > -1) {
				this.team.team_mates.splice(index, 1)
			}
		},
		getTeam () {
				TeamsService.getTeam(this.id).then(response => {
					this.team = response.data.data
				})
		}
	},
	mounted () {
		this.amplitude.logEvent('screen_view', {
			screen_name: 'Team Details'
		})
		this.getTeam()
	}
}
</script>
<style scoped lang="scss">
	.team {
		.team__item-controls {
			align-self: center;
			padding-right: 16px;
			.edit__team {
				font-weight: bold;
				background: #00E7DF;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
				padding: 3px 13px;
				white-space: nowrap;
				font-size: 14px;
				line-height: 30px;
				align-items: center;
				display: flex;
				text-align: center;
				letter-spacing: 0.3px;
				cursor: pointer;
				text-transform: uppercase;
				border: 1px solid #505050;
				box-sizing: border-box;
				border-radius: 5px;
				transition: 0.2s;
				&:hover {
					border-color: #505050;
				}
				svg {
					margin-right: 10px;
					margin-bottom: 2px;
				}
			}
		}
		.team__content {
			margin-top: 40px;
			display: flex;
			.team__content-viewport {
				flex-grow: 1;
				overflow: hidden;
				margin-right: 20px;
				padding-bottom: 200px;
				::v-deep {
					.tabs {
						ul {
							flex-direction: row;
						}
					}
				}
			}
			.team__content-sidebar {
				width: 377px;
				min-width: 377px;
			}
		}
	}

	@media screen and (max-width: 992px) {
		.team {
			.team__content {
				flex-wrap: wrap;
				.team__content-viewport {
					width: 100%;
					margin-right: 0;
				}
				.team__content-sidebar {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		.team {
			margin-top: 0;
			.team__content {
				.team__content-sidebar {
					min-width: 100%;
				}
				margin-top: 16px;
			}
		}
	}

	@media screen and (max-width: 575px) {
		::v-deep {
			.ui-page-top-bar__title {
				overflow: hidden;
				max-width: 70%;
				h1 {
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}
</style>

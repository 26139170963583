<template>
	<div class="ui-brackets-rounds component">
		<div class="ui-brackets-rounds__list">
			<div
				v-for="(item, index) in rounds"
				class="ui-brackets-rounds__item"
				:class="{ 'is-active': isActiveRound(item) }"
				:key="index"
			>
				<span>{{ $t('Round') }} {{ item.round }}/{{ rounds.length }}</span>
				<span>
					<UiBaseIcon
						iconName="crown"
						width="15"
						height="14"
					/>
					<span>B01</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import Moment from 'moment'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'

	export default {
		name: 'UiBracketsRounds',
		components: {
			UiBaseIcon
		},
		props: {
			rounds: Array
		},
		methods: {
			isActiveRound (round) {
				const moment = new Moment()
				return moment.isAfter(round.startedAt)
			}
		}
	}
</script>

<style lang="scss">
	.ui-brackets-rounds {
		margin-bottom: 20px;

		&__list {
			display: flex;
			align-items: center;
		}

		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 278px;
			padding: 11px 25px;
			background-color: $color-gray-700;
			border: 1px solid $color-gray-600;
			border-radius: 5px;

			+ * {
				margin-left: 20px;
			}

			.ui-icon-base {
				color: $primary-color;
				margin-right: 5px;
			}

			> span {
				&:first-child {
					font-weight: 700;
					font-size: 14px;
					line-height: 28px;
					letter-spacing: 0.15px;
					text-transform: uppercase;
					color: $color-gray-400;
				}

				&:last-child {
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.25px;
					color: $color-gray-300;

					> * {
						vertical-align: middle;
					}
				}
			}

			&.is-active {
				background: $color-gray-600;
				border: 1px solid $primary-color;

				> span {
					&:first-child {
						color: $color-white;
					}
				}
			}
		}
	}
</style>

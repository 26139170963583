import { render, staticRenderFns } from "./ResultTournamentModal.vue?vue&type=template&id=e897026e&scoped=true&"
import script from "./ResultTournamentModal.vue?vue&type=script&lang=js&"
export * from "./ResultTournamentModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e897026e",
  null
  
)

export default component.exports
import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
export class EmmitService extends BaseService {
	static async emmitInChatEvent (data) {
		try {
			const response = await this.request({ auth: true }).post('emmit/chat', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

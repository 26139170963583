<template>
	<div class="modal create-team-modal">
		<div class="modal__body">
			<div @click="$emit('close')" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<h1 v-if="!team">Create the team</h1>
			<h1 v-else>Edit the team</h1>

			<div class="create-team-modal__form">
				<div class="input__block" :class="{ 'is-invalid': $v.form.name.$error, 'is-correct': $v.form.name.$dirty && !$v.form.name.$error }">
					<div class="input__block-icon">
						<img src="@/assets/swords-white.svg" alt="">
					</div>
					<input type="text" v-model="$v.form.name.$model" placeholder="Team name">
				</div>
				<div class="input__block" v-if="!team" :class="{ 'is-invalid': $v.form.participants.$error, 'is-correct': $v.form.participants.$dirty && !$v.form.participants.$error }">
					<div class="input__block-icon">
						<img src="@/assets/groups.svg" alt="">
					</div>
					<v-select v-model="$v.form.participants.$model" :placeholder="'Team size'" :clearable="false" :searchable="false" :options="currentGame.participantNumber" label="name"></v-select>
				</div>
				<input type="file" @change="addAvatar" accept="image/*" id="photo">
				<label for="photo" class="upload__area">
					<div class="upload__area-circle">
						<svg v-if="!previewAvatar" width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1813 24.817C16.2267 23.8051 15.7495 22.5655 15.7495 21.0982C15.7495 19.631 16.2519 18.3914 17.2566 17.3795C18.2614 16.3676 19.4671 15.8616 20.8737 15.8616C22.3306 15.8616 23.5614 16.3676 24.5662 17.3795C25.5709 18.3914 26.0733 19.631 26.0733 21.0982C26.0733 22.5149 25.5709 23.7292 24.5662 24.7411C23.5614 25.753 22.3306 26.2589 20.8737 26.2589C19.4168 26.2589 18.186 25.7783 17.1813 24.817ZM15.222 26.7902C16.7794 28.3586 18.6633 29.1429 20.8737 29.1429C23.0842 29.1429 24.9681 28.3586 26.5255 26.7902C28.1331 25.2217 28.9369 23.3244 28.9369 21.0982C28.9369 18.872 28.1331 16.9747 26.5255 15.4062C24.9681 13.7872 23.0842 12.9777 20.8737 12.9777C18.6633 12.9777 16.7794 13.7872 15.222 15.4062C13.6646 16.9747 12.8859 18.872 12.8859 21.0982C12.8859 23.3244 13.6646 25.2217 15.222 26.7902ZM9.64562 14.5714V9.71429H14.4684V4.85714H25.6965L28.6354 8.12054H33.7597C34.6137 8.12054 35.3673 8.4494 36.0204 9.10714C36.6735 9.76488 37 10.5238 37 11.3839V30.8125C37 31.6726 36.6735 32.4315 36.0204 33.0893C35.3673 33.6964 34.6137 34 33.7597 34H8.06314C7.2091 34 6.45553 33.6964 5.80244 33.0893C5.14935 32.4315 4.82281 31.6726 4.82281 30.8125V14.5714H9.64562ZM4.82281 4.85714V0H8.06314V4.85714H12.8859V8.12054H8.06314V12.9777H4.82281V8.12054H0V4.85714H4.82281Z" fill="#8F8F8F"/>
						</svg>
						<img :src="previewAvatar" v-else alt="">
					</div>
					<div class="upload__area-title">
						Upload team avatar
					</div>
				</label>

				<div class="textarea" :class="{ 'is-invalid': $v.form.description.$error, 'is-correct': $v.form.description.$dirty && !$v.form.description.$error }">
					<textarea name="description" id="" cols="30" rows="10" v-model="$v.form.description.$model" placeholder="Team description"></textarea>
				</div>

				<button class="btn__create" v-if="!team" @click="create">
					<img src="@/assets/swords.svg" alt="">
					Create team
					<UiLoader v-if="loader"></UiLoader>
				</button>
				<button class="btn__create" v-else @click="edit">
					<img src="@/assets/swords.svg" alt="">
					Edit team
					<UiLoader v-if="loader"></UiLoader>
				</button>

			</div>
		</div>
	</div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { mapGetters, mapActions } from 'vuex'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import UiLoader from '@/components/UiLoader'
export default {
	props: {
		team: {
			type: Object
		}
	},
	data () {
		return {
			previewAvatar: null,
			loader: false,
			form: {
				participants: null,
				description: null,
				name: null,
				avatar: null
			}
		}
	},
	mixins: [validationMixin],
	validations: {
		form: {
			description: {
				minLength: minLength(3),
				maxLength: maxLength(15500)
			},
			participants: {
				required,
				numeric,
				isAvailable (participant) {
					return Number(participant) >= 1 && Number(participant) < 12
				}
			},
			name: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(255)
			}
		}
	},
	components: {
		UiLoader
	},
	mounted () {
		if (this.team) {
			this.initialTeamData()
		}
	},
	methods: {
		...mapActions({
			createTeam: 'user/createTeam',
			editTeam: 'user/editTeam'
		}),
		initialTeamData () {
			this.previewAvatar = this.team.logo
			this.form.name = this.team.name
			this.form.description = this.team.description
		},
		async edit () {
			const formData = new FormData()
			formData.append('name', this.form.name)
			if (this.form.description) {
				formData.append('description', this.form.description)
			}
			formData.append('_method', 'PUT')
			if (this.form.avatar instanceof File) {
				formData.append('logo', this.form.avatar)
			}
			this.loader = true
			await this.editTeam({ data: formData, id: this.team.id })
				.then(response => {
					this.amplitude.logEvent('screen_view', {
						screen_name: 'Team Manage'
					})
					this.$emit('update', response.data.data)
				})
				.finally(() => {
				this.loader = false
				this.$emit('close')
			})
		},
		async create () {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return
			}
			const formData = new FormData()
			formData.append('name', this.form.name)
			if (this.form.description) {
				formData.append('description', this.form.description)
			}
			formData.append('max_players', this.form.participants)
			formData.append('game_id', this.$currentGame.id)
			formData.append('platform_id', this.$currentGame.platformId)
			if (this.form.avatar instanceof File) {
				formData.append('logo', this.form.avatar)
			}
			this.loader = true
			await this.createTeam(formData).then(() => {
				this.amplitude.logEvent('screen_view', {
					screen_name: 'Team Manage'
				})
			}).finally(() => {
				this.loader = false
				this.$emit('close')
			})
		},
		addAvatar ($event) {
			this.form.avatar = $event.target.files[0]
			const reader = new FileReader()
			reader.onload = (e) => {
				this.previewAvatar = e.target.result
			}
			reader.readAsDataURL(this.form.avatar)
		}
	},
	computed: {
		...mapGetters({
			currentGame: 'user/getCurrentGame'
		})
	}
}
</script>
<style scoped lang="scss">
.create-team-modal {
	.modal__body {
		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 38px;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.create-team-modal__form {
			input[type="file"] {
				display: none;
			}
			margin-top: 40px;
			.input__block {
				padding: 0 21px;
				padding-right: 0;
				height: 50px;
				display: flex;
				border: 1px solid #505050;
				border-radius: 5px;
				align-items: center;
				.input__block-icon {
					display: flex;
					align-items: center;
					margin-right: 20px;
					width: 20px;
					justify-content: center;
				}
				input {
					flex-grow: 1;
					overflow: hidden;
					outline: none;
					height: 100%;
					padding-bottom: 2px;
					font-size: 10px;
					line-height: 20px;
					letter-spacing: 2px;
					text-transform: uppercase;
					padding-right: 21px;
					color: #FFFFFF;
					background: transparent;
					border: none;
					&::placeholder {
						color: #FFFFFF;
					}
				}
				::v-deep {
					.v-select {
						flex-grow: 1;
						height: 100%;
						padding-right: 21px;
						.vs__dropdown-toggle {
							padding: 0;
							height: 100%;
							margin: 0;
							border: none;
							* {
								border: none !important;
							}
							.vs__selected-options {
								.vs__selected {
									margin: 0;
									padding: 0;
									font-size: 10px;
									line-height: 20px;
									letter-spacing: 2px;
									position: static !important;
									text-transform: uppercase;
									color: #FFFFFF;
								}
								.vs__search {
									border: none !important;
									font-size: 10px;
									line-height: 20px;
									letter-spacing: 2px;
									text-transform: uppercase;
									color: #FFFFFF;
								}
							}
							.vs__actions {
								svg {
									path {
										fill: #00E7DF
									}
								}
							}
						}
						ul {
							background: #2F2F2F;
							box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
							li {
								color: #fff;
								transition: 0.2s;
								padding: 10px 15px;
								&.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
									background: #00E7DF;
								}
								&:hover {
									background: #00E7DF;
								}
							}
						}

					}

				}
				&.is-correct {
					border: 1px solid #00E7DF;
				}
				&.is-invalid {
					border: 1px solid #981d1d;
				}
			}
			.upload__area {
				margin-bottom: 36px;
				cursor: pointer;
				margin-top: 25px;
				border: 1px dashed #505050;
				border-radius: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 15px 0;
				position: relative;
				.upload__area-circle {
					width: 150px;
					height: 150px;
					border-radius: 100%;
					background: #2F2F2F;
					border: 1px solid #BDBDBD;
					display: flex;
					overflow: hidden;
					align-items: center;
					justify-content: center;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				.upload__area-title {
					position: absolute;
					font-size: 10px;
					line-height: 22px;
					text-align: center;
					padding: 0 10px;
					bottom: -12px;
					background: #2F2F2F;
					z-index: 1;
					height: 22px;
					letter-spacing: 2px;
					text-transform: uppercase;
					color: #505050;
				}

			}
			.textarea {
				&.is-correct {
					border: 1px solid #00E7DF;
				}
				&.is-invalid {
					border: 1px solid #981d1d;
				}
				border: 1px solid #505050;
				margin-bottom: 25px;

				border-radius: 5px;
				padding: 20px;
				height: 172px;

				textarea {
					height: 100%;
					background: transparent;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0.25px;
					color: #FFFFFF;
					border: none;
					outline: none;
					resize: none;
					width: 100%;
				}
			}
			.btn__create {
				background: #00E7DF;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
				border-radius: 4px;
				padding: 15px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 1px;
				text-transform: uppercase;
				width: 100%;
				cursor: pointer;
				outline: none;
				border: none;
				color: #232323;
				transition: 0.2s;
				::v-deep {
					.ui-loader {
						margin-left: 10px;
					}
				}
				img {
					position: absolute;
					left: 16px;
				}
				&:hover {
					background: #00E7DF;
					box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
				}
			}
			.input__block + .input__block {
				margin-top: 25px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.modal {
		padding-right: 10px;
		padding-left: 10px;
		.modal__body {
			max-width: 100%;
			padding: 26px 24px;
			h1 {
				font-size: 20px;
				line-height: 28px;
			}
			.create-team-modal__form {
				margin-top: 26px;
			}
		}
	}
}
</style>

<template>
	<div class="team-stats">
		<div class="team-stats__heading">
			<img src="@/assets/coup.svg" alt="">
			Team position in leaderboard
		</div>
		<div class="team-stats__statistics" v-if="leaderboard">
			<div class="team-stats__statistic">
				<div class="team-stats__statistic-label">
					position
				</div>
				<div class="team-stats__statistic-value">
					<span v-if="leaderboard.leaderboard_position === 0 || !leaderboard.leaderboard_position">-</span>
					<strong v-else>{{ leaderboard.leaderboard_position }}</strong>
					<span v-if="leaderboard.leaderboard_position === 1">st</span>
					<span v-else-if="leaderboard.leaderboard_position">nd</span>
				</div>
			</div>
			<div class="team-stats__statistic">
				<div class="team-stats__statistic-label">
					Score
				</div>
				<div class="team-stats__statistic-value">
					<strong>{{ leaderboard.points }}</strong>
				</div>
			</div>
		</div>
		<div class="team-stats__statistics" v-else>
			<div class="team-stats__statistic">
				<div class="team-stats__statistic-label">
					position
				</div>
				<div class="team-stats__statistic-value">
					<strong>-</strong>
				</div>
			</div>
			<div class="team-stats__statistic">
				<div class="team-stats__statistic-label">
					Score
				</div>
				<div class="team-stats__statistic-value">
					<strong>0</strong>
				</div>
			</div>
		</div>
		<UiButton @click="$router.push({ name: 'leaderboard' })" type="secondary" v-if="getMyProfile && !iRequest">Show leaderboard</UiButton>
		<div class="team-stats__controls">
			<button @click="sendRequest" class="send__request" v-if="!isJoined">
				<img src="@/assets/swords.svg" alt="">
				Join to the team
			</button>
			<template v-else>
				<template v-if="!iCaptain">
					<template v-if="iRequest">
						<button class="send__hasBeen" @click="cancelRequest">
							request has been sent
						</button>
					</template>
					<template v-else>
						<button class="send__hasBeen" @click="leave">
							<svg data-v-65f47e63="" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 18 14"><g><path d="M17.55 12.2475H15.3V3.10167C15.3 2.36234 14.6813 1.73254 13.95 1.73254H10.8V3.48503H13.5V14H17.55C17.775 14 18 13.8083 18 13.5619V12.6856C18 12.4666 17.775 12.2475 17.55 12.2475ZM8.775 0.0348089L3.375 1.37656C2.95313 1.48609 2.7 1.84207 2.7 2.25281V12.2475H0.45C0.196875 12.2475 0 12.4666 0 12.6856V13.5619C0 13.8083 0.196875 14 0.45 14H9.9V0.911056C9.9 0.308636 9.3375 -0.129487 8.775 0.0348089ZM7.425 7.86627C7.03125 7.86627 6.75 7.48291 6.75 6.99002C6.75 6.52452 7.03125 6.11377 7.425 6.11377C7.79063 6.11377 8.1 6.52452 8.1 6.99002C8.1 7.48291 7.79063 7.86627 7.425 7.86627Z" fill="currentColor"></path></g></svg>
							LEAVE TEAM
						</button>
					</template>
				</template>
				<template v-else>
					<button class="send__hasBeen" @click="deleteTeam">
						Delete team
					</button>

				</template>
			</template>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import { TeamsService } from '../../services/teams.service'
import { mapMutations } from 'vuex'
export default {
	data () {
		return {
			leaderboard: null
		}
	},
	props: {
		team: {
			type: Object,
			required: true
		}
	},
	computed: {
		getMyProfile () {
			const find = this.team.team_mates.find(item => item.profile.user.id === this.$currentUser.id)
			return find ?? false
		},
		isJoined () {
			if (!this.getMyProfile) {
				return false
			}
			return this.getMyProfile !== false
		},
		iRequest () {
			if (!this.isJoined) {
				return false
			}
			return !this.getMyProfile.role
		},
		iCaptain () {
			return this.captainId === this.$currentUser.id
		},
		captainId () {
			const find = this.team.team_mates.find(item => item.role === 'captain')
			return find ? find.user_id : null
		}
	},
	methods: {
		...mapMutations({
			makeError: 'toast/NEW',
			deleteTeamFromStore: 'user/DELETE_TEAM'
		}),
		deleteTeam () {
			TeamsService.delete(this.team.id).then(() => {
				this.deleteTeamFromStore(this.team.id)
				this.$router.push({ name: 'teams' })
			}).catch(error => {
				this.makeError({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
		},
		cancelRequest () {
			TeamsService.declineInvite(this.team.id, this.getMyProfile.id).then(() => {
				this.$emit('update')
			}).catch(error => {
				this.makeError({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
		},
		leave () {
			TeamsService.declineInvite(this.team.id, this.getMyProfile.id).then(() => {
				this.$emit('update')
			}).catch(error => {
				this.makeError({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
		},
		sendRequest () {
			TeamsService.sendRequest(this.team.id).then(() => {
				this.$emit('update')
			}).catch(error => {
				this.makeError({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
		},
		getLeaderboard () {
			TeamsService.getLeaderboardTeam(this.team.id, this.team.platform_id).then(response => {
				this.leaderboard = response.data[0]
			})
		}
	},
	mounted () {
		this.getLeaderboard()
	},
	components: {
		UiButton
	}
}
</script>
<style scoped lang="scss">
	.team-stats {
		background: #2F2F2F;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		padding: 25px;
		.team-stats__heading {
			display: flex;
			align-items: center;
			padding-left: 11px;
			font-weight: bold;
			font-size: 18px;
			line-height: 28px;
			letter-spacing: 0.15px;
			img {
				margin-right: 10px;
			}
		}
		.team-stats__statistics {
			margin-top: 20px;
			margin-bottom: 20px;
			display: flex;
			.team-stats__statistic {
				flex: 1;
				padding-top: 14px;
				box-shadow: 0px 0px 4px 0px rgba(0,231,223, 0.7);
				padding-bottom: 17px;
				border: 1px solid #00E7DF;
				border-radius: 4px;
				.team-stats__statistic-label {
					font-weight: 500;
					font-size: 10px;
					line-height: 16px;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					color: #BDBDBD;
					margin-bottom: 9px;
					text-align: center;
					width: 100%;
				}
				.team-stats__statistic-value {
					display: flex;
					align-items: flex-end;
					justify-content: center;
					strong {
						font-weight: bold;
						font-size: 30px;
						line-height: 24px;
						letter-spacing: 0.18px;
					}
					span {
						font-weight: bold;
						font-size: 14px;
						line-height: 16px;
						letter-spacing: 0.25px;
					}
				}
			}
			.team-stats__statistic + .team-stats__statistic {
				margin-left: 20px;
			}
		}
		& > button {
			margin-bottom: 20px;
			width: 100%;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		}
		.team-stats__controls {
			button {
				width: 100%;
			}
			.send__request {
				font-weight: bold;
				padding: 15px 15px;
				white-space: nowrap;
				font-size: 14px;
				align-items: center;
				display: flex;
				text-align: center;
				letter-spacing: 0.3px;
				cursor: pointer;
				text-transform: uppercase;
				box-sizing: border-box;
				justify-content: center;
				color: #000;
				background: #00E7DF;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
				border-radius: 4px;
				transition: 0.2s;
				outline: none;
				border: none;
				position: relative;
				img {
					position: absolute;
					left: 15px;
				}
				&:hover {
					opacity: 0.9;
				}
			}
			.send__hasBeen {
				align-items: center;
				display: flex;
				justify-content: center;
				svg {
					margin-right: 10px;
				}
				background: transparent;
				outline: none;
				padding: 15.5px 0;
				cursor: pointer;
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 1px;
				text-transform: uppercase;
				box-shadow: none;
				color: #BDBDBD;
				border: 1px solid #505050;
				border-radius: 4px;
				transition: 0.2s;
				&:hover {
					opacity: 0.9;
				}
			}
			.delete__team {
				height: 42px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-right: 29px;
				padding-left: 15px;
				border-radius: 4px;
				svg {
					margin-right: 10px;
				}
				outline: none;
				border: none;
				font-weight: bold;
				font-size: 14px;
				line-height: 30px;
				background: #720101;
				cursor: pointer;
				text-align: center;
				letter-spacing: 0.3px;
				-webkit-transition: 0.2s;
				transition: 0.2s;
				text-transform: uppercase;
				color: #fff;
			}
		}
	}

	@media screen and (max-width: 575px) {
		.team-stats {
			padding: 16px;
		}
	}
</style>

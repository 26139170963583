import $store from '../store'
import { AuthService } from '@/services/auth.service'

/**
 * Check access permission to auth routes
 */
export async function checkAccessMiddleware (to, from, next) {
	const currentUserId = $store.getters['user/getCurrentUser']
  const isAuthRoute = to.matched.some(item => item.meta.isAuth)
	const isGuestRoute = to.meta.isGuest

	if (isGuestRoute) {
		if (currentUserId && AuthService.getBearer()) {
			next('/')
		}
	}

  if (!isAuthRoute) return next()

  if (!AuthService.getBearer() || !currentUserId) {
    try {
      await AuthService.makeLogout()
      next()
    } catch (e) {
      console.log(e)
    }
  }

  next()
}

export function checkSelectGame (to, from, next) {
	const isAuth = $store.getters['user/isAuth']
	const isRequiredGame = to.matched.some(item => item.meta.isRequiredGame)
	if (isAuth && isRequiredGame && to.name !== 'games' && !localStorage.getItem('currentGame')) {
		next({ name: 'games' })
	}

	next()
}

export function setPageTitleMiddleware (to, from, next) {
  const pageTitle = to.matched.find(item => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}

export async function checkAuthenticatePage (to, from, next) {
	const name = to.name
	if (name === 'authenticator') {
		const token = to.query.token
		if (!token) next({ path: '/' })

		if (token) {
			await AuthService.setBearer(`Bearer ${token}`)
			setTimeout(async () => {
				await $store.dispatch('user/getCurrent')
				await AuthService.getWsToken()
					.then(() => {
						next({ path: '/' })
						// centrifugo got empty token sometimes.
						// now fixed with reload, please fix in the next future!
						setTimeout(() => {
							window.location.reload('/')
						}, 200)
					})
			}, 0)
		}
	}

	return next()
}

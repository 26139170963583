import state from './State'
import mutations from './Mutations'
import getters from './Getters'
import actions from './Actions'

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}

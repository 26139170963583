<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 17 20">
    <g>
      <path d="M14.1577 19.1946C13.0644 20.2474 11.8581 20.0833 10.7082 19.5869C9.48578 19.0805 8.36823 19.0485 7.0772 19.5869C5.46946 20.2754 4.61617 20.0753 3.64789 19.1946C-1.81882 13.6104 -1.01193 5.10388 5.20117 4.78364C6.70805 4.8637 7.76306 5.61027 8.65065 5.67232C9.96992 5.40611 11.2327 4.64353 12.6448 4.74361C14.3413 4.87971 15.6101 5.54422 16.4574 6.73913C12.9675 8.82071 13.7946 13.3842 17 14.6652C16.3585 16.3364 15.5355 17.9877 14.1557 19.2086L14.1577 19.1946ZM8.52961 4.72359C8.36622 2.24171 10.3935 0.200152 12.7255 0C13.0462 2.86218 10.1031 5.00381 8.52961 4.72359Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

<template>
	<div class="rr-rooms__bracket">
		<transition name="slide">
			<div class="rr-rooms__list" v-if="!isLoading">
				<Room @updateRoom="getRooms" v-for="(room, i) in rooms" :key="i" :room="room" />
			</div>
		</transition>
		<transition name="slide">
			<div class="loading" v-if="isLoading">
				<QlashLoader></QlashLoader>
			</div>
		</transition>

		<transition name="modal">
			<SidebarContainer v-if="!isLoading"/>
		</transition>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { RoundRobinService } from '../../../services/round-robin.service'
import { prepare as roomPrepare } from '../../../model-preparing/tournaments/round-robin/room'
import Room from './Room'
import QlashLoader from '../../QlashLoader'
import SidebarContainer from './../../../pages/tournaments/containers/SidebarContainer'

export default {
	data () {
		return {
			rooms: [],
			isLoading: true
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		getRooms () {
			RoundRobinService.getRooms(this.tournament.id).then(response => {
				if (response.status === 200 && response.data && response.data.data) {
					this.rooms = []

					response.data.data.forEach(item => {
						this.rooms.push(roomPrepare(item))
					})
				} else {
					return this.toast({
						type: 'error',
						message: 'An error occurred while loading rooms, please try again later'
					})
				}
			}).catch(err => {
				return this.toast({
					type: 'error',
					message: err.statusMessage ? err.statusMessage : 'An error occurred while loading rooms, please try again later'
				})
			}).finally(() => {
				this.isLoading = false
			})
		},
		init () {
			this.getRooms()
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	mounted () {
		this.init()
	},
	components: {
		QlashLoader,
		SidebarContainer,
		Room
	}
}
</script>
<style scoped lang="scss">
.tournament-sidebar {
	margin-left: auto;
	min-width: 377px;
}
	.rr-rooms__bracket {
		padding-right: 40px;
		width: 100%;
		display: flex;
		position: relative;
		.loading {
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			margin: 30px 0;
		}
		.rr-rooms__list {
			overflow: hidden;
			width: 100%;
			display: flex;
			padding-right: 20px;
			margin-right: -20px;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}

	@media screen and (max-width: 1400px) {
		.tournament-sidebar {
			margin-left: auto;
			min-width: 300px;
		}
	}

@media screen and (max-width: 1300px) {
	.tournament-sidebar {
		display: none;
	}
	.rr-rooms__bracket .rr-rooms__list {
		padding-right: 0;
		.rr__room {
			width: calc(50% - 10px);
		}
		.rr__room:last-child {
			margin-right: 0;
		}
	}

}

@media screen and (max-width: 992px) {
	.rr-rooms__bracket .rr-rooms__list {
		.rr__room {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 768px) {
	.rr-rooms__bracket {
		padding-right: 0;
	}
}

	.slide-enter-active, .slide-leave-active {
		transition: all .15s ease-in;
		top: -10px;
	}

	.slide-enter, .slide-leave-to {
		opacity: 0;
	}

	.slide-enter-to, .slide-leave {
		opacity: 1;
		top: 0;
	}

</style>

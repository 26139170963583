<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 15 13">
    <g>
      <path d="M12.375 11H2.625C2.41406 11 2.25 11.1875 2.25 11.375V12.125C2.25 12.3359 2.41406 12.5 2.625 12.5H12.375C12.5625 12.5 12.75 12.3359 12.75 12.125V11.375C12.75 11.1875 12.5625 11 12.375 11ZM13.875 3.5C13.2422 3.5 12.75 4.01562 12.75 4.625C12.75 4.8125 12.7734 4.95312 12.8438 5.09375L11.1562 6.125C10.7812 6.33594 10.3125 6.21875 10.1016 5.84375L8.20312 2.49219C8.46094 2.30469 8.625 1.97656 8.625 1.625C8.625 1.01562 8.10938 0.5 7.5 0.5C6.86719 0.5 6.375 1.01562 6.375 1.625C6.375 1.97656 6.53906 2.30469 6.77344 2.49219L4.875 5.84375C4.66406 6.21875 4.19531 6.33594 3.84375 6.125L2.13281 5.09375C2.20312 4.95312 2.25 4.8125 2.25 4.625C2.25 4.01562 1.73438 3.5 1.125 3.5C0.492188 3.5 0 4.01562 0 4.625C0 5.25781 0.492188 5.75 1.125 5.75C1.17188 5.75 1.24219 5.75 1.28906 5.75L3 10.25H12L13.6875 5.75C13.7344 5.75 13.8047 5.75 13.875 5.75C14.4844 5.75 15 5.25781 15 4.625C15 4.01562 14.4844 3.5 13.875 3.5Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

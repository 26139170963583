import { ErrorWrapper, ResponseWrapper } from './util'
import { BaseService } from './base.service'

export class TftService extends BaseService {
	static async getMatches (tournamentId) {
		try {
			const response = await this.request({ auth: true }).get(`tournaments/${tournamentId}/tft/matches`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getMatch (tournamentId, matchId) {
		try {
			const response = await this.request({ auth: true }).get(`tournaments/${tournamentId}/tft/matches/${matchId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async sendResult (tournamentId, matchId, data) {
		try {
			const response = await this.request({ auth: true }).post(`tournaments/${tournamentId}/tft/matches/${matchId}/result`, data, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

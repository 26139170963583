<template>
	<div class="tabs">
		<ul class="tabs__header">
			<li
				v-for="(tab, index) in tabs"
				:key="tab.title"
				@click="selectTab(index)"
				:class="{'is-active': (index === selectedIndex)}"
			>
				{{ tab.title }}
			</li>
		</ul>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				selectedIndex: 0,
				tabs: []
			}
		},
		created () {
			this.tabs = this.$children
		},
		mounted () {
			this.selectTab(0)
		},
		methods: {
			selectTab (i) {
				this.selectedIndex = i

				this.tabs.forEach((tab, index) => {
					tab.isActive = (index === i)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tabs {
		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			list-style: none;
			border-bottom: 1px solid $color-gray-500;
			border-top: 1px solid $color-gray-500;

			li {
				@include navigation-item();
				cursor: pointer;
				font-weight: 700;
				letter-spacing: 0.15px;
				text-transform: uppercase;
				white-space: nowrap;
				margin-bottom: 0;

				color: $color-gray-400;
			}

			@media (max-width: 767px) {
				flex-direction: column;

				li {
					width: 100%;
					text-align: center;
				}
			}
		}
	}

	.tab {
		padding-top: 25px;
		padding-bottom: 25px;
	}
</style>

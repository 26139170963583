<template>
	<div class="tournament page" v-if="Object.keys(tournament).length">
		<UiPageTopBar
			:backgroundImgUrl="currentGame.topBanner"
			:title="tournament.title"
			v-if="currentGame && tournament"
			:withPrevButton="true"
		>
			<div class="status-badge" v-if="!isMobile">
				{{ tournament.status.replace('_', ' ') }}
			</div>
		</UiPageTopBar>
		<div class="menu-wrapper" v-if="hasNavigation">
			<ul class="menu" v-if="!$route.meta.inRounds">
				<li>
					<RouterLink :to="{ name: 'overview' }" exact>{{ $t('Overview') }}</RouterLink>
				</li>
				<li>
					<RouterLink :to="{ name: 'participants' }" exact>{{ $t('List of participants') }}</RouterLink>
				</li>
				<li>
					<RouterLink :to="{ name: 'rules' }" exact>{{ $t('Rules') }}</RouterLink>
				</li>
				<li v-if="isLeaderBoard && currentRound">
					<RouterLink :to="{ name: 'leaderboard.leaderboard' }" exact>{{ $t('Leaderboard') }}</RouterLink>
				</li>
				<li v-if="!isLeaderBoard && isGenerateBrackets">
					<RouterLink :to="{ name: 'brackets' }" exact>{{ $t('Brackets') }}</RouterLink>
				</li>
			</ul>
			<RoundsList v-else-if="$route.meta.inRounds && currentRound"></RoundsList>
		</div>
			<template v-if="$route.meta.inRounds">
				<router-view v-if="tournament.id"></router-view>
			</template>
			<template v-else>
				<router-view v-if="tournament.id"></router-view>
			</template>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'

	import UiPageTopBar from '@/components/UiPageTopBar'
	import RoundsList from '@/components/tournaments/RoundsList'
	export default {
		name: 'Tournament',
		props: {
			id: {
				type: [Number, String]
			}
		},
		data () {
			return {
				showNavigation: true
			}
		},
		components: {
			UiPageTopBar,
			RoundsList
		},
		watch: {
		},
		computed: {
			...mapGetters({
				isMobile: 'dom/isMobile',
				currentGame: 'user/getCurrentGame',
				tournament: 'tournaments/getCurrentTournament',
				currentRound: 'tournaments/getCurrentMatch',
				getCurrentTournamentUser: 'tournaments/getCurrentTournamentUser'
			}),
			isGenerateBrackets () {
				return !['OPENED', 'REGISTRATION_OPENED', 'REGISTRATION_CLOSED', 'CHECKIN', 'PENDING'].includes(this.tournament.status)
			},
			isLeaderBoard () {
				return !['OPENED', 'REGISTRATION_OPENED', 'CHECKIN', 'REGISTRATION_CLOSED', 'PENDING'].includes(this.tournament.status) && (this.tournament.type === 2 || this.tournament.type === 5)
			},
			hasNavigation () {
				const hasNavigation = this.$route.meta.withPageNavigation
				return hasNavigation !== undefined ? hasNavigation : true
			}
		},
		methods: {
			...mapActions({
				getMatches: 'tournaments/getMatches',
				getTournament: 'tournaments/getTournament',
				getResults: 'tournaments/getResults'
			}),
			async init () {
				this.amplitude.logEvent('screen_view', {
					screen_name: 'Tournament Details'
				})
				console.log(this.amplitude.identify())

				await this.getTournament(this.id)
				await this.subscribeObserver(`tournament.${this.tournament.id}`, async (data) => {
					if (data.data.status === 'FINISHED') {
						await this.$router.push('/')
					} else {
						setTimeout(() => {
							this.getTournament(this.id)
						}, 1000)
					}
				})

				try {
					this.amplitude.logEvent('tournament_viewed', this.tournament.analyticData())
				} catch (e) {
					console.error('Error send information to amplitude')
				}
			}
		},
		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss" scoped>
	.tournament {
		padding-bottom: 30px;
	}

	.status-badge {
		font-weight: 700;
		font-size: 14px;
		text-align: center;
		line-height: 16px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: $color-gray-700;

		background: $primary-color;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
		border-radius: 4px;
		padding: 10px 15px;

		margin-right: 40px;
	}

	.menu {
		display: flex;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		padding-left: 40px;
		padding-right: 40px;
		border-bottom: 1px solid $color-gray-600;
		margin-bottom: 40px;

		a {
			white-space: nowrap;
			@include navigation-item;
			font-weight: 700;
		}
	}
	@media screen and (max-width: 768px) {
		.tournament {
			background: #121212;
		}
		.menu {
			max-width: 100%;
			overflow-x: auto;
			margin-bottom: 0;
			padding-left: 16px;
			padding-right: 16px;
			background: #121212;
		}
	}
</style>

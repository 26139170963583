<template>
	<div class="chat" :class="{'open': alwaysOpen}" ref="chat">
		<div class="chat__side">
			<div class="chat__body">
				<div class="chat__conversation" :class="{'is-active': activeChat ? activeChat.id === conversation.id : false }" @click="setActiveChat(conversation)" v-for="(conversation,i) in getChats" :key="i">
					<div class="conversation__head">
						<template v-if="!conversation.isTeam">
							match chat
						</template>
						<template v-else>
							team chat
						</template>
					</div>
					<div class="conversation__item">
						<div class="conversation__avatar">
							<UiAvatar :src="getAvatar(conversation)" :size="isMobile ? 32 : 50"></UiAvatar>
						</div>
					</div>
				</div>
			</div>
			<div class="chat__close" @click="closeChat" v-if="isMobile">
				<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.50971 3.98865L7.77921 1.71915C8.07425 1.44681 8.07425 0.992908 7.77921 0.720567L7.27992 0.221277C7.00758 -0.0737589 6.55368 -0.0737589 6.28134 0.221277L4.01184 2.49078L1.71964 0.221277C1.4473 -0.0737589 0.993396 -0.0737589 0.721056 0.221277L0.221765 0.720567C-0.0732706 0.992908 -0.0732706 1.44681 0.221765 1.71915L2.49127 3.98865L0.221765 6.28085C-0.0732706 6.55319 -0.0732706 7.00709 0.221765 7.27943L0.721056 7.77872C0.993396 8.07376 1.4473 8.07376 1.71964 7.77872L4.01184 5.50922L6.28134 7.77872C6.55368 8.07376 7.00758 8.07376 7.27992 7.77872L7.77921 7.27943C8.07425 7.00709 8.07425 6.55319 7.77921 6.28085L5.50971 3.98865Z" fill="white"/>
				</svg>
			</div>
		</div>
		<ChatComponent v-if="activeChat"></ChatComponent>
		<div class="please__select-chat" v-if="!activeChat && isMobile">
			Please, select a chat
		</div>
	</div>
</template>
<script>
import UiAvatar from '../components/UiAvatar'
import ChatComponent from '../components/chat/ChatComponent'
import { mapActions, mapGetters } from 'vuex'
import matchTypes from '@/definitions/available-match-status-chat.js'
import { eventBus } from '../buses/eventBus'
export default {
	props: {
		alwaysOpen: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapActions({
			getAllRounds: 'chat/getRounds',
			setActiveChat: 'chat/setActiveChat',
			removeChat: 'chat/removeChat',
			closeChat: 'chat/closeChat'
		}),
		getParticipant (conversation) {
			if (conversation.tournament) {
				return conversation.firstParticipant
			}
			return conversation.firstParticipant.profile.user.id === this.user.id ? conversation.firstParticipant : conversation.secondParticipant
		},
		getAvatar (conversation) {
			if (conversation.isTeam) {
				return conversation.logo
			}

			const participant = this.getParticipant(conversation)
			return participant ? participant.getAvatar() : null
		},
		subscribeChats () {
			const chats = this.chats
			for (const key in chats) {
				if (this.chats[key].isTeam) continue
				this.subscribeObserver(`tournament.${chats[key].tournamentId}.match.${chats[key].id}`, async (data) => {
					if (!matchTypes.includes(data.data.status)) {
						// если текущий раунд открыт закрываем
						if (this.activeChat && chats[key].id === this.activeChat.id) {
							this.closeChat()
						}
						// удаляем из списка
						this.removeChat(chats[key].id)
					}
					this.getAllRounds()
				})
			}
		}
	},
	watch: {
		chats () {
			this.subscribeChats()
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile',
			chats: 'chat/chats',
			user: 'user/getCurrentUser',
			activeChat: 'chat/activeChat'
		}),
		getChats () {
			if (!this.chats) {
				return []
			}
			const chats = []
			for (const key in this.chats) {
				if (this.chats[key].isTeam) {
					chats.push(this.chats[key])
				} else if ((this.chats[key].secondParticipant && this.chats[key].firstParticipant) || this.chats[key].participants.length) {
					chats.push(this.chats[key])
				}
			}
			return chats
		}
	},
	components: {
		UiAvatar,
		ChatComponent
	},
	async mounted () {
		await this.subscribeChats()
		if (this.$refs.chat) {
			eventBus.$on('chat::open', () => {
				this.$refs.chat.classList.add('open')
			})
			eventBus.$on('chat::close', () => {
				if (this.isMobile) {
					this.$router.push({ path: '/' })
				} else {
					this.$refs.chat.classList.remove('open')
				}
			})
		}
	}
}
</script>
<style scoped lang="scss">
.chat {
	width: 95px;
	padding-top: 60px;
	min-width: 95px;
	background: #232323;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
	position: fixed;
	right: 0;
	z-index: 99;
	bottom: 0;
	top: 0;
	height: 100vh;
	&.open {
		height: 100%
	}
	.chat__side {
		max-height: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 2px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: gray;
		}
		.chat__body {
			.divider {
				width: 100%;
				height: 1px;
				background: #2F2F2F;
			}
			.chat__conversation {
				width: 100%;
				padding-top: 7px;
				padding-bottom: 14px;
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				background: transparent;
				.conversation__head {
					font-size: 10px;
					line-height: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #BDBDBD;
				}
				.conversation__item {
					margin-top: 6px;
					.avatar {
						transition: 0.2s;
					}
				}
				transition: 0.2s;
				&.is-active {
					background: linear-gradient(270deg, rgba(0, 231, 223, 0.05) 0%, rgba(0, 231, 223, 0.15) 100%);
					.conversation__head {
						color: $primary-color
					}
					.conversation__item {
						.conversation__avatar {
							position: relative;
							&::after {
								position: absolute;
								left: -8px;
								top: calc(50% - 4.5px);
								content: "";
								display: block;
								border: 4px solid transparent;
								border-right: 4px solid $primary-color;
							}

						}
						.avatar {
							box-shadow: 0px 0px 6px 0px #00e7df;
							border: 1px solid #00E7DF;
						}
					}
				}
				&:hover {
					background: linear-gradient(270deg, rgba(0, 231, 223, 0.05) 0%, rgba(0, 231, 223, 0.15) 100%);
					.conversation__head {
						color: $primary-color
					}
					.conversation__item {

						.avatar {
							box-shadow: 0px 0px 6px 0px #00e7df;
							border: 1px solid #00E7DF;
						}
					}
				}

			}
		}
	}
	.please__select-chat {
		height: calc(100vh - 135px);
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(/img/chat-bg.9934a9db.png);
		background-repeat: repeat;
		background-size: contain;
	}
}

@media screen and (max-width: 1200px) {
	.chat {
		width: 80px;
		min-width: 80px;
		.chat__side {
			.chat__body {
				.chat__conversation {
					.conversation__head {
						font-size: 9px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.chat {
		display: none;
		&.open {
			display: block;
			width: 100%;
			background: #121212;
		}
		.chat__side {
			position: relative;
			display: flex;
			width: 100%;
			padding-right: 16px;
			border-bottom: 1px solid #2F2F2F;
			align-items: center;
			background: #232323;
			.chat__body {
				display: flex;
				flex-grow: 1;
				justify-content: flex-start;
				overflow-x: auto;
				.chat__conversation {
					border-right: 1px solid #2F2F2F;
					width: auto;
					padding-right: 8px;
					padding-left: 8px;
					.conversation__head {
						font-size: 8px;
						white-space: nowrap;
					}
					.conversation__item {
						margin-top: 0;
					}
					&.is-active {
						border-bottom: 1px solid #00E7DF;
						padding-bottom: 9px;
						background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
						.conversation__item {
							.conversation__avatar {
								&::after {
									bottom: -4px;
									border-right: none;
									left: calc(50% + 5px);
									transform: rotate(45deg);
									border-bottom: 4px solid #00E7DF;
								}
							}
						}
					}
				}
			}
			.chat__close {
				background: #2F2F2F;
				border: 1px solid #505050;
				display: flex;
				min-width: 28px;
				align-items: center;
				margin-left: 16px;
				justify-content: center;
				cursor: pointer;
				width: 28px;
				height: 28px;
				border-radius: 4px;
			}
		}
	}
}
</style>

<template>
	<div class="mobile__chat">
		<Chat v-if="isAuth" :alwaysOpen="true"></Chat>
	</div>
</template>
<script>
import Chat from '../../layout/Chat'
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({
			isAuth: 'user/isAuth'
		})
	},
	components: {
		Chat
	},
	watch: {
		$route: function () {
			const body = document.querySelector('body')
			body.classList.remove('chat-active')
		}
	},
	mounted () {
		const body = document.querySelector('body')
		body.classList.add('chat-active')
	}
}
</script>
<style scoped lang="scss">
	.mobile__chat {
		height: calc(100% - 62px);
		max-height: calc(100% - 62px);
	}
</style>

import auth from './auth'
import user from './user'
import tournaments from './tournaments'
import advertisements from './advertisements'
import common from './common'
import dom from './dom'
import toast from './toast'
import modals from './modals'
import chat from './chat'

export default {
  auth,
  user,
  tournaments,
  advertisements,
  common,
  dom,
  toast,
	modals,
	chat
}

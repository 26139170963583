<template>
	<div class="modal edit-profile-modal">
		<div class="modal__body">
			<div @click="$emit('close')" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<h1>Edit profile</h1>

			<input @change="addAvatar" accept="image/*" type="file" id="avatar">
			<label :class="{'is-invalid': !$v.avatar.isValidSize}" for="avatar" class="picture__area">
				<div class="area__circle">
					<img v-if="previewAvatar" class="fill" :src="previewAvatar" alt="">
					<img src="@/assets/add-picture.svg" v-else alt="">
				</div>
				<div class="label">
					Upload your avatar
				</div>
			</label>
			<div class="avatar-error" v-if="!$v.avatar.isValidSize">
				<template >The image may not be greater than 1024 kilobytes</template>
			</div>

			<div class="input" :class="{ 'is-invalid': $v.form.nickname.$error, 'is-correct': $v.form.nickname.$dirty && !$v.form.nickname.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2 7H2.40625C3.1875 8.78125 4.9375 10 7 10C9.03125 10 10.7812 8.78125 11.5625 7H12C12.25 7 12.5 6.78125 12.5 6.5V3.5C12.5 3.25 12.25 3 12 3H11.5625C10.7812 1.25 9.03125 0 7 0C4.9375 0 3.1875 1.25 2.40625 3H2C1.71875 3 1.5 3.25 1.5 3.5V6.5C1.5 6.78125 1.71875 7 2 7ZM3.25 4.25C3.25 3.5625 3.90625 3 4.75 3H9.25C10.0625 3 10.75 3.5625 10.75 4.25V5C10.75 6.65625 9.40625 8 7.75 8H6.25C4.59375 8 3.25 6.65625 3.25 5V4.25ZM5.5 6.5L5.875 5.375L7 5L5.875 4.625L5.5 3.5L5.125 4.625L4 5L5.125 5.375L5.5 6.5ZM10.2188 10.0625C9.28125 10.6562 8.1875 11 7 11C5.78125 11 4.6875 10.6562 3.75 10.0625C1.625 10.2812 0 12.0312 0 14.2188V14.5C0 15.3438 0.65625 16 1.5 16H4V14C4 13.4688 4.4375 13 5 13H9C9.53125 13 10 13.4688 10 14V16H12.5C13.3125 16 14 15.3438 14 14.5V14.2188C14 12.0312 12.3438 10.2812 10.2188 10.0625ZM8.5 14C8.21875 14 8 14.25 8 14.5C8 14.7812 8.21875 15 8.5 15C8.75 15 9 14.7812 9 14.5C9 14.25 8.75 14 8.5 14ZM5.5 14C5.21875 14 5 14.25 5 14.5V16H6V14.5C6 14.25 5.75 14 5.5 14Z" fill="#B0B0B0"/>
					</svg>

				</div>
				<div class="input__field">
					<input type="text" v-model="$v.form.nickname.$model" placeholder="NICKNAME">
				</div>
				<div v-if="$v.form.nickname.$dirty" class="error-input">
					<template v-if="!$v.form.nickname.required">Nickname is required</template>
					<template v-else-if="!$v.form.nickname.minLength">
						Nickname is not less than 6 characters</template>
					<template v-else-if="!$v.form.nickname.maxLength">Nickname is not more than 48 characters</template>
					<template v-else-if="!$v.form.nickname.isAvailableNickname">Invalid nickname format</template>
				</div>
			</div>
			<div class="input" :class="{ 'is-invalid': $v.form.name.$error, 'is-correct': $v.form.name.$dirty && !$v.form.name.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 8C9.1875 8 11 6.21875 11 4C11 1.8125 9.1875 0 7 0C4.78125 0 3 1.8125 3 4C3 6.21875 4.78125 8 7 8ZM9.78125 9H9.25C8.5625 9.34375 7.8125 9.5 7 9.5C6.1875 9.5 5.40625 9.34375 4.71875 9H4.1875C1.875 9 0 10.9062 0 13.2188V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V13.2188C14 10.9062 12.0938 9 9.78125 9Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input type="text" v-model="$v.form.name.$model" placeholder="Name">
				</div>
				<div v-if="$v.form.name.$dirty" class="error-input">
					<template v-if="!$v.form.name.required">Name is required</template>
					<template v-else-if="!$v.form.name.minLength">
						Name is not less than 3 characters</template>
					<template v-else-if="!$v.form.name.maxLength">Name is not more than 120 characters</template>
				</div>
			</div>
			<div class="input__group">
				<div class="input" :class="{'is-invalid': $v.form.dateOfBirth.$error, 'is-correct': $v.form.dateOfBirth.$dirty && !$v.form.dateOfBirth.$error}">
					<div class="input__icon">
						<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14 12V9.5C14 8.6875 13.3125 8 12.5 8H12V3.5H10V8H8V3.5H6V8H4V3.5H2V8H1.5C0.65625 8 0 8.6875 0 9.5V12C0.875 12 0.96875 11 2.3125 11C3.6875 11 3.78125 12 4.65625 12C5.5 12 5.65625 11 7 11C8.34375 11 8.46875 12 9.3125 12C10.1875 12 10.3125 11 11.6562 11C13 11 13.0938 12 14 12ZM14 16V13C12.625 13 12.5 12 11.6562 12C10.7812 12 10.6875 13 9.3125 13C7.96875 13 7.875 12 7 12C6.125 12 6 13 4.65625 13C3.3125 13 3.1875 12 2.3125 12C1.4375 12 1.34375 13 0 13V16H14ZM3 3C3.53125 3 4 2.59375 4 1.75C4 0.9375 3.375 0 3 0C3 1.28125 2 1.03125 2 2C2 2.5625 2.4375 3 3 3ZM7 3C7.53125 3 8 2.59375 8 1.75C8 0.9375 7.375 0 7 0C7 1.28125 6 1.03125 6 2C6 2.5625 6.4375 3 7 3ZM11 3C11.5312 3 12 2.59375 12 1.75C12 0.9375 11.375 0 11 0C11 1.28125 10 1.03125 10 2C10 2.5625 10.4375 3 11 3Z" fill="#B0B0B0"/>
						</svg>
					</div>
					<div class="input__field">
						<input type="date" v-model="$v.form.dateOfBirth.$model" dataformatas="DD/MM/YYYY" placeholder="DD/MM/YYYY">
					</div>
					<div v-if="$v.form.dateOfBirth.$dirty" class="error-input">
						<template v-if="!$v.form.dateOfBirth.required">Date of birth is required</template>
						<template v-else-if="!$v.form.dateOfBirth.minValue">Incorrect date of birth</template>
					</div>
				</div>
				<div class="input" :class="{'is-invalid': $v.form.country.$error, 'is-correct': $v.form.country.$dirty && !$v.form.country.$error}">
					<div class="input__icon">
						<svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.375 15.6875C5.65625 16.125 6.3125 16.125 6.59375 15.6875C11.1562 9.125 12 8.4375 12 6C12 2.6875 9.3125 0 6 0C2.65625 0 0 2.6875 0 6C0 8.4375 0.8125 9.125 5.375 15.6875ZM6 8.5C4.59375 8.5 3.5 7.40625 3.5 6C3.5 4.625 4.59375 3.5 6 3.5C7.375 3.5 8.5 4.625 8.5 6C8.5 7.40625 7.375 8.5 6 8.5Z" fill="#B0B0B0"/>
						</svg>
					</div>
					<div class="input__field">
						<input type="text" class="to-upper" v-model="searchCountry" @input="getCountries" @click.stop @focusin.stop="openCountrySelect = true" placeholder="SELECT COUNTRY" >
						<transition name="fade">
							<div class="dropdown-countries" v-if="openCountrySelect">
								<div class="country__item" @click="setCountry(country)" v-for="(country, i) in countries" :key="i">{{ country.value }}</div>
							</div>
						</transition>
					</div>
					<div v-if="$v.form.country.$dirty" class="error-input">
						<template v-if="!searchCountry">Country is required</template>
					</div>
				</div>

			</div>

			<UiButton type="primary" @click="save">
				SAVE SETTINGS
				<UiLoader v-show="loader" width="15"></UiLoader>
			</UiButton>
		</div>
	</div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { LoginService } from '../../../services/login.service'
import UiButton from '../../UiButton'
import UiLoader from '../../UiLoader'
import { UsersService } from '../../../services/users.service'
import { mapMutations, mapActions } from 'vuex'

export default {
	data () {
		return {
			avatar: null,
			searchCountry: '',
			countries: [],
			openCountrySelect: false,
			loader: false,
			previewAvatar: null,
			form: {
				nickname: null,
				name: null,
				country: null,
				dateOfBirth: null
			}
		}
	},
	components: {
		UiLoader,
		UiButton
	},
	validations: {
		avatar: {
			isValidSize (file) {
				if (!file) return true
				return (file.size < 1024000)
			}
		},
		form: {
			name: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(120)
			},
			nickname: {
				required,
				maxLength: maxLength(48),
				minLength: minLength(6),
				isAvailableNickname (nickname) {
					const regex = /^[^<>%$]*$/
					return regex.test(nickname)
				}
			},
			dateOfBirth: {
				required,
				minValue: value => value < new Date().toISOString()
			},
			country: {
				required
			}
		}
	},
	mixins: [validationMixin],
	methods: {
		...mapActions({
			getCurrentUser: 'user/getCurrent'
		}),
		...mapMutations({
			toast: 'toast/NEW'
		}),
		async save () {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return
			}
			this.loader = true
			if (this.avatar instanceof File) {
				const avatar = new FormData()
				avatar.append('image', this.avatar)
				avatar.append('_method', 'PUT')
				await this.updateAvatar(avatar)
			}

			const data = {
				country_id: this.form.country.id,
				birthday: this.form.dateOfBirth,
				nickname: this.form.nickname,
				name: this.form.name
			}
			await UsersService.updateUser(data, this.$currentUser.id).then(() => {
				this.amplitude.logEvent('screen_view', {
					screen_name: 'Profile Edit'
				})
			}).catch(e => {
				this.toast({
					type: 'error',
					message: e.message ?? 'Error update profile information'
				})
			})

			await this.getCurrentUser()

			this.loader = false
			this.$emit('close')
		},
		async updateAvatar (data) {
			try {
				await UsersService.updateAvatar(data)
			} catch (e) {
				this.toast({
					type: 'error',
					message: 'Error update avatar'
				})
			}
		},
		setCountry (country) {
			this.form.country = country
			this.openCountrySelect = false
			this.searchCountry = country.value
		},
		getCountries ($event) {
			LoginService.getCountries({ search: $event ? $event.target.value : this.searchCountry }).then((data) => {
				this.countries = data.data.data
			})
		},
		async fillDefaultData () {
			this.form.nickname = this.$currentUser.nickname
			this.form.name = this.$currentUser.name
			this.form.dateOfBirth = this.$currentUser.birthday
			this.previewAvatar = this.$currentUser.avatar
			await this.getCountry(this.$currentUser.country_id)
		},
		async getCountry (id) {
			await LoginService.getCountry(id).then(response => {
				this.form.country = response.data.data
				this.searchCountry = this.form.country.value
			})
			this.getCountries({
				target: {
					value: this.form.country.value
				}
			})
		},
		async init () {
			await this.fillDefaultData()
		},
		addAvatar ($event) {
			this.avatar = $event.target.files[0]
			const reader = new FileReader()
			reader.onload = (e) => {
				this.previewAvatar = e.target.result
			}
			reader.readAsDataURL(this.avatar)
		}
	},
	mounted () {
		this.init()
		window.addEventListener('click', async () => {
			this.openCountrySelect = false
		})
	}
}
</script>
<style scoped lang="scss">
	.edit-profile-modal {
		.ui-button {
			margin-top: 30px;
			padding: 16px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			::v-deep img {
				margin-left: 20px;
			}
		}
		.modal__body {
			overflow-y: unset;
		}
		input[type="file"] {
			display: none;
		}
		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 38px;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.picture__area {
			border: 1px dashed #505050;
			border-radius: 5px;
			padding: 15px 0;
			display: flex;
			justify-content: center;
			position: relative;
			margin: 25px 0;
			cursor: pointer;
			.label {
				position: absolute;
				bottom: -12px;
				background: #2F2F2F;
				padding: 0 10px;
				font-size: 10px;
				line-height: 22px;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #505050;
			}
			.area__circle {
				height: 150px;
				width: 150px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid #505050;
				overflow: hidden;
				img {
					max-width: 100%;
					max-height: 100%;
					&.fill {
						width: 100%;
						height: 100%;
						border-radius: 100%;
					}
				}
			}
			&.is-invalid {
				border-color: #D71411
			}
		}
		.input {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			border: 1px solid #505050;
			border-radius: 5px;
			.input__icon {
				width: 56px;
				min-width: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;
			}
			.input__field {
				flex-grow: 1;
				input {
					width: 100%;
					background: transparent;
					padding: 15px 0;
					padding-right: 15px;
					font-size: 10px;
					line-height: 20px;
					border: none;
					outline: none;
					letter-spacing: 2px;
					color: #B0B0B0;
					&::placeholder {
						text-transform: uppercase;
					}
				}
			}
			.eye {
				width: 18px;
				margin-right: 11px;
				cursor: pointer;
				position: relative;
				&.with-line {
					&:after {
						position: absolute;
						width: 100%;
						height: 1.5px;
						content: "";
						display: block;
						top: 9px;
						background: #b0b0b0;
						transform: rotate(45deg)
					}
				}
			}
			&.is-correct {
				border: 1px solid #00E7DF;
			}
			&.is-invalid {
				border: 1px solid #D71411;
				.input__icon {
					svg {
						path {
							fill: #D71411
						}
					}
				}
				.input__field {
					input {
						color: #D71411;
						&::placeholder {
							color: #D71411;
						}
					}
				}
			}
		}
		.avatar-error {
			font-size: 10px;
			line-height: 20px;
			text-transform: uppercase;
			color: #D71411;

			margin-bottom: 20px;
		}
		.error-input {
			font-size: 10px;
			line-height: 20px;
			margin-left: auto;
			text-align: right;
			display: block;
			text-transform: uppercase;
			color: #D71411;
			position: absolute;
			right: 0;
			bottom: -20px;
			&.error_static {
				position: static;
				text-align: left;
				margin-left: 0;
				margin: 10px 0;
			}
		}
		.input + .input {
			margin-top: 25px;
		}
		.input {
			transition: 0.2s;
			position: relative;
			.dropdown-countries {
				position: absolute;
				width: 100%;
				left: 0;
				background: #2F2F2F;
				max-height: 150px;
				overflow-y: auto;
				top: 100%;
				border: 1px solid #00E7DF;
				border-top: none;
				border-radius: 5px;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				&::-webkit-scrollbar {
					width: 0px;
				}
				.country__item {
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.15px;
					text-transform: uppercase;
					color: #FFFFFF;
					padding: 7.5px 0;
					padding-left: 20px;
					cursor: pointer;
				}
			}
		}
		.input__group {
			margin-top: 25px;
			display: flex;
			align-items: center;
			.input + .input {
				margin-top: 0;
				margin-left: 20px;
			}
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active{
		-webkit-text-fill-color: #B0B0B0 !important;
		-webkit-box-shadow: 0 0 0 30px #2f2f2f inset !important;
	}
	input[type="date"]::-webkit-calendar-picker-indicator {
		background: transparent;
		bottom: 0;
		color: transparent;
		cursor: pointer;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
	}
	.slide-fade-enter-active {
		transition: all .1s ease !important;
	}
	.slide-fade-leave-active {
		transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0) !important;
	}
	.slide-fade-enter, .slide-fade-leave-to {
		opacity: 0;
	}

</style>

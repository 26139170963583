/**
 * import and init global filters
 */

import Vue from 'vue'

import formatHours from '../filters/formatHours'
import formatWeekDayAndDate from '../filters/formatWeekDayAndDate'
import startTournamentDateFormat from '../filters/startTournamentDateFormat'

Vue.filter(formatHours)
Vue.filter(formatWeekDayAndDate)
Vue.filter(startTournamentDateFormat)

<template>
	<div class="call-admin">
		<div class="call-title">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.5 10.125C9.5 9.46094 8.91406 8.875 8.25 8.875H7.625C6.21875 8.875 5.125 10.0078 5.125 11.375V13.25C5.125 14.6562 6.21875 15.75 7.625 15.75H8.25C8.91406 15.75 9.5 15.2031 9.5 14.5V10.125ZM16.375 15.75C17.7422 15.75 18.875 14.6562 18.875 13.25V11.375C18.875 10.0078 17.7422 8.875 16.375 8.875H15.75C15.0469 8.875 14.5 9.46094 14.5 10.125V14.5C14.5 15.2031 15.0469 15.75 15.75 15.75H16.375ZM12 2C6.41406 2 2.15625 6.64844 2 12V12.625C2 12.9766 2.27344 13.25 2.625 13.25H3.25C3.5625 13.25 3.875 12.9766 3.875 12.625V12C3.875 7.54688 7.50781 3.875 12 3.875C16.4531 3.875 20.125 7.54688 20.125 12H20.0859C20.0859 12.1172 20.125 18.4844 20.125 18.4844C20.125 19.4219 19.3828 20.125 18.4453 20.125H14.5C14.5 19.1094 13.6406 18.25 12.625 18.25H11.375C10.3203 18.25 9.5 19.1094 9.5 20.125C9.5 21.1797 10.3203 22 11.375 22H18.4453C20.3984 22 22 20.4375 22 18.4844V12C21.8047 6.64844 17.5469 2 12 2Z" fill="#00E7DF"/>
			</svg>
			Сall admin to chat?
		</div>
		<div class="buttons">
			<UiButton @click="$emit('cancel')">Cancel</UiButton>
			<UiButton type="primary" @click="callAdmin">Call admin</UiButton>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import Message from '../../models/Message'
import Moment from 'moment'
import { ChatService } from '@/services/chat.service'
import { mapGetters } from 'vuex'
export default {
	components: {
		UiButton
	},
	computed: {
		...mapGetters({
			activeChat: 'chat/activeChat',
			currentUser: 'user/getCurrentUser'
		})
	},
	methods: {
		callAdmin () {
			const text = '<#>call_admin'
				const message = new Message({
					message: text,
					nickname: this.currentUser.nickname,
					image: this.currentUser.avatar,
					user_id: this.currentUser.id,
					created_at: new Moment()
				})
				this.$emit('sent', message)
				ChatService.sendMessage({
					tournamentId: this.activeChat.tournamentId,
					roundId: this.activeChat.id,
					message: {
					message: text,
						attached: null
					}
				}).finally(() => {
					this.$emit('cancel')
				})
		}
	}
}
</script>
<style scoped lang="scss">
.call-admin {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 9;
	background: #2F2F2F;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
	padding: 25px 25px 25px;

	.call-title {
		display: flex;
		svg {
			margin-right: 10px;
		}
		font-weight: bold;
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		align-items: center;
		justify-content: center;
		color: #FFFFFF;
	}
	.buttons {
		margin-top: 20px;
		display: flex;
		grid-gap: 25px;
		button {
			flex: 1;
		}
	}
}
</style>

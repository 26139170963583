export default {
	rounds: state => state.rounds,
	chats: (state, getters, rootGetters) => {
		const chats = []
		chats.push(...state.rounds)

		const teams = rootGetters.user.currentTeams

		if (teams) {
			teams.map(item => {
				item.isTeam = true
			})
			chats.push(...teams)
		}

		return chats
	},
	activeChat: state => state.activeChat
}

<template>
	<ul class="menu">
		<li v-for="(round, index) in getRounds" :key="index">
			<RouterLink v-if="currentRound.round === round.round" class="is-active" :to="{ name: 'rounds', params: { round: round.round } }" exact>Round {{ round.round }}</RouterLink>
			<a disabled v-else>Round {{ round.round }}</a>
		</li>
	</ul>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

	computed: {
		groupByRounds () {
			const rounds = {}
			const matches = this.matches
			matches.map(item => {
				if (!Object.prototype.hasOwnProperty.call(rounds, item.round)) {
					rounds[item.round] = {
						games: []
					}
				}

				rounds[item.round].games.push(item)
			})

			return rounds
		},
		getRounds () {
			const rounds = []
			const groupedRounds = this.groupByRounds

			if (!Object.keys(groupedRounds).length) return []

			Object.keys(groupedRounds).forEach(index => {
				const firstMatch = groupedRounds[index].games[0]
				rounds.push({
					round: firstMatch.round,
					startedAt: firstMatch.startedAt
				})
			})

			return rounds
		},
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			matches: 'tournaments/getMatches',
			currentRound: 'tournaments/getCurrentMatch'
		})
	}
}
</script>

<style scoped lang="scss">
.menu {
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.15px;
	text-transform: uppercase;
	padding-left: 40px;
	padding-right: 40px;
	border-bottom: 1px solid $color-gray-600;
	margin-bottom: 40px;

	a {
		white-space: nowrap;
		@include navigation-item;
		font-weight: 700;
		&.is-active {
			color: #00E7DF !important;
		}
		&[disabled] {
			cursor: not-allowed;
			user-select: none;
			&:hover {
				border-bottom: 1px solid transparent;
				background: transparent;
				color: #BDBDBD;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.menu {
		overflow-y: hidden;
	}
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 18">
    <g>
      <path d="M9 3C5.68548 3 3 5.68548 3 9C3 12.3145 5.68548 15 9 15C12.3145 15 15 12.3145 15 9C15 5.68548 12.3145 3 9 3ZM11.2258 10.5726L10.7419 11.1774C10.6694 11.2742 10.5726 11.3468 10.4274 11.3468C10.3548 11.3468 10.2581 11.2984 10.2097 11.25L8.58871 10.0403C8.34677 9.87097 8.22581 9.60484 8.22581 9.29032V5.51613C8.22581 5.32258 8.39516 5.12903 8.6129 5.12903H9.3871C9.58065 5.12903 9.77419 5.32258 9.77419 5.51613V9L11.1774 10.0403C11.25 10.1129 11.3226 10.2097 11.3226 10.3306C11.3226 10.4274 11.2742 10.5242 11.2258 10.5726Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

import Vue from 'vue'

import AppLayout from './layout/index.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import amplitude from './plugins/amplitude'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import './mixins'
import './filters'
import './plugins'
import './thirdParty'
import 'hooper/dist/hooper.css'
import './scss/style.scss'
import urlify from './directives/urlify'
Vue.directive('urlify', urlify)
Vue.use(amplitude)
Vue.component('v-select', vSelect)
window.EventBus = new Vue()
Vue.config.productionTip = false
const vueInstance = new Vue({
  name: 'Root',
	data () {
		return {
			centrifugeInstance: null,
			loaded: false
		}
	},
	computed: {
		isMobile () {
			return window.innerWidth < 768
		}
	},
  i18n,
  router,
  store,
  mounted () {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
    // dynamic load custom app css based on domain name
    const style = document.createElement('link')
    style.type = 'text/css'
    style.rel = 'stylesheet'
    style.href = '/themes/' + window.location.hostname + '.css'
    document.head.appendChild(style)
  },

  beforeDestroy () {
    window.removeEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
  },
  render: h => h(AppLayout)
}).$mount('#app')

export { vueInstance }

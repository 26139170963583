<template>
	<div class="round">
		<div class="shell">
			<div class="range" v-if="currentRound">
				<div class="cell-sm-8 md_full">
					<RoundResult :firstParticipant="currentRound.first_participant" :secondParticipant="currentRound.second_participant"></RoundResult>
					<RoundProoveScore></RoundProoveScore>
				</div>
				<div class="cell-sm-4 round_container">
					<SidebarContainer></SidebarContainer>
				</div>
			</div>
			<transition name="slide">
				<div class="loading" v-if="!currentRound">
					<QlashLoader></QlashLoader>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import RoundResult from './containers/round/RoundResult'
import SidebarContainer from './containers/round/SidebarContainer'
import RoundProoveScore from './containers/round/RoundProoveScore'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import isWinnerInMatch from '../../helpers/tournaments/isWinnerInMatch'
import * as statuses from '../../constants/tournament-statuses'
import QlashLoader from '../../components/QlashLoader'
export default {
	data () {
		return {
			cache: []
		}
	},
	components: {
		QlashLoader,
		SidebarContainer,
		RoundResult,
		RoundProoveScore
	},
	methods: {
		...mapMutations({
			showModal: 'modals/SHOW'
		}),
		...mapActions({
			getMatches: 'tournaments/getMatches',
			getResults: 'tournaments/getResults'
		}),
		async showResultRound (data) {
			if (data.data.status === statuses.STATUS_FINISHED) {
				// if match finished, show modal result
				const match = await isWinnerInMatch(this.tournament.id, data.data.match_id, this.currentParticipant.id)
				// show result round
				this.showModal({
					meta: {
						inRound: match.isRound,
						inTournament: match.inTournament,
						isWinner: match.isWinner
					},
					round: match.match
				})
			}
			// get next round
			await this.fetchData()
		},
		subscribeChannels () {
				if (!this.currentRound) return
				const channel = `tournament.${this.tournament.id}.match.${this.currentRound.id}`
				if (this.cache.includes(channel)) return
				this.cache.push(channel)

				this.subscribeObserver(channel, async (data) => {
						if (data.data.status === statuses.STATUS_FINISHED) {
							await this.showResultRound(data)
						} else {
							await this.fetchData()
						}
					this.subscribeChannels()
				})
		},
		async fetchData () {
			await this.getMatches(this.tournament.id)
			await this.getResults(this.tournament.id)
		}
	},
	watch: {
		currentRound () {
			this.subscribeChannels()
		}
	},
	computed: {
		...mapGetters({
			matches: 'tournaments/getMatches',
			currentParticipant: 'tournaments/getCurrentTournamentUser',
			currentRound: 'tournaments/getCurrentMatch',
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	mounted () {
		if (this.tournament.isJoined) {
			this.getMatches(this.tournament.id)
			this.getResults(this.tournament.id)
			this.subscribeChannels()

			this.subscribeObserver(`tournament.${this.tournament.id}`, (data) => {
				if (data.data.status === statuses.STATUS_FINISHED) {
					this.$router.push('/')
				} else {
					this.getMatches(this.tournament.id)
					this.getResults(this.tournament.id)
				}
			})
		}
	}
}
</script>
<style scoped lang="scss">
.loading {
	width: 100%;
	margin: 30px 0;
	display: flex;
	justify-content: center;
}
.slide-enter-active, .slide-leave-active {
	transition: all .15s ease-in;
	top: -10px;
}

.slide-enter, .slide-leave-to {
	opacity: 0;
}

.slide-enter-to, .slide-leave {
	opacity: 1;
	top: 0;
}
.proove-score {
	margin-top: 20px;
}

@media screen and (max-width: 1200px) {
	.round_container {
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 0;
		width: 100%;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		::v-deep {
			.tournament-sidebar {
				.tournament-sidebar__header {
					display: none;
				}
				.tournament-sidebar__content {
					border-radius: 0;
				}
			}
		}
	}
	.md_full {
		width: 100%;
		padding-right: 16px;
		padding-left: 16px;
		padding-bottom: 200px;
		min-width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.round {
		margin-top: 16px;
		.shell {
			width: 100%;
			min-width: 100%;
		}
	}
}
</style>

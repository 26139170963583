<template>
	<div class="modal player-lose-modal">
		<div class="modal__body">
			<div @click="removeModal" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<template v-if="!modal.meta.isWinner">
				<div class="lose__content">
					<h1 class="h1">You lost the round<br> but did not lose the war</h1>
					<img src="@/assets/modals/lose.svg" class="prefset-top-40" alt="">
				</div>
				<div class="score__blocks prefset-top-40" v-if="result">
					<div class="score__block">
						<div class="block__title">Your place</div>
						<h1>{{ result.tournament_position }}</h1>
						<h6 v-if="result.tournament_position === 1">st</h6>
						<h6 v-else-if="result.tournament_position === 2">nd</h6>
						<h6 v-else>nd</h6>
					</div>
					<div class="score__block with-sub">
						<div class="block__title">points earned</div>
						<div class="sub-block">
							<h1>{{ points[2] ? points[2] : 0 }}</h1>
						</div>
						<div class="sub-block">
							<h1>{{ points[1] ? points[1] : 0 }}</h1>
						</div>
						<div class="sub-block">
							<h1>{{ points[0] }}</h1>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="lose__content">
					<h1 class="h1">You win!</h1>
					<img src="@/assets/modals/win.svg" class="prefset-top-40" alt="">
				</div>
				<div class="score__blocks prefset-top-40" v-if="result">
					<div class="score__block">
						<div class="block__title">Your place</div>
						<h1>{{ result.tournament_position }}</h1>
						<h6 v-if="result.tournament_position === 1">st</h6>
						<h6 v-else-if="result.tournament_position === 2">nd</h6>
						<h6 v-else>nd</h6>
					</div>
					<div class="score__block with-sub">
						<div class="block__title">points earned</div>
						<div class="sub-block">
							<h1>{{ points.length > 2 ? points[2] : 0 }}</h1>
						</div>
						<div class="sub-block">
							<h1>{{ points.length > 1 ? points[1] : 0 }}</h1>
						</div>
						<div class="sub-block">
							<h1>{{ points[0] }}</h1>
						</div>
					</div>
				</div>
			</template>

			<div class="modal__buttons">
				<UiButton type="second" @click="$router.push({ path: '/leaderboard' }), removeModal()">See leaderboard</UiButton>
				<UiButton type="primary" @click="goTournamentPage">Tournament page</UiButton>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import { mapGetters, mapMutations } from 'vuex'
import { TournamentsService } from '../../services/tournaments.service'
export default {
	data () {
		return {
			result: null
		}
	},
	computed: {
		points () {
			if (!this.result) {
				return [0, 0, 0]
			}
			const points = String(this.result.earned_points)

			return [...points.split('')].reverse()
		},
		...mapGetters({
			teams: 'user/currentTeams',
			modal: 'modals/modal',
			user: 'user/getCurrentUser'
		})
	},
	methods: {
		...mapMutations({
			removeModal: 'modals/REMOVE'
		}),
		goTournamentPage () {
			this.$router.push({ name: 'tournament', params: { id: this.modal.round.tournament_id } })
			this.removeModal()
		},
		getResultParticipant () {
			let participantId = null
			if (this.modal.meta.isWinner) {
				participantId = this.modal.round.winner_id
			} else {
				if (this.modal.round.first_participant || this.modal.round.second_participant) {
					if (this.modal.round.first_participant.id !== this.modal.round.winner_id) {
						participantId = this.modal.round.first_participant.id
					} else {
						participantId = this.modal.round.second_participant.id
					}
				}
			}
			if (participantId) {
				TournamentsService.getParticipant(this.modal.round.tournament_id, participantId).then(response => {
					this.result = response.data.data
				})
			}
		}
	},
	mounted () {
		this.getResultParticipant()
	},
	components: {
		UiButton
	}
}
</script>
<style scoped lang="scss">

</style>

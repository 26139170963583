<template>
	<div class="select-login-method">
		<h1>Login to qlash</h1>
		<p>If you don’t have an account, we will create on for you.</p>
		<div class="methods">
			<a class="method method_google" @click="loginWith('google')">
				<img src="@/assets/google.svg" alt="">
				Sign in with Google
			</a>
			<!-- <a class="method method_apple" @click="loginWith('apple')">
				<img src="@/assets/apple.png" alt="" style="width:20px">
				Sign in with Apple
			</a> -->
			<a @click="$emit('next')" class="method method_email">
				<img src="@/assets/email.svg" alt="">
				Sign in with email
			</a>
			<a @click="loginWith('discord')" class="method method_discord">
				<img src="@/assets/discord.svg" alt="">
				Sign in with Discord
			</a>
			<a class="method method_twitch" @click="loginWith('twitch')">
				<img src="@/assets/twitch.svg" alt="">
				Sign in with Twitch
			</a>
		</div>
		<div class="policy">
			Authenticating in our service you accept <a @click="$emit('showPrivacyPolicyModal')">Privacy Policy</a>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			discordUrl: 'user/discordUrl',
			twitchUrl: 'user/twitchUrl',
			googleUrl: 'user/googleUrl',
			appleUrl: 'user/appleUrl'
		})
	},
	methods: {
		loginWith (type) {
			switch (type) {
				case 'discord': {
					window.location.href = `${this.discordUrl}`
					break
				}
				case 'twitch': {
					window.location.href = `${this.twitchUrl}`
					break
				}
				case 'google': {
					window.location.href = `${this.googleUrl}`
					break
				}
				case 'apple': {
					window.location.href = `${this.appleUrl}`
					break
				}
			}
		}
	}
}
</script>
<style scoped lang="scss">
.select-login-method {
	padding: 50px 0;
	h1 {
		font-weight: bold;
		font-size: 30px;
		line-height: 32px;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	p {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 1px;
	}
	.methods {
		margin-bottom: 25px;
		margin-top: 30px;
		.method {
			padding: 15.5px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 14px;
			border-radius: 4px;
			transition: 0.1s;
			line-height: 17px;
			img {
				margin-right: 17px;
			}
			&.method_google {
				background: #FFFFFF;
				color: #232323;
			}
			&.method_apple {
				background: #000000;
				color: #FFFFFF;
			}
			&.method_email {
				background: #018786;
			}
			&.method_discord {
				background: #5662F6;
			}
			&.method_twitch {
				background: #9146FF;
			}
			&:hover {
				box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			}

		}
		.method + .method {
			margin-top: 15px;
		}
	}
	.policy {
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		a {
			cursor: pointer;
			letter-spacing: 0.4px;
			text-decoration-line: underline;
			color: #00E7DF;
		}
		letter-spacing: 0.4px;
		color: #8F8F8F;
	}

}

@media screen and (max-width: 768px) {
	.select-login-method {
		padding-bottom: 24px;
		padding-top: 24px;
		h1 {
			font-size: 24px;
			line-height: 32px;
		}
		p {
			font-size: 14px;
			line-height: 24px;
		}
		.methods {
			margin-top: 24px;
			margin-bottom: 16px;
		}
	}
}
</style>

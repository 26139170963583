<template>
	<div class="tournament-sidebar">
		<div class="tournament-sidebar__header">
			<UiParticipantsCounter
				:all="tournament.maxMember"
				:registered="tournament.participantCount"
				:amountAvatars="3"
				:withIcon="true"
			/>
		</div>

		<div class="tournament-sidebar__content" v-if="!isLastRound">
			<template v-if="currentRound.status !== 'FINISHED'">
				<h4>Entry data for <em>ROUND {{ currentRound.round }}
					at {{ startRoundTime }}</em></h4>
				<div class="entry-blocks" v-if="currentRound.roomId || currentRound.password">
					<div class="entry-block" v-if="currentRound.roomId">
						<div class="entry-label">Room ID</div>
						<div class="entry-data">{{ currentRound.roomId }}</div>
					</div>
					<div class="entry-block" v-if="currentRound.password">
						<div class="entry-label">Password</div>
						<div class="entry-data">{{ currentRound.password }}</div>
					</div>
				</div>
				<div v-else class="entry-blocks">
					<div class="entry-block empty">
						<div class="entry-label">ENTRY CODE WILL APPEAR HERE</div>
					</div>
				</div>
			</template>
			<template v-else>
					<UiButton
						v-if="isAvailableNextRound"
						@click="$emit('next')"
						:type="'primary'"
					>
						Next Match
					</UiButton>
				<template v-else>
					<h4>Round {{ currentRound.round }}/{{ rounds.length }}</h4>
					<div class="divider"></div>
					<UiButton
						:disabled="currentRound.round === rounds.length"
						@click="$emit('goToNextRoundCustom')"
						:type="'primary'"
					>
						Next Match
					</UiButton>
				</template>
			</template>
		</div>
		<div class="tournament-sidebar__content" v-else>
			<h4>Match finished</h4>
			<UiButton
				@click="$router.push(`/tournament/${tournamentId}/`)"
				:type="'primary'"
			>
				Tournament page
			</UiButton>
		</div>

		<SidebarSocials></SidebarSocials>

	</div>
</template>

<script>
import UiParticipantsCounter from '@/components/UiParticipantsCounter'
import UiButton from '@/components/UiButton'
import { mapGetters } from 'vuex'
import Moment from 'moment'
import SidebarSocials from '@/components/tournaments/SidebarSocials'
export default {
	data () {
		return {
			nowDateTime: new Date().toDateString()
		}
	},
	props: {
		isAvailableNextRound: {
			type: Boolean,
			default: false
		},
		currentRound: {
			required: true,
			type: Number
		},
		rounds: {
			required: true,
			type: Number
		}
	},
	components: {
		UiParticipantsCounter,
		UiButton,
		SidebarSocials
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			tournamentId: 'tournaments/getCurrentTournamentId',
			tournamentStatus: 'tournaments/getCurrentTournamentStatus',
			tournamentUser: 'tournaments/getCurrentTournamentUser',
			rounds: 'tournaments/getLeaderBoard',
			round: 'tournaments/getCurrentRoundLeaderboard'
		}),
		isLastRound () {
			return Number(this.currentRound.round) === this.rounds.length
		},
		startRoundTime () {
			return new Moment(this.currentRound.startedAt).format('HH:mm')
		}
	}
}
</script>

<style scoped lang="scss">
.offset-top-50 {
	margin-top: 50px;
}
.tournament-sidebar__content {
	h4 {
		font-size: 18px;
		em {
			color: #00E7DF;
		}
	}
}
.entry-blocks {
	display: flex;
	margin-top: 15px;
	.entry-block {
		flex: 1;
		border: 1px dashed #505050;
		padding: 16px 2px;
		.entry-label {
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #BDBDBD;
			margin-bottom: 3px;
		}
		.entry-data {
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 1.25px;
			color: #00E7DF;
		}
		border-radius: 10px;
		&.empty {
			padding: 27px 0;
			.entry-label {
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}

	.entry-block + .entry-block {
		margin-left: 20px;
	}

}
.tournament-sidebar {
	&__content,
	&__header {
		width: 100%;
		padding: 25px 20px 25px 25px;
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
	&__enter-code {
		.ui-dashed-input {
			margin-top: 15px;
			margin-bottom: 25px;
		}
	}
	.tournament-sidebar__enter-code {
		.social-links {
			justify-content: center;
			margin-top: 10px;
			a {
				align-items: center;
				width: 202px;
				display: flex;
				border: 1px solid #505050;
				justify-content: center;
				.ui-icon-base {
					margin-right: 10px;
					transform: none;
					position: static;
				}
			}
		}
	}
	&__content {
		text-align: center;
		margin-top: 10px;

		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: $color-gray-300;

		h4 + p {
			text-transform: uppercase;
			margin-top: 10px;
		}
	}

	&__footer {
		margin-top: 20px;
	}

	.ui-participants-counter {
		justify-content: space-between;

		&__value {
			font-size: 20px;
			font-weight: 700;
			letter-spacing: 0.15px;
		}
	}

	.divider {
		display: inline-block;
		width: 100%;
		height: 1px;
		background: $color-gray-500;
		margin-top: 25px;
		margin-bottom: 15px;
	}

	.ui-button {
		width: 100%;
		margin-top: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.social-links {
		display: flex;
		justify-content: space-between;
		a {
			position: relative;
			display: inline-block;
			color: $primary-color;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 1px;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 13px 40px;

			text-align: center;

			.ui-icon-base {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			@include base-transition;

			@include hover-state {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.14), 0 0 7px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.2);
			}

			@include focus-state {
				color: $color-gray-700;
				background-color: $primary-color;
				border-color: $primary-color;
			}
		}

		> * {
			width: calc(50% - 10px);
		}
	}

	.button-group {
		display: flex;
		justify-content: space-between;

		.ui-button {
			max-width: calc(50% - 10px);
		}
	}
}
</style>

import Moment from 'moment'
import datetime from './../config/datetime'

/**
 * loop over all tournaments in array
 * return all tournament posts filtered per day
 */
export default {
  methods: {
    prepareTournamentsByDays (posts) {
      const tournaments = [...posts]
      const newTournaments = { 'Past Tournaments': { date: new Moment().format('YYYYMMDD'), items: [] } }
      tournaments.sort((a, b) => new Moment(a.startedAt).format('YYYYMMDD') - new Moment(b.startedAt).format('YYYYMMDD'))

      tournaments.map(item => {
        const date = new Moment(item.startedAt).format(datetime.FORMAT_DATETIME).split(' ')[0]
        const isExist = Object.keys(newTournaments).filter(key => key === date).length

        // consider only the tournaments that are not older than a week
        if (new Moment(date).diff(new Moment(), 'days') >= -7) {
          // if tournament date already exists, just add it to the existing object
          if (isExist) newTournaments[date].items.push(item)

          // if tournament date has passed, but not of more than a week, check if Past Tournaments object exists
          else if (item.status === 'FINISHED' || (new Moment(item.startedAt).isBefore(new Moment()) && new Moment(item.startedAt).diff(new Moment(), 'days') <= -1)) {
            newTournaments['Past Tournaments'].items.push(item)
          } else {
            // means that tournament date is in the future, so just add it normally
            newTournaments[date] = {
              date: new Moment(date).format(),
              items: [item]
            }
          }
        }
      })

      return newTournaments
    }
  }
}

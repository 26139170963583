import BaseModel from '../BaseModel'

export default class Room extends BaseModel {
	get schema () {
		return {
			id: undefined,
			name: undefined,
			participants: [],
			size: undefined,
			winners: []
		}
	}

	getParticipants () {
		return this.participants
	}

	prepareCreate () {
		this.baseClear()

		return this
	}

	prepareUpdate () {
		this.baseClear()

		return this
	}
}

import BaseModel from './BaseModel'

export default class UserModel extends BaseModel {
	get schema () {
		return {
			isSnakeCase: false,
			avatar: null,
			birthday: null,
			country: null,
			country_id: null,
			created_at: null,
			email: null,
			wallets: null,
			fcm_token: null,
			id: 2182,
			locale: null,
			name: null,
			nickname: null,
			platform: null,
			registration_state: null,
			timezone: null,
			updated_at: null,
			accepted_profiling: null
		}
	}

	getBalanceFromWallet (wallet) {
		if (!this.wallets || !Array.isArray(this.wallets)) {
			return 0
		}
		const findWallet = this.wallets.find(item => item.currency === wallet)
		return findWallet ? new Intl.NumberFormat('en-US').format(findWallet.value) : 0
	}

	prepareCreate () {
		this.baseClear()

		return this
	}

	prepareUpdate () {
		this.baseClear()

		return this
	}
}

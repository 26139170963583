import { CommonService } from '@/services/common.service'

export default {
	getRule ({ commit }, id) {
		return CommonService.getRule(id)
			.then(rule => commit('SET_RULE', rule.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	resetStore ({ commit }) {
		this.reset()
	}
}

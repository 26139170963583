<template>
	<input type="text" v-model="value" @input="$emit('enter', $event.target.value), $emit('input', $event.target.value)" class="ui-dashed-input" :placeholder="placeholder">
</template>
<script>
export default {
	props: {
		placeholder: {
			default: 'ENTRY CODE WILL APPEAR HERE',
			type: String
		},
		value: {
			type: String,
			required: false
		}
	}
}
</script>
<style scoped lang="scss">
input {
	background: #2F2F2F;
	border: 1px dashed #505050;
	padding: 27px 27px;
	padding-top: 24px;
	text-transform: uppercase;
	outline: none;
	text-align: center;
	display: block;
	width: 100%;
	border-radius: 10px;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 5px;
	color: #00E7DF;
	&::placeholder {
		font-size: 14px;
		line-height: 17px;
		font-family: Montserrat;
		letter-spacing: 1.25px;
		color: #8F8F8F;
	}
}
</style>

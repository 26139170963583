import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class LoginService extends BaseService {
	static get entity () {
		return 'login'
	}

	static async getCountries (params = {}) {
		try {
			const response = await this.request({ auth: true }).get(`countries?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getCountry (id) {
		try {
			const response = await this.request({ auth: true }).get(`countries/${id}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getTerms () {
		try {
			const response = await this.request({ auth: true }).get('terms/233/latest')
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async callRecovery (data) {
		try {
			const response = await this.request({ auth: false }).post('login/recovery', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async recoveryConfirmation (data) {
		try {
			const response = await this.request({ auth: false }).put('login/recovery', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async recoverySave (data) {
		try {
			const response = await this.request({ auth: false }).put('login/recovery/save', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

<template>
	<div class="sidebar__container">
		<div class="tournament-sidebar__header">
			<UiParticipantsCounter
				:all="tournament.maxMember"
				:registered="tournament.participantCount"
				:amountAvatars="3"
				:withIcon="true"
			/>
		</div>

		<div class="tournament-sidebar__content" v-if="round">
			<h4>ROUND {{ round.round }}</h4>
			<div class="best-of" v-if="isStartMatch && round.getStatus() !== 'FINISHED'">
				<span>Best of {{ round.gamesInRound }}</span>
				<UiCountDownTimer
					v-if="round.startedAt"
					class="timer__out"
					:startTime="nowDateTime"
					:showDays="false"
					:endTime="endMatchTime"
				/>
			</div>
			<div class="best-of" v-if="round.getStatus() === 'FINISHED'">
				<template v-if="currentParticipant && currentParticipant.participant && currentParticipant.participant.status === 'defeated'">
						Unfortunately you lost
				</template>
				<template v-else>
					<template v-if="Number(round.roomRoundsCount) === Number(round.round)">
						The rounds are over, wait for the next stage
					</template>
					<template v-else>
						<template v-if="tournament.type === 7">
							The match is over, wait for the start of the next round, it will begin after all players have finished playing the current
						</template>
						<template v-else>
							Round finished
						</template>
					</template>
				</template>

			</div>

			<!--			<p v-if="!isWaitStartMatch">The round will end in:-->
			<!--				<UiCountDownTimer-->
			<!--					class="timer__out"-->
			<!--					:startTime="nowDateTime"-->
			<!--					:showDays="false"-->
			<!--					:endTime="round.started_at"-->
			<!--				/>-->
			<!--			</p>-->
			<p v-if="isWaitStartMatch">
				The match will start when your opponent finishes his match
			</p>

			<div class="button__with-separator" v-if="isStartMatch && round.round < rounds && !tournament.type === 7">
				<UiButton
					class="next__match"
					:disabled="loader || round.getStatus() !== 'FINISHED' || Number(round.roomRoundsCount) === Number(round.round)"
					@click="$emit('nextMatch'), loader = true"
					:type="'primary'"
				>
					<template v-if="loader">
						<UiLoader></UiLoader>
					</template>
					<template v-else>
						Next Round
					</template>
				</UiButton>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UiCountDownTimer from '../../UiCountDownTimer'
// import UiCountDownTimer from '@/components/UiCountDownTimer'
import UiParticipantsCounter from '@/components/UiParticipantsCounter'
import startTournamentDateFormat from '@/filters/startTournamentDateFormat'
import UiButton from '@/components/UiButton'
import UiLoader from '../../UiLoader'
import Moment from 'moment'
export default {
	name: 'SidebarContainer',
	props: {
		rounds: {
			type: Number,
			required: true
		},
		round: {
			type: Object,
			required: true
		}
	},
	watch: {
		round () {
			this.loader = false
		}
	},
	components: {
		UiLoader,
		UiParticipantsCounter,
		UiButton,
		// UiCountDownTimer,
		UiCountDownTimer
	},
	filters: { startTournamentDateFormat },
	data () {
		return {
			loader: false,
			showUploadResult: false,
			showEnterCodeBlock: false,
			nowDateTime: new Date().toDateString()
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			tournamentId: 'tournaments/getCurrentTournamentId',
			tournamentUser: 'tournaments/getCurrentTournamentUser',
			currentRound: 'tournaments/getCurrentMatch'
		}),
		currentParticipant () {
			if (!this.tournament.participant) return false
			const participantId = this.tournament.participant.id
			if (this.round.firstParticipant && this.round.firstParticipant.participant && this.round.firstParticipant.participant.id === participantId) {
				return this.round.firstParticipant
			}
			return this.round.secondParticipant
		},
		endMatchTime () {
			const time = new Moment(this.round.startedAt)
			time.add(this.round.duration, 'minutes')
			return time.toDate()
		},
		isStartMatch () {
			return ['STARTED', 'FINISHED', 'PENDING', 'CONFLICT'].includes(this.round.getStatus())
		},
		isWaitMatch () {
			return ['OPENED', 'WAITING'].includes(this.round.getStatus())
		},
		isRegistrationOpenStatus () {
			return ['REGISTRATION_OPENED', 'CHECKIN'].includes(this.tournament.status)
		},
		isWaitStartMatch () {
			return ['WAITING'].includes(this.round.getStatus())
		},
		isJoin () {
			return !!this.tournamentUser && this.tournamentUser.status === 'active'
		},
		isShowEnterCode () {
			return this.tournament.isPrivate && this.showEnterCodeBlock
		},
		isShowInfoBlock () {
			return !['OPENED', 'REGISTRATION_OPENED', 'CHECKIN', 'REGISTRATION_CLOSED'].includes(this.tournament.status)
		},
		userMissingInMatch () {
			return !this.getCurrentTournamentUser
		},
		isRegisteredStatus () {
			return !!this.tournamentUser && !this.isActiveStatus
		},
		isActiveStatus () {
			return this.tournamentUser && this.tournamentUser.status === 'active'
		}
	},
	methods: {
		...mapActions({
			register: 'tournaments/registerParticipant',
			unRegister: 'tournaments/unRegisterParticipant',
			checkIn: 'tournaments/checkInParticipant'
		}),
		isCheckInStatus () {
			return this.tournament.status === 'CHECKIN' && !this.tournamentUser && !this.isActiveStatus
		},
		isUserMissedRegistration () {
			return ['STARTED', 'FINISHED'].includes(this.tournament.status) && !this.tournamentUser
		},
		isStarted () {
			return ['STARTED'].includes(this.tournament.status)
		},
		sendAnalyticData (event) {
			try {
				this.amplitude.logEvent(event, this.tournament.analyticData())
			} catch (e) {
				console.error('Error send information to amplitude')
			}
		},
		tryRegisterWithCode (secret_code) {
			this.register({ id: this.tournamentId, secret_code }).then(() => {
				this.showEnterCodeBlock = false
				const identify = this.amplitudeIdentify.add('registrations', 1)
				this.amplitude.identify(identify)
				this.sendAnalyticData('registered')
			})
		},
		registerClick () {
			if (this.tournament.isPrivate) {
				this.showEnterCodeBlock = true
				return
			}
			this.register({ id: this.tournamentId }).then(() => {
				const identify = this.amplitudeIdentify.add('registrations', 1)
				this.amplitude.identify(identify)
				this.sendAnalyticData('registered')
			})
		},
		unRegisterClick () {
			this.unRegister({
				id: this.tournamentId,
				userId: this.tournamentUser.profile.user.id
			}).then(() => {
				this.sendAnalyticData('unregistered')
			})
		},
		checkInClick () {
			this.checkIn({ tournamentId: this.tournamentId, vm: this }).then(() => {
				const identify = this.amplitudeIdentify.add('check-ins', 1)
				identify.setOnce('played_first_time', new Date())
				this.amplitude.identify(identify)
				this.sendAnalyticData('check_in')
			})
		}
	}
}
</script>

<style lang="scss">
.button__with-separator {
	margin-top: 20px;
	padding-top: 25px;
	padding-right: 25px;
	padding-left: 25px;
	border-top: 1px solid #505050;
	margin-right: -20px;
	margin-left: -25px;
}
.best-of {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	span {
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		text-transform: uppercase;
		color: #BDBDBD;
		margin-right: 10px;
	}

}
.next__match {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	width: 100%;
	height: 48px;
}
.timer__out {
	display: flex;
	align-items: center;
	background: #00E7DF;
	border-radius: 5px;
	padding: 0 7px;
	width: fit-content;
	color: #000
}
.offset-top-50 {
	margin-top: 50px;
}
.tournament-sidebar {
	&__content,
	&__header {
		width: 100%;
		padding: 25px 20px 25px 25px;
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
	&__enter-code {
		.ui-dashed-input {
			margin-top: 15px;
			margin-bottom: 25px;
		}
	}
	.tournament-sidebar__enter-code {
		.social-links {
			justify-content: center;
			margin-top: 10px;
			a {
				align-items: center;
				width: 202px;
				display: flex;
				border: 1px solid #505050;
				justify-content: center;
				.ui-icon-base {
					margin-right: 10px;
					transform: none;
					position: static;
				}
			}
		}
	}
	&__content {
		text-align: center;
		margin-top: 10px;

		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: $color-gray-300;

		h4 + p {
			text-transform: uppercase;
			margin-top: 10px;
		}
	}

	&__footer {
		margin-top: 20px;
	}

	.ui-participants-counter {
		justify-content: space-between;

		&__value {
			font-size: 20px;
			font-weight: 700;
			letter-spacing: 0.15px;
		}
	}

	.divider {
		display: inline-block;
		width: 100%;
		height: 1px;
		background: $color-gray-500;
		margin-top: 25px;
		margin-bottom: 15px;
	}

	.ui-button {
		width: 100%;
		margin-top: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.social-links {
		display: flex;
		justify-content: space-between;
		a {
			position: relative;
			display: inline-block;
			color: $primary-color;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 1px;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 13px 40px;

			text-align: center;

			.ui-icon-base {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			@include base-transition;

			@include hover-state {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.14), 0 0 7px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.2);
			}

			@include focus-state {
				color: $color-gray-700;
				background-color: $primary-color;
				border-color: $primary-color;
			}
		}

		> * {
			width: calc(50% - 10px);
		}
	}

	.button-group {
		display: flex;
		justify-content: space-between;

		.ui-button {
			max-width: calc(50% - 10px);
		}
	}
}
</style>

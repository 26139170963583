<template>
  <div id="app" :class="{'chat-page': $route.name === 'mobile-chat'}">
    <template v-if="!isLoginPage && isAuth">
			<App></App>
		</template>
		<template v-if="isLoginPage">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</template>
  </div>
</template>

<script>
import App from './App'
import { mapGetters } from 'vuex'
import Moment from 'moment'
export default {
  name: 'AppLayout',
  components: {
		App
  },
	computed: {
		...mapGetters({
			isAuth: 'user/isAuth'
		}),
		isLoginPage () {
			return this.$route.name === 'login' || this.$route.name === 'recovery'
		}
	},
	watch: {
		$route: function () {
			const body = document.querySelector('body')
			body.classList.remove('chat-active')
		}
	},
	methods: {
		async initAmplitude () {
			await this.amplitude.getInstance().init('83059baf00779a8717992a9737c6e6da')
			this.amplitude = await this.amplitude.getInstance()
			const identify = this.amplitudeIdentify.setOnce('cohort_date', new Moment().format('YYYY-DD-MM'))
			this.amplitude.identify(identify)
		}
	},
	async mounted () {
		await this.initAmplitude()
	}
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
body.chat-active {
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: #171d39;
		z-index: 99;
		-webkit-transition: 0.2s;
		transition: 0.2s;
		opacity: 0;
		visibility: hidden;
	}
}
html {
	height: 100%;
	width: 100%;
}
.chat-page {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
</style>

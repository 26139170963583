<template>
	<div class="modal player-lose-modal">
		<div class="modal__body">
			<div @click="removeModal" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>

			<div class="lose__content">
				<h1 class="h1">YOU WIN THE ROUND!</h1>
				<img src="@/assets/modals/win.svg" class="prefset-top-40" alt="">
			</div>
			<div class="modal__buttons">
				<UiButton type="primary" @click="removeModal">NEXT MATCH</UiButton>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../../UiButton'
import { mapMutations } from 'vuex'
export default {
	methods: {
		...mapMutations({
			removeModal: 'modals/REMOVE'
		})
	},
	components: {
		UiButton
	}
}
</script>
<style scoped lang="scss">

</style>

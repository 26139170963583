import { TournamentsService } from '../../services/tournaments.service'
import { TftService } from '../../services/tft.service'
export default async function isWinnerInMatch (tournament_id, match_id, participant_id, type) {
	const defaultHandler = async () => {
		const responseMatch = await TournamentsService.getMatch(tournament_id, match_id)
		if (Number(responseMatch.data.winner_id) === Number(participant_id)) {
			return {
				isWinner: true,
				isRound: responseMatch.data.next_match_id !== null,
				isTournament: !responseMatch.data.next_match_id,
				match: responseMatch.data
			}
		}

		return {
			isWinner: false,
			isRound: responseMatch.data.next_match_id !== null,
			isTournament: !responseMatch.data.next_match_id,
			match: responseMatch.data
		}
	}

	const tftHandler = async () => {
		const responseMatch = await TftService.getMatch(tournament_id, match_id)
		const findParticipant = responseMatch.data.participants.find(item => item.participant.id === participant_id)
		if (findParticipant && findParticipant.participant.status === 'winner') {
			return {
				isWinner: true,
				isRound: false,
				isTournament: true,
				match: Object.assign({}, responseMatch.data, { winner_id: participant_id })
			}
		}

		if (findParticipant && findParticipant.participant.status === 'defeated') {
			return {
				isWinner: false,
				isRound: false,
				isTournament: true,
				match: responseMatch.data
			}
		}

		return null
	}
	if (type === 6) {
		return await tftHandler()
	} else {
		return await defaultHandler()
	}
}

export default {
  favouriteGames: [],
	games: [],
	allGames: [],
	wsToken: localStorage.getItem('wsToken') || '',
  currentUser: localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : {},
  currentGame: localStorage.getItem('currentGame') ? localStorage.getItem('currentGame') : null,
	currentTournaments: [],
	currentTeams: null,
	currentSeason: {
		id: null
	},
	leaderboard: []
}

<template>
	<div class="modal">
		<div class="modal__body">
			<div class="close" @click="$emit('close')">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<div class="user">
				<UiAvatar size="50"></UiAvatar>
				<div class="nickname">
					{{ result.user.nickname ? result.user.nickname : (result.user.name ? result.user.name : result.user.email) }}
				</div>
			</div>
			<div class="image">
				<img :src="result.image" alt="">
			</div>
		</div>
	</div>
</template>
<script>
import UiAvatar from '../../../components/UiAvatar'
export default {
	props: {
		result: {
			type: Object,
			required: true
		}
	},
	components: {
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.modal {
	.modal__body {
		padding: 0;
		position: relative;
		.close {
			background: #2F2F2F;
			width: 40px;
			height: 40px;
			display: flex;
			border-bottom-left-radius: 5px;
			&:hover {
				background: rgba(255,255,255, 0.05);
			}
			transition: 0.2s;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid #505050;
			border-left: 1px solid #505050;
			position: absolute;
			right: 0;
			top: 0;
		}
		width: 1170px;
		.user {
			display: flex;
			width: 100%;
			padding: 19px 30px;
			align-items: center;
			.nickname {
				margin-left: 18px;
				font-weight: 700;
				font-size: 24px;
				line-height: 38px;
				letter-spacing: 1px;
				text-transform: uppercase;
			}
		}
		.image {
			width: 100%;
		}
	}
}
</style>

import BaseModel from './BaseModel'

export default class ArenaItem extends BaseModel {
  get schema () {
    return {
      id: undefined,
      duration: undefined,
      gamesCount: undefined,
      participants: undefined,
      password: undefined,
      roomId: undefined,
      round: undefined,
      startedAt: undefined,
      status: undefined,
      tournamentId: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

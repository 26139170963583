<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 13 14">
    <g>
      <path d="M10.2143 8.75C9.54688 8.75 8.9375 8.96875 8.47321 9.32422L5.48438 7.57422C5.54241 7.41016 5.54241 7.21875 5.54241 7C5.54241 6.80859 5.54241 6.61719 5.48438 6.45312L8.47321 4.70312C8.9375 5.05859 9.54688 5.25 10.2143 5.25C11.7522 5.25 13 4.10156 13 2.625C13 1.17578 11.7522 0 10.2143 0C8.64732 0 7.42857 1.17578 7.42857 2.625C7.42857 2.84375 7.42857 3.03516 7.48661 3.19922L4.49777 4.94922C4.03348 4.59375 3.42411 4.375 2.78571 4.375C1.21875 4.375 0 5.55078 0 7C0 8.47656 1.21875 9.625 2.78571 9.625C3.42411 9.625 4.03348 9.43359 4.49777 9.07812L7.48661 10.8281C7.42857 10.9922 7.42857 11.1836 7.42857 11.4023V11.375C7.42857 12.8516 8.64732 14 10.2143 14C11.7522 14 13 12.8516 13 11.375C13 9.92578 11.7522 8.75 10.2143 8.75Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

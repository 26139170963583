<template>
	<div class="auth-form">
		<div class="tabs">
			<div class="tab" @click="tab = 'login'" :class="{'is-active': tab === 'login'}">
				Sign in
			</div>
			<div class="tab" @click="tab = 'register'" :class="{'is-active': tab === 'register'}">
				sign up
			</div>
		</div>
		<LoginForm v-show="tab === 'login'"></LoginForm>
		<RegisterForm v-show="tab === 'register'"></RegisterForm>
	</div>
</template>
<script>
// import UiButton from '../UiButton'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
export default {
	data () {
		return {
			tab: 'login'
		}
	},
	components: {
		// UiButton,
		RegisterForm,
		LoginForm
	}
}
</script>
<style lang="scss">
.auth-form {
	padding-bottom: 41px;
	.error-input {
		font-size: 10px;
		line-height: 20px;
		margin-left: auto;
		text-align: right;
		display: block;
		text-transform: uppercase;
		color: #D71411;
		position: absolute;
		right: 0;
		bottom: -20px;
		&.error_static {
			position: static;
			text-align: left;
			margin-left: 0;
			margin: 10px 0;
		}
	}
	.tabs {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		.tab {
			color: #505050;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 30px;
			line-height: 32px;
			padding: 19px 15px;
			padding-top: 50px;
			transition: 0.2s;
			cursor: pointer;
			border-bottom: 1px solid transparent;
			&.is-active, &:hover {
				background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #fff;
				font-weight: bold;
				font-size: 30px;
				line-height: 32px;
				border-bottom: 1px solid #00E7DF
			}
		}
	}
	.form {
		.input {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			border: 1px solid #505050;
			border-radius: 5px;
			.input__icon {
				width: 56px;
				min-width: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				user-select: none;
			}
			.input__field {
				flex-grow: 1;
				input {
					width: 100%;
					background: transparent;
					padding: 15px 0;
					padding-right: 15px;
					font-size: 10px;
					line-height: 20px;
					border: none;
					outline: none;
					letter-spacing: 2px;
					color: #B0B0B0;
					&::placeholder {
						text-transform: uppercase;
					}
				}
			}
			.eye {
				width: 18px;
				margin-right: 11px;
				cursor: pointer;
				position: relative;
				&.with-line {
					&:after {
						position: absolute;
						width: 100%;
						height: 1.5px;
						content: "";
						display: block;
						top: 9px;
						background: #b0b0b0;
						transform: rotate(45deg)
					}
				}
			}
			&.is-correct {
				border: 1px solid #00E7DF;
			}
			&.is-invalid {
				border: 1px solid #D71411;
				.input__icon {
					svg {
						path {
							fill: #D71411
						}
					}
				}
				.input__field {
					input {
						color: #D71411;
						&::placeholder {
							color: #D71411;
						}
					}
				}
			}
		}
		.input + .input {
			margin-top: 25px;
		}
		.checkbox {
			margin-top: 20px;
			margin-bottom: 20px;
			input {
				display: none;
			}
			label {
				display: flex;
				cursor: pointer;
				align-items: center;
				.square {
					margin-right: 10px;
					border: 1px solid #00E7DF;
					width: 20px;
					transition: 0.01s;
					height: 20px;
					min-width: 20px;
					border-radius: 5px;
				}
				.name {
					font-size: 14px;
					line-height: 16px;
					letter-spacing: 1px;
					color: #BDBDBD;
				}
			}
			input:checked + label {
				.square {
					background: url('~@/assets/check.svg');
					background-size: 12px 12px;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
		}
		.ui-button {
			width: 100%;
			padding: 15.5px 0;
		}
	}
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
	-webkit-text-fill-color: #B0B0B0 !important;
	-webkit-box-shadow: 0 0 0 30px #2f2f2f inset !important;
}

@media screen and (max-width: 768px) {
	.auth-form {
		padding-bottom: 24px;
		.tabs {
			margin-left: -24px;
			margin-right: -24px;
			width: auto;
			.tab {
				padding-top: 24px;
				font-size: 24px;
				line-height: 32px;
				padding-right: 20px;
				padding-left: 20px;
				&.is-active {
					font-size: 24px;
					line-height: 32px;
				}
			}
		}
	}
}
</style>

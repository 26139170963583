import TournamentItemModel from '@/models/TournamentItemModel'
import TournamentModel from '@/models/TournamentModel'
import ParticipantModel from '@/models/ParticipantModel'
import TeamModel from '@/models/TeamModel'
import UserModel from '@/models/UserModel'
import UserInfoModel from '@/models/UserInfoModel'
import PageMetaModel from '@/models/PageMetaModel'
import { mapKeys, snakeCase } from 'lodash'
import { prepare as prepareLeaderBoard } from '@/model-preparing/tournaments/leaderboard/set-leaderboard.js'
import { prepare as prepareMatch } from '@/model-preparing/tournaments/match.js'

export default {
	SET_TOURNAMENTS (state, value) {
		const tournaments = []

		value.data.map(item => {
			tournaments.push(new TournamentItemModel(item))
		})

		state.tournaments = tournaments
		state.tournamentsMeta = new PageMetaModel(value.meta)
	},
	UPDATE_TOURNAMENTS (state, value) {
		const tournaments = state.tournaments

		value.data.map(item => {
			tournaments.push(new TournamentItemModel(item))
		})

		state.tournaments = tournaments
		state.tournamentsMeta = new PageMetaModel(value.meta)
	},
	SET_CURRENT_TOURNAMENT (state, value) {
		const tournament = value
		if (tournament.participant) {
			if (tournament.match_type === 2) {
				tournament.participant.team = new TeamModel(tournament.participant.team)
			}

			if (tournament.match_type === 1) {
				tournament.participant.profile.user = new UserModel(tournament.participant.profile.user)
				tournament.participant.profile.info = new UserInfoModel(tournament.participant.profile.info)
			}

			tournament.participant = new ParticipantModel(tournament.participant)
		}
		state.tournament = new TournamentModel(tournament)
	},
	SET_CURRENT_TOURNAMENT_PARTICIPANT (state, value) {
		state.tournament.participant = value
		const tournament = state.tournament
		if (tournament.match_type === 2) {
			tournament.participant.team = new TeamModel(value.team)
		}

		if (tournament.match_type === 1) {
			tournament.participant.profile.user = new UserModel(value.profile.user)
			tournament.participant.profile.info = new UserInfoModel(value.profile.info)
		}

		tournament.participant = new ParticipantModel(tournament.participant)

		const formattedForModel = mapKeys(tournament, (value, key) => {
			return snakeCase(key)
		})
		state.tournament = new TournamentModel(formattedForModel)
	},
	REPLACE_ROUND_LEADER_BOARD (state, { rounds, id }) {
		const prepareRounds = prepareLeaderBoard(state, rounds)
		const findInState = state.leaderBoard.findIndex(item => item.id === id)
		const findInData = prepareRounds.find(item => item.id === id)

		if (findInState > -1 && findInData) {
			state.leaderBoard.splice(findInState, 1, findInData)
		}
	},
	SET_CURRENT_TOURNAMENT_PARTICIPANT_ACTIVE (state) {
		state.tournament.participant.status = 'active'
	},
	DELETE_TOURNAMENT_PARTICIPANT (state) {
		state.tournament.participant = null
	},
	SET_PARTICIPANTS (state, value) {
		const participants = []

		value.data.map(item => {
			if (item.type === 2 && item.team) {
				item.team = new TeamModel(item.team)
			}

			if (item.type === 1) {
				item.profile.user = new UserModel(item.profile.user)
				item.profile.info = new UserInfoModel(item.profile.info)
			}

			participants.push(new ParticipantModel(item))
		})

		state.participants = participants
	},
	UPDATE_PARTICIPANT (state, data) {
		state.participants.map(item => {
			if (data.id === item.id) {
				item.update(data)
			}
		})
	},
	SET_LEADER_BOARD (state, value) {
		state.leaderBoard = prepareLeaderBoard(state, value)
	},
	SET_RESULTS (state, value) {
		state.results = value.data
	},
	SET_MATCHES (state, value) {
		state.matches = prepareMatch(state, value)
	},
	SET_CURRENT_MATCH (state, data) {
		const value = data.data.filter(item => item.first_participant || item.second_participant)
		const holder = data.holder
		if (!holder) {
			return
		}
		let match = null
		for (const item in value) {
			if (value[item].first_participant) {
				if (value[item].first_participant.id === holder.id) {
					match = value[item]
				}
			}
			if (value[item].second_participant) {
				if (value[item].second_participant.id === holder.id) {
					match = value[item]
				}
			}
		}
		if (match) {
			state.current_match = match
		}
	}
}

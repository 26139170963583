<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 8 5">
    <g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06 0L4 3.09042L0.94 0L0 0.951417L4 5L8 0.951417L7.06 0Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

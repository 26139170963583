<template>
	<div class="profile-game-form">
		<UiToastList/>
		<div class="profile-game-form__header">
			<div class="profile-game-form__logo">
				<UiImgLoader :src="logo"/>
			</div>
		</div>
		<div class="profile-game-form__content">
			<div class="shell">
				<div class="range">
					<div class="cell-xs-12">
						<div class="profile-game-form__banner">
							<UiImgLoader :src="banner"/>
						</div>
					</div>
					<div class="cell-xs-12">
						<h2>{{ $t('Form') }}</h2>
						<div class="input-wrapper" :class="{ 'is-set': form.teamName }">
							<label for="form-input-name">
								{{ $t('Nome del team *') }}
							</label>

							<input
								id="form-input-name"
								type="text"
								v-model="form.teamName"
								:placeholder="$t('Nome del team')"
							>
						</div>
					</div>
					<div class="cell-sm-6 offset-top-30">
						<div class="input-wrapper" :class="{ 'is-set': form.firstPlayer }">
							<label for="form-input-nickname">
								{{ $t('Nickname del 1° giocatore *') }}
							</label>

							<input
								id="form-input-nickname"
								type="text"
								v-model="form.firstPlayer"
								:placeholder="$t('Nickname del 1° giocatore')"
							>
						</div>
					</div>
					<div class="cell-sm-6 offset-top-30">
						<div class="input-wrapper" :class="{ 'is-set': form.secondPlayer }">
							<label for="form-input-surname">
								{{ $t('Nickname del 2° giocatore *') }}
							</label>

							<input
								id="form-input-surname"
								type="text"
								v-model="form.secondPlayer"
								:placeholder="$t('Nickname del 2° giocatore')"
							>
						</div>
					</div>
					<div class="cell-sm-6 offset-top-30">
						<div class="input-wrapper" :class="{ 'is-set': form.region }">
							<label for="form-input-region">
								{{ $t('Regione italiana per cui si vuole giocare *') }}
							</label>
							<input
								id="form-input-region"
								type="text"
								v-model="form.region"
								:placeholder="$t('Regione italiana')"
							>
						</div>
					</div>
					<div class="cell-sm-6 offset-top-30">
						<div class="input-wrapper" :class="{ 'is-set': form.email }">
							<label for="form-input-email">
								{{ $t('Email nella quale si riceverà la password *') }}
							</label>

							<input
								id="form-input-email"
								type="text"
								v-model="form.email"
								:placeholder="$t('Email nella quale si riceverà la password')"
							>
						</div>
					</div>
					<div class="cell-xs-12 offset-top-10">
						<div class="agreement">
							<UiCheckbox label="Acconsento di ricevere la password per partecipare all'evento via e-mail. Comprendo che la mia e-mail verrà utilizzata solo per inviarmi questa password." labelId="agree" :checked="form.isAgree" @change="form.isAgree = $event"></UiCheckbox>
						</div>
					</div>
					<div class="cell-xs-12 offset-top-30">
						<UiButton
							:disabled="isButtonDisabled()"
							:type="'primary'"
							@click="send()"
						>
							{{ $t('Send result') }}
						</UiButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UiImgLoader from '../components/UiImgLoader'
	import UiButton from '../components/UiButton'
	import UiToastList from '../components/UiToastList'
	import UiCheckbox from '@/components/UiCheckbox'
	import { mapMutations } from 'vuex'
	import { CommonService } from '@/services/common.service'

	export default {
		name: 'ProfileGameForm',
		data () {
			return {
				logo: require('../assets/logo-with-site-name.png'),
				banner: require('../assets/form_banner.jpg'),
				form: {
					teamName: '',
					firstPlayer: '',
					secondPlayer: '',
					region: '',
					email: '',
					isAgree: false
				}
			}
		},
		components: {
			UiImgLoader,
			UiButton,
			UiToastList,
			UiCheckbox
		},
		methods: {
			...mapMutations({
				newToast: 'toast/NEW'
			}),
			send () {
				if (this.isButtonDisabled()) return

				CommonService.sendChampionshipForm(this.form)
					.then(() => {
						this.newToast({
							type: 'success',
							message: 'Message was sent!'
						})

						this.form = {
							teamName: '',
							firstPlayer: '',
							secondPlayer: '',
							region: '',
							email: '',
							isAgree: false
						}
					})
					.catch(error => {
						this.newToast({
							type: 'error',
							message: error.message
						})
					})
			},
			isButtonDisabled () {
				return !(this.form.teamName && this.form.firstPlayer && this.form.secondPlayer && this.form.region && this.form.email) || !this.form.isAgree
			}
		}
	}
</script>

<style lang="scss" scoped>
	.profile-game-form {
		.agreement {
			display: flex;
			align-items: center;
			.ui-checkbox {
				margin-right: 15px;
			}
		}
		h2 {
			font-weight: 700;
			font-size: 20px;
			line-height: 28px;
			letter-spacing: 0.15px;
			text-transform: uppercase;
			color: $color-white;
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.ui-button {
			padding-top: 15px;
			padding-bottom: 15px;
			width: 100%;
		}

		&__header {
			display: flex;
			padding: 15px 135px;
			border-bottom: 1px solid $color-gray-600;

			@media (max-width: 767px) {
				justify-content: center;
				padding: 15px 20px;
			}
		}

		&__content {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&__logo {
			max-width: 137px;
		}

		&__banner {
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 12px;
			overflow: hidden;
		}

		.input-wrapper {
			width: 100%;
		}

		.offset-top-30 {
			margin-top: 30px;
		}

		.offset-top-10 {
			margin-top: 10px;
		}

		label {
			cursor: pointer;
			display: block;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.15px;
			color: $color-white;
			margin-bottom: 5px;
		}

		select,
		input {
			width: 100%;
			background-color: transparent;
			border: 1px solid $color-gray-500;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 15px 12px;
			outline: none;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0.15px;
			color: $color-gray-400;

			&:focus {
				border: 1px solid $color-white;
			}
		}

		.is-input-error {
			border: 1px solid #D71411;
		}

		.is-set {
			select,
			input {
				border-color: $primary-color;
			}
		}

		.input-select {
			position: relative;

			select {
				-moz-appearance: none;
				-webkit-appearance: none;
			}

			.ui-icon-base {
				position: absolute;
				right: 15px;
				top: 17px;
			}
		}
	}
</style>

import { render, staticRenderFns } from "./RoundResult.vue?vue&type=template&id=39e24bb2&scoped=true&"
import script from "./RoundResult.vue?vue&type=script&lang=js&"
export * from "./RoundResult.vue?vue&type=script&lang=js&"
import style0 from "./RoundResult.vue?vue&type=style&index=0&id=39e24bb2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e24bb2",
  null
  
)

export default component.exports
import { TournamentsService } from '@/services/tournaments.service'
import { find } from '../../../helpers/tournaments/findTeamForTournament'
export default {
	getTournaments ({ commit }, data) {
		return TournamentsService.getTournaments(data)
			.then(tournaments => commit('SET_TOURNAMENTS', tournaments.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	updateTournaments ({ commit }, data) {
		return TournamentsService.getTournaments(data)
			.then(tournaments => commit('UPDATE_TOURNAMENTS', tournaments.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	getTournament ({ commit }, id) {
		return TournamentsService.getTournament(id)
			.then(tournament => commit('SET_CURRENT_TOURNAMENT', tournament.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	getParticipants ({ commit }, id) {
		return TournamentsService.getParticipants(id)
			.then(participants => commit('SET_PARTICIPANTS', participants.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	getLeaderBoard ({ commit }, { id, commitToState = true, replaceById = 0 }) {
		return TournamentsService.getLeaderBoard(id)
			.then(rounds => {
				if (commitToState) {
					commit('SET_LEADER_BOARD', rounds.data)
				}
				if (replaceById) {
					commit('REPLACE_ROUND_LEADER_BOARD', { rounds: rounds.data, id: replaceById })
				}
				return rounds
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	getResults ({ commit }, id) {
		return TournamentsService.getResults(id)
			.then(results => commit('SET_RESULTS', results.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	setResults ({ commit }, data) {
		return TournamentsService.setResults(data).then(response => {
			commit('toast/NEW', { type: 'info', message: 'Success send results!' }, { root: true })
		}).catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async getMatches ({ commit, rootGetters, state }, id) {
		return await TournamentsService.getMatches(id)
			.then(async matches => {
				await commit('SET_MATCHES', matches.data)
				await commit('tournaments/SET_CURRENT_MATCH', {
					data: matches.data.data,
					holder: rootGetters['tournaments/getCurrentTournamentUser'],
					match_type: rootGetters['tournaments/getCurrentTournament'].matchType
				}, { root: true })
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},

	async getTftMatches ({ commit, rootGetters, state }, id) {
		return await TournamentsService.getTftMatches(id)
			.then(async matches => {
				await commit('SET_MATCHES', matches.data)
				await commit('tournaments/SET_CURRENT_MATCH', {
					data: matches.data.data,
					holder: rootGetters['tournaments/getCurrentTournamentUser'],
					match_type: rootGetters['tournaments/getCurrentTournament'].matchType
				}, { root: true })
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},

	async registerParticipant ({ commit, dispatch, state, rootGetters }, data) {
		const handler = async (payload) => {
			await TournamentsService.registerParticipant(payload)
				.then(participant => commit('SET_CURRENT_TOURNAMENT_PARTICIPANT', participant.data.data))
				.catch(error => {
					commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
				})
		}
		if (rootGetters['tournaments/getCurrentTournament'].isTeamType()) {
			const teams = rootGetters['user/currentTeams']
			const tournament = rootGetters['tournaments/getCurrentTournament']
			return await find(tournament, teams).then((team) => {
				const payload = Object.assign({}, data, { team_id: team.id })
				return handler(payload)
			}).catch((error) => {
				commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
			})
		}
		return await handler(data)
	},
	unRegisterParticipant ({ commit }, data) {
		return TournamentsService.unRegisterParticipant(data.id, data.userId)
			.then(() => commit('DELETE_TOURNAMENT_PARTICIPANT'))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	checkInParticipant ({ commit, dispatch }, { tournamentId, vm }) {
		return TournamentsService.checkInParticipant(tournamentId)
			.then(async () => {
				await commit('SET_CURRENT_TOURNAMENT_PARTICIPANT_ACTIVE')
				dispatch('user/getCurrentTournaments', vm, { root: true })
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	}
}

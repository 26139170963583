<template>
	<div class="teams page">
		<UiPageTopBar
		title="Teams"
		:with-prev-button="true"
		></UiPageTopBar>
		<div class="shell">
			<div class="teams__content">
				<div class="teams__content-viewport">
					<EmptyTeamsList v-if="!teams.length"></EmptyTeamsList>
					<TeamsList v-else></TeamsList>
				</div>
				<div class="teams__content-sidebar">
					<CreateTeamContainer></CreateTeamContainer>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CreateTeamContainer from '@/components/teams/CreateTeamContainer'
import EmptyTeamsList from '@/components/teams/EmptyTeamsList'
import TeamsList from '@/components/teams/TeamsList'
import { mapGetters } from 'vuex'
import UiPageTopBar from '../../components/UiPageTopBar'
export default {
	computed: {
		...mapGetters({
			teams: 'user/currentTeams'
		})
	},
	mounted () {
		this.amplitude.logEvent('screen_view', {
			screen_name: 'Teams'
		})
	},
	components: {
		UiPageTopBar,
		CreateTeamContainer,
		EmptyTeamsList,
		TeamsList
	}
}
</script>
<style scoped lang="scss">
.teams {
	.teams__content {
		display: flex;
		margin-top: 40px;
		.teams__content-viewport {
			flex-grow: 1;
			overflow: hidden;
			margin-right: 20px;
		}
		.teams__content-sidebar {
			width: 377px;
			min-width: 377px;
		}
	}
}
@media screen and (max-width: 992px) {
	.teams {
		.teams__content {
			flex-wrap: wrap;
			padding-bottom: 50px;
			.teams__content-viewport {
				width: 100%;
				margin-right: 0;
			}
			.teams__content-sidebar {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.teams {
		.shell {
			width: 100%;
			min-width: 100%;
		}
		.teams__content {
			margin-top: 0;
			.teams__content-sidebar {
				max-width: 100%;
				min-width: 100%;
			}
		}
	}
}
</style>

<template>
	<UiOnClickOutside :do="hideContent">
		<div class="profile-dropdown" :class="{opened: isShown}">
			<div class="profile-dropdown__toggle" @click="toggleMenu">
				<div class="profile-dropdown__avatar">
					<UiAvatar :src="user.avatar"/>
				</div>
				<div class="profile-dropdown__nickname">
					{{ user.nickname }}
				</div>
				<div class="profile-dropdown__icon">
					<UiBaseIcon width="8" height="5" iconName="arrow-down"/>
				</div>
			</div>
			<div class="profile-dropdown__content" v-if="isShown" @click="hideContent">
				<ul>
					<li>
						<RouterLink :to="{ name: 'profile' }" exact>
							<UiBaseIcon iconName="user"/>
							<span>{{ $t('Profile') }}</span>
						</RouterLink>
					</li>
					<li>
						<a href="#" @click.prevent="logout">
							<UiBaseIcon iconName="logout"/>
							<span>{{ $t('Logout') }}</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</UiOnClickOutside>
</template>

<script>
	import { AuthService } from '@/services/auth.service'

	import UiAvatar from '@/components/UiAvatar'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'
	import UiOnClickOutside from '@/components/UiOnClickOutside'

	export default {
		name: 'ProfileDropdown',
		props: {
			listType: {
				type: Boolean,
				default: true
			}
		},

		components: {
			UiAvatar,
			UiBaseIcon,
			UiOnClickOutside
		},

		data () {
			return {
				isShown: false
			}
		},
		computed: {
			user () {
				return {
					nickname: this.$currentUser.nickname,
					avatar: this.$currentUser.avatar
				}
			}
		},
		methods: {
			toggleMenu () {
				this.isShown = !this.isShown
			},
			hideContent () {
				if (this.isShown) this.isShown = false
			},
			async logout () {
				this.amplitude.logEvent('logout')
				await AuthService.makeLogout()
			},
			async goToForm () {
				this.$router.push('/send-data')
			}
		},

		mounted () {
		}
	}
</script>

<style lang="scss">
	.profile-dropdown {
		position: relative;
	}

	.profile-dropdown__content {
		text-align: center;
		position: absolute;
		top: 100%;
		right: 0;
		width: 100%;
		margin-top: 1px;
		background: $color-body;
		border: 1px solid $color-gray-600;
		z-index: 99999;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
	}

	.profile-dropdown__toggle {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-bottom: 1px solid transparent;
		transition: 0.2s linear;
		padding: 9px 35px 9px 9px;
		min-width: 195px;
		min-height: 62px;
		margin-bottom: -1px;

		.opened &,
		&:hover {
			border-color: $primary-color;
			background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
		}
	}

	.profile-dropdown__avatar {
		margin-right: 10px;
		max-height: 40px;
		overflow: hidden;
		.avatar {
			max-height: 40px;
		}
	}

	.profile-dropdown__icon {
		position: absolute;
		right: 25px;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.2s linear;
		font-size: 0;
		line-height: 0;

		.opened & {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.profile-dropdown__nickname {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.15px;
		color: $color-white;
	}

	.profile-dropdown__content {
		padding-top: 10px;
		padding-bottom: 10px;

		a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-weight: normal;
			font-size: 10px;
			line-height: 20px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: $color-white;
			text-decoration: none;
			padding: 10px 20px;
			transition: 0.2s linear;
			width: 100%;

			@include hover-state {
				color: $primary-color;
			}

			.ui-icon-base {
				margin-right: 10px;
			}
		}
	}
</style>

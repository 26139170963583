import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
export class RoundRobinService extends BaseService {
	static async getRooms (tournamentId) {
		try {
			const response = await this.request({ auth: true }).get(`tournaments/round-robin/${tournamentId}/rooms`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getCurrentMatch (tournamentId) {
		try {
			const response = await this.request({ auth: true }).get(`tournaments/round-robin/${tournamentId}/current-match`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async setResultsMatch (tournamentId, matchId, data) {
		try {
			const response = await this.request({ auth: true }).post(`tournaments/round-robin/${tournamentId}/matches/${matchId}/result`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

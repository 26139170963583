<template>
	<div
		class="ui-brackets-player"
		:class="getPlayerClass()"
		@click="(playerInfo.type === 2 && playerInfo.teamId ? $router.push(`/team/${playerInfo.teamId}`) : '')"
	>
		<div class="ui-brackets-player__content" v-if="playerInfo">
			<div class="ui-brackets-player__avatar">
				<UiAvatar :src="playerInfo.avatar"/>
			</div>
			<div class="ui-brackets-player__name">{{ playerInfo.nickname }}<br>
			<em>#{{ playerInfo.profileId }}</em>
			</div>
			<div class="ui-brackets-player__result">
				<span v-if="playerInfo.place">{{ playerInfo.place }}</span>
				<span v-else>{{ result }}</span>
			</div>
		</div>
		<div class="ui-brackets-player__content" v-else>
			<div class="ui-brackets-player__avatar">
				<UiAvatar :src="emptyAvatar"/>
			</div>
			<div class="ui-brackets-player__name">{{ $t('EMPTY') }}</div>
			<div class="ui-brackets-player__result" v-if="playerInfo">
				<span>{{ 0 }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import UiAvatar from '@/components/UiAvatar'

	export default {
		name: 'UiBracketItemPlayer',
		props: {
			winner: {
				type: Boolean,
				default: null
			},
			data: Object,
			player: String,
			result: String
		},
		components: {
			UiAvatar
		},
		data () {
			return {
				emptyAvatar: require('@/assets/avatar-gray.svg')
			}
		},
		computed: {
			playerInfo: function () {
				const match = this.data
				if (!this.data) return false
				const currentPlayer = match[this.player]
				if (!currentPlayer) return false

				return {
					id: currentPlayer.id,
					type: currentPlayer.type,
					avatar: currentPlayer.type === 2 ? currentPlayer.team.logo : currentPlayer.profile.user.avatar,
					nickname: currentPlayer.type === 2 ? currentPlayer.team.name : (currentPlayer.profile.user.nickname ? currentPlayer.profile.user.nickname : currentPlayer.profile.user.name),
					winnerId: match.winnerId,
					teamId: currentPlayer.type === 2 ? currentPlayer.team.id : null,
					profileId: currentPlayer.type === 1 ? (currentPlayer.profile.info.id ?? currentPlayer.profile.info.nickname) : currentPlayer.team.name,
					place: this.data.place,
					hasWinner: match.winnerId && currentPlayer.id !== null,
					isWinner: match.winnerId === currentPlayer.id
				}
			}
		},
		methods: {
			getPlayerClass () {
				if (!this.playerInfo) return ['is-empty']
			
				const classes = ['brackets__player-' + this.player]
				if (this.winner) {
					classes.push('winner')
				}
				if (this.playerInfo.type === 2) {
					classes.push('pointer')
				}
				if (!this.playerInfo.hasWinner) return classes.join(' ')

				if (this.playerInfo.isWinner) {
					classes.push('is-winner')
				} else {
					classes.push('is-defeated')
				}

				return classes.join(' ')
			}
		}
	}
</script>

<style lang="scss">
	.ui-brackets-player {
		position: relative;
		min-width: 278px;
		padding: 10px 40px 10px 10px;
		&.pointer {
			cursor: pointer;
		}
		.ui-brackets-player__name {
			em {
				font-weight: 400;
				font-size: 10px;
				line-height: 14px;
				letter-spacing: 0.25px;
				color: #BDBDBD;
			}
		}
		&.winner {
			background: #018786;
		}

		&__avatar {
			margin-right: 10px;
		}

		&__content {
			display: flex;
			align-items: center;
			width: 100%;
		}

		&__name {
			font-weight: 700;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.25px;

			color: $color-white;
		}

		&__result {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			width: 30px;
			height: 30px;

			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.75px;
			text-transform: uppercase;

			color: $color-white;
			border: 1px solid $color-gray-500;
			border-radius: 5px;
		}

		.avatar {
			max-width: 28px;
			min-width: 28px;
			min-height: 28px;

			> .img-loader {
				min-height: 26px;
			}
		}
	}

	.is-empty {
		.ui-brackets-player {
			&__result,
			&__name {
				color: $color-gray-400;
			}
		}
	}

	.is-winner {
		background: #018786;

		.ui-brackets-player {
			&__avatar {
				position: relative;
				color: $color-gray-400;

				&:before {
					content: "";
					position: absolute;
					bottom: -3px;
					right: -3px;
					width: 14px;
					height: 14px;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='%2300E7DF'/%3E%3Cpath d='M9.6 9.25H4.4C4.2875 9.25 4.2 9.34375 4.2 9.4375V9.8125C4.2 9.91797 4.2875 10 4.4 10H9.6C9.7 10 9.8 9.91797 9.8 9.8125V9.4375C9.8 9.34375 9.7 9.25 9.6 9.25ZM10.4 5.5C10.0625 5.5 9.8 5.75781 9.8 6.0625C9.8 6.15625 9.8125 6.22656 9.85 6.29688L8.95 6.8125C8.75 6.91797 8.5 6.85938 8.3875 6.67188L7.375 4.99609C7.5125 4.90234 7.6 4.73828 7.6 4.5625C7.6 4.25781 7.325 4 7 4C6.6625 4 6.4 4.25781 6.4 4.5625C6.4 4.73828 6.4875 4.90234 6.6125 4.99609L5.6 6.67188C5.4875 6.85938 5.2375 6.91797 5.05 6.8125L4.1375 6.29688C4.175 6.22656 4.2 6.15625 4.2 6.0625C4.2 5.75781 3.925 5.5 3.6 5.5C3.2625 5.5 3 5.75781 3 6.0625C3 6.37891 3.2625 6.625 3.6 6.625C3.625 6.625 3.6625 6.625 3.6875 6.625L4.6 8.875H9.4L10.3 6.625C10.325 6.625 10.3625 6.625 10.4 6.625C10.725 6.625 11 6.37891 11 6.0625C11 5.75781 10.725 5.5 10.4 5.5Z' fill='%23121212'/%3E%3C/svg%3E%0A");
				}
			}

			&__result {
				border-color: $primary-color;
				background: $primary-color;
				color: $color-gray-700;
			}
		}

		.avatar {
			background: #018786;
			border-color: $primary-color;
		}
	}

</style>

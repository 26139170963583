<template>
	<div class="leaderboard page">
		<UiPageTopBar
			:title="'Leaderboard'"
		>
			<div class="leaderboard__sorting">
				<span>Sort by:</span>
				<div class="leaderboard__sorting--list">
					<UiButton :class="{'active': typeLeaderboard === 'solo'}" type="secondary" @click="changeType('solo')">
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6 6.75C7.85156 6.75 9.375 5.25 9.375 3.375C9.375 1.52344 7.85156 0 6 0C4.125 0 2.625 1.52344 2.625 3.375C2.625 5.25 4.125 6.75 6 6.75ZM9 7.5H7.6875C7.17188 7.75781 6.60938 7.875 6 7.875C5.39062 7.875 4.80469 7.75781 4.28906 7.5H3C1.33594 7.5 0 8.85938 0 10.5V10.875C0 11.5078 0.492188 12 1.125 12H10.875C11.4844 12 12 11.5078 12 10.875V10.5C12 8.85938 10.6406 7.5 9 7.5Z" fill="#8F8F8F"/>
						</svg>
						Solo
					</UiButton>
					<UiButton :class="{'active': typeLeaderboard === 'team'}" type="secondary" @click="changeType('team')">
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11.6971 10.0663C11.6971 10.0663 10.8967 9.26617 10.4155 8.78525L8.78864 10.4121L10.0706 11.6936C10.6467 12.2713 11.3941 11.9012 11.6488 11.6457C12.0365 11.2617 12.1715 10.5421 11.6977 10.0669C11.6975 10.0667 11.6974 10.0665 11.6971 10.0663Z" fill="#8F8F8F"/>
							<path d="M10.1344 6.65609C9.7434 6.26507 9.1094 6.26507 8.71838 6.65609L6.6599 8.71456C6.26888 9.10558 6.26888 9.73958 6.6599 10.1306C7.05093 10.5216 7.68484 10.5216 8.07586 10.1306L10.1344 8.07204C10.5254 7.68102 10.5254 7.04711 10.1344 6.65609Z" fill="#8F8F8F"/>
							<path d="M0.302858 10.0663C0.302583 10.0665 0.3024 10.0667 0.302217 10.0668C-0.171568 10.5421 -0.036528 11.2616 0.351106 11.6457C0.605806 11.9012 1.35324 12.2712 1.92929 11.6935L3.2113 10.412L1.58441 8.78525C1.10321 9.26617 0.302858 10.0663 0.302858 10.0663Z" fill="#8F8F8F"/>
							<path d="M3.12286 5.50278L5.50278 3.12286L2.48291 0.102997C2.41708 0.0369873 2.32764 0 2.23434 0H0.351562C0.157379 0 0 0.157379 0 0.351562V2.23434C0 2.32764 0.0369873 2.41708 0.102997 2.48291L3.12286 5.50278Z" fill="#8F8F8F"/>
							<path d="M12.0001 2.23434V0.351562C12.0001 0.157379 11.8427 0 11.6485 0H9.76572C9.67242 0 9.58307 0.0369873 9.51715 0.102997L3.62006 6L6.00006 8.38L11.8971 2.483C11.9631 2.41708 12.0001 2.32764 12.0001 2.23434Z" fill="#8F8F8F"/>
							<path d="M3.28173 6.65609C2.89071 6.26507 2.25671 6.26507 1.86568 6.65609C1.47466 7.04711 1.47466 7.68102 1.86568 8.07204L3.92416 10.1306C4.31518 10.5216 4.94918 10.5216 5.3402 10.1306C5.73122 9.73958 5.73122 9.10558 5.3402 8.71456L3.28173 6.65609Z" fill="#8F8F8F"/>
						</svg>

						Team
					</UiButton>
					<UiButton :class="{'active': typeLeaderboard === 'clan'}" type="secondary" @click="changeType('clan')">
						<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M11.25 1.94626L6.45 0.0703468C6.3 0.0234489 6.15 0 6 0C5.825 0 5.675 0.0234489 5.525 0.0703468L0.725 1.94626C0.275 2.13385 0 2.53249 0 2.97802C0 7.64436 2.85 10.8569 5.525 11.9121C5.825 12.0293 6.15 12.0293 6.45 11.9121C8.6 11.0679 12 8.18368 12 2.97802C12 2.53249 11.7 2.13385 11.25 1.94626ZM6 10.4582V1.52418L10.375 3.23595C10.3 6.77675 8.325 9.35613 6 10.4582Z" fill="#8F8F8F"/>
						</svg>

						Clan
					</UiButton>
				</div>
			</div>

		</UiPageTopBar>
		<div class="shell">
			<div class="leaderboard__content">
				<SingleLeaderboardTable v-if="typeLeaderboard === 'solo'"></SingleLeaderboardTable>
				<TeamLeaderboardTable v-if="typeLeaderboard === 'team'"></TeamLeaderboardTable>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../../components/UiButton'
import TeamLeaderboardTable from '@/components/leaderboard/TeamLeaderboardTable'
import UiPageTopBar from '@/components/UiPageTopBar'
import SingleLeaderboardTable from '../../components/leaderboard/SingleLeaderboardTable'
export default {
	data () {
		return {
			typeLeaderboard: 'team'
		}
	},
	components: {
		SingleLeaderboardTable,
		UiPageTopBar,
		UiButton,
		TeamLeaderboardTable
	},
	methods: {
		changeType (type) {
			this.$set(this, 'typeLeaderboard', type)
		}
	}
}
</script>
<style scoped lang="scss">
.leaderboard__content {
	margin: 40px 0;
}
.leaderboard__sorting {
	padding: 0 40px;
	span {
		font-size: 10px;
		line-height: 20px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: #8F8F8F;
	}
	.leaderboard__sorting--list {
		margin-top: 12px;
		display: flex;
		::v-deep button {
			width: 115px;
			color: #8F8F8F;
			align-items: center;
			display: flex;
			background: #2F2F2F;
			justify-content: center;
			svg {
				margin-right: 8px;
				path {
					transition: 0.2s;
				}
			}
			&:hover, &.active {
				border: 1px solid #00E7DF;
				color: #00E7DF;
				svg {
					path {
						fill: #00E7DF
					}
				}
			}
			font-weight: bold;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.25px;
			text-transform: uppercase;
			text-align: center;
		}
		::v-deep button + button {
			margin-left: 15px;
		}
	}
}
@media screen and (max-width: 992px) {
	.ui-page-top-bar {
		align-items: center;
	}
	.leaderboard__sorting {
		flex-wrap: wrap;
		margin: 10px 0;
		.leaderboard__sorting--list {
			flex-wrap: wrap;
			button {
				width: 100%;
				margin-left: 0 !important;
			}
			button + button {
				margin-top: 10px;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.ui-page-top-bar {
		flex-wrap: wrap;
		justify-content: flex-start;
		::v-deep {
			.ui-page-top-bar__content {
				justify-content: flex-start;
				.leaderboard__sorting {
					padding-left: 16px;
				}
			}
		}
	}
	.leaderboard {
		.leaderboard__content {
			margin-top: 16px;
		}
		.shell {
			width: 100%;
			min-width: 100%;
		}
	}
}
</style>

<template>
	<div class="header is-fixed">
		<UiToastList/>

		<MobileHeader v-if="isMobile"></MobileHeader>
		<div class="header__inner" v-else>
			<div class="header__left">

				<RouterLink :event="$currentGame ? 'click' : ''" :to="{ name: 'index' }" exact class="logo">
					<img :src="logo" alt="Qlash">
				</RouterLink>
				<div class="menu-wrapper">
					<ul class="menu">
						<li>
							<RouterLink :to="{ name: 'games' }" :class="{'is-active': $route.name === 'games'}" exact>{{ $t('Games') }}</RouterLink>
						</li>
						<li>
							<RouterLink
								:event="$currentGame ? 'click' : ''"
								:to="{ name: 'index' }"
								:disabled="!$currentGame"
								:class="{'is-active': $route.name === 'index', 'disabled': !$currentGame}"
								exact>{{ $t('Tournaments') }}</RouterLink>
						</li>
						<li>
							<RouterLink
								:event="$currentGame ? 'click' : ''"
								:to="{ name: 'news' }"
								:disabled="!$currentGame"
								:class="{'is-active': $route.name === 'news' || $route.name === 'news.detail', 'disabled': !$currentGame}"
								exact>{{ $t('News') }}</RouterLink>
						</li>
						<li>
							<RouterLink
								:event="$currentGame ? 'click' : ''"
								:to="{ name: 'teams' }"
								:disabled="!$currentGame"
								:class="{'is-active': $route.name === 'teams', 'disabled': !$currentGame}"
								exact>{{ $t('Teams') }}</RouterLink>
						</li>
						<li>
							<RouterLink
								:event="$currentGame ? 'click' : ''"
								:to="{ name: 'leaderboard' }"
								:disabled="!$currentGame"
								:class="{'is-active': $route.name === 'leaderboard', 'disabled': !$currentGame}"
								exact>{{ $t('Leaderboard') }}</RouterLink>
						</li>
					</ul>
				</div>
			</div>
			<div class="header__right">
				<NotificationsDropdown />
				<ProfileDropdown />
				<div class="chat__head">
					<img src="@/assets/mail.svg" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { AuthService } from '../services/auth.service'
	import UiToastList from '@/components/UiToastList'
	import ProfileDropdown from './ProfileDropdown'
	import NotificationsDropdown from './NotificationsDropdown'
	import MobileHeader from './components/header/MobileHeader'
	import { mapGetters } from 'vuex'
	export default {
		name: 'Header',
		components: {
			UiToastList,
			ProfileDropdown,
			MobileHeader,
			NotificationsDropdown
		},
		data () {
			return {
				logo: require('../assets/logo.svg')
			}
		},
		computed: {
			...mapGetters({
				games: 'user/getGames',
				isOpenModal: 'modals/isOpenModal',
				modal: 'modals/modal',
				isMobile: 'dom/isMobile'
			})
		},
		methods: {
			async logout () {
				this.amplitude.logEvent('logout')
				await AuthService.makeLogout()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		&.is-fixed {
			min-height: 62px;
				> * {
					position: fixed;
					width: 100%;
					z-index: 99999;
					top: 0;
					left: 0;
				}
		}

		&__inner {
			display: flex;
			justify-content: space-between;
			padding: 0;
			position: relative;
			border-bottom: 1px solid $color-gray-600;
			background: $color-body;
			z-index: 9999;
		}

		&__left,
		&__right {
			display: flex;
			.chat__head {
				width: 95px;
				min-width: 95px;
				border-left: 1px solid #2F2F2F;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 24px 0;
				border-bottom: 1px solid #2F2F2F;
			}
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 95px;
			min-height: 60px;
			border-right: 1px solid $color-gray-600;

			img {
				max-width: 46px;
			}
		}

		a {
			text-decoration: none;
			font-weight: 600;
		}

		.menu-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.menu {
			display: flex;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.15px;
			text-transform: uppercase;
			a {
				@include navigation-item;
				&.disabled {
					cursor: not-allowed;
					z-index: -1;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.header {
			.logo {
				width: 80px;
				min-width: 80px;
			}
			.menu-wrapper {
				.menu {
					li {
						a {
							padding-left: 10px;
							padding-right: 10px;
							font-size: 12px;
						}
					}
				}
			}
		}
		.header__inner {
			.header__right {
				.chat__head {
					width: 80px;
					min-width: 80px;
				}
			}

		}
	}
</style>

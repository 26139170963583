export default {
	getTournaments: state => state.tournaments,
	getTournamentsMeta: state => state.tournamentsMeta,
	getCurrentTournament: state => state.tournament,
	getCurrentTournamentId: state => state.tournament ? state.tournament.id : null,
	getCurrentTournamentStatus: state => state.tournament ? state.tournament.status : null,
	getCurrentTournamentUser: state => state.tournament ? state.tournament.participant : null,
	getParticipants: state => state.participants,
	getLeaderBoard: state => state.leaderBoard,
	getResults: state => state.results,
	getMatches: state => state.matches,
	getCurrentMatch: state => state.current_match,
	getCurrentRoundLeaderboard: state => {
		if (!state.leaderBoard.length) {
			return {}
		}
		for (const item in state.leaderBoard) {
			if (state.leaderBoard[item].status === 'STARTED' || state.leaderBoard[item].status === 'PENDING') {
				return Number(item)
			}
		}
		const find = state.leaderBoard.findIndex(item => item.status === 'WAITING')
		return find > -1 ? Number(find) : Number(state.leaderBoard.length - 1)
	}
}

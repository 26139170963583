import { Http } from './http.init'
import { ResponseWrapper, ErrorWrapper } from './util'
import store from '../store'
import $router from '../router'
import { UsersService } from './users.service'
import { TermsService } from './terms.service'
import { vueInstance } from '../main'

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */

	static async makeRegister ({ email, password, dataForUpdate, avatar }) {
				return new Http({ auth: false }).post(`${process.env.VUE_APP_API_URL}/register`,
					{ email, password }).then(async (response) => {
					await _resetAuthData()
					await _setAuthData({
						accessToken: response.data.token,
						user: response.data.user
					})
					await UsersService.updateUser(dataForUpdate, response.data.user.id)
					try {
						await TermsService.setTermByCountry(dataForUpdate.country_id)
					} catch (e) {}
					if (avatar) {
						try {
							await UsersService.updateAvatar(avatar)
						} catch (e) {}
					}

					await store.dispatch('user/getCurrent')
					await this.getWsToken()
					vueInstance.amplitude.logEvent('registration_finished')
					$router.push({ path: '/' }).catch(() => {})
					return response
				})
	}

	static async getWsToken () {
		try {
			const response = await new Http({ auth: true }).get(`${process.env.VUE_APP_API_URL}/users/${store.getters['user/getCurrentUser'].id}/ws-token`)
			await _setWsToken(response.data.token)
			return new ResponseWrapper(response, response.data)
		} catch (error) {
			throw new ErrorWrapper(error)
		}
	}

  static async makeLogin ({ email, password }) {
    try {
      // const fingerprint = await _getFingerprint()
      const response = await new Http({ auth: false }).post(`${process.env.VUE_APP_API_URL}/login`,
        { email, password })

      await _resetAuthData()
      await _setAuthData({
        accessToken: response.data.token,
        user: response.data.user
      })

			await this.getWsToken()
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLogout () {
    try {
      // const response = await new Http({ auth: false }).post(`${VUE_APP_API_URL}/logout`, {})
      await _resetAuthData()
      $router.push({ name: 'login' }).catch(() => {})
      // return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static getBearer () {
    return localStorage.getItem('token')
  }

  static setBearer (accessToken) {
    localStorage.setItem('token', accessToken)
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

async function _resetAuthData () {
  // reset userData in store
  await store.commit('user/SET_CURRENT_USER', {})
  await store.commit('auth/SET_ATOKEN_EXP_DATE', null)
	await store.commit('user/SET_WS_TOKEN', null)
  // reset tokens
  AuthService.setBearer('')
}
async function _setWsToken (token) {
	await store.commit('user/SET_WS_TOKEN', token)
}

async function _setAuthData ({ accessToken, exp, user } = {}) {
  await AuthService.setBearer(`Bearer ${accessToken}`)
	const currentGame = localStorage.getItem('currentGame')
	if (currentGame) {
		store.commit('user/SET_CURRENT_GAME', currentGame)
	}
  await store.commit('user/SET_CURRENT_USER', user)
}

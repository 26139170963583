<template>
	<div class="register-form">
		<div class="form" @keyup.enter="makeRegister">
			<div class="input" :class="{ 'is-invalid': $v.form.email.$error, 'is-correct': $v.form.email.$dirty && !$v.form.email.$error }">
				<div class="input__icon">
					<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.6875 3.96875C14.9688 4.53125 14.0625 5.21875 10.875 7.53125C10.25 8 9.09375 9.03125 8 9.03125C6.875 9.03125 5.75 8 5.09375 7.53125C1.90625 5.21875 1 4.53125 0.28125 3.96875C0.15625 3.875 0 3.96875 0 4.125V10.5C0 11.3438 0.65625 12 1.5 12H14.5C15.3125 12 16 11.3438 16 10.5V4.125C16 3.96875 15.8125 3.875 15.6875 3.96875ZM8 8C8.71875 8.03125 9.75 7.09375 10.2812 6.71875C14.4375 3.71875 14.75 3.4375 15.6875 2.6875C15.875 2.5625 16 2.34375 16 2.09375V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V2.09375C0 2.34375 0.09375 2.5625 0.28125 2.6875C1.21875 3.4375 1.53125 3.71875 5.6875 6.71875C6.21875 7.09375 7.25 8.03125 8 8Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input type="email" v-model.trim="$v.form.email.$model" placeholder="EMAIL">
				</div>
				<div v-if="$v.form.email.$dirty" class="error-input">
					<template v-if="!$v.form.email.required">Email is required</template>
					<template v-if="!$v.form.email.email">Email is incorrect format</template>
				</div>
			</div>
			<div class="input" :class="{ 'is-invalid': $v.form.password.$error, 'is-correct': $v.form.password.$dirty && !$v.form.password.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.5 7H11.75V4.75C11.75 2.15625 9.59375 0 7 0C4.375 0 2.25 2.15625 2.25 4.75V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.53125 5.75 2.5 7 2.5C8.21875 2.5 9.25 3.53125 9.25 4.75V7Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input type="password" v-model="$v.form.password.$model" placeholder="PASSWORD">
				</div>
				<div v-if="$v.form.password.$dirty" class="error-input">
					<template v-if="!$v.form.password.required">Password is required</template>
					<template v-else-if="!$v.form.password.minLength">Password min length 6 chars</template>
					<template v-else-if="!$v.form.password.maxLength">Password is not more than 48 characters</template>
				</div>
			</div>

			<div class="input" :class="{ 'is-invalid': $v.form.password_confirmation.$error, 'is-correct': $v.form.password_confirmation.$dirty && !$v.form.password_confirmation.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.5 7H11.75V4.75C11.75 2.15625 9.59375 0 7 0C4.375 0 2.25 2.15625 2.25 4.75V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.53125 5.75 2.5 7 2.5C8.21875 2.5 9.25 3.53125 9.25 4.75V7Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input type="password" v-model="$v.form.password_confirmation.$model" placeholder="REPEAT PASSWORD">
				</div>
				<div v-if="$v.form.password_confirmation.$dirty" class="error-input">
					<template v-if="!$v.form.password_confirmation.required">Password is required</template>
					<template v-else-if="!$v.form.password_confirmation.minLength">
						Password is not less than 6 characters</template>
					<template v-else-if="!$v.form.password_confirmation.sameAsPassword">Password mismatch</template>
					<template v-else-if="!$v.form.password_confirmation.maxLength">Password is not more than 48 characters</template>
				</div>
			</div>
			<input @change="addAvatar" accept="image/*" type="file" id="avatar">
			<label for="avatar" class="picture__area">
				<div class="area__circle">
					<img v-if="previewAvatar && avatar" class="fill" :src="previewAvatar" alt="">
					<img src="@/assets/add-picture.svg" v-else alt="">
				</div>
				<div class="label">
					Upload your avatar
				</div>
			</label>
			<div class="input" :class="{ 'is-invalid': $v.form.nickname.$error, 'is-correct': $v.form.nickname.$dirty && !$v.form.nickname.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7 8C9.1875 8 11 6.21875 11 4C11 1.8125 9.1875 0 7 0C4.78125 0 3 1.8125 3 4C3 6.21875 4.78125 8 7 8ZM9.78125 9H9.25C8.5625 9.34375 7.8125 9.5 7 9.5C6.1875 9.5 5.40625 9.34375 4.71875 9H4.1875C1.875 9 0 10.9062 0 13.2188V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V13.2188C14 10.9062 12.0938 9 9.78125 9Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input type="text" v-model="$v.form.nickname.$model" placeholder="NICKNAME">
				</div>
				<div v-if="$v.form.nickname.$dirty" class="error-input">
					<template v-if="!$v.form.nickname.required">Nickname is required</template>
					<template v-else-if="!$v.form.nickname.minLength">
						Nickname is not less than 6 characters</template>
					<template v-else-if="!$v.form.nickname.maxLength">Nickname is not more than 48 characters</template>
					<template v-else-if="!$v.form.nickname.isAvailableNickname">Invalid nickname format</template>
				</div>
			</div>
			<div class="input__group">
				<div class="input" :class="{'is-invalid': $v.form.dateOfBirth.$error, 'is-correct': $v.form.dateOfBirth.$dirty && !$v.form.dateOfBirth.$error}">
					<div class="input__icon">
						<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14 12V9.5C14 8.6875 13.3125 8 12.5 8H12V3.5H10V8H8V3.5H6V8H4V3.5H2V8H1.5C0.65625 8 0 8.6875 0 9.5V12C0.875 12 0.96875 11 2.3125 11C3.6875 11 3.78125 12 4.65625 12C5.5 12 5.65625 11 7 11C8.34375 11 8.46875 12 9.3125 12C10.1875 12 10.3125 11 11.6562 11C13 11 13.0938 12 14 12ZM14 16V13C12.625 13 12.5 12 11.6562 12C10.7812 12 10.6875 13 9.3125 13C7.96875 13 7.875 12 7 12C6.125 12 6 13 4.65625 13C3.3125 13 3.1875 12 2.3125 12C1.4375 12 1.34375 13 0 13V16H14ZM3 3C3.53125 3 4 2.59375 4 1.75C4 0.9375 3.375 0 3 0C3 1.28125 2 1.03125 2 2C2 2.5625 2.4375 3 3 3ZM7 3C7.53125 3 8 2.59375 8 1.75C8 0.9375 7.375 0 7 0C7 1.28125 6 1.03125 6 2C6 2.5625 6.4375 3 7 3ZM11 3C11.5312 3 12 2.59375 12 1.75C12 0.9375 11.375 0 11 0C11 1.28125 10 1.03125 10 2C10 2.5625 10.4375 3 11 3Z" fill="#B0B0B0"/>
						</svg>
					</div>
					<div class="input__field">
						<input type="date" v-model="$v.form.dateOfBirth.$model" dataformatas="DD/MM/YYYY" placeholder="DD/MM/YYYY">
					</div>
					<div v-if="$v.form.dateOfBirth.$dirty" class="error-input">
						<template v-if="!$v.form.dateOfBirth.required">Date of birth is required</template>
						<template v-else-if="!$v.form.dateOfBirth.minValue">Incorrect date of birth</template>
					</div>
				</div>
				<div class="input" :class="{'is-invalid': $v.form.country.$error, 'is-correct': $v.form.country.$dirty && !$v.form.country.$error}">
					<div class="input__icon">
						<svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.375 15.6875C5.65625 16.125 6.3125 16.125 6.59375 15.6875C11.1562 9.125 12 8.4375 12 6C12 2.6875 9.3125 0 6 0C2.65625 0 0 2.6875 0 6C0 8.4375 0.8125 9.125 5.375 15.6875ZM6 8.5C4.59375 8.5 3.5 7.40625 3.5 6C3.5 4.625 4.59375 3.5 6 3.5C7.375 3.5 8.5 4.625 8.5 6C8.5 7.40625 7.375 8.5 6 8.5Z" fill="#B0B0B0"/>
						</svg>
					</div>
					<div class="input__field">
						<input type="text" class="to-upper" v-model="searchCountry" @input="getCountries" @click.stop @focusin.stop="openCountrySelect = true" placeholder="SELECT COUNTRY" >
						<transition name="fade">
							<div class="dropdown-countries" v-if="openCountrySelect">
								<div class="country__item" @click="setCountry(country)" v-for="(country, i) in countries" :key="i">{{ country.value }}</div>
							</div>
						</transition>
					</div>
					<div v-if="$v.form.country.$dirty" class="error-input">
						<template v-if="!searchCountry">Country is required</template>
					</div>
				</div>

			</div>

			<UiButton type="primary" @click="makeRegister">
				Sign up
				<UiLoader v-show="loader"></UiLoader>
			</UiButton>
		</div>
	</div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import UiButton from '../UiButton'
import { LoginService } from '../../services/login.service'
import { debounce } from '../../directives/debounce'
import { required, email, sameAs, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import UiLoader from '../UiLoader'
export default {
	data () {
		return {
			searchCountry: '',
			countries: [],
			openCountrySelect: false,
			avatar: null,
			previewAvatar: null,
			loader: false,
			clientCountry: null,
			form: {
				nickname: null,
				email: null,
				country: null,
				password: null,
				password_confirmation: null,
				dateOfBirth: null
			}
		}
	},
	components: {
		UiButton,
		UiLoader
	},
	validations: {
		form: {
			email: {
				email,
				required
			},
			password: {
				required,
				maxLength: maxLength(48),
				minLength: minLength(6)
			},
			password_confirmation: {
				required,
				maxLength: maxLength(48),
				minLength: minLength(6),
				sameAsPassword: sameAs('password')
			},
			nickname: {
				required,
				maxLength: maxLength(48),
				minLength: minLength(6),
				isAvailableNickname (nickname) {
					const regex = /^[a-zA-Z ]+$/
					return regex.test(nickname)
				}
			},
			dateOfBirth: {
				required,
				minValue: value => value < new Date().toISOString()
			},
			country: {
				required
			}
		}
	},
	mixins: [validationMixin],
	methods: {
		...mapActions({
			register: 'auth/register'
		}),
		addAvatar ($event) {
			this.avatar = $event.target.files[0]
			const reader = new FileReader()
			reader.onload = (e) => {
				this.previewAvatar = e.target.result
			}
			reader.readAsDataURL(this.avatar)
		},
		makeRegister () {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return
			}
			let avatar = null
			if (this.avatar instanceof File) {
				avatar = new FormData()
				avatar.append('image', this.avatar)
				avatar.append('_method', 'PUT')
			}
			this.loader = true
			this.register({
				email: this.form.email,
				password: this.form.password,
				dataForUpdate: {
					country_id: this.form.country.id,
					birthday: this.form.dateOfBirth,
					nickname: this.form.nickname
				},
				avatar
			}).finally(() => {
				this.loader = false
			})
		},
		setCountry (country) {
			this.form.country = country
			this.openCountrySelect = false
			this.searchCountry = country.value
		},
		getCountryByCode (code) {
			LoginService.getCountries({ code: code }).then((data) => {
				if (data.data.data[0]) {
					this.form.country = data.data.data[0]
					this.searchCountry = this.form.country.value
				}
			})
		},
		getCountries ($event) {
			LoginService.getCountries({ search: $event ? $event.target.value : this.searchCountry }).then((data) => {
				this.countries = data.data.data
			})
		},
		getCountryByClient () {
			fetch('https://ipinfo.io/?token=9f66b2420463e4').then(response => response.text()).then(response => {
				const data = JSON.parse(response)
				this.getCountryByCode(data.country)
			})
		},
		init () {
			this.getCountries()
			this.getCountryByClient()
		}
	},
	watch: {
		searchCountry () {
			debounce(this.getCountries, 500)
		}
	},
	mounted () {
		this.init()

		window.addEventListener('click', async () => {
			this.openCountrySelect = false
		})
	}
}
</script>
<style scoped lang="scss">
.form {
	.to-upper {
		text-transform: uppercase;
	}
	input[type="file"] {
		display: none;
	}
	.input {
		transition: 0.2s;
		position: relative;
		.dropdown-countries {
			position: absolute;
			width: 100%;
			left: 0;
			background: #2F2F2F;
			max-height: 150px;
			overflow-y: auto;
			top: 100%;
			border: 1px solid #00E7DF;
			border-top: none;
			border-radius: 5px;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			&::-webkit-scrollbar {
				width: 0px;
			}
			.country__item {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.15px;
				text-transform: uppercase;
				color: #FFFFFF;
				padding: 7.5px 0;
				padding-left: 20px;
				cursor: pointer;
			}
		}
	}
	.picture__area {
		border: 1px dashed #505050;
		border-radius: 5px;
		padding: 15px 0;
		display: flex;
		justify-content: center;
		position: relative;
		margin: 25px 0;
		cursor: pointer;
		.label {
			position: absolute;
			bottom: -12px;
			background: #2F2F2F;
			padding: 0 10px;
			font-size: 10px;
			line-height: 22px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #505050;
		}
		.area__circle {
			height: 150px;
			width: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			border: 1px solid #505050;
			overflow: hidden;
			img {
				max-width: 100%;
				max-height: 100%;
				&.fill {
					width: 100%;
					height: 100%;
					border-radius: 100%;
				}
			}
		}
	}
	.input__group {
		margin-top: 25px;
		display: flex;
		align-items: center;
		.input + .input {
			margin-top: 0;
			margin-left: 20px;
		}
	}
	.ui-button {
		margin-top: 30px;
		padding: 15.5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		::v-deep img {
			margin-left: 20px;
		}
	}
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
	-webkit-text-fill-color: #B0B0B0 !important;
	-webkit-box-shadow: 0 0 0 30px #2f2f2f inset !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

.slide-fade-enter-active {
	transition: all .1s ease !important;
}
.slide-fade-leave-active {
	transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0) !important;
}
.slide-fade-enter, .slide-fade-leave-to {
	opacity: 0;
}

@media screen and (max-width: 575px) {
	.form {
		.input__group {
			flex-wrap: wrap;
			.input {
				width: 100%;
				margin-left: 0 !important;
			}
			.input + .input {
				margin-top: 24px;
			}
		}
	}
}

</style>

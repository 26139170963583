<template>
	<div class="participants">
		<div class="shell">
			<transition name="slide">
				<template v-if="!loading">
					<div class="empty-content" v-if="!participantsExists">
						<h2>{{ $t('No players have registered for the tournament yet!') }}</h2>
					</div>
					<template v-if="tournament.matchType === 1">
						asd
						<div class="range" v-if="participantsExists">
							<div class="cell-sm-8 participants__content">
								<div class="table solo" v-if="!tournament.isTeamType()">
									<div class="table__header">
										<div class="table__col">
											{{ $t('Name') }}
										</div>
									</div>
									<div class="table__body">
										<div class="table__row" v-for="participant in participants" :key="participant.id">
											<div class="table__col">
												<UiAvatar
													:alt="participant.profile.info.nickname"
													:src="participant.profile.user.avatar"
												/>
												<span>{{ participant.profile.user.nickname }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="table solo" v-if="tournament.isTeamType()">
									<div class="table__header">
										{{ $t('Name') }}
									</div>
									<div class="table__body">
										<div
											class="table__row"
											v-for="participant in participants"
											:key="participant.id"
											:class="{empty: !participant.team}"
										>
											<div class="table__col" v-if="participant.team">
												<UiAvatar
													:alt="participant.team.name"
													:src="participant.team.logo"
												/>
												<span>{{ participant.getName() }}</span>
											</div>
											<div class="table__col" v-else>
												<UiAvatar/>
												<span>{{ $t('DELETED') }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="cell-sm-4 participants__container">
								<SidebarContainer></SidebarContainer>
							</div>

							<!--<div class="team" v-if="tournament.isTeamType()">-->
							<!--<div class="range">-->
							<!--<div class="cell-sm-6" v-for="participant in participants" :key="participant.id">-->
							<!--<div class="team-card">-->
							<!--<div class="team-card__header">-->
							<!--<UiAvatar-->
							<!--:alt="participant.team.name"-->
							<!--:src="participant.team.logo"-->
							<!--/>-->
							<!--<span>{{ participant.team.name }}</span>-->
							<!--</div>-->
							<!--<div class="team-card__content">-->
							<!--</div>-->
							<!--</div>-->
							<!--</div>-->
							<!--</div>-->
							<!--</div>-->
						</div>
					</template>
					<template v-else-if="tournament.matchType === 2">
						<ParticipantsTeam :participants="participants"></ParticipantsTeam>
					</template>
				</template>
			</transition>

			<transition name="slide">
				<div v-if="loading" class="loading">
					<QlashLoader></QlashLoader>
				</div>
			</transition>

		</div>
	</div>
</template>

<script>
	import ParticipantsTeam from './components/ParticipantsTeam'
	import { mapActions, mapGetters } from 'vuex'

	import UiAvatar from '@/components/UiAvatar'
	import SidebarContainer from './containers/SidebarContainer'
	import QlashLoader from '../../components/QlashLoader'
	export default {
		data () {
			return {
				loading: true
			}
		},
		name: 'Participants',
		components: {
			UiAvatar,
			SidebarContainer,
			ParticipantsTeam,
			QlashLoader
		},
		computed: {
			...mapGetters({
				tournament: 'tournaments/getCurrentTournament',
				participants: 'tournaments/getParticipants'
			}),
			participantsExists () {
				return this.participants.length > 0
			}
		},
		methods: {
			...mapActions({
				getParticipants: 'tournaments/getParticipants'
			}),
			async init () {
				await this.getParticipants(this.tournament.id)
				this.loading = false
			}
		},

		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss">
.loading {
	width: 100%;
	margin: 30px 0;
	display: flex;
	justify-content: center;
}
.slide-enter-active, .slide-leave-active {
	transition: all .15s ease-in;
	top: -10px;
}

.slide-enter, .slide-leave-to {
	opacity: 0;
}

.slide-enter-to, .slide-leave {
	opacity: 1;
	top: 0;
}

	.participants {
		.avatar {
			max-width: 50px;
			min-width: 50px;
			min-height: 50px;

			> .img-loader {
				min-height: 48px;
			}
		}

		.solo {
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			letter-spacing: 0.25px;
			color: $color-white;

			.avatar + span,
			.avatar {
				display: inline-block;
				vertical-align: middle;
			}

			.avatar {
				margin-right: 25px;
			}

			.empty {
				color: $color-gray-400;
			}
		}

		.team-card {
			background: $color-gray-600;
			border-radius: 5px;
			padding: 20px 25px;
			height: 100%;

			&__header {
				position: relative;
				width: 100%;
				padding: 7px 15px;
				border: 1px solid $primary-color;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 12px;
				line-height: 15px;
				font-weight: 700;
				text-transform: uppercase;
				color: $color-white;

				.avatar {
					max-width: 26px;
					min-width: 26px;
					min-height: 26px;
					margin-right: 10px;
					border-color: $color-gray-300;

					> .img-loader {
						min-height: 24px;
					}
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border: 1px solid $primary-color;
					filter: blur(4px);
					border-radius: 5px;
				}
			}
		}

		.team {
			[class^="cell"] {
				margin-bottom: 20px;
			}
		}

		.empty-content {
			text-align: center;

			h2 {
				color: $color-gray-300;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.participants {
			margin-top: 20px;
			.table {
				margin-bottom: 20px;
				.table__row {
					padding: 10px 16px;
					.table__col {
						display: flex;
						align-items: center;
						max-width: 100%;
						overflow-x: hidden;
						span {
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.participants__content {
				order: 1;
			}
			.participants__container {
				order: 0;
				margin-bottom: 16px;
			}
		}
	}
</style>

import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import $store from '../store'

export class UsersService extends BaseService {
  static get entity () {
    return 'users'
  }

  static get currentUserId () {
    return $store.getters['user/getCurrentUser'].id
  }

  static async getCurrent () {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${this.currentUserId}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

	static async authWithToken () {
		try {
			const response = await this.request({ auth: true }).get('users/me')
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getCurrentTournaments (actual = true, past = false) {
		try {
			const response = await this.request({ auth: true }).get(`my-tournaments?actual=${actual}&past=${past}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getSeasons (params) {
		try {
			const response = await this.request({ auth: true }).get(`seasons?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getCurrentTeams (params) {
		try {
			const response = await this.request({ auth: true }).get(`teams?${this.querystring(params)}&user_id=${this.currentUserId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

  static async getFavouriteGames () {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${this.currentUserId}/favourite-games`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

	static async updateAvatar (data) {
		try {
			const response = await this.request({ auth: true }).post(`${this.entity}/${this.currentUserId}/image`, data, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async updateUser (data, id = null) {
		try {
			const response = await this.request({ auth: true }).put(`${this.entity}/${this.currentUserId ?? id}`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async addProfile (data) {
		try {
			const response = await this.request({ auth: true }).post(`${this.entity}/${this.currentUserId}/profiles`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async editProfile (data) {
		try {
			const response = await this.request({ auth: true }).patch(`${this.entity}/${this.currentUserId}/profiles`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getProfiles () {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${this.currentUserId}/profiles`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getNotifications (params) {
		try {
			const response = await this.request({ auth: true }).get(`notifications?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async sendReadNotifications (data) {
		try {
			const response = await this.request({ auth: true }).patch('notifications', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getAllLeaderboard (params) {
		try {
			const response = await this.request({ auth: true }).get(`/leaderboard?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getLeaderboard () {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${this.currentUserId}/leaderboard`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async createTeam (data) {
		try {
			const response = await this.request({ auth: true }).post('teams', data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async editTeam (data, id) {
		try {
			const response = await this.request({ auth: true }).post(`teams/${id}`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

<template>
	<div>
		<transition name="modal">
			<template v-if="isOpenModal && modal">
				<template v-if="modal.meta.inRound && modal.meta.isWinner">
					<PlayerWinRoundModal v-if="modal.meta.isWinner"></PlayerWinRoundModal>
				</template>
				<template v-else>
					<ResultTournamentModal></ResultTournamentModal>
				</template>
			</template>
		</transition>
	</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import * as statuses from '../constants/tournament-statuses'
import isWinnerInMatch from '../helpers/tournaments/isWinnerInMatch'
import ResultTournamentModal from '@/components/modals/ResultTournamentModal'
import PlayerWinRoundModal from '@/components/modals/rounds/PlayerWinRoundModal'

export default {
	data () {
		return {
			cache: []
		}
	},
	components: {
		PlayerWinRoundModal,
		ResultTournamentModal
	},
	computed: {
		...mapGetters({
			matches: 'chat/rounds',
			participants: 'user/getTournamentsParticipants',
			isOpenModal: 'modals/isOpenModal',
			modal: 'modals/modal'
		})
	},
	watch: {
		matches () {
			this.init()
		}
	},
	methods: {
		...mapMutations({
			setModal: 'modals/SHOW'
		}),
		init () {
			const matches = this.matches
			const participants = this.participants

			for (const item in matches) {
				const channel = `tournament.${matches[item].tournamentId}.match.${matches[item].id}`
				if (this.cache.includes(channel)) continue
				this.cache.push(channel)

				const matchInstance = JSON.parse(JSON.stringify(matches[item]))
				this.subscribeObserver(channel, async (data) => {
					if (data.data.status === statuses.STATUS_FINISHED) {
						const match = await isWinnerInMatch(matchInstance.tournamentId, data.data.match_id, participants[matchInstance.tournamentId], matchInstance.tournament.type)
						// show result round
						this.setModal({
							meta: {
								inRound: match.isRound,
								inTournament: match.inTournament,
								isWinner: match.isWinner
							},
							round: match.match
						})
					}
				})
			}
		}
	},
	mounted () {
		this.init()
	}
}
</script>

<template>
	<div class="round__robin-bracket">
		<div class="rr_stages">
			<div class="rr_stages-label">
				Stages:
			</div>
			<div class="rr_stages-list">
				<button class="rr_stages-list_button" title="Round robin stage" :class="{'active': stage === ROUND_ROBIN_STAGE}" @click="stage = ROUND_ROBIN_STAGE">Round Robin</button>
				<button class="rr_stages-list_button"
								:title="tournament.rr.stage === ROUND_ROBIN_STAGE ? 'The single elimination stage has not started yet' : 'Single elimination'"
								:disabled="tournament.rr.stage === ROUND_ROBIN_STAGE"
								:class="{'active': stage === BRACKETS_STAGE}"
								@click="stage = BRACKETS_STAGE">Single Elimination </button>
			</div>
		</div>
		<div class="rr_stages-content">
			<RoomsBracket v-if="stage === ROUND_ROBIN_STAGE"></RoomsBracket>
			<RoundRobinBracket v-if="stage === BRACKETS_STAGE"></RoundRobinBracket>
		</div>
	</div>
</template>
<script>
import { ROUND_ROBIN_STAGE, BRACKETS_STAGE } from '../../constants/round_robin/round-robin-stages'
import RoomsBracket from './components/RoomsBracket'
import { mapGetters } from 'vuex'
import RoundRobinBracket from './components/RoundRobinBracket'
export default {
	data () {
		return {
			ROUND_ROBIN_STAGE: ROUND_ROBIN_STAGE,
			BRACKETS_STAGE: BRACKETS_STAGE,

			stage: ROUND_ROBIN_STAGE
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	components: {
		RoundRobinBracket,
		RoomsBracket
	}
}
</script>
<style scoped lang="scss">
	.round__robin-bracket {
		.rr_stages {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			.rr_stages-label {
				font-weight: 700;
				font-size: 12px;
				line-height: 15px;
				text-transform: uppercase;
				color: #8F8F8F;
				margin-right: 20px;
			}
			.rr_stages-list {
				display: flex;
				.rr_stages-list_button {
					padding: 12px 25px;
					background: transparent;
					outline: none;
					border: 1px solid #505050;
					box-sizing: border-box;
					filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12)) drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
					border-radius: 5px;
					font-weight: 700;
					font-size: 14px;
					line-height: 17px;
					letter-spacing: 1px;
					text-transform: uppercase;
					cursor: pointer;
					color: #8F8F8F;
					transition: 0.1s;
					&.active {
						color: #00E7DF;
						background: #2F2F2F;
						border: 1px solid #00E7DF;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					}
					&:hover {
						background: #2F2F2F;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					}
					&[disabled] {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}
				.rr_stages-list_button + .rr_stages-list_button {
					margin-left: 20px;
				}
			}
		}
	}

	@media screen and (max-width: 575px) {
		.round__robin-bracket .rr_stages {
			flex-wrap: wrap;
			.rr_stages-label {
				width: 100%;
				margin-bottom: 10px;
			}
		}
		.round__robin-bracket .rr_stages .rr_stages-list .rr_stages-list_button + .rr_stages-list_button {
			margin-left: 10px;
		}
		.round__robin-bracket .rr_stages .rr_stages-list .rr_stages-list_button {
			padding: 12px 13px;
			font-size: 14px;
		}
	}

	@media screen and (max-width: 400px) {
		.round__robin-bracket .rr_stages .rr_stages-list .rr_stages-list_button + .rr_stages-list_button {
			margin-left: 8px;
		}
		.round__robin-bracket .rr_stages .rr_stages-list .rr_stages-list_button {
			padding: 12px 13px;
			font-size: 11px;
		}
	}
</style>

<template>
	<div class="profile page">
		<div class="shell">
			<div class="range range-center">
				<div class="cell-md-8">
					<div class="profile__info">
						<div class="profile__info-avatar">
							<img :src="$currentUser.avatar ? $currentUser.avatar : require('@/assets/default-avatar.png')" alt="">
						</div>
						<div>
							<div class="nickname">
								{{ $currentUser.nickname }}
							</div>
							<div class="name">
								{{ $currentUser.name }}
							</div>
							<div class="email">
								{{ $currentUser.email }}
							</div>
						</div>
						<button @click="isShowEditProfile = true" class="profile__info-edit">
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.6101 3.89225C14.13 3.37237 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.419 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.419V11.581Z" fill="#00E7DF"/>
							</svg>
							Edit profile
						</button>
					</div>
					<div class="profile__tabs">
						<UiTabs>
							<UiTab :title="$t('Leaderboard')">
								<div class="range">
									<div class="cell-md-6" v-for="(leader, i) in leaderboard" :key="i">
										<div class="game-card">
											<div class="game-card__img">
												<UiImgLoader :src="getLeaderboardGameImage(leader.game_id)" />
											</div>
											<div class="game-card__content">
												<ul>
													<li>
														<span class="title">{{ $t('Your position') }}</span>
														<span class="value">{{ leader.leaderboard_position }}</span>
													</li>
													<li>
														<span class="title">{{ $t('Your points') }}</span>
														<span class="value">{{ leader.points }}</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<UiNotFoundBlock v-if="!leaderboard.length"></UiNotFoundBlock>
							</UiTab>
							<UiTab :title="$t('Upcoming tournaments')">
								<div class="range">
									<div class="cell-sm-6" v-for="item in tournaments" :key="item.id">
										<UiTournamentItem
											:id="item.id"
											:title="item.title"
											:image="item.getAvatar()"
											:status="item.status"
											:platforms="getGamePlatforms(item.gameId, item.platformId)"
											:type="item.type"
											:matchType="item.matchType"
											:teamMates="item.teamMates"
											:participants="item.maxMember"
											:registeredParticipants="item.participantCount"
											:startedAt="item.startedAt"
											:sponsorText="item.sponsorText"
											:sponsorUrl="item.sponsorUrl"
											:sponsorImage="item.sponsorImage"
										/>
									</div>
								</div>
								<UiNotFoundBlock v-if="!tournaments.length"></UiNotFoundBlock>
							</UiTab>
							<UiTab :title="$t('Past tournaments')">
								<div class="range">
									<div class="cell-sm-6" v-for="item in pastTournaments" :key="item.id">
										<UiTournamentItem
											:id="item.id"
											:title="item.title"
											:image="item.getAvatar()"
											:status="item.status"
											:platforms="getGamePlatforms(item.gameId, item.platformId)"
											:type="item.type"
											:matchType="item.matchType"
											:teamMates="item.teamMates"
											:participants="item.maxMember"
											:registeredParticipants="item.participantCount"
											:startedAt="item.startedAt"
											:sponsorText="item.sponsorText"
											:sponsorUrl="item.sponsorUrl"
											:sponsorImage="item.sponsorImage"
										/>
									</div>
								</div>
								<UiNotFoundBlock v-if="!pastTournaments.length"></UiNotFoundBlock>
							</UiTab>
						</UiTabs>
					</div>
				</div>
			</div>
		</div>
		<transition name="modal">

		<EditProfileModal v-if="isShowEditProfile" @close="isShowEditProfile = false"></EditProfileModal>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
	import EditProfileModal from '../../components/profile/modals/EditProfileModal'
	import UiImgLoader from '@/components/UiImgLoader'
	import UiTabs from '@/components/UiTabs'
	import UiTab from '@/components/UiTab'
	import TournamentItemModel from '../../models/TournamentItemModel'
	import UiTournamentItem from '@/components/UiTournamentItem'
	import { UsersService } from '../../services/users.service'
	import UiNotFoundBlock from '@/components/UiNotFoundBlock'
	export default {
		data () {
			return {
				isShowEditProfile: false,
				pastTournaments: []
			}
		},
		name: 'Profile',
		components: {
			UiImgLoader,
			UiTabs,
			UiTab,
			UiTournamentItem,
			UiNotFoundBlock,
			EditProfileModal
		},
		computed: {
			...mapGetters({
				games: 'user/getGames',
				leaderboard: 'user/getLeaderboard',
				tournaments: 'user/myTournaments'
			})
		},
		methods: {
			...mapActions({
				getLeaderboard: 'user/getLeaderboard'
			}),
			getLeaderboardGameImage (game_id) {
				const game = this.games.find(item => item.id === game_id)
				if (!game) {
					return null
				}
				return game.web_icon ?? game.icon
			},
			getGameById (id) {
				return this.games.filter(item => item.id === id)[0]
			},
			getGamePlatforms (gameId, platformId) {
				const game = this.games.filter(item => item.id === gameId)[0]
				if (!game) {
					return []
				}
				return game.isGlobal ? [1, 2, 3, 4] : [platformId]
			},
			getPastTournaments () {
				UsersService.getCurrentTournaments(false, true).then(response => {
					const tournaments = []
					response.data.data.map((item) => {
						tournaments.push(new TournamentItemModel(item))
					})
					this.pastTournaments = tournaments
				})
			},
			async init () {
				this.amplitude.logEvent('screen_view', {
					screen_name: 'Profile'
				})
				await this.getPastTournaments()
				await this.getLeaderboard()
			}
		},
		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss">
	.profile {
		padding-top: 40px;
		padding-bottom: 40px;

		.tab {
			padding-top: 5px;
		}

		&__info {
			display: flex;
			align-items: center;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			padding: 25px;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 0.15px;
			position: relative;
			color: $color-gray-300;
			.profile__info-avatar {
				margin-right: 48px;
				width: 150px;
				display: flex;
				align-items: center;
				overflow: hidden;
				justify-content: center;
				img {
					max-width: 100%;
					max-height: 100%;
					height: 100%;
					border-radius: 100%;
				}
				height: 150px;
				min-width: 150px;
				border-radius: 100%;
				border: 1px solid #505050;
			}

			.nickname {
				font-weight: 700;
				font-size: 24px;
				line-height: 29px;
				letter-spacing: 0.15px;
				color: $color-white;
			}
			.profile__info-edit {
				position: absolute;
				top: 25px;
				right: 25px;
				background: #2F2F2F;
				border: 1px solid #505050;
				box-sizing: border-box;
				align-items: center;
				border-radius: 5px;
				cursor: pointer;
				padding: 14px 13px;
				transition: 0.2s;
				display: flex;
				svg {
					margin-right: 8px;
				}
				&:hover {
					background-color: #505050;
					border-color: #505050;
					box-shadow: 0px 8px 10px rgba(0,0,0, 0.14), 0px 3px 14px rgba(0,0,0, 0.12), 0px 5px 5px rgba(0,0,0, 0.2);
				}
				font-weight: bold;
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #00E7DF;
			}
			.name,
			.email {
				margin-top: 15px;
			}
		}

		&__tabs {
			margin-top: 25px;
		}

		.avatar {
			max-width: 150px;
			min-width: 150px;
			min-height: 150px;
		}
	}

	.game-card {
		position: relative;
		display: flex;
		align-items: center;
		padding: 25px;
		border:  1px solid $primary-color;
		margin-top: 20px;
		background: #2F2F2F;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			border: 1px solid $primary-color;
			filter: blur(4px);
			border-radius: 4px;
			pointer-events: none;
		}
		.game-card__img {
			padding: 17px;
			min-width: 90px;
			height: 90px;
			overflow: hidden;
		}
		&__img {
			max-width: 90px;
			overflow: hidden;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			margin-right: 20px;
			.img-loader {
				overflow: hidden;
			}
			.img-container {
				display: flex;
				align-items: center;
			}
			img {
				object-fit: cover;
			}
		}

		&__content {
			width: 100%;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 2px;
			text-transform: uppercase;

			color: $color-white;

			ul {
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;

					+ li {
						margin-top: 12px;
					}

					.value {
						position: relative;
						background: $color-gray-600;
						border: 1px solid $primary-color;
						border-radius: 5px;
						padding: 8px 17px;

						font-weight: 700;
						font-size: 18px;
						line-height: 20px;

						letter-spacing: 0.25px;

						color: $color-white;

						&:before {
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
							border: 1px solid $primary-color;
							filter: blur(4px);
							border-radius: 4px;
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		.profile {
			padding-top: 16px;
			.profile__info {
				padding: 16px;
				.profile__info-avatar {
					width: 60px;
					height: 60px;
					min-width: 60px;
					margin-right: 16px;
				}
				.nickname {
					font-size: 18px;
					line-height: 22px;
				}
				.name {
					margin-top: 6px;
					font-size: 12px;
					line-height: 15px;
				}
				.email {
					font-size: 12px;
					line-height: 15px;
					margin-top: 6px;
				}
			}
			.profile__tabs {
				.tabs {
					.tabs__header {
						justify-content: flex-start;
						display: flex;
						flex-direction: row;
						overflow-x: auto;
					}
				}
			}
		}
		.game-card {
			padding: 16px;
			box-shadow: none;
			border: none;
			margin-top: 16px;
			&::before {
				display: none;
			}
		}
	}
</style>

import { ErrorWrapper, ResponseWrapper } from './util'
import { BaseService } from './base.service'
export class DepositService extends BaseService {
	static async makePayment (params, type) {
		try {
			const response = await this.request({ auth: true }).post(`https://api.tournaments.qlash.dev/deposit/paysafe/${type}`, params)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

import BaseModel from './BaseModel'

export default class Match extends BaseModel {
  get schema () {
    return {
      id: undefined,
      firstParticipant: undefined,
      secondParticipant: undefined,
      gamesCount: undefined,
      duration: undefined,
      nextMatchId: undefined,
      winnerId: undefined,
			status: undefined,
			participants: undefined,
      matchStatus: undefined,
      startedAt: undefined,
			isThirdMatch: undefined,
      round: undefined,
			tournament: undefined,
			isLooserMatch: undefined,
			tournamentId: undefined,
      result: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

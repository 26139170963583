import BaseModel from '../BaseModel'

export default class RoomParticipant extends BaseModel {
	get schema () {
		return {
			id: undefined,
			is_winner: undefined,
			participant: undefined,
			room_id: []
		}
	}

	getProfileId () {
		if (this.participant.type === 2) {
			return this.participant.team.id
		}

		return this.participant.profile.info.id
	}

	getAvatar () {
		const participant = this.participant
		if (Number(participant.type) === 2) {
			return participant.team.logo
		} else if (Number(participant.type) === 1) {
			return participant.profile.user.avatar
		}

		return require('@/assets/logo.svg')
	}

	getName () {
		if (this.participant.type === 2) {
			return this.participant.team !== null && this.participant.team.name !== null ? this.participant.team.name : 'TEAM DELETED'
		}

		return this.participant.profile.user.nickname ?? this.participant.profile.user.name
	}

	prepareCreate () {
		this.baseClear()

		return this
	}

	prepareUpdate () {
		this.baseClear()

		return this
	}
}

<template>
	<div class="ui-not-found-block component">
		<div class="ui-not-found-block__content">
			<UiBaseIcon
				width="36px"
				height="36px"
				iconName="error"
			/>
			<h3>{{ text }}</h3>
		</div>
	</div>
</template>
<script>
import UiBaseIcon from './icons/UiBaseIcon'
export default {
	props: {
		text: {
			type: String,
			default: 'It\'s empty here...'
		}
	},
	components: {
		UiBaseIcon
	}
}
</script>
<style scoped lang="scss">
.ui-not-found-block {
	border: 1px dashed #505050;
	border-radius: 5px;
	width: 100%;
	margin-top: 20px;
	padding: 50px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.ui-not-found-block__content {
		text-align: center;
		h3 {
			font-weight: bold;
			font-size: 24px;
			line-height: 34px;
			display: block;
			width: 100%;
			margin-top: 20px;
		}
	}

}
</style>

<template>
	<div class="privacy-policy">
			<div class="body">
				<div @click="$emit('close')" class="modal__close">
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
					</svg>
				</div>
				<h2 v-if="term">{{ term.title }}</h2>
				<div class="text" v-if="term" v-html="term.content">
					{{ term.content }}
				</div>
			</div>
	</div>
</template>
<script>
import { LoginService } from '../../../services/login.service'
export default {
	data () {
		return {
			agree: false,
			term: null
		}
	},
	components: {
	},
	methods: {
		getTerms () {
			LoginService.getTerms().then(data => {
				this.term = data.data.data
			})
		}
	},
	mounted () {
		this.getTerms()
	}

}
</script>
<style scoped lang="scss">
.privacy-policy {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	background: rgba(0,0,0, 0.6);
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	.body {
		position: relative;
		.modal__close {
			position: absolute;
			right: 15px;
			top: 15px;
			border: 1px solid #505050;
			box-sizing: border-box;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.2s;
			&:hover {
				opacity: 0.8;
			}
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}
		display: flex;
		background: #2F2F2F;
		width: 575px;
		border-radius: 2px;
		flex-direction: column;
		height: 80vh;
		padding: 25px;

		.text {
			flex-grow: 1;
			overflow: hidden;
			overflow-y: auto;
			padding-top: 0;
			margin-top: 25px;
			&::-webkit-scrollbar {
				width: 4px;
			}
			p {
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.5px;
				color: #FFFFFF;
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			}

			&::-webkit-scrollbar-thumb {
				background-color: #00E7DF;
			}
		}
		.controls {
			padding: 25px;
			padding-top: 16px;
			border-top: 1px solid #505050;
			.checkbox {
				input {
					display: none;
				}
				label {
					cursor: pointer;
					display: flex;
					align-items: center;
					.name {
						font-size: 14px;
						line-height: 16px;
						letter-spacing: 0.4px;
						color: #E0E0E0;
					}
					.square {
						transition: 0.2s;
						width: 16px;
						display: block;
						height: 16px;
						background: #2F2F2F;
						border: 1px solid #505050;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
						border-radius: 4px;
						margin-right: 10px;
					}
				}
				input:checked + label {
					.square {
						background: #00E7DF;
					}
				}
			}
			.buttons {
				margin-top: 16px;
				display: flex;
				grid-gap: 20px;
				button {
					flex: 1;
					&.button-secondary {
						color: #B7B7B7;
					}
				}
			}
		}
	}
}
</style>

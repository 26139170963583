// profile
import profilePage from '../pages/profile/ProfilePage'

// tournaments
import tournaments from '../pages/tournaments/TournamentsPage'
import tournament from '../pages/tournaments/TournamentPage'
import overview from '../pages/tournaments/Overview'
import participants from '../pages/tournaments/Participants'
import rules from '../pages/tournaments/Rules'
import leaderboard from '../pages/tournaments/LeaderBoard'
import match from '../pages/tournaments/leaderboard/Match'
import brackets from '../pages/tournaments/Brackets'
import matchResult from '../pages/tournaments/MatchResult'
import roundInfo from '../pages/tournaments/RoundInfo'

// single pages
import loginPage from '../pages/login/Login'
import loginRecovery from '../pages/login/Recovery'
import oldLogin from '../pages/Login'
import notFoundPage from '../pages/NotFound'
import qrLogin from '../pages/QRLogin'
import DepositCoins from '../pages/deposit/DepositCoins'
import Games from '@/pages/games/Games'
import profileGameForm from '../pages/ProfileGameForm'
import Teams from '@/pages/teams/Teams'
import Team from '@/pages/teams/Team'
import FindTeam from '@/pages/teams/FindTeam'
import Leaderboard from '@/pages/leaderboard/Leaderboard'
import News from '@/pages/news/News'
import NewsDetail from '@/pages/news/NewsDetail'
import AddGames from '@/pages/games/AddGames'
import MobileChat from '@/pages/chat/MobileChat'
import Play from '@/pages/tournaments/Play'
import { routePropResolver } from './util'

export const routes = [
	{
		name: 'authenticator',
		path: '/authenticator'
	},
	{
		path: '/games',
		name: 'games',
		component: Games,
		meta: {
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Games`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{ name: 'Games', path: '/games' }
			]
		}
	},
	{
		path: '/games/add',
		name: 'games.add',
		component: AddGames,
		meta: {
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Add games`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{ name: 'Add new game', path: '/games/add' }
			]
		}
	},
	{
		path: '/news',
		name: 'news',
		component: News,
		meta: {
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | News`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{ name: 'News', path: '/news' }
			]
		}
	},
	{
		path: '/news/:id',
		name: 'news.detail',
		props: true,
		component: NewsDetail,
		meta: {
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | News`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{ name: 'News', path: '/news' }
			]
		}
	},
	{
		path: '/teams',
		name: 'teams',
		component: Teams,
		meta: {
			isRequiredGame: true,
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Teams`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{
					name: 'Teams',
					path: '/teams'
				}
			]
		}
	},
	{
		path: '/teams/find',
		name: 'teams.find',
		component: FindTeam,
		meta: {
			isRequiredGame: true,
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Find teams`,
			breadcrumb: [
				{ name: 'Find team' }
			]
		}
	},
	{
		path: '/team/:id',
		name: 'team',
		props: true,
		component: Team,
		meta: {
			isRequiredGame: true,
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Team`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{
					name: 'Team'
				}
			]
		}
	},
	{
		path: '/leaderboard',
		name: 'leaderboard',
		component: Leaderboard,
		meta: {
			isRequiredGame: true,
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Leaderboard`,
			breadcrumb: [
				{ name: 'Main', path: '/' },
				{
					name: 'Leaderboard',
					path: '/leaderboard'
				}
			]
		}
	},
  {
    path: '/',
    name: 'index',
    component: tournaments,
    meta: {
			isRequiredGame: true,
			isAuth: true,
      withHeader: true,
      withSidebar: true,
      title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournaments`,
      breadcrumb: [
        { name: 'Tournaments' },
        {
          isDynamic: true,
          getter: 'user/getCurrentGame',
          property: 'name'
        }
      ],
      onChangeBreadcrumb: ['user/SET_CURRENT_GAME']
    }
  },
  {
    path: '/tournament/:id/',
    props: true,
		name: 'tournament',
    component: tournament,
		redirect: { name: 'overview' },
    meta: {
			isRequiredGame: true,
			isAuth: true,
      withHeader: true,
      withSidebar: true,
      title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
      breadcrumb: [
        { name: 'Tournaments' },
        {
          isDynamic: true,
          getter: 'user/getCurrentGame',
          property: 'name'
        },
        {
          isDynamic: true,
          getter: 'tournaments/getCurrentTournament',
          property: 'title'
        }
      ],
      onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
    },
    children: [
      {
        path: '/',
        name: 'overview',
        component: overview,
        meta: {
          isAuth: true,
          withHeader: true,
          withSidebar: true,
          title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
          breadcrumb: [
            { name: 'Tournaments' },
            {
              isDynamic: true,
              getter: 'user/getCurrentGame',
              property: 'name'
            },
            {
              isDynamic: true,
              getter: 'tournaments/getCurrentTournament',
              property: 'title'
            }
          ],
          onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
        }
      },
      {
        path: '/tournament/:id/participant',
        name: 'participants',
        component: participants,
        meta: {
          isAuth: true,
          withHeader: true,
          withSidebar: true,
          title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
          breadcrumb: [
            { name: 'Tournaments' },
            {
              isDynamic: true,
              getter: 'user/getCurrentGame',
              property: 'name'
            },
            {
              isDynamic: true,
              getter: 'tournaments/getCurrentTournament',
              property: 'title'
            }
          ],
          onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
        },
        props: routePropResolver
      },
      {
        path: '/tournament/:id/rules',
        name: 'rules',
        component: rules,
        meta: {
          isAuth: true,
          withHeader: true,
          withSidebar: true,
          title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
          breadcrumb: [
            { name: 'Tournaments' },
            {
              isDynamic: true,
              getter: 'user/getCurrentGame',
              property: 'name'
            },
            {
              isDynamic: true,
              getter: 'tournaments/getCurrentTournament',
              property: 'title'
            }
          ],
          onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
        },
        props: routePropResolver
      },
			{
				path: '/tournaments/:id/leaderboard',
				name: 'leaderboard.leaderboard',
				component: leaderboard,
				meta: {
					isAuth: true,
					withHeader: true,
					withSidebar: true,
					withPageNavigation: true,
					title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament | Leaderboard`,
					breadcrumb: [
						{ name: 'Tournaments' },
						{
							isDynamic: true,
							getter: 'user/getCurrentGame',
							property: 'name'
						},
						{
							isDynamic: true,
							getter: 'tournaments/getCurrentTournament',
							property: 'title'
						}
					],
					onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
				},
				props: routePropResolver
			},
      {
        path: '/tournament/:id/matches',
        name: 'leaderboard.match',
        component: match,
        meta: {
          isAuth: true,
          withHeader: true,
          withSidebar: true,
          withPageNavigation: false,
          title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
          breadcrumb: [
            { name: 'Tournaments' },
            {
              isDynamic: true,
              getter: 'user/getCurrentGame',
              property: 'name'
            },
            {
              isDynamic: true,
              getter: 'tournaments/getCurrentTournament',
              property: 'title'
            }
          ],
          onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
        },
        props: routePropResolver
      },
			{
				path: '/tournament/:id/play',
				name: 'tournament.play',
				component: Play,
				meta: {
					isAuth: true,
					withHeader: true,
					withSidebar: true,
					withPageNavigation: false,
					title: `${process.env.VUE_APP_DOMAIN_TITLE} | Play`,
					breadcrumb: [
						{ name: 'Tournaments' },
						{
							isDynamic: true,
							getter: 'user/getCurrentGame',
							property: 'name'
						},
						{
							isDynamic: true,
							getter: 'tournaments/getCurrentTournament',
							property: 'title'
						}
					],
					onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
				},
				props: routePropResolver
			},
      {
        path: '/tournament/:id/bracket',
        name: 'brackets',
        component: brackets,
        meta: {
          isAuth: true,
          withHeader: true,
          withSidebar: true,
          withPageNavigation: true,
          title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
          breadcrumb: [
            { name: 'Tournaments' },
            {
              isDynamic: true,
              getter: 'user/getCurrentGame',
              property: 'name'
            },
            {
              isDynamic: true,
              getter: 'tournaments/getCurrentTournament',
              property: 'title'
            }
          ],
          onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
        },
        props: routePropResolver
      },
			{
				path: '/tournament/:id/rounds',
				name: 'rounds',
				component: roundInfo,
				meta: {
					inRounds: true,
					isAuth: true,
					withHeader: true,
					withSidebar: true,
					withPageNavigation: true,
					title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament - Rounds`,
					breadcrumb: [
						{
							name: 'Tournaments'
						},
						{
							isDynamic: true,
							getter: 'user/getCurrentGame',
							property: 'name'
						},
						{
							isDynamic: true,
							getter: 'tournaments/getCurrentTournament',
							property: 'title'
						},
						{
							name: 'Rounds'
						}
					]
				},
				props: routePropResolver
			}
    ]
  },
  {
		name: 'match-result',
    path: '/tournament/:id/match',
    component: matchResult,
    meta: {
			isRequiredGame: true,
			isAuth: true,
      withHeader: true,
      withSidebar: true,
      title: `${process.env.VUE_APP_DOMAIN_TITLE} | Tournament`,
      breadcrumb: [
        { name: 'Tournaments' },
        {
          isDynamic: true,
          getter: 'user/getCurrentGame',
          property: 'name'
        },
        {
          isDynamic: true,
          getter: 'tournaments/getCurrentTournament',
          property: 'title'
        }
      ],
      onChangeBreadcrumb: ['user/SET_CURRENT_GAME', 'tournaments/SET_CURRENT_TOURNAMENT']
    },
    props: true
  },
  {
    path: '/qr-login',
    name: 'qr-login',
    component: qrLogin,
    meta: { withHeader: false, withSidebar: false, title: 'Login' }
  },
  {
    path: '/send-data',
    name: 'send-data',
    component: profileGameForm,
    meta: { isAuth: false, withHeader: false, withSidebar: false, title: 'Qlash' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: profilePage,
    meta: {
      isAuth: true,
      withHeader: true,
      withSidebar: true,
      withPageNavigation: false,
      title: `${process.env.VUE_APP_DOMAIN_TITLE} | Profile`
    }
  },
	{
		path: '/old-login',
		name: 'olg-login',
		component: oldLogin,
		meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | login` }
	},
  {
    path: '/login',
    name: 'login',
    component: loginPage,
    meta: { isGuest: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | login` }
  },
	{
		path: '/login/recovery',
		name: 'recovery',
		component: loginRecovery,
		meta: { isGuest: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | recovery` }
	},
	{
		path: '/coins',
		name: 'deposit',
		component: DepositCoins,
		meta: {
			isRequiredGame: true,
			isAuth: true,
			withHeader: true,
			withSidebar: true,
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | QLASH COINS`,
			breadcrumb: [
				{
					name: 'MAIN'
				},
				{
					name: 'QLASH COINS'
				}
			]
		}
	},
	{
		path: '/mobile-chat',
		name: 'mobile-chat',
		component: MobileChat,
		meta: {
			title: `${process.env.VUE_APP_DOMAIN_TITLE} | Chat`,
			withHeader: true,
			isAuth: true
		}
	},
  {
    path: '*',
    component: notFoundPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | not found` }
  }
]

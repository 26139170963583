<template>
	<div>
		<RoundRobinStage v-if="tournament.rr.stage === ROUND_ROBIN_STAGE"></RoundRobinStage>
		<BracketStage v-if="tournament.rr.stage === BRACKETS_STAGE"></BracketStage>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { BRACKETS_STAGE, ROUND_ROBIN_STAGE } from '../../../constants/round_robin/round-robin-stages'
import RoundRobinStage from './RoundRobinStage'
import BracketStage from './BracketStage'
export default {
	data () {
		return {
			ROUND_ROBIN_STAGE: ROUND_ROBIN_STAGE,
			BRACKETS_STAGE: BRACKETS_STAGE
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	methods: {
		...mapActions({
			getTournament: 'tournaments/getTournament'
		})
	},
	mounted () {
		this.subscribeObserver(`rr.${this.tournament.id}`, () => {
			this.getTournament(this.tournament.id)
		})
	},
	components: {
		BracketStage,
		RoundRobinStage
	}
}
</script>

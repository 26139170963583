import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class CommonService extends BaseService {
  static async getRule (id) {
    try {
      const response = await this.request({ auth: true }).get(`rules/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async sendChampionshipForm (data) {
    try {
      const response = await this.request({ auth: true }).post('/it-championship', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}

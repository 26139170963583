export default {
	bind: (el, binding) => {
		const ele = el
		let x = 0
		let w = 0

		const mouseDownHandler = function (e) {
			x = e.clientX

			const styles = window.getComputedStyle(ele)
			w = parseInt(styles.width, 10)

			document.addEventListener('mousemove', mouseMoveHandler)
			document.addEventListener('mouseup', mouseUpHandler)
		}

		const mouseMoveHandler = function (e) {
			const dx = e.clientX - x

			const width = w - dx
			if ((window.innerWidth - 300) < width || width < 250) return
			ele.style.width = `${width}px`
		}

		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler)
			document.removeEventListener('mouseup', mouseUpHandler)
		}

		const resizer = document.createElement('div')
		resizer.style.height = '100%'
		resizer.classList.add('resizer-l')
		resizer.style.cursor = 'col-resize'
		resizer.style['user-select'] = 'none'
		resizer.style.width = '10px'
		resizer.style.position = 'absolute'
		resizer.style.bottom = '0'
		resizer.style.left = '0'
		el.appendChild(resizer)
		resizer.addEventListener('mousedown', mouseDownHandler)
	}
}

<template>
	<div class="tft__play">
		<template v-if="currentMatch">
			<div class="leader-board__tabs-wrapper">
				<div class="leader-board__tabs">
					<div class="leader-board__tabs--left">
						<button
							class="leader-board__tab" v-for="(r, index) in rounds" :key="index"
							:class="{ 'is-active': r === currentMatch.round}"
							@click="currentTab = index"
							:disabled="r !== currentMatch.round"
						>
							<svg v-if="r !== currentMatch.round" width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M10.9375 6.875H10.2812V4.90625C10.2812 2.63672 8.39453 0.75 6.125 0.75C3.82812 0.75 1.96875 2.63672 1.96875 4.90625V6.875H1.3125C0.574219 6.875 0 7.47656 0 8.1875V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V8.1875C12.25 7.47656 11.6484 6.875 10.9375 6.875ZM8.09375 6.875H4.15625V4.90625C4.15625 3.83984 5.03125 2.9375 6.125 2.9375C7.19141 2.9375 8.09375 3.83984 8.09375 4.90625V6.875Z" fill="#8F8F8F"/>
							</svg>
							{{ $t('Round') }} {{ index + 1 }}
						</button>
					</div>
				</div>
			</div>
			<div class="shell">
				<div class="range">
					<div class="cell-sm-8">
						<div class="table solo" v-if="currentMatch.participants">
							<div class="table__header">
								<div class="table__col">
									{{ $t('Position') }}
								</div>
								<div class="table__col">
									{{ $t('Name') }}
								</div>
								<div class="table__col">
									{{ $t('Pictures') }}
								</div>
							</div>
							<div class="table__body">
								<div class="table__row" :class="{'active': participant.status === 'winner'}" v-for="(participant, index) in currentMatch.participants" :key="participant.id">
									<div class="table__col">
										<span>{{ Number(index) + 1 }}</span>
									</div>
									<div class="table__col">
										<UiAvatar
											size="50"
											:alt="getParticipantNickName(participant.participant)"
											:src="getParticipantAvatar(participant.participant)"
										/>
										<span>{{ getParticipantNickName(participant.participant) }}</span>
									</div>
									<div class="table__col">
										<div class="participant_picture" @click="openResult = getResultForParticipant(participant.participant)" :class="{'active': getResultForParticipant(participant.participant)}">
											<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M16 3.25C16 2.49554 15.3125 1.85714 14.5 1.85714H11.75L11.3438 0.928571C11.125 0.377232 10.5625 0 9.9375 0H6.03125C5.40625 0 4.84375 0.377232 4.625 0.928571L4.25 1.85714H1.5C0.65625 1.85714 0 2.49554 0 3.25V11.6071C0 12.3906 0.65625 13 1.5 13H14.5C15.3125 13 16 12.3906 16 11.6071V3.25ZM11.75 7.42857C11.75 9.37277 10.0625 10.9107 8 10.9107C5.90625 10.9107 4.25 9.37277 4.25 7.42857C4.25 5.51339 5.90625 3.94643 8 3.94643C10.0625 3.94643 11.75 5.51339 11.75 7.42857ZM10.75 7.42857C10.75 6.03571 9.5 4.875 8 4.875C6.46875 4.875 5.25 6.03571 5.25 7.42857C5.25 8.85045 6.46875 9.98214 8 9.98214C9.5 9.98214 10.75 8.85045 10.75 7.42857Z" fill="#8F8F8F"/>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cell-sm-4">
						<TftSidebarContainer @updateMatch="updateCurrentMatch" @nextMatch="getMatches" :rounds="rounds.size" :round="currentMatch"></TftSidebarContainer>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="loading-play">
				<UiLoader></UiLoader>
			</div>
		</template>
		<transition name="modal">
			<ScreenshotResult v-if="openResult" @close="openResult = null" :result="currentMatch.results[0]"></ScreenshotResult>
		</transition>
	</div>
</template>
<script>
import ScreenshotResult from './ScreenshotResult'
import TftSidebarContainer from '../../../components/tft/TftSiderbarContainer'
import UiAvatar from '../../../components/UiAvatar'
import { TftService } from '../../../services/tft.service'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UiLoader from '../../../components/UiLoader'
export default {
	data () {
		return {
			socketsCache: [],
			matches: [],
			openResult: null,
			currentMatch: null
		}
	},
	watch: {
		async currentMatch () {
			if (this.currentMatch) {
				this.subscribeResult()
				this.subscribeStatus()
				//
				// const findParticipant = this.currentMatch.participants.find(i => {
				// 	return i.participant.profile.user.id === this.$currentUser.id
				// })
				// if (this.currentMatch.status === 'FINISHED') {
				// 	if (findParticipant && findParticipant.status === 'defeated') {
				// 		// get place and points for current participant
				// 		await this.getTournament(this.$route.params.id)
				// 		await this.showModal({
				// 			meta: {
				// 				inRound: true,
				// 				inTournament: true,
				// 				isWinner: false
				// 			},
				// 			round: this.currentMatch
				// 		})
				// 		await this.$router.push({ name: 'tournament', params: { id: this.$route.params.id } })
				// 	} else if (findParticipant && !this.currentMatch.next_match_id && findParticipant.participant.status === 'winner') {
				// 		await this.showModal({
				// 			meta: {
				// 				inRound: false,
				// 				inTournament: true,
				// 				isWinner: true
				// 			},
				// 			round: Object.assign({}, this.currentMatch, { winner_id: findParticipant.participant.id })
				// 		})
				// 		await this.$router.push({ name: 'tournament', params: { id: this.$route.params.id } })
				// 	}
				// }
			}
		}
	},
	methods: {
		...mapMutations({
			showModal: 'modals/SHOW',
			toast: 'toast/NEW'
		}),
		...mapActions({
			getTournament: 'tournaments/getTournament'
		}),
		updateCurrentMatch () {
			TftService.getMatch(this.$route.params.id, this.currentMatch.id).then(response => {
				this.currentMatch = response.data
			})
		},
		getResultForParticipant (participant) {
			return this.currentMatch.results.find(item => item.reporter_id === participant.profile.user.id)
		},
		async setCurrentMatch () {
			this.currentMatch = this.matches.find(item => {
				return item.participants.find(i => {
					return i.participant.profile.user.id === this.$currentUser.id
				}) && item.status !== 'FINISHED'
			})
		},
		getParticipantAvatar (participant) {
			if (!this.isTeam) return participant.profile.user.avatar

			return participant.team !== undefined ? participant.team.logo : ''
		},
		onTabClick (id) {
			this.currentTab = id
		},
		getParticipantNickName (participant) {
			if (!this.isTeam) return participant.profile.user.name ?? (participant.profile.user.nickname ? participant.profile.user.nickname : (participant.profile.user.name ? participant.profile.user.name : participant.profile.user.email))

			return participant.team !== undefined ? participant.team.name : ''
		},
		subscribeResult () {
			const channel = `result.tournament.${this.$route.params.id}.match.${this.currentMatch.id}`
			if (this.socketsCache.includes(channel)) return
			this.socketsCache.push(channel)
			this.subscribeObserver(channel, () => {
				this.updateCurrentMatch()
			})
		},
		subscribeStatus () {
			const channel = `tournament.${this.$route.params.id}.match.${this.currentMatch.id}`
			if (this.socketsCache.includes(channel)) return
			this.socketsCache.push(channel)
			this.subscribeObserver(channel, (data) => {
				if (['CONFLICT', 'OPENED', 'WAITING', 'CONFLICT'].includes(data.data.status)) {
					this.getMatches()
				} else {
					this.updateCurrentMatch()
				}
			})
		},
		getMatches () {
			TftService.getMatches(this.$route.params.id).then(response => {
				this.matches = response.data.data
				this.setCurrentMatch()
			})
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		}),
		rounds () {
			const rounds = new Set()
			this.matches.forEach(item => {
				rounds.add(item.round)
			})
			return rounds
		}
	},
	mounted () {
		this.getMatches()
	},
	components: {
		UiAvatar,
		ScreenshotResult,
		UiLoader,
		TftSidebarContainer
	}
}
</script>
<style scoped lang="scss">
.tft__play {
	.loading-play {
		width: 100%;
		margin: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.leader-board__tabs {
		display: flex;
		justify-content: space-between;
		padding-left: 40px;

		.leader-board__tabs--left {
			overflow-x: auto;
			overflow-y: hidden;
			max-width: 100%;
			&::-webkit-scrollbar {
				height: 4px;
			}
			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #888;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}
		}

		border-bottom: 1px solid $color-gray-600;

		&--left {
			display: flex;
			flex-wrap: nowrap;
		}

		&-wrapper {
			max-width: 100%;
			overflow-x: auto;
			margin-bottom: 30px;
			padding-bottom: 10px;
		}
	}
	.leader-board__tab {
		@include navigation-item;
		outline: none;
		border: none;
		display: flex;
		align-items: center;
		svg {
			margin-right: 8px;
			margin-bottom: 2px;
			min-width: 13px;
		}
		white-space: nowrap;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		color: $color-gray-400;
		text-align: center;
		border-bottom: 2px solid transparent;

		&.total {
			text-align: center;
			min-width: 205px;
			color: $primary-color;

			&.is-active,
			&:hover {
				color: #00E7DF;
			}

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.ui-icon-base {
				margin-right: 15px;
			}
		}
		&.is-active {
			color: $primary-color;
			border-bottom: 2px solid #00E7DF;
		}
		padding-right: 22.5px;
		padding-left: 22.5px;
	}
}

.participant_picture {
	width: 50px;
	height: 50px;
	border: 1px dashed #505050;
	min-width: 50px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	justify-content: center;
	&.active {
		cursor: pointer;
		border: 1px solid #00E7DF;
		svg {
			path {
				fill: #00E7DF
			}
		}
	}
}

.avatar {
	max-width: 50px;
	min-width: 50px;
	min-height: 50px;
}

.table__row {
	padding: 0;
}

.table__header {
	padding-left: 0;
	padding-right: 0;

	> *:nth-child(2) {
		padding-left: 25px;
	}
}

.table__row {
	&.active {
		background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
	}
}

.table__row,
.table__header {
	> *:nth-child(1) {
		width: 78px;
		min-width: 78px;
		justify-content: center;
		text-align: center;
	}

	> *:nth-child(2) {
		flex-grow: 1;
		justify-content: flex-start;
	}

	> *:nth-child(3) {
		min-width: 100px;
		justify-content: center;
		text-align: center;
	}
}

.table__body {
	.table__col {
		display: flex;
		align-items: center;
		padding: 10px 25px;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		letter-spacing: 0.25px;

		color: $color-white;

		&:first-child {
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 2px;
			border-right: 1px solid $color-gray-500;
		}

		&:last-child {
			font-size: 18px;
			line-height: 16px;
			letter-spacing: 0.75px;

			border-left: 1px solid $color-gray-500;
		}
	}

	.avatar {
		margin-right: 25px;
	}
}

</style>

<template>
	<div class="games page">
		<UiPageTopBar
			:title="'Your games'"
			:withPrevButton="true"
		>
			<router-link :to="{ name: 'games.add' }">
				<UiButton class="add_game" type="primary">
					+
					Add new game
				</UiButton>
			</router-link>
		</UiPageTopBar>
		<div class="shell">
				<hooper :settings="{
					wheelControl: false,
					autoPlay: false,
					breakpoints: {
						1200: {
							itemsToShow: 3.1
						},
						500: {
							itemsToShow: 2.1
						},
						300: {
							itemsToShow: 1.1
						}
					}
				}">
					<slide v-for="(game,i) in profilesList" :key="i">
							<div class="game" :class="{'open': open === game.game.id}">
								<div class="game__background">
									<img :src="game.game.web_image" alt="">
								</div>
								<div class="game__body">
									<div class="game__icon">
										<div class="game__icon-backdrop"></div>
										<img :src="game.game.web_icon" alt="">
									</div>
								</div>

								<div class="game__controls">
									<div class="game__controls-title">
										{{ game.game.name }}
									</div>
									<div class="game__controls-profiles">

										<div class="game__controls-profile" v-for="(profile,i) in game.profiles" :key="i">
											<div class="icon">
												<img :src="getImageByPlatform(profile.platform_id)" alt="">
											</div>
											<span>{{ profile.info.id }}</span>
										</div>

									</div>
									<div class="game__controls-buttons">
										<button class="game__controls-buttons_edit" @click="openGameId = game.game.id">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M13.6101 3.89225C14.13 3.37237 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.419 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.419V11.581Z" fill="#B7B7B7"/>
											</svg>

											Edit game id
										</button>
									</div>
								</div>

							</div>
					</slide>
					<template v-if="profilesList.length < 3">
						<slide v-for="(game, i) in (3 - profilesList.length)" :key="i">
							<div class="game empty">
								<div class="game__add">
									<router-link :to="{ name: 'games.add' }">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M14.8571 6.28571H9.71429V1.14286C9.71429 0.535714 9.17857 0 8.57143 0H7.42857C6.78571 0 6.28571 0.535714 6.28571 1.14286V6.28571H1.14286C0.5 6.28571 0 6.82143 0 7.42857V8.57143C0 9.21429 0.5 9.71429 1.14286 9.71429H6.28571V14.8571C6.28571 15.5 6.78571 16 7.42857 16H8.57143C9.17857 16 9.71429 15.5 9.71429 14.8571V9.71429H14.8571C15.4643 9.71429 16 9.21429 16 8.57143V7.42857C16 6.82143 15.4643 6.28571 14.8571 6.28571Z" fill="#8F8F8F"/>
										</svg>
									</router-link>
									<span>Add game to your list</span>
								</div>
							</div>
						</slide>
					</template>
					<template v-else>
						<slide>
							<div class="game empty">
								<div class="game__add">
									<router-link :to="{ name: 'games.add' }">
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M14.8571 6.28571H9.71429V1.14286C9.71429 0.535714 9.17857 0 8.57143 0H7.42857C6.78571 0 6.28571 0.535714 6.28571 1.14286V6.28571H1.14286C0.5 6.28571 0 6.82143 0 7.42857V8.57143C0 9.21429 0.5 9.71429 1.14286 9.71429H6.28571V14.8571C6.28571 15.5 6.78571 16 7.42857 16H8.57143C9.17857 16 9.71429 15.5 9.71429 14.8571V9.71429H14.8571C15.4643 9.71429 16 9.21429 16 8.57143V7.42857C16 6.82143 15.4643 6.28571 14.8571 6.28571Z" fill="#8F8F8F"/>
										</svg>
									</router-link>
									<span>Add game to your list</span>
								</div>
							</div>
						</slide>
					</template>
				</hooper>
		</div>
		<transition name="modal">
			<AddGame @update="getProfiles" @close="openGameId = null" v-if="openGameId" :game_id="openGameId"></AddGame>
		</transition>
	</div>
</template>
<script>
import UiButton from '../../components/UiButton'
import UiPageTopBar from '@/components/UiPageTopBar'
import AddGame from '../../components/games/modals/AddGame'
import { GamesService } from '../../services/games.service'
import { UsersService } from '../../services/users.service'
import { Hooper, Slide } from 'hooper'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	data () {
		return {
			open: null,
			openGameId: null,
			games: [],
			profiles: [],
			loader: false,
			editMode: false,
			platforms: {
				1: null,
				2: null,
				3: null,
				4: null
			}
		}
	},
	components: {
		UiButton,
		Hooper,
		Slide,
		AddGame,
		UiPageTopBar
	},
	methods: {
		...mapActions({
			updateGames: 'user/getGames'
		}),
		...mapMutations({
			setCurrentGame: 'user/SET_CURRENT_GAME'
		}),
		getProfile (game_id, platform_id) {
			const find = this.profiles.find(item => item.game && item.game.id === game_id && item.platform_id === platform_id)
			return find ?? null
		},
		getImageByPlatform (platform) {
			switch (platform) {
				case 1:
					return require('@/assets/ps.png')
				case 2:
					return require('@/assets/xbox.png')
				case 4:
					return require('@/assets/mobile.svg')
				case 3:
					return require('@/assets/pc.svg')
			}
		},
		resetPlatforms () {
			this.platforms = {
				1: null,
				2: null,
				3: null,
				4: null
			}
		},
		getLabel (platform) {
			switch (platform) {
				case 1:
					return 'Playstation ID'
				case 2:
					return 'Xbox ID'
				case 3:
					return 'GAME ID'
				case 4:
					return 'GAME ID'
			}
		},
		edit (id) {
			const platforms = this.profiles.filter(item => item.game.id === id)
			for (const item in platforms) {
				this.platforms[platforms[item].platform_id] = platforms[item].info.id
			}
			this.open = id
			this.editMode = true
		},
		async addGame () {
			const game = this.games.find(item => item.id === this.open)
			this.loader = true
			for (const item in this.platforms) {
				if (!this.platforms[item]) continue
				if (!this.editMode) {
					await UsersService.addProfile({
						game_id: game.id,
						platform_id: item,
						info: {
							id: this.platforms[item],
							nickname: this.user.nickname ?? 'unknown',
							meta: []
						}
					}).then(() => {
						if (!this.currentGame) {
							this.setCurrentGame(game.id)
						}
					})
				} else {
					await UsersService.editProfile({
						data: [
							{
								game_id: game.id,
								platform_id: item,
								info: {
									id: this.platforms[item],
									nickname: this.user.nickname ?? 'unknown',
									meta: []
								}
							}
						]
					})
				}
			}
			this.editMode = false
			this.open = null

			this.getProfiles()
			this.updateGames()
			this.loader = false
			this.resetPlatforms()
		},
		add (id) {
			this.open = id
		},
		getGames () {
			GamesService.getGames().then(response => {
				this.games = response.data
			})
		},
		getProfiles () {
			UsersService.getProfiles().then(response => {
				this.profiles = response.data.data
			})
		}
	},
	computed: {
		...mapGetters({
			profiles: 'user/getGames',
			currentGame: 'user/getCurrentGame',
			user: 'user/getCurrentUser'
		}),
		profilesList () {
			const array = this.profiles
			const profiles = []
			array.forEach(item => {
				if (!item.game) return
				const find = profiles.find(i => i.game && i.game.id === item.game.id)
				if (find) return
				profiles.push(item)
			})
			profiles.forEach(item => {
				item.profiles = []
				item.game.platforms.forEach(i => {
					const profile = this.getProfile(item.game.id, i)
					if (profile) {
						item.profiles.push(profile)
					}
				})

				return item
			})
			return profiles.filter(item => item.profiles.length)
		}
	},
	mounted () {
		this.getGames()
		this.getProfiles()
	}
}
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
.games {
	.hooper {
		height: 100%;
		.hooper-slide {
			padding: 10px;
		}
	}
	.add_game {
		margin-right: 40px;
	}
	.shell {
		.range {
			display: flex;
			overflow-x: auto;
			flex-wrap: nowrap;
		}
		padding: 40px;
			h2 {
				font-weight: bold;
				font-size: 36px;
				line-height: 44px;
				letter-spacing: 1px;
				margin-bottom: 40px;
			}
		.game {
			height: 800px;
			width: 100%;
			border-radius: 5px;
			overflow: hidden;
			display: flex;
			align-items: center;
			border: 1px solid transparent;
			transition: 0.2s;
			justify-content: center;
			position: relative;
			&.empty {
				border: 1px dashed #505050;
				.game__add {
					a {
						width: 50px;
						height: 50px;
						background: #2F2F2F;
						border: 1px solid #505050;
						display: flex;
						margin: 0 auto;
						box-sizing: border-box;
						margin-bottom: 22px;
						justify-content: center;
						align-items: center;
						border-radius: 5px;
						transition: 0.2s;
						&:hover {
							box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
						}
					}
					span {
						width: 100%;
						display: block;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 0.15px;
						text-transform: uppercase;
						color: #8F8F8F;
					}
				}
			}
			&.open {
				&:after {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					content: "";
					border-radius: 5px;
					background: rgba(0, 0, 0, 0.8);
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				}
			}
			.game__attach {
				z-index: 9999;
				padding: 0 25px;
				width: 100%;
				margin-top: 50px;
				color: #FFFFFF;
				position: relative;
				span {
					text-align: center;
					display: block;
					width: 100%;
					font-weight: bold;
					font-size: 24px;
					line-height: 29px;
					letter-spacing: 0.15px;
				}
				.input {
					display: flex;
					align-items: center;
					border: 1px solid #00E7DF;
					padding: 12px 15px;
					margin-top: 22px;
					border-radius: 5px;
					margin-bottom: 25px;
					img {
						width: 26px;
					}
					input {
						margin-left: 15px;
						flex-grow: 1;
						font-size: 10px;
						background: transparent;
						line-height: 20px;
						border: none;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: #B0B0B0;
						outline: none;
					}
				}
				button {
					display: flex;
					justify-content: center;
					::v-deep img {
						margin-left: 10px;
					}
					align-items: center;
					width: 100%;
					padding: 15.5px 0;
				}

			}
			&:hover {
				.game__body {
					.change__buttons {
						opacity: 1;
					}
				}

			}
			.game__body {
				width: 100%;
				position: relative;
				.game__added {
					margin: 0 auto;
					margin-bottom: 14px;
					border-radius: 5px;
					padding: 16px 17px;
					display: flex;
					justify-content: center;
					position: relative;
					border: 1px solid #00E7DF;
					background: transparent;
					box-shadow: 0px 0px 3px 2px rgba(0,231,223, 0.36);
					width: fit-content;
					.bottom__linear {
						position: absolute;
						top: 103%;
						width: 1px;
						height: 14px;
						background: #00E7DF;
						box-shadow: 0px 0px 3px 2px rgba(0,231,223, 0.36);
						z-index: 9999;
						&:after {
							position: absolute;
							top: -2px;
							width: 5px;
							height: 5px;
							transform: rotate(130deg);
							background: #00E7DF;
							content: "";
							left: calc(50% - 2.5px);
							display: block;
						}
					}
					span {
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.15px;
						color: #00E7DF;
						z-index: 999;
						font-weight: bold;
						position: relative;
					}
					svg {
						margin-right: 10px;
						position: relative;
						z-index: 999;
					}

					&::after {
						border-radius: 5px;
						content: "";
						display: block;
						position: absolute;
						left: 0;
						width: 100%;
						height: 100%;
						top: 0;
						background: rgba(47, 47, 47, 0.7);
					}
				}
				.change__buttons {
					position: relative;
					z-index: 9999;
					transition: 0.2s;
					display: flex;
					margin-top: 16px;
					justify-content: center;
					opacity: 0;
					button {
						padding: 16px 25px;
						position: relative;
						border: 1px solid #00E7DF;
						border-radius: 5px;
						box-shadow: 0px 0px 3px 2px rgba(0,231,223, 0.36);

						background: transparent;
						z-index: 999;
						&::after {
							border-radius: 5px;
							content: "";
							display: block;
							position: absolute;
							left: 0;
							width: 100%;
							height: 100%;
							top: 0;
							background: rgba(47, 47, 47, 0.7);
						}
						span {
							color: #00E7DF;
							font-weight: bold;
							font-size: 14px;
							line-height: 17px;
							letter-spacing: 0.15px;
							text-transform: uppercase;
							position: relative;
							z-index: 9999;
						}
						.top__linear {
							position: absolute;
							bottom: 100%;
							height: 15px;
							box-shadow: 0px 0px 3px 2px rgba(0,231,223, 0.36);
							width: 1px;
							background: #00E7DF;
						}
					}
					button:first-child {
						.top__linear {
							left: 50%
						}
					}
					button + button {
						margin-left: 16px;
					}
				}
			}
			.game__icon {
				margin: 0 auto;
				position: relative;
				border: 1px solid #505050;
				.game__icon-backdrop {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					background: rgba(18, 18, 18, 0.7);
					border-radius: 4px;
				}
				border-radius: 5px;
				width: 130px;
				height: 130px;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 999;
				img {
					position: relative;
					z-index: 999;
					width: 88px;
				}
			}
			&:hover {
				.game__join {
					opacity: 1;
				}

			}
			.game__join {
				position: relative;
				justify-content: center;
				overflow: hidden;
				text-align: center;
				opacity: 0;
				transition: 0.2s;
				button {
					overflow: hidden;
					padding: 16px 24px;
					z-index: 99;
					position: relative;
					border-radius: 5px;
					display: flex;
					margin: 0 auto;
					width: fit-content;
					outline: none;
					background: transparent;
					border: 1px solid #00E7DF;
					cursor: pointer;
					span {
						position: relative;
						z-index: 99;
						font-weight: bold;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: 0.15px;
						color: #00E7DF;
					}
					span + span {
						margin-left: 12px;
					}
					&::after {
						content: "";
						display: block;
						position: absolute;
						left: 0;
						width: 100%;
						height: 100%;
						top: 0;
						background: rgba(47, 47, 47, 0.7);
					}
				}
				.top__linear {
					width: 1px;
					margin: 0 auto;
					background: #00E7DF;
					height: 15px;
					box-shadow: 0px 0px 2px 1px rgba(0,231,223, 0.36);
					position: relative;
					&:after {
						position: absolute;
						top: -2px;
						width: 5px;
						height: 5px;
						transform: rotate(130deg);
						background: #00E7DF;
						content: "";
						left: calc(50% - 2.5px);
						display: block;
					}
				}

			}
			.game__background {
				min-width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				max-width: 100%;
				min-height: 100%;
				img {
					min-height: 100%;
					min-width: 100%;
					max-height: 100%;
					max-width: 115%;
				}
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					content: "";
					background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.7) 100%);
				}
			}
			.game__title {
				position: absolute;
				bottom: 25px;
				text-align: center;
				width: 100%;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				letter-spacing: 0.15px;
			}
			&:hover:not(.empty), &.active {
				border: 1px solid #00E7DF;
			}
			.game__controls {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 16px 20px;
				padding-bottom: 0;
				transition: 0.3s;
				transform: translateY(45px);
				.game__controls-title {
					font-weight: bold;
					font-size: 24px;
					line-height: 29px;
					letter-spacing: 0.15px;
				}
				.game__controls-profiles {
					margin-top: 10px;
					margin-bottom: 16px;
					.game__controls-profile {
						display: flex;
						align-items: center;
						.icon {
							width: 18px;
							img {
								max-width: 100%;
							}
							display: flex;
							align-items: center;
							justify-content: center;
						}
						span {
							font-size: 12px;
							line-height: 20px;
							letter-spacing: 2px;
							text-transform: uppercase;
							color: #BDBDBD;
							margin-left: 15px;
						}
					}
					.game__controls-profile + .game__controls-profile {
						margin-top: 9px;
					}
				}
				.game__controls-buttons {
					display: flex;
					transition: 0.5s;
					width: 100%;
					button {
						padding: 12px 0;
						display: flex;
						outline: none;
						background: rgba(18, 18, 18, 0.6);
						justify-content: center;
						cursor: pointer;
						align-items: center;
						border: 1px solid #505050;
						border-radius: 4px;
						flex: 1;
						letter-spacing: 1px;
						text-transform: uppercase;
						font-weight: bold;
						font-size: 12px;
						transition: 0.2s;
						color: #B7B7B7;
						svg {
							margin-right: 6px;
						}
						&.game__controls-buttons_remove {
							line-height: 17px;
						}
						&:hover {
							svg {
								path {
									fill: #00E7DF
								}
							}
							border: 1px solid #00E7DF;
							color: #00E7DF;
						}
					}
					button + button {
						margin-left: 15px;
					}
				}
			}
			&:hover {
				.game__controls {
					transform: translateY(-20px);
					.game__controls-buttons {
						display: flex;
						height: auto;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.games {
		.shell {
			.game {
				height: 500px;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.games {
		.shell {
			width: 100%;
			min-width: 100%;
			padding: 0;
			margin-top: 10px;
		}
	}
	.games {
		.add_game {
			font-size: 12px;
			margin-right: 15px;
			padding: 10px;
		}
		.hooper {
			padding-left: 10px;
			.hooper-slide {
				padding: 5px;
			}
		}
	}
}
</style>

<template>
	<div class="overview">
		<div class="mobile__banner" v-if="isMobile">
			<img :src="tournament.image ? tournament.image : $currentGame.image" alt="">
		</div>
		<div class="shell">
			<div class="range">
				<div class="cell-sm-8 range__content">
					<div class="overview__img" :class="{'game': !tournament.image}" v-if="!isMobile">
						<UiImgLoader

							v-if="currentGame"
							:src="tournament.image ? tournament.image : currentGame.image"
							:alt="tournament.title"
						/>
					</div>
					<div class="overview__info" v-if="tournament.sponsorImage">
						<div class="overview__content" style="min-width: 100%">
							<div class="tournament-sponsor-wrapper">
								<div class="ui-tournament-item__info__item">
									<span class="title" style="white-space: nowrap;">{{ $t('POWERED BY') }}</span>
								</div>
								<div class="ui-tournament-item__info__item tournament-sponsor-img-container">
									<img :src="tournament.sponsorImage" class="tournament-sponsor-img">
								</div>
							</div>
						</div>
						<div class="overview__content" v-if="tournament.sponsorUrl && tournament.sponsorText">
							<div class="tournament-sponsor-a-container">
								<a target="_blank" :href="tournament.sponsorUrl">
									{{ tournament.sponsorText }} &gt;
								</a>
							</div>
						</div>
					</div>
					<div class="overview__info" v-if="tournament.description || tournament.prizePool.length > 0">
						<div v-if="tournament.description">
							<div class="overview__about-label">
								<h5>{{ $t('Info') }}</h5>
							</div>
							<div class="overview__content">
								<p v-breaks>
									{{ tournament.description }}
								</p>
							</div>
						</div>
						<div class="overview__about-prizepulls" v-if="tournament.prizePool.length > 0">
							<div class="overview__about-label">
								<h5>{{ $t('Prizes and awards') }}</h5>
							</div>
							<div class="overview__content">
								<ul>
									<li v-for="(prize, i) in getTournamentPrizePools()" :key="i">
										<div class="overview__about-prizepulls_place">
											<img src="@/assets/prize.svg" alt="">
											<strong>{{ prize.place }}</strong>
											<span v-if="prize.place === 1">st</span>
											<span v-else-if="prize.place === 2">nd</span>
											<span v-else-if="prize.place === 3">rd</span>
											<span v-else>th</span>
										</div>
										<div class="overview__about-prizepulls_prize">
											<strong>
												{{ prize.prizes[0].value }}
											</strong>
											<img src="@/assets/coin.svg" alt="">
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="overview__about">
						<div>
							<div class="overview__about-label">
								<h5>{{ $t('About tournament') }}</h5>
							</div>
							<div class="overview__content">
								<ul>
									<li>
										<span>{{ $t('Start tournament') }}</span>
										<span>
										<UiBaseIcon
											iconName="calendar"
											width="18"
											height="18"
										/>
										<span>{{ tournament.startedAt | formatWeekDayAndDate }}</span>
										<UiBaseIcon
											iconName="clock"
											width="18"
											height="18"
										/>
										<span>{{ tournament.startedAt | formatHours }}</span>
									</span>
									</li>
									<li>
										<span>{{ $t('Game mode') }}</span>
										<span>
										<span>
											<UiBaseIcon
												iconName="cup"
												width="18"
												height="18"
											/>
										</span>
										<span>{{ getMode() }}</span>
									</span>
									</li>
									<li>
										<span>{{ $t('Tournament type') }}</span>
										<span>
										<span>
											<UiBaseIcon
												iconName="cup"
												width="18"
												height="18"
											/>
										</span>
										<span>
										<template v-if="tournament.type === 1">
                                Single elimination
                            </template>
                            <template v-if="tournament.type === 2">
                                Arena
                            </template>
                            <template v-if="tournament.type === 3">
                                Double Elimination
                            </template>
                            <template v-if="tournament.type === 5">
                                Multi lobby arena
                            </template>
														<template v-if="tournament.type === 4">
                                Round robin
                            </template>
                            <template v-if="tournament.type === 6">
                                TFT
                            </template>
														<template v-if="tournament.type === 7">
                                Swiss
                            </template>
														<template v-if="tournament.type === 8">
                                Challenge
                            </template>
										</span>
									</span>
									</li>
									<li>
										<span>{{ $t('Number of participants') }}</span>
										<span>
										<span>
											<UiBaseIcon
												iconName="users"
												width="18"
												height="18"
											/>
										</span>
										<span>{{ tournament.participantCount }}</span>
									</span>
									</li>
									<li>
										<span>{{ $t('Platforms') }}</span>
										<span>
										<UiPlatformsList v-if="favouriteGames" :platforms="getGamePlatforms()"/>
									</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<SidebarSocials v-if="isMobile"></SidebarSocials>
				</div>
				<div class="cell-sm-4 range__container">
					<SidebarContainer/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	import UiImgLoader from '@/components/UiImgLoader'
	import UiPlatformsList from '@/components/UiPlatformsList'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'

	import SidebarContainer from './containers/SidebarContainer'

	import formatHours from '@/filters/formatHours'
	import formatWeekDayAndDate from '@/filters/formatWeekDayAndDate'
	import SidebarSocials from '@/components/tournaments/SidebarSocials'
	import breaks from '../../directives/breaks'

	export default {
		name: 'Overview',
		filters: { formatHours, formatWeekDayAndDate },
		components: {
			UiImgLoader,
			UiBaseIcon,
			UiPlatformsList,
			SidebarContainer,
			SidebarSocials
		},
		directives: {
			breaks: breaks
		},
		computed: {
			...mapGetters({
				isMobile: 'dom/isMobile',
				currentGame: 'user/getCurrentGame',
				favouriteGames: 'user/getFavouriteGames',
				tournament: 'tournaments/getCurrentTournament'
			})
		},

		methods: {
			async init () {
			},
			getMode () {
				if (this.tournament.type === 2) return 'Arena'
				if (this.tournament.matchType === 2) return `${this.tournament.teamMates}v${this.tournament.teamMates}`

				return '1v1'
			},

			getGamePlatforms () {
				const game = this.favouriteGames.filter(item => item.id === this.tournament.gameId)[0]
				return game && game.isGlobal ? [1, 2, 3, 4] : [this.tournament.platformId]
			},

			getTournamentPrizePools () {
				return this.tournament.prizePool.filter((o) => o.prizes.length > 0)
			}
		},

		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss">
	.overview {
		img {
			max-width: 100%;
			object-position: center center;
			border-radius: 5px;
			&.game {
				max-height: 280px;
			}
		}
		.overview__img.game {
			img {
				max-height: 280px;
				object-fit: cover;
			}
		}

		&__info,
		&__about {
			& > div {
				display: flex;
				width: 100%;
			}
			& > div + div {
				margin-top: 25px;
			}
			.overview__about-label {
				flex-grow: 1;
			}
			.overview__content {
				width: 455px;
				min-width: 455px;
				p {
					font-size: 14px;
					line-height: 24px;
					color: #E0E0E0;
				}
			}
			.overview__about-prizepulls {
				.overview__content {
					ul {
						padding: 0;
						li {
							padding: 6px 0;
							display: flex;
							align-items: center;
							margin-top: 0;
							justify-content: space-between;
							.overview__about-prizepulls_place {
								display: flex;
								align-items: center;
								img {
									margin-right: 6px;
									width: 14px;
								}
								strong {
									font-weight: bold;
									font-size: 18px;
									line-height: 18px;
									margin-right: 2px;
									display: block;
								}
								span {
									align-self: flex-end;
									font-size: 10px;
									line-height: 12px;
									color: #BDBDBD;
								}
							}
							.overview__about-prizepulls_prize {
								display: flex;
								align-items: center;
								strong {
									font-size: 14px;
									line-height: 24px;
									margin-right: 5px;
									letter-spacing: 0.15px;
									font-weight: bold;
								}
								img {
									width: 16px;
								}
							}
						}
						li + li {
							border-top: 1px solid #505050;
						}
					}
				}
			}

			background: $color-gray-600;
			border-radius: 5px;
			padding: 25px;
			margin-top: 20px;

			h5 {
				font-weight: 700;
			}

			> div:first-child {
				min-width: 35%;
			}

			> div:nth-child(2) {
				width: 100%;
			}
		}

		&__about {
			ul {
				margin-top: -10px;
				width: 100%;

				li {
					display: flex;
					padding-top: 10px;
					padding-bottom: 10px;
					border-bottom: 1px solid $color-gray-500;

					+ li {
						margin-top: 5px;
					}

					&:last-child {
						border-bottom: none;
					}

					> span:first-child {
						display: inline-block;
						min-width: 40%;
						font-size: 10px;
						line-height: 20px;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: $color-white;
					}

					> span:nth-child(2) {
						display: flex;
						font-size: 12px;
						line-height: 20px;
						font-weight: 700;
						letter-spacing: 0.25px;
						color: $color-white;
					}

					.ui-icon-base {
						font-size: 0;
						color: $primary-color;
						margin-left: 5px;
						margin-right: 5px;
					}

					.ui-platforms-list .ui-icon-base {
						color: $color-white;
					}
				}
			}

			> div:nth-child(2) {
				width: 100%;
				margin-right: -25px;
			}
		}
		.mobile__banner {
			img {
				height: 180px;
				border-radius: 0;
			}
		}
	}

	.tournament-sponsor-img {
		max-width: 100%;
		max-height: 100%;
		min-height: 30px !important;
		object-fit: contain !important;
		width: -webkit-fill-available;
	}

	.tournament-sponsor-wrapper {
		display: flex;
		justify-content: space-around;
	}

	.tournament-sponsor-img-container {
		max-height: 50px !important;
		padding-left: 0px;
		padding-right: 0px;
	}

	.tournament-sponsor-a-container {
		width: 100%;
		text-align: center;
		border-top: 1px solid #505050;
		padding-top: 10px;
	}

	.tournament-sponsor-a-container a {
		color: #00E7DF;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		font-size: 14px;
	}

	@media screen and (max-width: 768px) {
		.overview {
			.shell {
				.range {
					.range__content {
						order: 1;
						.tournament-sidebar__footer {
							margin-top: 16px;
						}
					}
					.range__container {
						order: 0;
						margin-top: -40px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.overview {
			&__info, &__about {
				margin-top: 16px;
				padding: 16px;
				& > div {
					flex-wrap: wrap;

				}
				.overview__about-label {
					width: 100%;
					margin-bottom: 10px;
				}
				.overview__content {
					width: 100%;
					min-width: 100%;
				}
			}
		}
	}
</style>

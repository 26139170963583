<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 19 20">
		<g>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M0.395043 19.3445L9.87742 10.0089L0.395043 0.655273C0.1615 1.02723 0 1.62961 0 2.49739V10.0088V17.5019C0 18.3698 0.1615 18.9721 0.395043 19.3445Z" :fill="color"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M1.54419 0L10.7751 9.12293L13.8822 6.04065L4.2021 0.867855C3.10671 0.283478 2.42429 0 1.6342 0C1.59802 0 1.56212 0 1.54419 0Z" :fill="color"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0488 6.66113L11.6726 10.0095L15.0488 13.3398L17.0961 12.2413C17.8502 11.8337 18.9998 11.0897 18.9998 10.0095C18.9998 8.91095 17.8502 8.167 17.0961 7.75934L15.0488 6.66113Z" :fill="color"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M13.8822 13.9589L10.7751 10.877L1.54419 20.0001C1.56216 20.0001 1.59802 20.0001 1.6342 20.0001C2.42425 20.0001 3.10671 19.7163 4.2021 19.1317L13.8822 13.9589Z" :fill="color"/>
		</g>
	</svg>
</template>

<script>
	export default {
		props: {
			width: { type: String },
			height: { type: String },
			color: { type: String }
		}
	}
</script>

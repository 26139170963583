<template>
  <div class="notifications-dropdown">
    <button class="button" @click="open">
      <UiBaseIcon
        width="14"
        height="18"
        color="currentColor"
        iconName="bell"
      />
      <span class="count">{{ unreadNotifications }}</span>
    </button>
		<div class="notifications-content" v-show="showContent">
			<h3>Notifications</h3>
			<div @click="showContent = false" v-if="isMobile" class="notifications-content__close">
				<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.50971 3.98865L7.77921 1.71915C8.07425 1.44681 8.07425 0.992908 7.77921 0.720567L7.27992 0.221277C7.00758 -0.0737589 6.55368 -0.0737589 6.28134 0.221277L4.01184 2.49078L1.71964 0.221277C1.4473 -0.0737589 0.993396 -0.0737589 0.721056 0.221277L0.221765 0.720567C-0.0732706 0.992908 -0.0732706 1.44681 0.221765 1.71915L2.49127 3.98865L0.221765 6.28085C-0.0732706 6.55319 -0.0732706 7.00709 0.221765 7.27943L0.721056 7.77872C0.993396 8.07376 1.4473 8.07376 1.71964 7.77872L4.01184 5.50922L6.28134 7.77872C6.55368 8.07376 7.00758 8.07376 7.27992 7.77872L7.77921 7.27943C8.07425 7.00709 8.07425 6.55319 7.77921 6.28085L5.50971 3.98865Z" fill="white"/>
				</svg>
			</div>
			<div class="notifications-content__item new" :class="{'old': notification.read_at, 'new': !notification.read_at}" v-for="(notification, i) in notifications" :key="i">
				<div class="notifications-content__item-status"></div>
				<div class="notifications-content__item-text">{{ notification.message }}</div>
			</div>
			<UiNotFoundBlock v-if="!notifications.length"></UiNotFoundBlock>
		</div>
  </div>
</template>

<script>
import UiNotFoundBlock from '../components/UiNotFoundBlock'
import UiBaseIcon from '@/components/icons/UiBaseIcon'
import { UsersService } from '../services/users.service'
import { mapGetters } from 'vuex'
export default {
  name: 'NotificationsDropdown',
	data () {
		return {
			notifications: [],
			showContent: false
		}
	},
	methods: {
		async open () {
			if (this.showContent) {
				this.showContent = false
				return
			}
			this.showContent = true
			this.amplitude.logEvent('screen_view', {
				screen_name: 'Notifications'
			})
			const messages = this.getUnreadNotifications()
			if (messages.length) {
				await UsersService.sendReadNotifications({
					identities: messages
				})
			}
		},
		getUnreadNotifications () {
			const ids = []
			this.notifications.map((item) => {
				if (!item.read_at) {
					ids.push(item.id)
				}
			})
			return ids
		},
		async getNotifications () {
			await UsersService.getNotifications({
				user_id: this.$currentUser.id
			}).then(response => {
				this.notifications = response.data.data
			})
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		}),
		unreadNotifications () {
			return this.notifications.filter(item => !item.read_at).length
		}
	},
	mounted () {
		this.getNotifications()
	},
  components: {
		UiNotFoundBlock,
    UiBaseIcon
  }
}
</script>

<style lang="scss">
  .notifications-dropdown {
    .button {
      @include navigation-item;
      display: flex;
      position: relative;
      background: transparent;
      margin: 0 0 -1px 0;
      min-width: 70px;
      min-height: 62px;
      border-left: 1px solid $color-gray-600;
      border-right: 1px solid $color-gray-600;

      .ui-icon-base {
        color: #B0B0B0;
      }

      @include hover-state {
        .ui-icon-base {
          color: $primary-color;
        }
      }

      @include focus-state {
        outline: none;
      }
    }

    .count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(2px, -12px);
      display: inline-block;
      font-weight: 900;
      font-size: 8px;
      line-height: 10px;
      width: 10px;
      align-items: center;
      letter-spacing: 0.15px;
      color: $color-body;
      background: $primary-color;
      border-radius: 50%;
    }

		position: relative;
		.notifications-content {
			background: #232323;
			right: 0;
			border: 1px solid #2F2F2F;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 100%;
			width: 376px;
			padding: 25px;
			h3 {
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.15px;
				text-transform: uppercase;
				margin-bottom: 20px;
			}
			.notifications-content__item {
				display: flex;
				align-items: center;
				.notifications-content__item-status {
					margin-right: 10px;
					min-width: 5px;
					width: 5px;
					height: 5px;
					border-radius: 100%;
				}
				&.new {
					.notifications-content__item-status {
						background: #00E7DF;
					}
					.notifications-content__item-text {
						color: #FFFFFF;
					}
				}
				&.old {
					.notifications-content__item-status {
						background: #8F8F8F;
					}
					.notifications-content__item-text {
						color: #8F8F8F;
					}
				}
				.notifications-content__item-text {
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.4px;
					color: #FFFFFF;
				}
			}
			.notifications-content__item + .notifications-content__item {
				margin-top: 15px;
			}
			.notifications-content__close {
				position: absolute;
				right: 16px;
				top: 16px;
				background: #2F2F2F;
				border: 1px solid #505050;
				width: 28px;
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
			}
		}
  }

	@media screen and (max-width: 1200px) and (min-width: 768px) {
		.notifications-dropdown {
			.button {
				width: 50px;
				height: 100%;
				padding-right: 0;
				padding-left: 0;
				justify-content: center;
				min-width: 50px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.notifications-dropdown {
			height: 100%;
			.button {
				min-height: 100%;
				width: 60px;
				height: 100%;
				padding-right: 0;
				padding-left: 0;
				justify-content: center;
				min-width: 60px;
			}
			.notifications-content {
				position: fixed;
				padding-top: 16px;
				padding-left: 25px;
				left: 0;
				top: 60px;
				border-top: none;
				overflow-y: auto;
				max-height: calc(100% - 60px);
				width: 100%;
				h3 {
					font-size: 16px;
					margin-bottom: 15px;
				}
			}
		}
	}
</style>

<template>
	<div class="modal add-game">
		<div class="modal__body">
			<div @click="$emit('close')" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<transition name="fade">
				<form @submit.prevent.stop="submit" class="form" v-if="game">
					<h2 v-if="!editMode">Connect your game ID</h2>
					<h2 v-else>
						Edit game id
					</h2>
					<div :style="{'background-image': `url(${game.web_image})`}" class="form__game-info">
						<div class="form__game-info_icon">
							<img :src="game.web_icon" alt="">
						</div>
					</div>
					<div class="input" v-for="(item, i) in game.platforms" :key="i">
						<div class="icon">
							<img :src="getImageByPlatform(item)" alt="">
						</div>
						<input minlength="1" :placeholder="getLabelByPlatform(item) ? getLabelByPlatform(item) : game.label" type="text" v-model="platforms[item]">
					</div>
					<UiButton type-action="submit" :disabled="load" type="primary">
						<UiLoader :width="15" v-if="load"></UiLoader>
						<template v-if="!editMode">Add game</template>
						<template v-else>Save</template>
					</UiButton>
				</form>
			</transition>
		</div>
	</div>
</template>
<script>
import UiButton from '../../UiButton'
import UiLoader from '../../UiLoader'
import { GamesService } from '../../../services/games.service'
import { UsersService } from '../../../services/users.service'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	props: {
		game_id: {
			required: true,
			type: Number
		}
	},
	data () {
		return {
			load: false,
			editMode: false,
			game: null,
			platforms: {
				1: null,
				2: null,
				3: null,
				4: null
			}
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		...mapActions({
			updateGames: 'user/getGames'
		}),
		getProfile (platform) {
			const find = this.profiles.find(item => item.platformId === platform && item.id === this.game.id)
			return find.info ?? null
		},
		checkEdit () {
			if (!this.profile) return
			const platforms = this.profiles.filter(item => item.id === this.game.id)
			for (const item in platforms) {
				this.platforms[platforms[item].platformId] = platforms[item].info.id !== 'unknown' ? platforms[item].info.id : ''
			}
			this.editMode = true
		},
		async submit () {
			let isEmpty = true
			for (const item in this.platforms) {
				const value = String(this.platforms[item]).replace(/\s+/g, '')
				if (value && value !== 'null') {
					isEmpty = false
				}
			}
			if (isEmpty) {
				this.toast({
					message: 'Please enter at least 1 account',
					type: 'error'
				})
				return
			}
			this.load = true

			for (const item in this.platforms) {
				if (!this.editMode) {
					this.amplitude.logEvent('game_subscribe', {
						game: this.game.id
					})
					await UsersService.addProfile({
						game_id: this.game.id,
						platform_id: item,
						info: {
							id: this.platforms[item],
							nickname: this.$currentUser.nickname ?? 'unknown',
							meta: []
						}
					}).catch((e) => {
						this.toast({
							message: e.message ?? 'Error',
							type: 'error'
						})
					})
				} else {
					await UsersService.editProfile({
						data: [
							{
								game_id: this.game.id,
								platform_id: item,
								info: {
									id: this.platforms[item],
									nickname: this.$currentUser.nickname ?? 'unknown',
									meta: []
								}
							}
						]
					}).catch((e) => {
						this.toast({
							message: e.message ?? 'Error',
							type: 'error'
						})
					})
				}
			}
			await this.updateGames()
			this.amplitude.setUserProperties({
				favourites: this.gamesFav.map(item => item.id)
			})
			this.$emit('update')
			this.$emit('close')
			this.load = false
		},
		getLabelByPlatform (platform) {
			switch (platform) {
				case 1:
					return 'PSN ID'
				case 2:
					return 'XBOX ID'
				default:
					return null
			}
		},
		getImageByPlatform (platform) {
			switch (platform) {
				case 1:
					return require('@/assets/ps.png')
				case 2:
					return require('@/assets/xbox.png')
				case 4:
					return require('@/assets/mobile.svg')
				case 3:
					return require('@/assets/pc.svg')
			}
		},
		getGame () {
			GamesService.getGame(this.game_id).then(response => {
				this.game = response.data.data
				this.platforms = {}
				const values = Object.values(this.game.platforms)
				for (const item in values) {
					this.platforms[values[item]] = null
				}
			})
		}
	},
	components: {
		UiLoader,
		UiButton
	},
	computed: {
		...mapGetters({
			profiles: 'user/allGames',
			gamesFav: 'user/getGames'
		}),
		profile () {
			if (!this.game) return false
			return this.profiles.find(item => item.id === this.game.id)
		}
	},
	watch: {
		profile () {
			this.checkEdit()
		}
	},
	mounted () {
		this.amplitude.logEvent('screen_view', {
			screen_name: 'Game Details'
		})
		this.getGame()
	}
}
</script>
<style scoped lang="scss">
	.add-game {
		.modal__body {
			width: 457px;
			padding: 30px 40px;
			.form {
				h2 {
					font-weight: bold;
					font-size: 24px;
					line-height: 38px;
					letter-spacing: 1px;
					display: block;
					width: 100%;
					text-transform: uppercase;
					margin-bottom: 20px;
				}
				width: 100%;
				button {
					margin-top: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					.loader {
						margin-right: 10px;
					}
					width: 100%;
				}
				.form__game-info {
					width: 100%;
					height: 300px;
					background-size: cover;
					background-position: center center;
					position: relative;
					margin-bottom: 15px;
					overflow: hidden;
					&::after {
						position: absolute;
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.7) 100%)
					}
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					.form__game-info_icon {
						width: 80px;
						height: 80px;
						border: 1px solid #505050;
						background: rgba(18, 18, 18, 0.7);
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						z-index: 999;
						position: relative;
						img {
							max-width: 100%;
						}
						overflow: hidden;
						backdrop-filter: blur(5px);
						border-radius: 4px;
					}
				}
				.input {
					border: 1px solid #8F8F8F;
					border-radius: 5px;
					height: 50px;
					display: flex;
					padding: 0 15px;
					.icon {
						width: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 13px;
					}
					input {
						flex-grow: 1;
						outline: none;
						border: none;
						background: transparent;
						font-size: 12px;
						line-height: 20px;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: #B0B0B0;
						overflow: hidden;

					}
				}
				.input + .input {
					margin-top: 20px;
				}
			}
		}
	}
	@media screen and (max-width: 575px) {
		.add-game {
			padding-right: 10px;
			padding-left: 10px;
			.modal__body {
				max-width: 100%;
				padding: 20px 20px;
				.form {
					h2 {
						font-size: 18px;
					}
				}
			}
		}
	}
</style>

import { render, staticRenderFns } from "./CreateTeamModal.vue?vue&type=template&id=0bb3a791&scoped=true&"
import script from "./CreateTeamModal.vue?vue&type=script&lang=js&"
export * from "./CreateTeamModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateTeamModal.vue?vue&type=style&index=0&id=0bb3a791&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb3a791",
  null
  
)

export default component.exports
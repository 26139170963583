<template>
	<div class="login">
		<div class="wrapper">
			<div class="auth-form">
				<h1>Password recovery</h1>
				<span v-if="step === 'call'">Enter email you registered with  to get <br> confirmation code</span>
				<span v-if="step === 'confirmation'">Enter confirmation code from your email</span>
				<span v-if="step === 'save'">Enter new password</span>

					<form @submit.prevent="call" v-if="step === 'call'" class="form">
						<div class="form__input">
							<div class="form__input-icon">
								<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.6875 3.96875C14.9688 4.53125 14.0625 5.21875 10.875 7.53125C10.25 8 9.09375 9.03125 8 9.03125C6.875 9.03125 5.75 8 5.09375 7.53125C1.90625 5.21875 1 4.53125 0.28125 3.96875C0.15625 3.875 0 3.96875 0 4.125V10.5C0 11.3438 0.65625 12 1.5 12H14.5C15.3125 12 16 11.3438 16 10.5V4.125C16 3.96875 15.8125 3.875 15.6875 3.96875ZM8 8C8.71875 8.03125 9.75 7.09375 10.2812 6.71875C14.4375 3.71875 14.75 3.4375 15.6875 2.6875C15.875 2.5625 16 2.34375 16 2.09375V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V2.09375C0 2.34375 0.09375 2.5625 0.28125 2.6875C1.21875 3.4375 1.53125 3.71875 5.6875 6.71875C6.21875 7.09375 7.25 8.03125 8 8Z" fill="#B0B0B0"/>
								</svg>
							</div>
							<input v-model="email" type="email" placeholder="Email">
						</div>
						<div v-if="errors.email" class="error-input">
							<template>{{ errors.email }}</template>
						</div>
						<UiButton :disabled="loader" typeAction="submit" type="primary">GET CODE
							<UiLoader :width="15" v-show="loader"></UiLoader>
						</UiButton>
					</form>
					<form @submit.prevent="confirmation" v-if="step === 'confirmation'" class="form">
						<label for="">Enter confirmation code</label>
						<div class="form__input ml">
							<input v-model="code" type="number" maxlength="5" minlength="5" placeholder="Confirmation code">
						</div>
						<div v-if="errors.code" class="error-input">
							<template>{{ errors.code }}</template>
						</div>
						<UiButton :disabled="loader" typeAction="submit" type="primary">CONFIRM
							<UiLoader :width="15" v-show="loader"></UiLoader>
						</UiButton>
					</form>
					<form @submit.prevent="save" v-if="step === 'save'" class="form">
						<div class="form__input">
							<div class="form__input-icon">
								<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.5 7H11.75V4.75C11.75 2.15625 9.59375 0 7 0C4.375 0 2.25 2.15625 2.25 4.75V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.53125 5.75 2.5 7 2.5C8.21875 2.5 9.25 3.53125 9.25 4.75V7Z" fill="#B0B0B0"/>
								</svg>
							</div>
							<input v-model="password" type="password" placeholder="Password">
						</div>
						<div class="form__input">
							<div class="form__input-icon">
								<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.5 7H11.75V4.75C11.75 2.15625 9.59375 0 7 0C4.375 0 2.25 2.15625 2.25 4.75V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.53125 5.75 2.5 7 2.5C8.21875 2.5 9.25 3.53125 9.25 4.75V7Z" fill="#B0B0B0"/>
								</svg>
							</div>
							<input v-model="password_confirmation" type="password" placeholder="Repeat Password">
						</div>
						<div v-if="errors.password" class="error-input">
							<template>{{ errors.password }}</template>
						</div>
						<UiButton :disabled="loader" typeAction="submit" type="primary">Save new password
							<UiLoader :width="15" v-show="loader"></UiLoader>
						</UiButton>
					</form>
					<form v-if="step === 'success'" class="form">
						<span>Password changed successfully</span>
						<UiButton @click="$router.push('/login')" typeAction="button" type="primary">GO LOGIN</UiButton>
					</form>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import { LoginService } from '../../services/login.service'
import UiLoader from '../UiLoader'
export default {
	data () {
		return {
			email: null,
			loader: false,
			step: 'call',
			errors: {
				code: null,
				email: null
			},
			code: null,
			password: null,
			password_confirmation: null
		}
	},
	watch: {
		email () {
			this.errors.email = null
		},
		code () {
			this.errors.code = null
		}
	},
	methods: {
		save () {
			if (!this.password || !this.password_confirmation) {
				this.errors.password = 'Passwords do not match'
				return false
			}

			this.loader = true

			LoginService.recoverySave({ email: this.email, code: this.code, password: this.password, password_confirmation: this.password_confirmation }).then(response => {
				if (response.status === 200 && response.data.status === 200) {
					this.step = 'success'
				}
			}).catch(() => {
				this.errors.password = 'An error occurred, try a different password'
			}).finally(() => {
				this.loader = false
			})
		},
		confirmation () {
			if (!this.code || this.code.length < 5) {
				this.errors.code = 'Please, enter the code'
				return false
			}
			this.loader = true

			LoginService.recoveryConfirmation({ email: this.email, code: this.code }).then(response => {
				if (response.status === 200 && response.data.status === 200) {
					this.step = 'save'
				}
			}).catch(() => {
				this.errors.code = 'Given confirmation code was invalid'
			}).finally(() => {
				this.loader = false
			})
		},
		call () {
			if (!this.email) {
				this.errors.email = 'Please, enter the email'
				return false
			}
			this.loader = true
			LoginService.callRecovery({ email: this.email }).then(response => {
				if (response.status === 200) {
					this.step = 'confirmation'
				}
			}).catch(err => {
				this.errors.email = err
			}).finally(() => {
				this.loader = false
			})
		}
	},
	components: {
		UiLoader,
		UiButton
	}
}
</script>
<style scoped lang="scss">
.error-input {
	position: static;
}
.login {
	background: url('~@/assets/login-bg.png'), radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7679446778711485) 24%);
	min-height: 100vh;
	width: 100%;

	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		position: relative;
		::v-deep img {
			margin-left: 20px;
			position: absolute;
			right: 20px;
		}

		display: flex;
		align-items: center;
		justify-content: center;

	}
	.wrapper {
		width: 575px;
		background: #2F2F2F;
		padding: 50px;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		.auth-form {
			h1 {
				font-weight: 700;
				font-size: 30px;
				line-height: 32px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #FFFFFF;
				margin-bottom: 15px;
			}

			padding-bottom: 0;
			span {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				align-items: center;
				letter-spacing: 1px;
				color: #FFFFFF;
			}
			.form {
				margin-top: 15px;
				label {
					font-weight: 400;
					font-size: 10px;
					line-height: 20px;
					display: block;
					margin-left: 7px;
					margin-bottom: 2px;
					letter-spacing: 2px;
					color: #B7B7B7;
				}
				.form__input {
					border: 1px solid #505050;
					height: 50px;
					border-radius: 5px;
					display: flex;
					&.ml {
						padding-left: 25px;
					}
					.form__input-icon {
						width: 56px;
						min-width: 56px;
						display: flex;
						align-items: center;
						justify-content: center;

					}
					input {
						flex-grow: 1;
						background: transparent;
						outline: none;
						border: none;
						font-weight: 400;
						font-size: 10px;
						color: #fff;
						line-height: 20px;
						letter-spacing: 2px;
						&::placeholder {
							color: #B0B0B0;
						}
					}
				}
				.form__input + .form__input {
					margin-top: 20px;
				}
				.ui-button {
					margin-top: 25px;
				}
			}
		}
	}

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

</style>

<template>
	<div class="ui-tournament-item component">
		<div class="ui-tournament-item__img" @click="goToPost(id)">
			<UiImgLoader
				:src="image"
				:alt="title"
			/>
		</div>
		<div class="ui-tournament-item__header">
			<div class="ui-tournament-item__platforms">
				<UiPlatformsList :platforms="platforms"/>
			</div>
			<div class="ui-tournament-item__status">
				{{ status }}
			</div>
		</div>
		<div class="ui-tournament-item__content">
			<div class="ui-tournament-item__title">
				{{ title }}
			</div>
			<div class="ui-tournament-item__info">
				<div class="ui-tournament-item__info__item">
					<span class="title">{{ $t('START') }}</span>
					<span class="icon">
						<UiBaseIcon
							width="18px"
							height="18px"
							iconName="clock"
						/>
					</span>
					<span class="value">{{ startedAt | formatHours }}</span>
				</div>
				<div class="ui-tournament-item__info__item">
					<span class="title">{{ $t('Mode') }}</span>
					<span class="icon">
						<UiBaseIcon
							width="18px"
							height="18px"
							iconName="cup"
						/>
					</span>
					<span class="value">{{ getMode() }}</span>
				</div>
				<div class="ui-tournament-item__info__item" v-if="getPrize()">
					<span class="title">{{ $t('prize pool') }}</span>
					<span class="icon">
						<UiBaseIcon
							width="18px"
							height="18px"
							iconName="prize"
						/>
					</span>
					<span class="value">{{ getPrize() }}</span>
				</div>
				<!--<div class="ui-tournament-item__info__item">-->
					<!--<span class="title">{{ $t('Prize pool') }}</span>-->
					<!--<span class="icon">-->
						<!--<UiBaseIcon-->
							<!--width="18px"-->
							<!--height="18px"-->
							<!--iconName="prize"-->
						<!--/>-->
					<!--</span>-->
					<!--<span class="value">20&#x24;</span>-->
				<!--</div>-->
			</div>
		</div>
		<div class="ui-tournament-item__content" v-if="getSponsor()">
			<div class="ui-tournament-item__info">
				<div class="ui-tournament-item__info__item">
					<span class="title" style="white-space: nowrap;">{{ $t('POWERED BY') }}</span>
				</div>
				<div class="ui-tournament-item__info__item tournament-sponsor-img-container">
					<a href="#" @click="goToPost(id)">
						<img :src="sponsorImage" class="tournament-sponsor-img">
					</a>
				</div>
			</div>
		</div>
		<div class="ui-tournament-item__footer">
			<UiParticipantsCounter
				:all="participants"
				:registered="registeredParticipants"
				:amountAvatars="registeredParticipants > 2 ? 3 : registeredParticipants"
			/>
			<UiButton @click="goToPost(id)">{{ $t('DETAILS') }}</UiButton>
		</div>
	</div>
</template>

<script>
	import UiImgLoader from '@/components/UiImgLoader'
	import UiPlatformsList from '@/components/UiPlatformsList'
	import UiBaseIcon from './icons/UiBaseIcon'
	import UiParticipantsCounter from '@/components/UiParticipantsCounter'
	import UiButton from '@/components/UiButton'

	import formatHours from '@/filters/formatHours'

	export default {
		name: 'UiTournamentItem',
		filters: { formatHours },
		components: {
			UiImgLoader,
			UiPlatformsList,
			UiBaseIcon,
			UiParticipantsCounter,
			UiButton
		},
		props: {
			id: {
				type: [String, Number]
			},
			prizePool: [Array],
			title: {
				type: String
			},
			image: {
				type: String
			},
			status: {
				type: String
			},
			platforms: {
				type: Array
			},
			type: {
				type: [String, Number]
			},
			matchType: {
				type: [String, Number]
			},
			teamMates: {
				type: [String, Number]
			},
			participants: {
				type: [String, Number]
			},
			registeredParticipants: {
				type: [String, Number]
			},
			startedAt: {
				type: String
			},
			sponsorText: {
				type: String
			},
			sponsorUrl: {
				type: String
			},
			sponsorImage: {
				type: String
			}
		},
		methods: {
			goToPost (id) {
				this.$router.push({ path: `/tournament/${id}` })
			},
			getMode () {
				if (this.type === 2) return 'Arena'
				if (this.matchType === 2) return `${this.teamMates}v${this.teamMates}`

				return '1v1'
			},
			getPrize () {
				if (!this.prizePool || !this.prizePool.length) {
					return false
				}

				if (!this.prizePool[0] || !this.prizePool[0].prizes || !this.prizePool[0].prizes.length) return false

				const find = this.prizePool[0].prizes.find(item => item.wallet === 'TURQ')

				if (find) {
					return find.value ?? false
				}
			},
			getSponsor () {
				if (this.sponsorImage) {
					return true
				}
				return false
			}
		}
	}
</script>

<style lang="scss">
	.ui-tournament-item {
		width: 100%;
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		overflow: hidden;

		margin-top: 20px;
		.ui-tournament-item__img {
			cursor: pointer;
		}
		img {
			width: 100%;
			height: 100%;
			min-height: 140px;
			object-fit: cover;
		}

		&__img {
			min-height: 140px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__info {
			display: flex;
			margin-left: -20px;
			margin-right: -20px;
			padding-bottom: 10px;

			> * + * {
				border-left: 1px solid $color-gray-500;
			}

			&__item {
				margin-top: 10px;
				padding-left: 20px;
				padding-right: 20px;

				.title {
					display: block;
					text-align: left;
					font-size: 10px;
					line-height: 20px;
					letter-spacing: 2px;
					text-transform: uppercase;

					color: $color-gray-300;
				}

				.icon {
					display: inline-block;
					line-height: 0;
					vertical-align: middle;
					margin-right: 9px;
					color: $primary-color;
				}
			}
		}

		&__title {
			font-size: 14px;
			line-height: 22px;
			font-weight: 700;

			letter-spacing: 0.15px;
			text-transform: uppercase;

			color: $color-white;
		}

		&__content {
			padding-left: 15px;
			padding-right: 15px;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 10px;

			> * {
				margin-top: 15px;
			}
		}

		&__status {
			display: inline-block;
			font-size: 10px;
			line-height: 1;
			font-weight: 600;
			letter-spacing: 1px;
			text-transform: uppercase;
			padding: 6px 5px 4px 5px;
			background: $primary-color;
			color: $color-gray-700;
			border-radius: 5px;
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 15px;
			border-top:1px solid $color-gray-500;
		}
	}

	.tournament-sponsor-img {
		max-width: 90% !important;
		width: 90% !important;
		max-height: 100%;
		min-height: 30px !important;
		object-fit: contain !important;
	}

	.tournament-sponsor-img-container {
		max-height: 50px !important;
		display: flex;
		justify-content: center;
	}

	@media screen and (max-width: 1200px) {
		.ui-tournament-item__footer {
				.ui-button {
					padding: 10px 10px;
				}
		}
	}
</style>

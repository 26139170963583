import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class TournamentsService extends BaseService {
  static get entity () {
    return 'tournaments'
  }

  static async getTournaments (params = {}) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}?${this.querystring(params)}`)
      const data = {
        data: response.data.data,
        total: Number(response.headers['x-total-count'])
      }
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getTournament (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}`)
      const data = {
        data: response.data,
        total: Number(response.headers['x-total-count'])
      }
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

	static async getParticipant (tournament_id, participant_id) {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${tournament_id}/participants/${participant_id}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getParticipants (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}/participants`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getLeaderBoard (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}/arena`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getResults (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}/results`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

	static async setResults ({ tournament_id, match_id, data }) {
		try {
			const response = await this.request({ auth: true }).post(`${this.entity}/${tournament_id}/matches/${match_id}/result`, data)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

  static async getMatches (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}/matches`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

	static async getTftMatches (id) {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${id}/tft/matches`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getMatch (tournament_id, match_id) {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${tournament_id}/matches/${match_id}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

  static async registerParticipant (data) {
    try {
      console.log(data)
			const payload = {
				secret_code: data.secret_code
			}
			if (data.team_id) {
				payload.team_id = data.team_id
			}
      const response = await this.request({ auth: true }).post(`${this.entity}/${data.id}/participants`, payload)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async unRegisterParticipant (id, userId) {
    try {
      const response = await this.request({ auth: true }).delete(`${this.entity}/${id}/participants/${userId}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async checkInParticipant (id) {
    try {
      const response = await this.request({ auth: true }).put(`${this.entity}/${id}/participants`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}

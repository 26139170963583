<template>
	<div class="news-detail page">
		<UiPageTopBar
			:backgroundImgUrl="null"
			:title="'News'"
			:with-prev-button="true" />
		<div class="shell">
			<div class="range">
				<div class="news-detail__content" v-if="news">
					<div class="news-detail__content-image">
						<img :src="news.image" alt="">
					</div>
					<div class="news-detail__content-description">
						<div @click.stop="openShareBlock = !openShareBlock" class="news__item-description__info-share">
							<div class="icon">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12.5714 10C11.75 10 11 10.25 10.4286 10.6562L6.75 8.65625C6.82143 8.46875 6.82143 8.25 6.82143 8C6.82143 7.78125 6.82143 7.5625 6.75 7.375L10.4286 5.375C11 5.78125 11.75 6 12.5714 6C14.4643 6 16 4.6875 16 3C16 1.34375 14.4643 0 12.5714 0C10.6429 0 9.14286 1.34375 9.14286 3C9.14286 3.25 9.14286 3.46875 9.21429 3.65625L5.53571 5.65625C4.96429 5.25 4.21429 5 3.42857 5C1.5 5 0 6.34375 0 8C0 9.6875 1.5 11 3.42857 11C4.21429 11 4.96429 10.7813 5.53571 10.375L9.21429 12.375C9.14286 12.5625 9.14286 12.7813 9.14286 13.0313V13C9.14286 14.6875 10.6429 16 12.5714 16C14.4643 16 16 14.6875 16 13C16 11.3438 14.4643 10 12.5714 10Z" fill="#00E7DF"/>
								</svg>
							</div>
							<transition name="fade">
								<div v-show="openShareBlock" class="news__item-description__info-share-content">
									<a @click.stop.prevent="copyLink" target="_blank">
											<span class="pre-icon">
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8.92962 5.07038C8.7654 4.90616 8.5738 4.74194 8.38221 4.60508C8.19062 4.46823 7.97165 4.4956 7.80743 4.63245L7.23265 5.20723C7.01369 5.45357 6.9042 5.75464 6.9042 6.02835C6.9042 6.19257 6.98631 6.30205 7.0958 6.38416C7.17791 6.43891 7.28739 6.52102 7.39687 6.60313C8.16325 7.39687 8.16325 8.62854 7.39687 9.39492L5.53568 11.2287C4.76931 12.0225 3.51026 12.0225 2.74389 11.2287C1.97752 10.4624 2.00489 9.20332 2.77126 8.43695L3.12708 8.08113C3.26393 7.97165 3.2913 7.78006 3.23656 7.61584C3.07234 7.15054 2.99022 6.65787 2.96285 6.19257C2.96285 5.80938 2.49756 5.61779 2.22385 5.8915L1.21114 6.9042C-0.403715 8.51906 -0.403715 11.174 1.21114 12.7889C2.826 14.4037 5.48094 14.4037 7.0958 12.7889L8.92962 10.955C8.92962 10.955 8.92962 10.955 8.92962 10.9277C10.5445 9.34018 10.5718 6.71261 8.92962 5.07038ZM12.7889 1.21114C11.174 -0.403715 8.51906 -0.403715 6.9042 1.21114L5.07038 3.04497C5.07038 3.04497 5.07038 3.04497 5.07038 3.07234C3.45552 4.65982 3.42815 7.28739 5.07038 8.92962C5.2346 9.09384 5.4262 9.25806 5.61779 9.39492C5.80938 9.53177 6.02835 9.5044 6.19257 9.36755L6.76735 8.79277C6.98631 8.54643 7.0958 8.24536 7.0958 7.97165C7.0958 7.80743 7.01369 7.69795 6.9042 7.61584C6.82209 7.56109 6.71261 7.47898 6.60313 7.39687C5.83675 6.60313 5.83675 5.37146 6.60313 4.60508L8.46432 2.77126C9.23069 1.97752 10.4897 1.97752 11.2561 2.77126C12.0225 3.53763 11.9951 4.79668 11.2287 5.56305L10.8729 5.91887C10.7361 6.02835 10.7087 6.21994 10.7634 6.38416C10.9277 6.84946 11.0098 7.34213 11.0371 7.80743C11.0371 8.19062 11.5024 8.38221 11.7761 8.1085L12.7889 7.0958C14.4037 5.48094 14.4037 2.826 12.7889 1.21114Z" fill="#00E7DF"/>
												</svg>
											</span>
										Share link
									</a>
									<a @click="goLink(`https://www.facebook.com/sharer/sharer.php?u=${newsLink}`)" target="_blank">
											<span class="pre-icon">
												<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M7.47636 7.875L7.88364 5.35938H5.29455V3.71875C5.29455 3.00781 5.64364 2.35156 6.80727 2.35156H8V0.191406C8 0.191406 6.92364 0 5.90545 0C3.78182 0 2.38545 1.23047 2.38545 3.41797V5.35938H0V7.875H2.38545V14H5.29455V7.875H7.47636Z" fill="#00E7DF"/>
											</svg>
											</span>

										Facebook
									</a>
									<a @click="goLink(`https://twitter.com/share?url=${newsLink}`)" target="_blank">
											<span class="pre-icon">
												<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.4736 2.97831C14.0607 2.54458 14.589 2.0241 15 1.41687C14.4716 1.64819 13.8552 1.82169 13.2387 1.87952C13.8845 1.50361 14.3542 0.925301 14.589 0.20241C14.002 0.549398 13.3268 0.809639 12.6517 0.954217C12.0646 0.346988 11.272 0 10.3914 0C8.68885 0 7.3092 1.35904 7.3092 3.03614C7.3092 3.26747 7.33855 3.4988 7.39726 3.73012C4.84344 3.58554 2.55382 2.37108 1.0274 0.549398C0.763209 0.983133 0.616438 1.50361 0.616438 2.08193C0.616438 3.12289 1.14481 4.04819 1.99609 4.59759C1.49706 4.56868 0.998043 4.45301 0.587084 4.22169V4.2506C0.587084 5.7253 1.64384 6.93976 3.05284 7.22892C2.818 7.28675 2.52446 7.34458 2.26027 7.34458C2.05479 7.34458 1.87867 7.31566 1.67319 7.28675C2.05479 8.50121 3.19961 9.36868 4.5499 9.39759C3.49315 10.2072 2.17221 10.6988 0.733855 10.6988C0.469667 10.6988 0.234834 10.6699 0 10.641C1.35029 11.5084 2.96478 12 4.72603 12C10.3914 12 13.4736 7.40241 13.4736 3.38313C13.4736 3.23855 13.4736 3.12289 13.4736 2.97831Z" fill="#00E7DF"/>
												</svg>
											</span>

										Twitter
									</a>
								</div>
							</transition>

						</div>
						<div class="news-detail__content-description-info">
							<div class="date">{{ news.created_at | formatMonthDayYear }}</div>
							<div class="separator"></div>
							<div class="author">
								By {{ news.created_by.name }}
							</div>
						</div>
						<h1>{{ news.title }}</h1>
						<p v-html="news.content ? news.content : news.description">{{ news.content ? news.content : news.description }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import UiPageTopBar from '../../components/UiPageTopBar'
import { NewsService } from '@/services/news.service'
import formatMonthDayYear from '@/filters/formatMonthDayYear'
import { mapMutations } from 'vuex'

export default {
	data () {
		return {
			openShareBlock: false,
			news: null
		}
	},
	filters: {
		formatMonthDayYear
	},
	props: {
		id: {
			required: true
		}
	},
	components: {
		UiPageTopBar
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		eventHandle () {
			this.amplitude.logEvent('news_share', {
				game: this.news.game_id ? this.news.game_id : 0
			})
		},
		goLink (url) {
			this.eventHandle()
			window.open(url, '_blank').focus()
		},
		copyLink () {
			navigator.clipboard.writeText(this.newsLink).then(() => {
				this.eventHandle()
				this.toast({ type: 'success', message: 'The link was successfully copied to the clipboard' })
			}, () => {
				this.toast({ type: 'error', message: 'Your browser does not support the copy feature' })
			})
		},
		init () {
			this.amplitude.logEvent('screen_view', {
				screen_name: 'News Details'
			})
			NewsService.getNewsDetail(this.id).then(response => {
				this.news = response.data
			})
		}
	},
	computed: {
		newsLink () {
			return window.location.href
		}
	},
	mounted () {
		this.init()
		document.addEventListener('click', () => {
			this.openShareBlock = false
		})
	}
}
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
.news-detail {
	.news-detail__content {
		margin-top: 20px;
		margin-bottom: 50px;
		background: #2F2F2F;
		width: 100%;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		overflow: hidden;
		.news-detail__content-image {
			overflow: hidden;
			width: 100%;
			img {
				min-width: 100%;
				max-width: 100%;
			}
		}
		.news-detail__content-description {
			min-height: 200px;
			position: relative;
			.news__item-description__info-share {
				width: 42px;
				top: -21px;
				right: 30px;
				height: 42px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #2F2F2F;
				border: 1px solid #00E7DF;
				box-sizing: border-box;
				border-radius: 4px;
				transition: 0.2s;
				position: absolute;
				&:hover {
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
				}
				.icon {
					display: flex;
					align-items: center;
					justify-content: center;

				}
				.news__item-description__info-share-content {
					width: 194px;
					right: 0;
					position: absolute;
					background: #2F2F2F;
					top: calc(100% + 4px);
					padding: 20px 25px;
					border: 1px solid #00E7DF;
					box-sizing: border-box;
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
					border-radius: 4px;
					a {
						text-decoration: none;
						width: 100%;
						display: flex;
						align-items: center;
						.pre-icon {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 15px;
							margin-right: 12px;
						}
						font-size: 14px;
						line-height: 24px;
						letter-spacing: 0.15px;
						text-transform: uppercase;
						color: #BDBDBD;
						transition: 0.2s;
						&:hover {
							color: #fff;
						}
					}
					a + a {
						margin-top: 14px;
					}
				}
			}
			.news-detail__content-description-info {
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: 10px;
				.date {
					font-size: 10px;
					line-height: 20px;
					letter-spacing: 2px;
					text-transform: uppercase;
					color: #BDBDBD;
				}
				.separator {
					background: #8F8F8F;
					width: 4px;
					height: 4px;
					border-radius: 100%;
					margin: 0 10px;
				}
				.author {
					font-size: 10px;
					line-height: 20px;
					letter-spacing: 2px;
					color: #00E7DF;
				}
				p {
					color: #fff;
					img {
						max-width: 100%;
					}
				}
			}
			padding: 20px 30px;
			h1 {
				font-weight: bold;
				font-size: 20px;
				line-height: 22px;
				letter-spacing: 0.15px;
				margin-bottom: 15px;
				display: block;
				width: 100%;
				color: #FFFFFF;
			}
			p {
				font-size: 12px;
				::v-deep {
					a {
						color: #00E7DF !important;
					}
				}
				white-space: pre-line;
				line-height: 22px;
				display: block;
				margin-bottom: 0;
				width: 100%;
				letter-spacing: 1px;
			}
		}
	}
}

@media screen and (max-width: 575px) {
	.news-detail {
		.news-detail__content {
			margin: 0 16px;
			margin-top: 16px;
			margin-bottom: 16px;
			.news-detail__content-description {
				padding: 15px;
				h1 {
					font-size: 16px;
				}
			}

		}
	}
}
</style>

<template>
	<div class="ui-participants-counter component">
		<div class="ui-participants-counter__avatars">
			<UiBaseIcon
				class="ui-platforms-list__item__icon"
				width="20px"
				height="20px"
				iconName="logo-small"
				v-for="avatar in amountAvatars"
				:key="avatar"
			/>
			<div class="ui-platforms-list__item__more" v-if="Number(registered - amountAvatars) > 0">
				+{{ Number(registered - amountAvatars) }}
			</div>
		</div>
		<div class="ui-participants-counter__value">
			<span v-if="withIcon">
				<UiBaseIcon
					width="20px"
					height="20px"
					iconName="users"
				/>
			</span>
			<span v-if="withIndicator">{{ registered }}/{{ all }}</span>
		</div>
	</div>
</template>

<script>
	import UiBaseIcon from './icons/UiBaseIcon'

	export default {
		name: 'UiParticipantsCounter',
		props: {
			withIndicator: {
				type: Boolean,
				default: true
			},
			all: {
				type: [Number, String]
			},
			registered: {
				type: [Number, String]
			},
			amountAvatars: {
				type: [Number, String]
			},
			withIcon: {
				type: Boolean,
				default: false
			}
		},
		components: {
			UiBaseIcon
		}
	}
</script>

<style lang="scss" scoped>
	.ui-participants-counter {
		display: flex;
		align-items: center;

		&__avatars {
			display: flex;
			padding-left: 10px;
			margin-right: 10px;

			.ui-icon-base {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $color-gray-600;
				border: 1px solid $color-gray-500;
				border-radius: 50%;
				color: $primary-color;
				margin-left: -10px;
			}

			.ui-platforms-list__item__more {
				width: 40px;
				margin-left: -10px;
				height: 40px;
				background: #00E7DF;
				border: 1px solid #505050;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.15px;
				color: #2F2F2F;
			}
		}

		&__value {
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: $color-white;

			.ui-icon-base {
				font-size: 0;
				line-height: 1;
				margin-right: 10px;
			}

			> * {
				display: inline-block;
				vertical-align: middle;
			}
		}

		&__item {
			color: $color-gray-400;

			+ * {
				margin-left: 10px;
			}
		}
	}
</style>

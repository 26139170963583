/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'

import { AuthService } from '@/services/auth.service'

export class Http {
  constructor (status, xweb) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios
    this.instance.defaults.headers.common = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
    if (xweb) {
      this.instance.defaults.headers.common['X-WEB'] = true
    }
    
    this.instance.defaults.baseURL = process.env.VUE_APP_API_URL

    if (this.isAuth) {
      this.instance.defaults.headers.common.Authorization = AuthService.getBearer()
    }

    return this.init()
  }

  init () {
		this.instance.interceptors.response.use(function (response) {
			return response
		}, function (error) {
			if (error.response.status === 401) {
				AuthService.makeLogout()
			}
			return Promise.reject(error)
		})
    return this.instance
  }
}

<template>
	<div class="modal player-lose-modal" v-if="showSelectTeamModal">
		<div class="modal__body">
			<div @click="removeModal" class="modal__close">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>

				<h1 class="h1">Choose team</h1>
			<div class="list">
				<div @click="selectTeam(team)" :class="{'active': Object.is(currentTeam, team)}" v-for="(team, i) in teams" :key="i" class="list-item">
					<UiAvatar :src="team.avatar" :size="60"></UiAvatar>
					<div class="list-item__description">
						<strong>{{ team.name }}</strong>
						<div class="spans">
							<span>Розмір: {{ team.team_mates_count }}/{{ team.max_players }}</span>
							<div class="spans-separator"></div>
							<span>Заміна: {{ team.substitute_count }}</span>
						</div>
					</div>

				</div>
			</div>

			<UiButton type="primary" @click="submit">Register now</UiButton>
		</div>
	</div>
</template>
<script>
import { eventBus } from '@/buses/eventBus.js'
import UiAvatar from '../UiAvatar'
import UiButton from '../UiButton'
export default {
	data () {
		return {
			showSelectTeamModal: false,
			teams: [],
			currentTeam: null
		}
	},
	components: {
		UiAvatar,
		UiButton
	},
	watch: {
		showSElectTeamModal () {
			this.currentTeam = false
		}
	},
	methods: {
		removeModal () {
			eventBus.$emit('destroySelectTeamTournament')
			this.showSelectTeamModal = false
		},
		selectTeam (team) {
			this.currentTeam = team
		},
		submit () {
			eventBus.$emit('selectTeamTournament', this.currentTeam)
			this.removeModal()
		}
	},
	mounted () {
		eventBus.$on('callSelectTeamTournament', (teams) => {
			this.showSelectTeamModal = true
			this.teams = teams
		})
		eventBus.$on('destroySelectTeamTournament', () => {
			this.showSelectTeamModal = false
		})
	}
}
</script>
<style scoped lang="scss">
.modal {
	.modal__body {
		padding: 40px;
		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 38px;
			text-align: left;
			letter-spacing: 1px;
		}
	}
	.list {
		margin-top: 30px;
		margin-bottom: 20px;
		.list-item {
			display: flex;
			padding: 15px;
			cursor: pointer;
			transition: 0.2s;
			background: #2F2F2F;
			border: 1px solid #505050;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			.avatar {
				margin-right: 15px;
			}
			align-items: center;
			&:hover {
				background: #282828;
			}
			strong {
				width: 100%;
				display: block;
				font-size: 18px;
				line-height: 20px;
				margin-bottom: 8px;
			}
			span {
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.25px;
				color: #BDBDBD;
			}
			.spans {
				display: flex;
				align-items: center;
				.spans-separator {
					margin: 0 10px;
					background: #8F8F8F;
					width: 3px;
					height: 3px;
					border-radius: 100%;
				}
			}
			&.active {
				border-color: #00E7DF
			}
		}
		.list-item + .list-item {
			margin-top: 10px;
		}
	}

}

</style>

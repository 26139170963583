import BaseModel from './BaseModel'

export default class TournamentItemModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      image: undefined,
      title: undefined,
      description: undefined,
      type: undefined,
      winnerId: undefined,
      status: undefined,
      teamMates: undefined,
      participantCount: undefined,
      isPrivate: undefined,
			prizePool: undefined,
      chatLink: undefined,
      createdAt: undefined,
      createdBy: undefined,
      currentRound: undefined,
      deeplink: undefined,
      gameId: undefined,
      locale: undefined,
      matchType: undefined,
      maxMember: undefined,
      participant: undefined,
      platformId: undefined,
			participantId: undefined,
      region: undefined,
      rounds: undefined,
      ruleId: undefined,
      require_user_profiling: undefined,
      secretCode: undefined,
      siteLink: undefined,
			sponsorUrl: undefined,
			sponsorText: undefined,
			sponsorImage: undefined,
      startedAt: undefined,
			isJoined: undefined,
      substituteLimit: undefined,
      supportedCountries: undefined,
      templateId: undefined,
			rr: undefined
    }
  }

	isSponsored () {
		return !!(this.sponsorImage || this.sponsorText || this.sponsorUrl)
	}

	getBracketType () {
		switch (this.type) {
			case 1:
				return 'bracket'
			case 2:
				return 'arena'
			case 3:
				return 'double_elimination'
		}
	}

	isTeamType () {
		return this.matchType === 2
	}

	analyticData () {
		return {
			actual: !['FINISHED', 'CLOSED'].includes(this.status),
			game: this.gameId,
			participant: Boolean(this.participant),
			participation_type: this.participant ? this.participant.getTypeParticipant() : 0,
			platform: this.platformId ?? 0,
			sponsored: this.isSponsored(),
			tournament: this.id,
			tournament_type: this.getBracketType()
		}
	}

	isRR () {
		return this.type === 4
	}

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

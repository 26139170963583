<template>
	<button
		class="ui-button component"
		:class="classList"
		:disabled="disabled"
		:type="typeAction"
		@click="callback($event)"
	>
		<slot></slot>
	</button>
</template>

<script>
	export default {
		name: 'UiButton',
		props: {
			typeAction: {
				type: String,
				default: 'button'
			},
			type: {
				type: String,
				default: 'default'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			classList () {
				return {
					'button-default': this.type === 'default',
					'button-primary': this.type === 'primary',
					'button-secondary': this.type === 'secondary',
					disabled: this.disabled
				}
			}
		},
		methods: {
			callback: function (e) {
				this.$emit('click', e)
			}
		}
	}
</script>

<style lang="scss">
	.ui-button.component {
		display: inline-block;
		font-size: 14px;
		line-height: 17px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align: center;

		color: $primary-color;
		background: $color-gray-600;
		border: 1px solid $color-gray-500;

		padding: 10px 25px;
		border-radius: 4px;
		cursor: pointer;

		@include base-transition;

		@include hover-state {
			color: $primary-color;
			background-color: $color-gray-500;
			border-color: $color-gray-500;
		}

		@include focus-state {
			color: $color-gray-700;
			background-color: $primary-color;
			border-color: $primary-color;
		}

		&.disabled,
		&[disabled] {
			cursor: not-allowed;
			background: $color-gray-500;
			color: $color-gray-400;
			border-color: $color-gray-500;
			box-shadow: none;

			@include hover-state {
				box-shadow: none;
			}
		}

		&.button-primary {
			color: $color-gray-700;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
			background-color: $primary-color;
			border-color: $primary-color;

			&.disabled,
			&[disabled] {
				cursor: not-allowed;
				background: $color-gray-500;
				border-color: $color-gray-500;
				color: $color-gray-400;
				box-shadow: none;

				@include hover-state {
					box-shadow: none;
				}
			}

			@include hover-state {
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
			}
		}
	}
</style>

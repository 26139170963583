import { AdvertisementsService } from '@/services/advertisements.service'
export default {
	getAdvertisements ({ commit }, data) {
		return AdvertisementsService.getAdvertisements(data)
            .then((advertisements) => {
				commit('SET_ADVERTISEMENTS', advertisements.data)
			})
			.catch((error) => {
				console.log('error during get advs ', error)
				const advertisements = {
					status: 200,
					data: [],
					statusMessage: '',
					statusText: 'OK'
				}
				commit('SET_ADVERTISEMENTS', advertisements)
			})
	}
}

import BaseModel from './BaseModel'

export default class PageMetaModel extends BaseModel {
  get schema () {
    return {
      currentPage: undefined,
      lastPage: undefined,
      perPage: undefined,
      total: undefined,
      path: undefined,
      from: undefined,
      to: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

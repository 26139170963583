import BaseModel from './BaseModel'

export default class Message extends BaseModel {
	get schema () {
		return {
			id: undefined,
			createdAt: undefined,
			image: undefined,
			matchId: undefined,
			message: undefined,
			nickname: undefined,
			role: undefined,
			teamId: undefined,
			tournamentId: undefined,
			tournamentName: undefined,
			userId: undefined
		}
	}

	prepareCreate () {
		this.baseClear()

		return this
	}

	prepareUpdate () {
		this.baseClear()

		return this
	}
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <g>
      <rect x="6.68457" y="16.1172" width="1.89473" height="0.941164" :fill="color"/>
      <rect x="4.78906" y="17.0586" width="5.6842" height="0.941164" rx="0.470582" :fill="color"/>
      <path d="M13.5 8.22192H2C1.44772 8.22192 1 8.66964 1 9.22192V15.2218C1 15.7741 1.44772 16.2218 2 16.2218H13.5C14.0523 16.2218 14.5 15.7741 14.5 15.2218V9.22192C14.5 8.66964 14.0523 8.22192 13.5 8.22192Z" :fill="color"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3V7.33327H14.4C14.9523 7.33327 15.4 7.78098 15.4 8.33327V16.1109C15.4 16.6632 14.9523 17.1109 14.4 17.1109H12.2444C11.999 17.1109 11.8 17.3099 11.8 17.5554C11.8 17.8008 11.999 17.9998 12.2444 17.9998H18C18.5523 17.9998 19 17.5521 19 16.9998V3C19 2.44772 18.5523 2 18 2H11C10.4477 2 10 2.44771 10 3ZM11.8 4.222C11.8 3.97654 11.999 3.77756 12.2444 3.77756H16.7555C17.001 3.77756 17.2 3.97654 17.2 4.222C17.2 4.46746 17.001 4.66644 16.7555 4.66644H12.2444C11.999 4.66644 11.8 4.46746 11.8 4.222ZM12.2444 5.55561C11.999 5.55561 11.8 5.75459 11.8 6.00005C11.8 6.2455 11.999 6.44448 12.2444 6.44448H16.7555C17.001 6.44448 17.2 6.2455 17.2 6.00005C17.2 5.75459 17.001 5.55561 16.7555 5.55561H12.2444Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

<template>
	<div class="uploader__tft-result">
		<span>Upload a picture to prove your score</span>

		<div @click="showUploadResult = true" class="ui-button component button-secondary">
					Upload picture
			</div>
		<transition name="modal">
			<UploadResult @upload="$emit('upload')" v-if="showUploadResult" :matchId="matchId" @close="showUploadResult = false"></UploadResult>
		</transition>
	</div>
</template>
<script>
import UploadResult from '../../pages/tournaments/Tft/UploadResult'

export default {
	data () {
		return {
			showUploadResult: false
		}
	},
	props: {
		matchId: {
			type: Number,
			required: true
		},
		tournamentId: {
			type: Number,
			required: true
		}
	},
	components: {
		UploadResult
	}
}
</script>
<style scoped lang="scss">
.uploader__tft-result {
	margin-top: 10px;
	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: #BDBDBD;
	}
	#file_uploader {
		display: none;
	}
		.ui-button {
			margin-top: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 48px;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			&.disabled {
				cursor: not-allowed;

			}
		}
		width: 100%;
}
</style>

<template>
	<router-link :to="{ name: 'team', params: { id: team.id } }" class="team__item">
		<div class="team__item-avatar">
			<UiAvatar :src="team.logo" :size="isMobile ? 60 : 90"></UiAvatar>
		</div>
		<div class="team__item-information">
			<strong>{{ team.name }}</strong>
			<div class="team__item-information__indicators">
				<span>Team size: {{ team.team_mates_count }}/{{ team.max_players }}</span>
				<div class="separator"></div>
				<span>Substitutes: {{ team.substitute_count }}/{{ team.max_players }}</span>
			</div>
		</div>
	</router-link>
</template>
<script>
import UiAvatar from '../UiAvatar'
import { mapGetters } from 'vuex'
export default {
	props: {
		team: {
			required: true,
			type: Object
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		})
	},
	components: {
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
	.team__item {
		background: #2F2F2F;
		border: 1px solid #2F2F2F;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		text-decoration: none;
		.team__item-information {
			margin-left: 15px;
			strong {
				width: 100%;
				display: block;
				font-weight: bold;
				font-size: 24px;
				color: #fff;
				line-height: 20px;
				letter-spacing: 0.25px;
			}
			.team__item-information__indicators {
				margin-top: 15px;
				align-items: center;
				display: flex;
				span {
					font-size: 12px;
					line-height: 20px;
					letter-spacing: 0.25px;
					color: #BDBDBD;
				}
				.separator {
					background: #8F8F8F;
					width: 3px;
					height: 3px;
					border-radius: 100%;
					margin: 0 10px;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		.team__item {
			margin-bottom: 16px;
			.team__item-information {
				margin-left: 8px;
				strong {
					font-size: 18px;
					line-height: 20px;
				}
				.team__item-information__indicators {
					margin-top: 10px;
				}
			}
		}
	}
</style>

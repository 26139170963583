<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 18">
    <g>
      <path d="M8.23856 6.07031L6.62197 3.375C6.50483 3.16406 6.24711 3 5.98939 3H3.38879C3.08422 3 2.89679 3.35156 3.06079 3.60938L5.68482 7.33594C6.36426 6.67969 7.25455 6.23438 8.23856 6.07031ZM14.6112 3H12.0106C11.7529 3 11.5186 3.14062 11.378 3.375L9.76144 6.07031C10.7454 6.23438 11.6357 6.67969 12.3152 7.33594L14.9392 3.60938C15.1032 3.35156 14.9158 3 14.6112 3ZM9.01171 6.75C6.71569 6.75 4.88824 8.60156 4.88824 10.875C4.88824 13.1719 6.71569 15 9.01171 15C11.2843 15 13.1352 13.1719 13.1352 10.875C13.1352 8.60156 11.2843 6.75 9.01171 6.75ZM11.1672 10.4531L10.2769 11.3203L10.4877 12.5391C10.5346 12.75 10.3003 12.9141 10.0894 12.8203L9.01171 12.2344L7.91056 12.8203C7.6997 12.9141 7.46541 12.75 7.51227 12.5391L7.72313 11.3203L6.83283 10.4531C6.66883 10.2891 6.76255 10.0313 6.97341 9.98438L8.21513 9.82031L8.754 8.69531C8.80085 8.60156 8.89457 8.55469 8.98829 8.55469C9.10543 8.55469 9.19914 8.60156 9.246 8.69531L9.78487 9.82031L11.0266 9.98438C11.2375 10.0313 11.3312 10.2891 11.1672 10.4531Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

import BaseModel from './BaseModel'

export default class TeamModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      isLocked: undefined,
      logo: undefined,
      name: undefined,
      maxPlayers: undefined,
      platformId: undefined,
      substituteCount: undefined,
      teamMatesCount: undefined,
			creatorId: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

<template>
	<div class="brackets" v-if="tournament.type">
		<template v-if="tournament.isRR()">
			<RoundRobinBracket></RoundRobinBracket>
		</template>
		<template v-if="!loading">
			<template v-if="tournament.type === 3">
				<div class="brackets__title">
					UPPER BRACKET
				</div>
				<UiBrackets
					:matches="upperMatches"
				/>
				<div class="brackets__title">
					LOWER BRACKET
				</div>
				<UiBrackets
					:matches="lowerMatches"
				/>
			</template>
			<template v-else-if="![3,4].includes(tournament.type)">
				<UiBrackets
					:matches="matches"
				/>
			</template>
		</template>

	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'

	import UiBrackets from '@/components/brackets/UiBrackets'

	import formatHours from '@/filters/formatHours'
	import formatWeekDayAndDate from '@/filters/formatWeekDayAndDate'
	import RoundRobinBracket from '../../components/round_robin/RoundRobinBracket'
	export default {
		data () {
			return {
				loading: true
			}
		},
		name: 'Brackets',
		filters: { formatHours, formatWeekDayAndDate },
		components: {
			UiBrackets,
			RoundRobinBracket
		},
		computed: {
			...mapGetters({
				tournament: 'tournaments/getCurrentTournament',
				matches: 'tournaments/getMatches'
			}),
			upperMatches () {
				return this.matches.filter(item => !item.isLooserMatch)
			},
			lowerMatches () {
				return this.matches.filter(item => item.isLooserMatch)
			}
		},

		methods: {
			...mapActions({
				getMatches: 'tournaments/getMatches',
				getTftMatches: 'tournaments/getTftMatches',
				getResults: 'tournaments/getResults'
			}),
			async init () {
				await this.getResults(this.tournament.id)

				if (this.tournament.type === 6) {
					await this.getTftMatches(this.tournament.id)
				} else {
					await this.getMatches(this.tournament.id)
				}

				this.$root.centrifugeInstance.subscribe(`tournament.${this.tournament.id}.match.*`, () => {
					this.getMatches(this.tournament.id)
				})
				this.loading = false
			}
		},

		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss">
	.brackets {
		padding: 0 0 0 40px;
		padding-bottom: 80px;
		.brackets__title {
			font-weight: bold;
			margin-bottom: 20px;
			font-size: 20px;
			line-height: 28px;
			letter-spacing: 0.15px;
			text-transform: uppercase;
		}
	}

	@media screen and (max-width: 768px) {
		.brackets {
			margin: 25px 0;
			padding-left: 16px;
			padding-right: 16px;
			.brackets__title {
				margin-top: 20px;
			}
		}
	}
</style>

import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
export class TeamsService extends BaseService {
	static async getTeam (teamId) {
		try {
			const response = await this.request({ auth: true }).get(`teams/${teamId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getInvites (teamId) {
		try {
			const response = await this.request({ auth: true }).get(`teams/${teamId}/invites`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async sendRequest (teamId) {
		try {
			const response = await this.request({ auth: true }).post(`teams/${teamId}/team-mates`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async acceptInvite (teamId, mateId) {
		try {
			const response = await this.request({ auth: true }).put(`teams/${teamId}/team-mates/${mateId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async updatePlayerRole (teamId, mateId, role) {
		try {
			const response = await this.request({ auth: true }).put(`teams/${teamId}/team-mates/${mateId}/role`, {
				role: role
			})
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async delete (teamId) {
		try {
			const response = await this.request({ auth: true }).delete(`teams/${teamId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async declineInvite (teamId, mateId) {
		try {
			const response = await this.request({ auth: true }).delete(`teams/${teamId}/team-mates/${mateId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getTeams (data) {
		try {
			const response = await this.request({ auth: true }).get(`teams?${this.querystring(data)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getLeaderboardAll (params) {
		try {
			const response = await this.request({ auth: true }).get(`team-leaderboard?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getLeaderboardTeam (teamId, platformId) {
		try {
			const response = await this.request({ auth: true }).get(`team-leaderboard?team_id=${teamId}&platform_id=${platformId}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async sendMessage ({ roomId, message }) {
		try {
			const response = await this.request({ auth: true }).post(`chat/${roomId}`, message)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

<template>
	<div class="ui-loader loader">
		<img :style="{'width': `${width}px`}" src="@/assets/loader.gif" alt="">
	</div>
</template>
<script>
export default {
	props: {
		width: {
			default: 20
		}
	}
}
</script>
<style scoped lang="scss">
	.loader {
		display: flex;
		align-items: center;

	}
</style>

<template>
	<div class="login-form">
		<div class="form" @keyup.enter="makeLogin">
			<div class="input" :class="{ 'is-invalid': $v.form.email.$error, 'is-correct': $v.form.email.$dirty && !$v.form.email.$error }">
				<div class="input__icon">
					<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15.6875 3.96875C14.9688 4.53125 14.0625 5.21875 10.875 7.53125C10.25 8 9.09375 9.03125 8 9.03125C6.875 9.03125 5.75 8 5.09375 7.53125C1.90625 5.21875 1 4.53125 0.28125 3.96875C0.15625 3.875 0 3.96875 0 4.125V10.5C0 11.3438 0.65625 12 1.5 12H14.5C15.3125 12 16 11.3438 16 10.5V4.125C16 3.96875 15.8125 3.875 15.6875 3.96875ZM8 8C8.71875 8.03125 9.75 7.09375 10.2812 6.71875C14.4375 3.71875 14.75 3.4375 15.6875 2.6875C15.875 2.5625 16 2.34375 16 2.09375V1.5C16 0.6875 15.3125 0 14.5 0H1.5C0.65625 0 0 0.6875 0 1.5V2.09375C0 2.34375 0.09375 2.5625 0.28125 2.6875C1.21875 3.4375 1.53125 3.71875 5.6875 6.71875C6.21875 7.09375 7.25 8.03125 8 8Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input v-model="$v.form.email.$model" type="email" placeholder="EMAIL">
				</div>
				<div v-if="$v.form.email.$dirty" class="error-input">
					<template v-if="!$v.form.email.required">Email is required</template>
					<template v-if="!$v.form.email.email">Email is incorrect format</template>
				</div>
			</div>
			<div class="input" :class="{ 'is-invalid': $v.form.password.$error, 'is-correct': $v.form.password.$dirty && !$v.form.password.$error }">
				<div class="input__icon">
					<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.5 7H11.75V4.75C11.75 2.15625 9.59375 0 7 0C4.375 0 2.25 2.15625 2.25 4.75V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.53125 5.75 2.5 7 2.5C8.21875 2.5 9.25 3.53125 9.25 4.75V7Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div class="input__field">
					<input v-model="$v.form.password.$model" :type="showPassword ? 'text' : 'password'" placeholder="PASSWORD">
				</div>
				<div class="eye" :class="{'with-line': showPassword}" @click="showPassword = !showPassword">
					<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M17.875 5.5625C16.1875 2.25 12.8125 0 9 0C5.15625 0 1.78125 2.25 0.09375 5.5625C0.03125 5.6875 0 5.84375 0 6C0 6.1875 0.03125 6.34375 0.09375 6.46875C1.78125 9.78125 5.15625 12 9 12C12.8125 12 16.1875 9.78125 17.875 6.46875C17.9375 6.34375 17.9688 6.1875 17.9688 6.03125C17.9688 5.84375 17.9375 5.6875 17.875 5.5625ZM9 10.5C6.5 10.5 4.5 8.5 4.5 6C4.5 3.53125 6.5 1.5 9 1.5C11.4688 1.5 13.5 3.53125 13.5 6V6.03125C13.5 8.5 11.4688 10.5312 9 10.5312V10.5ZM9 3C8.71875 3.03125 8.4375 3.0625 8.1875 3.125C8.375 3.375 8.46875 3.6875 8.46875 4.03125C8.46875 4.84375 7.8125 5.5 7 5.5C6.65625 5.5 6.34375 5.40625 6.09375 5.21875C6.03125 5.46875 6 5.75 6 6C6 7.65625 7.34375 9 9 9C10.6562 9 12 7.65625 12 6C12 4.34375 10.6562 3.03125 9 3.03125V3Z" fill="#B0B0B0"/>
					</svg>
				</div>
				<div v-if="$v.form.password.$dirty" class="error-input">
					<template v-if="!$v.form.password.required">Password is required</template>
					<template v-else-if="!$v.form.password.minLength">Password min length 6 chars</template>
					<template v-else-if="!$v.form.password.maxLength">Password is not more than 48 characters</template>
				</div>
			</div>
			<div class="error-input error_static" v-show="showIncorrectData">
				Incorrect login data
			</div>

			<router-link to="/login/recovery" class="recovery">
				<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.5 6.5625H11.75V4.45312C11.75 2.02148 9.59375 0 7 0C4.375 0 2.25 2.02148 2.25 4.45312V6.5625H1.5C0.65625 6.5625 0 7.20703 0 7.96875V13.5938C0 14.3848 0.65625 15 1.5 15H12.5C13.3125 15 14 14.3848 14 13.5938V7.96875C14 7.20703 13.3125 6.5625 12.5 6.5625ZM9.25 6.5625H4.75V4.45312C4.75 3.31055 5.75 2.34375 7 2.34375C8.21875 2.34375 9.25 3.31055 9.25 4.45312V6.5625Z" fill="#8F8F8F"/>
				</svg>
				<span>Password recovery</span>
			</router-link>
<!--			<div class="checkbox">-->
<!--				<input type="checkbox" id="remember_me">-->
<!--				<label for="remember_me">-->
<!--					<span class="square"></span>-->
<!--					<span class="name">Remember me</span>-->
<!--				</label>-->
<!--			</div>-->
			<UiButton type="primary" @click="makeLogin">Sign in
				<UiLoader :width="15" v-show="loader"></UiLoader>
			</UiButton>
		</div>
	</div>
</template>
<script>
import UiButton from '../UiButton'
import UiLoader from '../UiLoader'
import { validationMixin } from 'vuelidate'
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
	data () {
		return {
			loader: false,
			showPassword: false,
			showIncorrectData: false,
			form: {
				email: null,
				password: null
			}
		}
	},
	mixins: [validationMixin],
	validations: {
		form: {
			email: {
				required,
				email
			},
			password: {
				required,
				maxLength: maxLength(48),
				minLength: minLength(4)
			}
		}
	},
	methods: {
		...mapActions({
			login: 'auth/login'
		}),
		async makeLogin () {
			this.$v.$touch()
			if (this.$v.$invalid) {
				return
			}
			this.loader = true
			this.login(this.form).then(() => {
				this.amplitude.logEvent('login')
				this.$router.replace('/games')
			}).catch(() => {
				this.showIncorrectData = true
			}).finally(() => {
				this.loader = false
			})
		}
	},
	components: {
		UiButton,
		UiLoader
	}
}
</script>
<style scoped lang="scss">
.login-form {
	button {
		margin-top: 20px;
		::v-deep img {
			margin-left: 20px;
		}
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.recovery {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 22px;
		text-decoration: none;
		cursor: pointer;
		transition: 0.2s;
		&:hover {
			opacity: 0.8;
		}
		width: 100%;
		span {
			margin-left: 6px;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			align-items: center;
			letter-spacing: 1px;
			color: #BDBDBD !important;
		}
	}
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
	-webkit-text-fill-color: #B0B0B0 !important;
	-webkit-box-shadow: 0 0 0 30px #2f2f2f inset !important;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <g>
      <path d="M2.97367 12.4072L2.36652 12.6445C1.4558 13.0343 0.983571 13.4411 1.00044 13.7971C1.05103 14.3225 1.64132 14.7123 2.72069 15.0005C4.12051 15.3733 5.53719 15.4581 7.00447 15.2377V13.7632L5.84077 14.2038C4.62647 14.6276 4.00245 14.2716 4.00245 14.2716C3.81694 14.153 3.73261 13.8649 4.23857 13.6784L4.87945 13.4411L7.00447 12.6784V10.9666L6.46478 11.153L2.97367 12.4072Z" :fill="color"/>
      <path d="M11.8449 6.45763V10.8475C12.7556 11.2881 13.5652 11.322 14.1723 10.8644C14.7963 10.4237 15.1336 9.66102 15.1336 8.50847C15.1336 7.30508 14.8975 6.38983 14.3747 5.77966C13.9025 5.13559 13.0592 4.59322 11.8112 4.16949C10.2596 3.66102 8.94408 3.22034 7.89844 3V16.1356L10.7149 17V6.08475C10.6981 5.42373 11.8449 5.47458 11.8449 6.45763Z" :fill="color"/>
      <path d="M18.9963 13.2363C18.9795 12.7787 18.4398 12.4228 17.4785 12.1008C16.3991 11.7448 15.4715 11.5753 14.4596 11.6092C13.532 11.6262 12.4189 11.9313 11.4238 12.2533V13.9313L14.156 12.9652C14.156 12.9652 14.9824 12.6601 15.6907 12.8465C16.2304 12.9821 16.1967 13.2872 15.657 13.4736L15.033 13.7279L11.4238 15.033V16.7448L13.1104 16.1347L17.158 14.694L17.6302 14.4736C18.6084 14.1177 19.0469 13.7279 18.9963 13.2363Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

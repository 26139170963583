import ParticipantModel from '@/models/ParticipantModel'
import Match from '@/models/Match'
export function prepare (state, value) {
	const matches = []

	value.data.map(item => {
		const matchResult = state.results.filter(result => result.type === 'INT' && result.match_id === item.id)[0]

		if (item.participants) {
			item.participants.map(item => {
				return new ParticipantModel(item.participant)
			})
		} else {
			item.first_participant = item.first_participant ? new ParticipantModel(item.first_participant) : null
			item.second_participant = item.second_participant ? new ParticipantModel(item.second_participant) : null
		}

		item.result = matchResult ? matchResult.value_state : null

		matches.push(new Match(item))
	})

	return matches
}

<template>
	<div class="tournament-sidebar">
		<div class="tournament-sidebar__header">
			<UiParticipantsCounter
				:all="tournament.maxMember"
				:registered="tournament.participantCount"
				:amountAvatars="3"
				:withIcon="true"
			/>
		</div>

		<div class="tournament-sidebar__content">
			<h4>ROUND {{ currentRound.round }}</h4>
			<p v-if="!isWaitStartMatch && currentRound.match_status !== 'FINISHED'">The round will end in:
				<UiCountDownTimer
					class="timer__out"
					:startTime="nowDateTime"
					:showDays="false"
					:endTime="currentRound.started_at"
				/>
			</p>
			<p v-if="currentRound.match_status === 'FINISHED'">
				The rounds are over
			</p>
			<p v-if="isWaitStartMatch && currentRound.match_status !== 'FINISHED'">
				The round will start in:
				<UiCountDownTimer
					class="timer__out"
					:startTime="nowDateTime"
					:showDays="false"
					:endTime="currentRound.started_at"
				/>
			</p>
			<div class="offset-top-50" v-if="currentRound.next_match_id">
				<UiButton
					disabled
					:type="'primary'"
				>
					Next Match
				</UiButton>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UiCountDownTimer from '@/components/UiCountDownTimer'
import UiParticipantsCounter from '@/components/UiParticipantsCounter'
import startTournamentDateFormat from '@/filters/startTournamentDateFormat'
import UiButton from '@/components/UiButton'
export default {
	name: 'SidebarContainer',
	components: {
		UiParticipantsCounter,
		UiButton,
		UiCountDownTimer
	},
	filters: { startTournamentDateFormat },
	data () {
		return {
			showEnterCodeBlock: false,
			nowDateTime: new Date().toDateString()
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			tournamentId: 'tournaments/getCurrentTournamentId',
			tournamentStatus: 'tournaments/getCurrentTournamentStatus',
			tournamentUser: 'tournaments/getCurrentTournamentUser',
			currentRound: 'tournaments/getCurrentMatch'
		}),
		isRegistrationOpenStatus () {
			return ['REGISTRATION_OPENED', 'CHECKIN'].includes(this.tournamentStatus)
		},
		isWaitStartMatch () {
			return ['WAITING', 'OPENED'].includes(this.currentRound.match_status)
		},
		isJoin () {
			return !!this.tournamentUser && this.tournamentUser.status === 'active'
		},
		isShowEnterCode () {
			return this.tournament.isPrivate && this.showEnterCodeBlock
		},
		isShowInfoBlock () {
			return !['OPENED', 'REGISTRATION_OPENED', 'CHECKIN', 'REGISTRATION_CLOSED'].includes(this.tournament.status)
		},
		userMissingInMatch () {
			return !this.getCurrentTournamentUser
		},
		isRegisteredStatus () {
			return !!this.tournamentUser && !this.isActiveStatus
		},
		isActiveStatus () {
			return this.tournamentUser && this.tournamentUser.status === 'active'
		}
	},
	methods: {
		...mapActions({
			register: 'tournaments/registerParticipant',
			unRegister: 'tournaments/unRegisterParticipant',
			checkIn: 'tournaments/checkInParticipant'
		}),
		isCheckInStatus () {
			return this.tournamentStatus === 'CHECKIN' && !this.tournamentUser && !this.isActiveStatus
		},
		isUserMissedRegistration () {
			return ['STARTED', 'FINISHED'].includes(this.tournamentStatus) && !this.tournamentUser
		},
		isStarted () {
			return ['STARTED'].includes(this.tournamentStatus)
		},
		sendAnalyticData (event) {
			try {
				this.amplitude.logEvent(event, this.tournament.analyticData())
			} catch (e) {
				console.error('Error send information to amplitude')
			}
		},
		tryRegisterWithCode (secret_code) {
			this.register({ id: this.tournamentId, secret_code }).then(() => {
				this.showEnterCodeBlock = false
				const identify = this.amplitudeIdentify.add('registrations', 1)
				this.amplitude.identify(identify)
				this.sendAnalyticData('registered')
			})
		},
		registerClick () {
			if (this.tournament.isPrivate) {
				this.showEnterCodeBlock = true
				return
			}
			this.register({ id: this.tournamentId }).then(() => {
				const identify = this.amplitudeIdentify.add('registrations', 1)
				this.amplitude.identify(identify)
				this.sendAnalyticData('registered')
			})
		},
		unRegisterClick () {
			this.unRegister({
				id: this.tournamentId,
				userId: this.tournamentUser.profile.user.id
			}).then(() => {
				this.sendAnalyticData('unregistered')
			})
		},
		checkInClick () {
			this.checkIn({ tournamentId: this.tournamentId, vm: this }).then(() => {
				const identify = this.amplitudeIdentify.add('check-ins', 1)
				identify.setOnce('played_first_time', new Date())
				this.amplitude.identify(identify)
				this.sendAnalyticData('check_in')
			})
		},
		nextMatchClick () {
		}
	}
}
</script>

<style lang="scss">
.timer__out {
	display: flex;
	align-items: center;
	background: #00E7DF;
	border-radius: 5px;
	padding: 0 7px;
	width: fit-content;
	color: #000
}
.offset-top-50 {
	margin-top: 50px;
}
.tournament-sidebar {
	&__content,
	&__header {
		width: 100%;
		padding: 25px 20px 25px 25px;
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
	&__enter-code {
		.ui-dashed-input {
			margin-top: 15px;
			margin-bottom: 25px;
		}
	}
	.tournament-sidebar__enter-code {
		.social-links {
			justify-content: center;
			margin-top: 10px;
			a {
				align-items: center;
				width: 202px;
				display: flex;
				border: 1px solid #505050;
				justify-content: center;
				.ui-icon-base {
					margin-right: 10px;
					transform: none;
					position: static;
				}
			}
		}
	}
	&__content {
		text-align: center;
		margin-top: 10px;

		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		color: $color-gray-300;

		h4 + p {
			text-transform: uppercase;
			margin-top: 10px;
		}
	}

	&__footer {
		margin-top: 20px;
	}

	.ui-participants-counter {
		justify-content: space-between;

		&__value {
			font-size: 20px;
			font-weight: 700;
			letter-spacing: 0.15px;
		}
	}

	.divider {
		display: inline-block;
		width: 100%;
		height: 1px;
		background: $color-gray-500;
		margin-top: 25px;
		margin-bottom: 15px;
	}

	.ui-button {
		width: 100%;
		margin-top: 10px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.social-links {
		display: flex;
		justify-content: space-between;
		a {
			position: relative;
			display: inline-block;
			color: $primary-color;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 1px;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 13px 40px;

			text-align: center;

			.ui-icon-base {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			@include base-transition;

			@include hover-state {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.14), 0 0 7px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.2);
			}

			@include focus-state {
				color: $color-gray-700;
				background-color: $primary-color;
				border-color: $primary-color;
			}
		}

		> * {
			width: calc(50% - 10px);
		}
	}

	.button-group {
		display: flex;
		justify-content: space-between;

		.ui-button {
			max-width: calc(50% - 10px);
		}
	}
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 18">
    <g>
      <path d="M6.2 9C7.53437 9 8.65 7.88393 8.65 6.5C8.65 5.13839 7.53437 4 6.2 4C4.84375 4 3.75 5.13839 3.75 6.5C3.75 7.88393 4.84375 9 6.2 9ZM7.8625 9.71429H7.6875C7.22813 9.9375 6.725 10.0714 6.2 10.0714C5.65313 10.0714 5.15 9.9375 4.69062 9.71429H4.51562C3.11563 9.71429 2 10.875 2 12.3036V12.9286C2 13.5312 2.45937 14 3.05 14H9.35C9.91875 14 10.4 13.5312 10.4 12.9286V12.3036C10.4 10.875 9.2625 9.71429 7.8625 9.71429ZM12.5 9C13.6594 9 14.6 8.04018 14.6 6.85714C14.6 5.67411 13.6594 4.71429 12.5 4.71429C11.3406 4.71429 10.4 5.67411 10.4 6.85714C10.4 8.04018 11.3406 9 12.5 9ZM13.55 9.71429H13.4625C13.1562 9.82589 12.8281 9.89286 12.5 9.89286C12.15 9.89286 11.8219 9.82589 11.5156 9.71429H11.45C10.9906 9.71429 10.575 9.84821 10.225 10.0714C10.75 10.6518 11.1 11.433 11.1 12.3036V13.1518C11.1 13.1964 11.0781 13.2411 11.0781 13.2857H14.95C15.5188 13.2857 16 12.817 16 12.2143C16 10.8527 14.8844 9.71429 13.55 9.71429Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

import BaseModel from './BaseModel'

export default class GamesModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      isGlobal: undefined,
      name: undefined,
      label: undefined,
      participantNumber: undefined,
      image: undefined,
      icon: undefined,
			topBanner: undefined,
			web_image: undefined,
			web_icon: undefined,
			platformId: undefined,
      platforms: undefined,
			info: undefined
    }
  }

	getPlatformIcon () {
		switch (this.platformId) {
			case 1: {
				return require('@/assets/ps_platform.svg')
			}
			case 2: {
				return require('@/assets/xbox_platform.svg')
			}
			case 3: {
				return require('@/assets/pc_platform.svg')
			}
			case 4: {
				return require('@/assets/mobile_platform.svg')
			}
		}
	}

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

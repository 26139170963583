<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 18">
    <g>
      <path d="M14.2147 4.22C14.2071 4.09266 14.1516 3.97306 14.0595 3.88569C13.9674 3.79831 13.8457 3.74977 13.7193 3.75H12.1367V3.5C12.1367 3.36739 12.0845 3.24021 11.9916 3.14645C11.8988 3.05268 11.7728 3 11.6414 3H5.69734C5.56597 3 5.43997 3.05268 5.34708 3.14645C5.25419 3.24021 5.202 3.36739 5.202 3.5V3.75H3.51042C3.38619 3.75291 3.26758 3.80285 3.17812 3.88991C3.08866 3.97697 3.03489 4.09479 3.02747 4.22C3.01013 4.565 2.93335 6.3175 3.14883 6.925C3.3643 7.5325 5.42986 9.39 5.44967 9.425C5.6971 9.9593 6.07097 10.4239 6.5383 10.778C7.00563 11.132 7.55207 11.3645 8.12944 11.455H8.17402V14H6.68801C6.55664 14 6.43065 14.0527 6.33776 14.1464C6.24486 14.2402 6.19267 14.3674 6.19267 14.5C6.19267 14.6326 6.24486 14.7598 6.33776 14.8536C6.43065 14.9473 6.55664 15 6.68801 15H10.6507C10.7821 15 10.9081 14.9473 11.001 14.8536C11.0939 14.7598 11.146 14.6326 11.146 14.5C11.146 14.3674 11.0939 14.2402 11.001 14.1464C10.9081 14.0527 10.7821 14 10.6507 14H9.1647V11.455C9.7246 11.3547 10.2527 11.1209 10.705 10.773C11.1573 10.4251 11.5208 9.97327 11.7652 9.455C12.32 9 13.8704 7.615 14.1181 6.925C14.3212 6.3175 14.2345 4.565 14.2147 4.22ZM4.06025 6.5875C3.97852 6.36 3.96118 5.535 3.98842 4.75H5.202V7.8175C4.60512 7.275 4.12712 6.78 4.05529 6.5875H4.06025ZM13.1794 6.5875C13.1125 6.7775 12.6841 7.2525 12.1417 7.7725V4.75H13.2512C13.271 5.535 13.2562 6.36 13.1745 6.5875H13.1794Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

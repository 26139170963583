export default {
	tournaments: [],
	tournamentsMeta: {},
	tournament: {},
	participants: {},
	leaderBoard: [],
	results: [],
	matches: [],
	current_match: null
}

<template>
	<div class="rr__room">
		<div class="rr__room-row">
			<div class="rr__room-row_left-col">
				<div class="room_name">{{ room.name }}</div>
				<div class="room_joined" v-if="tournament.participant && room.participants.find(item => item.participant.id === tournament.participant.id)">your</div>
			</div>
			<div class="rr__room-row_right-col">
				<em>Points</em>
			</div>
		</div>
		<div class="rr__room-row" v-for="(participant, j) in room.participants" :key="j" :class="{'active': tournament.rr.stage === 2 && room.winners.includes(participant.id) }">
			<div class="rr__room-row_left-col">
				<div class="room__participant">
					<UiAvatar :size="40" :src="participant.participant.getAvatar()"></UiAvatar>
					<span>{{ participant.participant.getName() }}</span>
				</div>
			</div>
			<div class="rr__room-row_right-col">
				<span>{{ participant.participant.getEarnedPoints() }}</span>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import UiAvatar from '../../UiAvatar'
export default {
	props: {
		room: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	methods: {
		subscribeRoomUpdate () {
			this.subscribeObserver(`rr.room.${this.room.id}`, () => {
				this.$emit('updateRoom')
			})
		}
	},
	mounted () {
		this.subscribeRoomUpdate()
	},
	components: {
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.rr__room {
	width: calc(50% - 20px);
	margin-right: 20px;
	background: #2F2F2F;
	border-radius: 5px;
	.rr__room-row {
		display: flex;
		width: 100%;
		min-height: 45px;
		&.active {
			background: #018786;
		}
		.room_joined {
			background: #018786;
			border-radius: 5px;
			padding: 4px 10px;
			font-weight: 700;
			font-size: 10px;
			line-height: 12px;
			text-transform: uppercase;
			color: #FFFFFF;
			margin: 0 10px;
		}
		.room__participant {
			align-items: center;
			margin: 7px 0;
			display: flex;
			span {
				margin-left: 10px;
			}
		}
		.rr__room-row_left-col {
			flex-grow: 1;
			overflow: hidden;
			display: flex;
			align-items: center;
			padding-left: 25px;
			.room_name {
				font-weight: 700;
				font-size: 12px;
				line-height: 15px;
				text-transform: uppercase;
				color: #FFFFFF;
			}
		}
		.rr__room-row_right-col {
			width: 80px;
			min-width: 80px;
			border-left: 1px solid #505050;
			display: flex;
			align-items: center;
			justify-content: center;
			em {
				font-style: normal;
				font-weight: 400;
				font-size: 10px;
				line-height: 20px;
				letter-spacing: 0.25px;
				color: #8F8F8F;
			}
			span {
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.75px;
				text-transform: uppercase;
				color: #FFFFFF;
			}
		}
	}
	.rr__room-row + .rr__room-row {
		border-top: 1px solid #505050;
	}
}
</style>

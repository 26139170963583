<template>
	<div class="chat__message" v-if="message.message" :class="[direction, { 'system': isSystem }]">
		<template v-if="!isSystem">
			<UiAvatar :size="32" :src="message.image ? message.image : getAvatar"></UiAvatar>
			<div class="message__info">
				<div class="nickname">{{ message.role === 'admin' ? 'Admin' : getNickname }}</div>
				<div class="text__item" :class="[`text-type`]">
					<!--				<img :src="message.image" alt="" v-if="type === 'img'">-->
					<template>
						{{ message.message }}
					</template>
					<span>{{ time }}</span>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="system__message">{{ systemMessage }}</div>
		</template>
	</div>
</template>
<script>
import UiAvatar from '../UiAvatar'
import Moment from 'moment'
import { mapGetters } from 'vuex'
export default {
	props: {
		team: {
			type: Object || null,
			required: false,
			default: null
		},
		message: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters({
			activeChat: 'chat/activeChat',
			currentUser: 'user/getCurrentUser'
		}),
		getAvatar () {
			if (this.team) {
				const find = this.team.team_mates.find(item => this.message.userId === item.user_id)
				return find ? find.profile.user.avatar : null
			}
			return null
		},
		getNickname () {
			if (!this.activeChat.isTeam) {
				return this.message.nickname
			}

			if (!this.team) return 'Unknown'
			const find = this.team.team_mates.find(item => item.profile.user.id === this.message.userId)
			return find ? find.profile.user.nickname : null
		},
		isSystem () {
			return this.message.message === '<#>call_admin'
		},
		systemMessage () {
			switch (this.message.message) {
				case '<#>call_admin': {
					return 'Admin joined the chat'
				}
				default: {
					return 'Admin joined the chat'
				}
			}
		},
		direction () {
			return this.message.userId === this.currentUser.id ? 'right' : 'left'
		},
		type () {
			return this.message.image ? 'img' : 'text'
		},
		time () {
			return new Moment(this.message.created_at).format('HH:mm')
		}
	},
	components: {
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.chat__message {
	display: flex;
	align-items: flex-start;
	grid-gap: 8px;
	margin-top: 32px;
	&:not(.system) {
		max-width: 80%;
	}
	.system__message {
		background: #004042;
		border-radius: 5px;
		padding: 2px 6px;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.4px;
		margin: 20px auto;
		color: #FFFFFF;
	}
	.message__info {
		position: relative;
		.nickname {
			position: absolute;
			white-space: nowrap;
			top: -17px;
			font-weight: 500;
			font-size: 10px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: #E0E0E0;
			left: 0;
		}
		.text__item {
			position: relative;
			&::after {
				position: absolute;
				left: -8px;
				top: 14px;
				content: "";
				display: block;
				border: 4px solid transparent;
				border-right: 4px solid #505050;
			}
			background: #505050;
			border-radius: 5px;
			padding: 8px 35px 8px 8px;
			font-size: 12px;
			line-height: 16px;
			width: fit-content;
			max-width: 100%;
			letter-spacing: 0.4px;
			color: #FFFFFF;
			span {
				position: absolute;
				right: 5px;
				bottom: 0;
				font-size: 10px;
				line-height: 16px;
				letter-spacing: 0.4px;
				color: #BDBDBD;
			}
			&.text-type {
				padding-right: 37px !important;
			}
			&.img-type {
				padding: 1px;
				padding-right: 1px !important;
				img {
					max-width: 100%;
				}
				span {
					bottom: 3px;
					background: #2F2F2F;
					border-radius: 5px;
					padding: 1px 4px 2px;
				}
			}
		}
		.text__item + .text__item {
			margin-top: 5px;
		}
	}
	&.right {
		.message__info {
			.nickname {
				white-space: nowrap;
				right: 0;
				left: unset;
			}
			.text__item {
				&.img-type {
					&::after {
						display: none;
					}
				}
				background: #018786;
				padding-right: 8px;
				&.text-type {
					&::after {
						right: -8px;
						border-right: 4px solid #018786;
						transform: rotate(180deg);
						left: unset;
					}
				}
			}
		}
		margin-left: auto;
		flex-direction: row-reverse;
	}
}
</style>

import GamesModel from '@/models/GamesModel'
import TournamentItemModel from '@/models/TournamentItemModel'
export default {
	SET_CURRENT_USER (state, currentUserData) {
		state.currentUser = JSON.stringify(currentUserData)
		localStorage.setItem('currentUser', state.currentUser)
	},
	SET_WS_TOKEN (state, token) {
		state.wsToken = token
		localStorage.setItem('wsToken', token)
	},
	SET_FAVOURITE_GAMES (state, games) {
		const newGames = []
		games.forEach((item) => newGames.push(new GamesModel(item)))

		state.favouriteGames = newGames
	},
	SET_GAMES (state, games) {
		const newGames = []
		const allGames = []
		games.forEach((item) => {
			item.game.info = item.info
			item.game.platform_id = item.platform_id
			allGames.push(new GamesModel(item.game))
		})
		state.allGames = allGames
		games = games.forEach((item) => {
			if (!newGames.find(i => i.game.id === item.game.id)) {
				newGames.push(item)
			}
		})
		const result = []
		newGames.forEach((item) => {
			item.game.info = item.info
			item.game.platform_id = item.platform_id
			result.push(new GamesModel(item.game))
		})
		state.games = result
	},
	SET_CURRENT_TOURNAMENTS (state, tournaments) {
		const tournamentsList = []
		tournaments.data.map(item => {
			tournamentsList.push(new TournamentItemModel(item))
		})
		state.currentTournaments = tournamentsList
	},
	SET_CURRENT_GAME (state, value) {
		state.currentGame = value
		localStorage.setItem('currentGame', value)
	},
	SET_CURRENT_TEAMS (state, value) {
		state.currentTeams = value.data
	},
	SET_CURRENT_SEASON (state, value) {
		state.currentSeason = value.data[0] ?? null
	},
	SET_LEADERBOARD (state, value) {
		state.leaderboard = value
	},
	ADD_TEAM (state, value) {
		state.currentTeams.push(value)
	},
	DELETE_TEAM (state, id) {
		const find = state.currentTeams.findIndex(item => item.id === id)
		if (find > -1) {
			state.currentTeams.splice(find, 1)
		}
	}
}

<template>
	<div class="teams-list">
		<div class="teams-list__heading">
			Your teams
		</div>
		<TeamItem v-for="(team, i) in teams" :team="team" :key="i"></TeamItem>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import TeamItem from './TeamItem'
export default {
	computed: {
		...mapGetters({
			teams: 'user/currentTeams'
		})
	},
	components: {
		TeamItem
	}
}
</script>
<style scoped lang="scss">
.teams-list {
	width: 100%;
	.teams-list__heading {
		font-weight: bold;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		color: #FFFFFF;
		overflow: hidden;
		display: flex;
		margin-bottom: 20px;
		white-space: nowrap;
		align-items: flex-end;
		&::after {
			height: 1px;
			width: 100%;
			content: "";
			display: block;
			background: #2F2F2F;
			margin-bottom: 5px;
			margin-left: 10px;
		}
	}
}

@media screen and (max-width: 768px) {
	.teams-list {
		padding-top: 20px;
	}
}
</style>

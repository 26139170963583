<template>
	<div class="welcome">
		<img src="@/assets/logo.svg" alt="">
		<h1>Welcome to QLASH</h1>
		<p>Game tournaments organized for you.<br>
			Compete alone or join a team. </p>
		<UiButton type="primary" @click="$emit('next')">GET STARTED</UiButton>
	</div>
</template>
<script>
import UiButton from '../UiButton'
export default {
	components: {
		UiButton
	}
}
</script>
<style scoped lang="scss">
	.welcome {
		padding: 78px 0;
		img {
			width: 200px;
			margin: 0 auto;
			display: block;
		}
		h1 {
			margin-top: 50px;
			text-align: center;
			width: 100%;
			display: block;
			font-weight: bold;
			font-size: 30px;
			line-height: 32px;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin-bottom: 15px;
		}
		p {
			text-align: center;
			width: 100%;
			display: block;
			font-size: 16px;
			line-height: 28px;
			letter-spacing: 1px;
			margin-bottom: 25px;
		}
		button {
			padding: 15.5px 83px;
			display: block;
			margin: 0 auto;
		}
	}

	@media screen and (max-width: 768px) {
		.welcome {
			h1 {
				font-size: 24px;
				line-height: 32px;
			}
			p {
				font-size: 14px;
				line-height: 24px;
			}
		}
	}
</style>

<template>
	<div class="ui-countdown-timer component">
		<template v-if="showDays">
			{{ days }} {{ days > 1 ? $t('days') : $t('day') }}
		</template> {{ hours }}:{{ minutes }}:{{ seconds }}
	</div>
</template>

<script>
	export default {
		name: 'UiCountDownTimer',

		props: {
			showDays: {
				default: true,
				type: Boolean
			},
			startTime: { type: String },
			endTime: { type: String }
		},

		data () {
			return {
				timer: '',
				start: '',
				end: '',
				interval: '',
				days: '',
				minutes: '',
				hours: '',
				seconds: ''
			}
		},

		mounted () {
			this.start = new Date(this.startTime.toString()).getTime()
			this.end = new Date(this.endTime.toString()).getTime()
			this.timerCount(this.start, this.end)
			this.interval = setInterval(() => {
				this.timerCount(this.start, this.end)
			}, 1000)
		},

		methods: {
			timerCount (start, end) {
				const now = new Date().getTime()
				const distance = start - now
				const passTime = end - now

				if (distance < 0 && passTime < 0) {
					clearInterval(this.interval)

					this.days = '0'
					this.hours = '00'
					this.minutes = '00'
					this.seconds = '00'
				} else if (distance < 0 && passTime > 0) {
					this.calcTime(passTime)
				} else if (distance > 0 && passTime > 0) {
					this.calcTime(distance)
				}
			},
			calcTime (dist) {
				this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
				this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				this.hours = this.hours < 10 ? `0${this.hours}` : this.hours
				this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60))
				this.seconds = Math.floor((dist % (1000 * 60)) / 1000)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ui-countdown-timer {
		display: inline-block;
		font-weight: bold;
	}
</style>

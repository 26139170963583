import Room from '../../../models/round_robin/Room'
import RoomParticipant from '../../../models/round_robin/RoomParticipant'
import ParticipantModel from '../../../models/ParticipantModel'
export function prepare (room) {
	const roomInstance = new Room(room)
	const roomParticipants = []

	room.participants.forEach(item => {
		item.participant = new ParticipantModel(item.participant)
		roomParticipants.push(new RoomParticipant(item))
	})

	roomInstance.participants = roomParticipants

	return roomInstance
}

<template>
	<div class="avatar component" :class="`size-${size}`">
		<UiImgLoader
			:src="avatar"
			:alt="alt"
			:animated="animated"
		/>
	</div>
</template>

<script>
	/**
	 * @description Component check if src to avatar is exist and replace by default image if src does`t exist
	 */
	import UiImgLoader from './UiImgLoader'

	export default {
		name: 'UiAvatar',
		components: {
			UiImgLoader
		},
		props: {
			size: {
				type: Number,
				default: 40
			},
			alt: {
				type: String
			},
			src: {
				type: String,
				default: ''
			},
			animated: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				avatar: '',
				defaultAvatar: require('../assets/avatar.svg')
			}
		},

		watch: {
			src: {
				handler: 'checkSrc',
				immediate: true
			}
		},

		methods: {
			checkSrc () {
				this.avatar = this.src ? this.src : this.defaultAvatar
			}
		}
	}
</script>

<style lang="scss" scoped>
.avatar {
	width: 100%;
	display: flex;
	align-items: center;
	min-height: auto !important;
	border: 1px solid $color-gray-500;
	overflow: hidden;
	border-radius: 50%;
	background: $color-gray-600;
	&.size-40 {
		max-width: 40px;
		min-width: 40px;
		min-height: 40px !important;
		max-height: 40px;
	}
	&.size-32 {
		max-width: 32px;
		min-width: 32px;
		height: 32px;
		max-height: 32px;
		min-height: 32px !important;
	}
	&.size-70 {
		max-width: 70px;
		min-width: 70px;
		min-height: 70px !important;
		max-height: 70px;
	}

	&.size-50 {
		max-width: 50px;
		min-width: 50px;
		min-height: 50px !important;
		max-height: 50px;
	}
	&.size-60 {
		max-width: 60px;
		min-width: 60px;
		min-height: 60px !important;
		max-height: 60px;
	}
	&.size-90 {
		max-width: 90px;
		min-width: 90px;
		min-height: 90px !important;
		max-height: 90px;
	}

	> .img-loader {
		min-height: 40px !important;

	}

}
.img-container {
	align-items: center;
}
</style>

import { eventBus } from '@/buses/eventBus'
export async function find (tournament, teams) {
	return new Promise((resolve, reject) => {
		teams = teams.filter(item => !item.is_locked)
		/* Do not block multiplatform tournaments */
		// teams = teams.filter(item => item.platform_id === tournament.platformId)
		teams = teams.filter(item => item.game_id === tournament.gameId)
		teams = teams.filter(item => item.team_mates_count === tournament.teamMates)
		if (!teams.length) {
			reject(new Error("You don't have the right team"))
		}
		if (teams.length > 1) {
				eventBus.$emit('callSelectTeamTournament', teams)
				eventBus.$on('selectTeamTournament', (team) => {
					resolve(team)
				})
				eventBus.$on('destroySelectTeamTournament', () => {
					reject(new Error())
				})
		} else {
			resolve(teams[0])
		}
	})
}

<template>
	<div class="team-player" :class="{'is-member': !player.status}" v-if="!isRequest || (isRequest && isCaptain) && (!editMode && isRequest)">
		<div class="team-player__avatar">
			<UiAvatar :src="player.profile.user.avatar" :size="60"></UiAvatar>
		</div>
		<div class="team-player__information">
			<em v-if="player.role === 'captain'">Captain</em>
			<em v-if="player.role === 'co-captain'">Co-captain</em>
			<em v-if="player.role === 'player'">Player</em>
			<em v-if="isRequest">Member</em>
			<em v-if="player.role === 'substitute'">Substitute</em>
			<strong>{{ player.profile.user.nickname ? player.profile.user.nickname : player.profile.user.name }}</strong>
			<span>In-game ID: {{ player.profile.info.id !== "unknown" ? player.profile.info.id : player.profile.info.nickname }}</span>
		</div>
		<div class="team-player__controls" v-if="editMode">
			<v-select v-model="role" :disabled="player.role === 'captain'" :placeholder="'Role'" :clearable="false" :searchable="false" :options="roles" label="name"></v-select>
		</div>
		<div class="team-player__controls" v-else-if="isRequest && isCaptain">
			<button class="accept" @click="accept" :disabled="load">
				<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4.40527 9.80367C4.65918 10.0654 5.09082 10.0654 5.34473 9.80367L12.8096 2.10733C13.0635 1.84555 13.0635 1.40052 12.8096 1.13874L11.8955 0.196335C11.6416 -0.065445 11.2354 -0.065445 10.9814 0.196335L4.8877 6.47906L2.01855 3.54712C1.76465 3.28534 1.3584 3.28534 1.10449 3.54712L0.19043 4.48953C-0.0634766 4.75131 -0.0634766 5.19634 0.19043 5.45812L4.40527 9.80367Z" fill="#121212"/>
				</svg>
				Accept
			</button>
			<button class="decline" :disabled="load" @click="decline">
				<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.88653 4.98582L9.7234 2.14894C10.0922 1.80851 10.0922 1.24113 9.7234 0.900709L9.09929 0.276596C8.75887 -0.0921986 8.19149 -0.0921986 7.85106 0.276596L5.01418 3.11348L2.14894 0.276596C1.80851 -0.0921986 1.24113 -0.0921986 0.900709 0.276596L0.276596 0.900709C-0.0921986 1.24113 -0.0921986 1.80851 0.276596 2.14894L3.11348 4.98582L0.276596 7.85106C-0.0921986 8.19149 -0.0921986 8.75887 0.276596 9.09929L0.900709 9.7234C1.24113 10.0922 1.80851 10.0922 2.14894 9.7234L5.01418 6.88653L7.85106 9.7234C8.19149 10.0922 8.75887 10.0922 9.09929 9.7234L9.7234 9.09929C10.0922 8.75887 10.0922 8.19149 9.7234 7.85106L6.88653 4.98582Z" fill="#121212"/>
				</svg>
				Remove
			</button>
		</div>
	</div>
</template>
<script>
import UiAvatar from '../UiAvatar'
import { TeamsService } from '../../services/teams.service'
import { mapMutations } from 'vuex'
export default {
	data () {
		return {
			newRole: null,
			roles: [
				{
					type: 'player',
					name: 'Player'
				},
				{
					type: 'co-captain',
					name: 'Co-captain'
				},
				{
					type: 'substitute',
					name: 'Substitute'
				},
				{
					type: 'delete',
					name: 'Delete'
				}
			],
			load: false
		}
	},
	props: {
		editMode: {
			type: Boolean,
			default: false
		},
		teamId: {
			required: true,
			type: Number
		},
		captainId: {
			required: true,
			type: Number
		},
		player: {
			type: Object,
			required: true
		}
	},
	computed: {
		role: {
			get () {
				return this.newRole ?? this.player.role
			},
			async set (value) {
				if (this.load) return
				if (this.player.role === value.type) return
				await this.updatePlayerRole(value.type).then(() => {
					this.makeToast({
						type: 'success',
						message: value.type === 'delete' ? 'Player has been delete from team' : 'Role has been changed!'
					})
					this.player.role = value.type
					this.newRole = value
				}).catch(error => {
					this.makeToast({
						type: 'error',
						message: error.message ?? 'Error'
					})
				})
			}
		},
		isRequest () {
			return !this.player.status || !this.player.role
		},
		isCaptain () {
				return this.captainId === this.$currentUser.id
		}
	},
	methods: {
		...mapMutations({
			makeToast: 'toast/NEW'
		}),
		async updatePlayerRole (role) {
			this.load = true
			if (role === 'delete') {
				return await TeamsService.declineInvite(this.teamId, this.player.id).then(() => {
					this.$emit('remove', this.player.id)
				}).finally(() => {
					this.load = false
				})
			}
			return await TeamsService.updatePlayerRole(this.teamId, this.player.id, role).finally(() => {
				this.load = false
			})
		},
		async decline () {
			this.load = true
			await TeamsService.declineInvite(this.teamId, this.player.id).then(() => {
				this.$emit('update')
			}).catch(error => {
				this.makeToast({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
			this.load = false
		},
		async accept () {
			this.load = true
			await TeamsService.acceptInvite(this.teamId, this.player.id).then(() => {
				this.$emit('update')
			}).catch(error => {
				this.makeToast({
					type: 'error',
					message: error.message ?? 'Error'
				})
			})
			this.load = false
		}
	},
	components: {
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.team-player {
	border: 1px solid #2F2F2F;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 12px 0;
	padding-left: 15px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	.team-player__avatar {
		width: 60px;
		margin-right: 15px;
	}
	.team-player__information {
		flex-grow: 1;
		overflow: hidden;
		em {
			font-size: 10px;
			line-height: 16px;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			color: #00E7DF;
			width: 100%;
			display: block;
			margin-bottom: 4px;
		}
		strong {
			font-weight: bold;
			font-size: 20px;
			line-height: 20px;
			letter-spacing: 0.25px;
			margin-bottom: 5px;
			display: block;
			width: 100%;
		}
		span {
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: #BDBDBD;
			display: block;
			width: 100%;
		}

	}
	.team-player__controls {
		padding-right: 15px;
		display: flex;
		::v-deep {
			.v-select {
				background: #2F2F2F;
				width: 180px;
				border: 1px solid #505050;
				box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
				border-radius: 4px;
				flex-grow: 1;
				height: 100%;
				padding-right: 21px;
				padding-right: 0;
				&.vs--disabled {
					cursor: not-allowed;

					opacity: 0.5;
					& * {
						background: transparent !important;

					}
				}
				.vs__dropdown-toggle {
					padding: 14px 15px;
					width: 100%;
					height: 100%;
					margin: 0;
					border: none;
					* {
						border: none !important;
					}
					.vs__selected-options {
						.vs__selected {
							margin: 0;
							padding: 0;

							position: static !important;
							text-transform: uppercase;
							font-weight: bold;
							font-size: 12px;
							line-height: 16px;
							/* identical to box height, or 133% */

							letter-spacing: 0.4px;

							/* Main */

							color: #00E7DF;
						}
						.vs__search {
							border: none !important;
							font-size: 10px;
							line-height: 20px;
							letter-spacing: 2px;
							text-transform: uppercase;
							color: #FFFFFF;
						}
					}
					.vs__actions {
						svg {
							path {
								fill: #00E7DF
							}
						}
					}
				}
				ul {
					background: #2F2F2F;
					box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					li {
						color: #fff;
						transition: 0.2s;
						padding: 10px 15px;
						&.vs__dropdown-option--selected, &.vs__dropdown-option--highlight {
							background: #00E7DF;
						}
						&:hover {
							background: #00E7DF;
						}
					}
				}

			}

		}
		button {
			height: 42px;
			display: flex;
			align-items: center;
			padding-right: 29px;
			padding-left: 15px;
			border-radius: 4px;
			outline: none;
			border: none;
			font-weight: bold;
			font-size: 14px;
			line-height: 30px;
			cursor: pointer;
			text-align: center;
			letter-spacing: 0.3px;
			&[disabled] {
				opacity: 0.6;
			}
			&:hover {
				opacity: 0.9;
				font-size: 14px;
				font-weight: bold;
			}
			transition: 0.2s;
			text-transform: uppercase;
			color: #121212;
			svg {
				margin-right: 20px;
			}
			&.accept {
				background: #00E7DF;
			}
			&.decline {
				background: #D71411;
			}
		}
		button + button {
			margin-left: 15px;
		}
	}
	&.is-member {
		border: 1px solid #00E7DF;
	}
}

@media screen and (max-width: 768px) {
	.team-player {
		padding: 10px;
		margin-bottom: 10px;
		padding-right: 0;
		padding-left: 0;
		.team-player__avatar {
			margin-left: 16px;
		}
		.team-player__information {
			width: calc(100% - 92px);
			strong {
				font-size: 16px;
				line-height: 20px;
				overflow: hidden;
				max-width: 100%;
				text-overflow: ellipsis;
			}
		}
		flex-wrap: wrap;
		.team-player__controls {
			width: 100%;
			padding-top: 16px;
			border-top: 1px solid #00E7DF;
			padding-left: 16px;
			padding-bottom: 6px;
			margin-top: 6px;
			button {
				flex: 1;
				justify-content: center;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}
</style>

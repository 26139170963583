<template>
	<div class="tournament-sidebar">
		<RulesModal
			:showModal="showModal"
			:showEnterCodeBlock="showEnterCodeBlock"
			:profiling="this.tournament.require_user_profiling"
			@reject="reject"
			@accept="accept"
		/>
		<div class="tournament-sidebar__header">
			<UiParticipantsCounter
				:all="tournament.maxMember"
				:registered="tournament.participantCount"
				:amountAvatars="3"
				:withIcon="true"
			/>
		</div>

		<div class="tournament-sidebar__content">
			<div v-if="isRegistrationOpenStatus && !isJoin">
				<div v-if="isRegisteredStatus">
					<h4>{{ $t('JOIN TOURNAMENT') }}</h4>
					<p>{{ $t('SIGN UPS CLOSE') }} {{ tournament.startedAt | startTournamentDateFormat }}</p>
					<span class="divider"></span>
					<p v-if="!isStarted()">
						<span>{{ $t('Registration and check will close in') }} </span>
						<UiCountDownTimer
							:startTime="nowDateTime"
							:endTime="tournament.startedAt"
						/>
					</p>
					<div class="button-group">
						<UiButton @click="unRegisterClick()">
							{{ $t('Unregister') }}
						</UiButton>
						<UiButton
							:type="'primary'"
							:disabled="!isCheckInStatus"
							@click="checkInClick()"
						>
							{{ $t('Check in') }}
						</UiButton>
					</div>
				</div>
				<div v-else>
					<div v-if="!isShowEnterCode">
						<h4>{{ $t('JOIN TOURNAMENT') }}</h4>
						<p>{{ $t('SIGN UPS CLOSE') }} {{ tournament.startedAt | startTournamentDateFormat }}</p>
						<span class="divider"></span>
						<p v-if="!isStarted()">
							<span>{{ $t('Registration and check will close in') }} </span>
							<UiCountDownTimer
								:startTime="nowDateTime"
								:endTime="tournament.startedAt"
							/>
						</p>
						<UiButton
							:disabled="!isRegistrationOpenStatus"
							:type="'primary'"
							@click="checkProfiling()"
						>
							{{ $t('Register') }}
						</UiButton>
					</div>
					<div v-else>
						<div class="tournament-sidebar__enter-code">
							<h4>ENTER THE TOURNAMENT CODE IN ORDER TO JOIN</h4>
							<div class="access_code-input">
								<UiDashedInput placeholder="Tournament code" class="mb-10" v-model="accessCode"></UiDashedInput>
								<UiButton @click="tryRegisterWithCode" :type="'primary'" :disabled="!accessCode.length">
									Join
								</UiButton>
							</div>
							<span>Need any help? Ask our admin on Discord</span>

							<div class="social-links">
								<a :target="tournament.chatLink ? '_blank' : '_self'" :href="tournament.chatLink ? getChatLink : '#'">
									<UiBaseIcon
										width="16px"
										height="12px"
										iconName="discord"
									/>
									<span>{{ $t('discord') }}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<template v-if="!isShowInfoBlock">
					<h4>INFO</h4>
					<p>You have joined this tournament, wait for the match to start</p>
					<div class="divider"></div>
					<p>
						The match starts in
						<UiCountDownTimer
							:startTime="nowDateTime"
							:showDays="false"
							:endTime="tournament.startedAt"
						/>
					</p>
				</template>

				<template v-if="userMissingInMatch">
					<UiButton @click="$router.push({name: 'rounds'})" :type="'primary'" disabled>
						Match info
					</UiButton>
				</template>
				<template v-else>
					<template v-if="[1,3].includes(tournament.type)">
						<UiButton @click="$router.push({name: 'rounds'})" :type="'primary'" :disabled="!isShowInfoBlock || isFinished || userMissingInMatch">
							Match info
						</UiButton>
					</template>
					<template v-else-if="[6,4].includes(tournament.type)">
						<UiButton @click="$router.push({name: 'tournament.play'})" :type="'primary'" :disabled="!isShowInfoBlock || isFinished || userMissingInMatch || tournamentUser.status === 'defeated'">
							Match details
						</UiButton>
					</template>
					<template v-else>
						<UiButton @click="$router.push({name: 'leaderboard.match'})" :type="'primary'" :disabled="!isShowInfoBlock || isFinished || userMissingInMatch">
							Match details
						</UiButton>
					</template>
				</template>

			</div>
		</div>
		<SelectTournamentTeamModal v-if="tournament.isTeamType()"></SelectTournamentTeamModal>
		<SidebarSocials v-if="!isShowEnterCode && !isMobile" ></SidebarSocials>
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import UiParticipantsCounter from '@/components/UiParticipantsCounter'
	import UiCountDownTimer from '@/components/UiCountDownTimer'
	import UiButton from '@/components/UiButton'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'
	import UiDashedInput from '@/components/UiDashedInput'
	import startTournamentDateFormat from '@/filters/startTournamentDateFormat'
	import SidebarSocials from '@/components/tournaments/SidebarSocials'
	import SelectTournamentTeamModal from '../../../components/modals/SelectTournamentTeamModal'
	import { UsersService } from '../../../services/users.service'

	export default {
		name: 'SidebarContainer',
		components: {
			UiParticipantsCounter,
			UiCountDownTimer,
			UiButton,
			UiBaseIcon,
			SidebarSocials,
			UiDashedInput,
			SelectTournamentTeamModal,
			RulesModal: () => import('@/components/modals/RulesModal')
		},
		filters: { startTournamentDateFormat },
		data () {
			return {
				accessCode: '',
				nowDateTime: new Date().toDateString(),
				showModal: false,
				showEnterCodeBlock: false
			}
		},
		computed: {
			...mapGetters({
				isMobile: 'dom/isMobile',
				tournament: 'tournaments/getCurrentTournament',
				tournamentId: 'tournaments/getCurrentTournamentId',
				tournamentStatus: 'tournaments/getCurrentTournamentStatus',
				tournamentUser: 'tournaments/getCurrentTournamentUser',
				userTeams: 'user/currentTeams',
				user: 'user/getCurrentUser'
			}),
			getChatLink () {
				const x = this.tournament.chatLink.replace(/(^\w+:|^)\/\//, '')
				const r = new URL(`https://${x}`)
				return r
			},
			isRegistrationOpenStatus () {
				return ['REGISTRATION_OPENED', 'CHECKIN'].includes(this.tournamentStatus)
			},
			isJoin () {
				return !!this.tournamentUser && this.tournamentUser.status === 'active'
			},
			isFinished () {
				return ['FINISHED'].includes(this.tournament.status)
			},
			isShowEnterCode () {
				return this.tournament.isPrivate && this.showEnterCodeBlock
			},
			isShowInfoBlock () {
				return !['OPENED', 'REGISTRATION_OPENED', 'CHECKIN', 'REGISTRATION_CLOSED', 'PENDING'].includes(this.tournament.status)
			},
			userMissingInMatch () {
				return !this.tournamentUser
			},
			isRegisteredStatus () {
				return !!this.tournamentUser && !this.isActiveStatus && this.tournamentUser.status
			},
			isActiveStatus () {
				return this.tournamentUser && this.tournamentUser.status === 'active'
			},
			isCheckInStatus () {
				return this.tournamentStatus === 'CHECKIN' || (this.tournamentStatus === 'REGISTRATION_OPENED' && this.isActiveStatus)
			}
		},
		mounted () {

		},
		methods: {
			...mapActions({
				register: 'tournaments/registerParticipant',
				unRegister: 'tournaments/unRegisterParticipant',
				checkIn: 'tournaments/checkInParticipant'
			}),
			isUserMissedRegistration () {
				return ['STARTED', 'FINISHED'].includes(this.tournamentStatus) && !this.tournamentUser
			},
			isStarted () {
				return ['STARTED'].includes(this.tournamentStatus)
			},
			async tryRegisterWithCode () {
				await this.setAcceptedProfiling()

				this.register({ id: this.tournamentId, secret_code: this.accessCode }).then(() => {
					this.showEnterCodeBlock = false
					this.accessCode = ''
					const identify = this.amplitudeIdentify.add('registrations', 1)
					this.amplitude.identify(identify)
					this.sendAnalyticData('registered')
				})
			},
			sendAnalyticData (event) {
				try {
					this.amplitude.logEvent(event, this.tournament.analyticData())
				} catch (e) {
					console.error('Error send information to amplitude')
				}
			},
			checkProfiling () {
				this.showModal = true
			},
			unRegisterClick () {
				this.unRegister({
					id: this.tournamentId,
					userId: (!this.tournament.isTeamType() ? this.tournamentUser.profile.user.id : (this.tournamentUser.team.creatorId ? this.tournamentUser.team.creatorId : this.tournamentUser.team.creator_id))
				}).then(() => {
					this.sendAnalyticData('unregistered')
					this.showModal = false
				})
			},
			async registerClick () {
				if (this.tournament.isPrivate) {
					this.showEnterCodeBlock = true
					this.showModal = false
					return
				}

				await this.setAcceptedProfiling()

				this.register({ id: this.tournamentId }).then(() => {
					const identify = this.amplitudeIdentify.add('registrations', 1)
					this.amplitude.identify(identify)
					this.sendAnalyticData('registered')
					this.showModal = false
				})
			},
			checkInClick () {
				this.checkIn({ tournamentId: this.tournamentId, vm: this }).then(() => {
					const identify = this.amplitudeIdentify.add('check-ins', 1)
					identify.setOnce('played_first_time', new Date())
					this.amplitude.identify(identify)
					this.sendAnalyticData('check_in')
					this.showModal = false
				})
			},
			accept () {
				this.registerClick()
			},
			reject () {
				this.showModal = false
			},
			async setAcceptedProfiling () {
				if (this.tournament.require_user_profiling && !this.user.accepted_profiling) {
					await UsersService.updateUser({ accepted_profiling: true }, this.user.id)
					// set user accepted profiling to true in localstorage at "currentUser"
					const user = JSON.parse(localStorage.getItem('currentUser'))
					user.accepted_profiling = true
					localStorage.setItem('currentUser', JSON.stringify(user))
				}
			}
		}
	}
</script>

<style lang="scss">
.access_code-input {
	button {
		margin-bottom: 20px;
	}
}
	.tournament-sidebar {
		&__content,
		&__header {
			width: 100%;
			padding: 25px 20px 25px 25px;
			background: $color-gray-600;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}
		&__enter-code {
			.ui-dashed-input {
				margin-top: 15px;
				margin-bottom: 25px;
				&.mb-10 {
					margin-bottom: 10px;
				}
			}
		}
		.tournament-sidebar__enter-code {
			.social-links {
				justify-content: center;
				margin-top: 10px;
				a {
					align-items: center;
					width: 202px;
					display: flex;
					border: 1px solid #505050;
					justify-content: center;
					.ui-icon-base {
						margin-right: 10px;
						transform: none;
						position: static;
					}
				}
			}
		}
		&__content {
			text-align: center;
			margin-top: 10px;

			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: $color-gray-300;

			h4 + p {
				text-transform: uppercase;
				margin-top: 10px;
			}
		}

		&__footer {
			margin-top: 20px;
		}

		.ui-participants-counter {
			justify-content: space-between;

			&__value {
				font-size: 20px;
				font-weight: 700;
				letter-spacing: 0.15px;
			}
		}

		.divider {
			display: inline-block;
			width: 100%;
			height: 1px;
			background: $color-gray-500;
			margin-top: 25px;
			margin-bottom: 15px;
		}

		.ui-button {
			width: 100%;
			margin-top: 10px;
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.button-group {
			display: flex;
			justify-content: space-between;

			.ui-button {
				max-width: calc(50% - 10px);
			}
		}
	}

	@media screen and (max-width: 768px) {
		.tournament-sidebar {
			&__content {
				margin-top: 16px !important;
			}
			&__content, &__header {
				padding: 16px !important;
			}
		}
	}
</style>

<template>
	<div class="team__leaderboard leaderboard-table">
		<div class="leaderboard-table__head">
			<div class="leaderboard-table__head-name">
				Name of the team
			</div>
			<div class="leaderboard-table__head-players">
				Players
			</div>
		</div>
		<div class="leaderboard-table__items">
			<div class="leaderboard-table__items-loader" v-if="!participants.length">
				<UiLoader></UiLoader>
			</div>
			<div class="leaderboard-table__item" v-for="(team,i) in filteredParticipants" :key="i">
				<div class="leaderboard-table__item-main">
					<div class="leaderboard-table__item-main__team"  @click="$router.push(`/team/${team.team.id}`)">
						<UiAvatar :src="team.team.logo" :size="isMobile ? 40 : 50"></UiAvatar>
						<span>{{ team.team.name }}</span>
						<div class="your" v-if="tournament && tournament.participant && tournament.participant.team && tournament.participant.team.id === team.team.id">
							<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.1 4.24286C2.86562 4.24286 3.5 3.62411 3.5 2.82857C3.5 2.05513 2.86562 1.41429 2.1 1.41429C1.3125 1.41429 0.7 2.05513 0.7 2.82857C0.7 3.62411 1.3125 4.24286 2.1 4.24286ZM11.9 4.24286C12.6656 4.24286 13.3 3.62411 13.3 2.82857C13.3 2.05513 12.6656 1.41429 11.9 1.41429C11.1125 1.41429 10.5 2.05513 10.5 2.82857C10.5 3.62411 11.1125 4.24286 11.9 4.24286ZM12.6 4.95H11.2C10.9075 4.95 10.6391 5.04759 10.4128 5.19744C10.2986 5.27306 10.3176 5.44006 10.4267 5.52274C10.8793 5.86548 11.4203 6.51676 11.6667 7.15C11.9169 7.79341 11.7854 8.39793 11.8562 8.87857H13.3C13.6719 8.87857 14 8.5692 14 8.17143V6.36429C14 5.59085 13.3656 4.95 12.6 4.95ZM7 4.95C8.33437 4.95 9.45 3.84509 9.45 2.475C9.45 1.12701 8.33437 0 7 0C5.64375 0 4.55 1.12701 4.55 2.475C4.55 3.84509 5.64375 4.95 7 4.95ZM8.6625 5.65714H8.4875C8.02812 5.87813 7.525 6.01071 7 6.01071C6.45312 6.01071 5.95 5.87813 5.49062 5.65714H5.31562C3.91562 5.65714 2.8 6.80625 2.8 8.22054V9.93929C2.8 10.5359 3.25937 11 3.85 11H10.15C10.7187 11 11.2 10.5359 11.2 9.93929V8.22054C11.2 6.80625 10.0625 5.65714 8.6625 5.65714ZM3.55061 5.52156C3.66031 5.43957 3.67952 5.27312 3.56548 5.19728C3.34031 5.04751 3.07619 4.95 2.8 4.95H1.4C0.6125 4.95 0 5.59085 0 6.36429V8.17143C0 8.5692 0.30625 8.87857 0.7 8.87857H2.12188C2.18986 8.41745 2.09956 7.76999 2.33333 7.15C2.58213 6.49015 3.09586 5.86142 3.55061 5.52156Z" fill="#00E7DF"/>
							</svg>
							<span>Your team</span>
						</div>
					</div>
					<div class="leaderboard-table__item-main__players" @click="setOpen(team.team.id)">
						<UiParticipantsCounter
							:all="team.team.teamMatesCount"
							:amountAvatars="isMobile ? 1 : (team.team.teamMatesCount < 3 ? team.team.teamMatesCount : 3)"
							:with-indicator="false"
						/>
						<img src="@/assets/arrow.svg" :class="{'rotate': open === team.team.id}" class="arrow" alt="">
					</div>
				</div>
				<LeaderboardTablePlayers :id="team.team.id" v-if="open === team.team.id"></LeaderboardTablePlayers>
			</div>
		</div>
	</div>
</template>
<script>
import UiParticipantsCounter from '../../../components/UiParticipantsCounter'
import UiAvatar from '../../../components/UiAvatar'
import LeaderboardTablePlayers from '../../../components/leaderboard/LeaderboardTablePlayers'
import { mapGetters } from 'vuex'
import UiLoader from '../../../components/UiLoader'
export default {
	props: {
		participants: {
			type: Array,
			required: true
		}
	},
	data () {
		return {
			open: null,
			load: false
		}
	},
	methods: {
		setOpen (id) {
			if (this.open === id) {
				this.open = null
				return
			}
			this.open = id
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			isMobile: 'dom/isMobile'
		}),
		filteredParticipants () {
			const participants = [...this.participants]
			if (this.tournament && this.tournament.participant && this.tournament.participant.team) {
				participants.forEach((item, index) => {
					if (item.team.id === this.tournament.participant.team.id) {
						participants.unshift(item)
						participants.splice(index + 1)
						return participants.filter(item => item !== undefined)
					}
				})
			}

			return participants
		}
	},
	mounted () {
	},
	components: {
		UiLoader,
		LeaderboardTablePlayers,
		UiAvatar,
		UiParticipantsCounter
	}
}
</script>
<style scoped lang="scss">
.leaderboard-table {
	background: #2F2F2F;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	.leaderboard-table__head {
		padding: 5px 30px;
		padding-right: 0;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #505050;
		& > div {
			font-size: 10px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: #8F8F8F;
		}
		.leaderboard-table__head-name {
			flex-grow: 1;
			overflow: hidden;
		}
		.leaderboard-table__head-players {
			text-align: center;
			width: 220px;
			min-width: 220px;
		}
	}
	.leaderboard-table__items-loader {
		padding: 20px;
		display: flex;
		justify-content: center;
	}
	.leaderboard-table__items {
		.leaderboard-table__item {
			.leaderboard-table__item-main {
				border-bottom: 1px solid #505050;
				padding-left: 30px;
				display: flex;
				.leaderboard-table__item-main__team {
					flex-grow: 1;
					overflow: hidden;
					padding: 10px 0;
					display: flex;
					border-right: 1px solid #505050;
					align-items: center;
					cursor: pointer;
					position: relative;
					.your {
						padding: 7px 15px;
						position: absolute;
						right: 18px;
						border: 1px solid #505050;
						box-sizing: border-box;
						border-radius: 5px;
						span {
							font-weight: 700;
							font-size: 12px;
							line-height: 20px;
							letter-spacing: 0.25px;
							margin-left: 6px;
						}
					}
					span {
						margin-left: 25px;
						font-weight: bold;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 0.25px;
					}
				}
				.leaderboard-table__item-main__players {
					width: 220px;
					min-width: 220px;
					display: flex;
					align-items: center;
					cursor: pointer;
					justify-content: center;
					.arrow {
						margin-left: 20px;
						margin-right: 10px;
						transition: 0.3s;
						&.rotate {
							transform: rotate(180deg)
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.leaderboard-table {
		.leaderboard-table__head {
			.leaderboard-table__head-score {
				width: 60px;
				min-width: 60px;
			}
			.leaderboard-table__head-players {
				width: 120px;
				min-width: 120px;
			}
		}
		.leaderboard-table__items {
			.leaderboard-table__item {
				.leaderboard-table__item-main {
					padding-left: 10px;
					.leaderboard-table__item-main__players {
						width: 120px;
						min-width: 120px;
						.arrow {
							margin-right: 2px;
							margin-left: 2px;
						}
					}
					.leaderboard-table__item-main__score {
						width: 60px;
						min-width: 60px;
						font-size: 14px;
					}
					.leaderboard-table__item-main__team {
						span {
							font-size: 14px;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
}
</style>

<template>
	<div class="rules" v-if="rule">
		<div class="shell">
			<div class="range">
				<div class="cell-sm-8 order-1">
					<div class="rules__content" v-html="rule.content"></div>
				</div>
				<div class="cell-sm-4 order-0">
					<SidebarContainer/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SidebarContainer from './containers/SidebarContainer'

	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'Rules',
		components: {
			SidebarContainer
		},
		computed: {
			...mapGetters({
				tournament: 'tournaments/getCurrentTournament',
				rule: 'common/getRule'
			})
		},
		methods: {
			...mapActions({
				getRule: 'common/getRule'
			}),
			async init () {
				await this.getRule(this.tournament.ruleId)
			}
		},

		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss">
	.rules {
		&__content {
			border-radius: 5px;
			overflow: hidden;
			text-decoration: none;
			* {
				color: #fff;
			}
			p {
				color: #fff;
			}
		}

		u {
			text-decoration: none;
		}

		#injected-rules-markup {
			padding: 25px;
		}
	}

	@media screen and (max-width: 768px) {
		.rules {
			margin-top: 20px;
			.order-1 {
				order: 1;
			}
			.rules__content {
				margin-top: 16px;
			}
			.order-0 {
				order: 0;
			}
		}
	}

</style>

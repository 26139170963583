export default {
	bind: (el, binding) => {
		const content = el.innerHTML

		const urlRegex = /(https?:\/\/[^\s]+)/g
		el.innerHTML = content.replace(urlRegex, function (url) {
			return '<a href="' + url + '" target="_blank">' + url + '</a>'
		})
	}
}

<template>
	<div class="page add-games">
		<UiPageTopBar
			:title="'Add new games'"
			:withPrevButton="true"
		>
		</UiPageTopBar>
		<div class="shell">
			<div class="add-games__top">
				<div class="add-games__top-input">
					<div class="icon">
						<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15.7812 13.8438L12.6562 10.7188C12.5 10.5938 12.3125 10.5 12.125 10.5H11.625C12.4688 9.40625 13 8.03125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8 13 9.375 12.5 10.5 11.625V12.1562C10.5 12.3438 10.5625 12.5312 10.7188 12.6875L13.8125 15.7812C14.125 16.0938 14.5938 16.0938 14.875 15.7812L15.75 14.9062C16.0625 14.625 16.0625 14.1562 15.7812 13.8438ZM6.5 10.5C4.28125 10.5 2.5 8.71875 2.5 6.5C2.5 4.3125 4.28125 2.5 6.5 2.5C8.6875 2.5 10.5 4.3125 10.5 6.5C10.5 8.71875 8.6875 10.5 6.5 10.5Z" fill="#8F8F8F"/>
						</svg>
					</div>

					<input v-model="search" type="text" placeholder="Search a game">
				</div>
				<div class="add-games__top-platforms">
					<div @click="setCurrentPlatform(2)" :class="{'active': currentPlatform === 2}" class="add-games__top-platform">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.00957 1.52708C4.00047 1.53407 3.99347 1.54177 3.98508 1.54876C4.03615 1.4949 4.10961 1.43684 4.15789 1.40536C5.56694 0.489672 7.19497 0 9.00141 0C10.691 0 12.2736 0.466587 13.6245 1.27804C13.7225 1.3361 13.9898 1.51938 14.1276 1.68587V1.68657C12.8242 0.249733 8.99301 3.33256 8.99301 3.33256C7.66792 2.31125 6.44917 1.61522 5.54175 1.36758C4.78335 1.15982 4.25863 1.33331 4.00957 1.52708ZM15.6038 2.88556C15.5639 2.84149 15.5205 2.80022 15.4807 2.75475C15.1511 2.39309 14.746 2.30565 14.3808 2.32944C14.0485 2.43367 12.5128 2.987 10.6203 4.7736C10.6203 4.7736 12.7507 6.84491 14.0555 8.96239C15.3589 11.0799 16.1383 12.7441 15.6591 15.0539C17.1136 13.4555 18.0007 11.3317 18.0007 8.99946C18.0007 6.63925 17.0919 4.49099 15.6038 2.88556ZM12.3519 9.86688C11.774 9.21842 10.9142 8.28944 9.77869 7.1604C9.53033 6.91346 9.26797 6.65603 8.99231 6.38951C8.99231 6.38951 8.57743 6.80434 8.03732 7.34997V7.34927C7.34609 8.0474 6.44987 8.95819 5.95243 9.48983C5.0667 10.4349 2.52915 13.403 2.38643 15.0672C2.38643 15.0672 1.82183 13.7472 3.06087 10.7028C3.87034 8.71266 6.31624 5.72426 7.33769 4.75051C7.33769 4.75051 6.40439 3.7236 5.23321 3.01428L5.22622 3.01218C5.22622 3.01218 5.21292 3.00169 5.19194 2.9884C4.62874 2.65262 4.01376 2.39379 3.42188 2.35952C2.8174 2.40219 2.4347 2.84429 2.4347 2.84429C0.924208 4.45461 0 6.61896 0 9.00016C0 13.9703 4.02985 18.0003 9.00141 18.0003C11.6397 18.0003 14.0135 16.8629 15.6605 15.0546C15.6598 15.0518 15.4709 13.8654 14.2598 12.1634C13.9751 11.7647 12.9347 10.5202 12.3519 9.86688Z" fill="#8F8F8F"/>
						</svg>
					</div>
					<div @click="setCurrentPlatform(1)" :class="{'active': currentPlatform === 1}" class="add-games__top-platform">
						<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2.19297 10.752L1.51836 11.0232C0.506443 11.4687 -0.0182543 11.9336 0.000484922 12.3404C0.0567024 12.9409 0.712574 13.3865 1.91188 13.7158C3.46723 14.1419 5.04132 14.2388 6.67163 13.987V12.3017L5.37863 12.8053C4.02941 13.2896 3.33606 12.8828 3.33606 12.8828C3.12993 12.7472 3.03623 12.4179 3.59841 12.2048L4.31049 11.9336L6.67163 11.0619V9.10547L6.07198 9.31855L2.19297 10.752Z" fill="#8F8F8F"/>
							<path d="M12.0502 3.95121V8.96831C13.0622 9.47195 13.9616 9.5107 14.6363 8.98768C15.3296 8.48403 15.7044 7.61233 15.7044 6.2951C15.7044 4.91976 15.442 3.87372 14.8611 3.17636C14.3364 2.44026 13.3995 1.82039 12.0128 1.33611C10.2888 0.754983 8.82711 0.251335 7.66528 -0.000488281V15.0121L10.7947 16V3.52504C10.776 2.76957 12.0502 2.82768 12.0502 3.95121Z" fill="#8F8F8F"/>
							<path d="M19.9957 11.6985C19.9769 11.1754 19.3773 10.7686 18.3092 10.4006C17.1098 9.99381 16.0792 9.8001 14.9548 9.83884C13.9242 9.85821 12.6874 10.2069 11.5818 10.5749V12.4927L14.6175 11.3885C14.6175 11.3885 15.5358 11.0398 16.3228 11.2529C16.9225 11.4079 16.885 11.7566 16.2853 11.9697L15.592 12.2602L11.5818 13.7518V15.7083L13.4557 15.0109L17.9531 13.3644L18.4778 13.1125C19.5647 12.7058 20.0519 12.2602 19.9957 11.6985Z" fill="#8F8F8F"/>
						</svg>

					</div>
					<div @click="setCurrentPlatform(3)" :class="{'active': currentPlatform === 3}" class="add-games__top-platform">
						<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="6.31567" y="15.8818" width="2.10523" height="1.05882" fill="#8F8F8F"/>
							<rect x="4.21069" y="16.9409" width="6.3157" height="1.05882" rx="0.529412" fill="#8F8F8F"/>
							<path d="M14.0001 6.99976H1.00024C0.447959 6.99976 0.000244141 7.44747 0.000244141 7.99976V14.9998C0.000244141 15.552 0.44796 15.9998 1.00024 15.9998H14.0001C14.5523 15.9998 15.0001 15.552 15.0001 14.9998V7.99976C15.0001 7.44747 14.5523 6.99976 14.0001 6.99976Z" fill="#8F8F8F"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99976 1V6H14.9997C15.552 6 15.9997 6.44771 15.9997 7V16C15.9997 16.5523 15.552 17 14.9997 17H12.4997C12.2236 17 11.9997 17.2239 11.9997 17.5C11.9997 17.7761 12.2236 18 12.4997 18H18.9996C19.5519 18 19.9996 17.5523 19.9996 17V1C19.9996 0.447715 19.5519 0 18.9996 0H10.9998C10.4475 0 9.99976 0.447715 9.99976 1ZM11.9999 2.49995C11.9999 2.2238 12.2237 1.99995 12.4999 1.99995H17.4998C17.7759 1.99995 17.9998 2.2238 17.9998 2.49995C17.9998 2.77609 17.7759 2.99995 17.4998 2.99995H12.4999C12.2237 2.99995 11.9999 2.77609 11.9999 2.49995ZM12.4999 4.00014C12.2237 4.00014 11.9999 4.22399 11.9999 4.50014C11.9999 4.77628 12.2237 5.00014 12.4999 5.00014H17.4998C17.7759 5.00014 17.9998 4.77628 17.9998 4.50014C17.9998 4.22399 17.7759 4.00014 17.4998 4.00014H12.4999Z" fill="#8F8F8F"/>
						</svg>

					</div>
					<div @click="setCurrentPlatform(4)" :class="{'active': currentPlatform === 4}" class="add-games__top-platform">
						<svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1V19C10 19.5523 9.55229 20 9 20H1C0.447715 20 0 19.5523 0 19V1ZM2.5 1.875C2.5 1.52982 2.77982 1.25 3.125 1.25H6.875C7.22018 1.25 7.5 1.52982 7.5 1.875C7.5 2.22018 7.22018 2.5 6.875 2.5H3.125C2.77982 2.5 2.5 2.22018 2.5 1.875ZM5 16.25C4.30964 16.25 3.75 16.8096 3.75 17.5C3.75 18.1904 4.30964 18.75 5 18.75C5.69036 18.75 6.25 18.1904 6.25 17.5C6.25 16.8096 5.69036 16.25 5 16.25Z" fill="#8F8F8F"/>
						</svg>

					</div>
				</div>
			</div>
				<UiNotFoundBlock text="No results were found for your search" v-if="!filterGames.length"></UiNotFoundBlock>
				<div class="add-games__list" v-else>
					<div class="add-games__list-item" @click="openGameId = item.id" v-for="(item,i) in filterGames" :key="i" :style="{'background-image': `url(${item.web_image})`}">
						<div class="add-games__list-item__circle">
							<img :src="item.web_icon" alt="">
						</div>
						<div class="add-games__list-item__title">
							{{ item.name }}
						</div>
					</div>
				</div>
		</div>
		<transition name="modal">
			<AddGame @close="openGameId = null" v-if="openGameId" :game_id="openGameId"></AddGame>
		</transition>
	</div>
</template>
<script>
import UiPageTopBar from '@/components/UiPageTopBar'
import { GamesService } from '../../services/games.service'
import UiNotFoundBlock from '../../components/UiNotFoundBlock'
import AddGame from '../../components/games/modals/AddGame'
export default {
	data () {
		return {
			openGameId: null,
			games: [],
			search: '',
			currentPlatform: null
		}
	},
	components: {
		UiPageTopBar,
		UiNotFoundBlock,
		AddGame
	},
	computed: {
		filterGames () {
			var games = this.games.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()))
			if (this.currentPlatform) {
				return games.filter(item => item.platforms.includes(this.currentPlatform))
			}
			return games
		}
	},
	methods: {
		getGames () {
			GamesService.getGames().then(response => {
				this.games = response.data
			})
		},
		setCurrentPlatform (platformId) {
			platformId === this.currentPlatform ? this.currentPlatform = null : this.currentPlatform = platformId
		}
	},
	mounted	() {
		this.amplitude.logEvent('screen_view', {
			screen_name: 'Game List'
		})
		this.getGames()
	}
}
</script>
<style scoped lang="scss">
.shell {
	width: 774px;
}
.add-games__top {
	margin-top: 40px;
	margin-bottom: 20px;
		display: flex;
		.add-games__top-platforms {
			display: flex;
			.add-games__top-platform {
				width: 50px;
				height: 50px;
				background: #2F2F2F;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #505050;
				box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
				border-radius: 5px;
				cursor: pointer;
				transition: 0.2s;
				&.active, &:hover {
					border: 1px solid #00E7DF;
					svg {
						path {
							fill: #00E7DF
						}
					}
				}
			}
			.add-games__top-platform + .add-games__top-platform {
				margin-left: 26px;
			}
		}
	.add-games__top-input {
		flex-grow: 1;
		overflow: hidden;
		margin-right: 20px;
		background: #2F2F2F;
		border: 1px solid #505050;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		input {
			flex-grow: 1;
			outline: none;
			border: none;
			background: transparent;
			font-size: 10px;
			line-height: 20px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #FFFFFF;
			&::placeholder {
				color: #FFFFFF;
			}
		}
		.icon {
			width: 16px;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.add-games__list {
	padding-bottom: 55px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	.add-games__list-item {
		border-radius: 5px;
		cursor: pointer;
		overflow: hidden;
		position: relative;
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.7) 100%);
		}
		background-size: 100% auto;
		background-position: center center;
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		.add-games__list-item__circle {
			width: 80px;
			height: 80px;
			background: rgba(18, 18, 18, 0.6);
			position: relative;
			z-index: 99;
			border: 1px solid #505050;
			box-sizing: border-box;
			backdrop-filter: blur(5px);
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				max-width: 100%;
			}
			border-radius: 5px;
		}
		.add-games__list-item__title {
			position: absolute;
			bottom: 20px;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.15px;
			z-index: 99;
		}
	}
}

@media screen and (max-width: 992px) {
	.shell {
		max-width: 100%;
		width: 750px;
	}
}
@media screen and (max-width: 575px) {
	.add-games__top {
		flex-wrap: wrap;
		margin-top: 20px;
		.add-games__top-input {
			width: 100%;
			height: 50px;
			margin-right: 0;
			margin-bottom: 15px;
		}
		.add-games__top-platforms {
			width: 100%;
			justify-content: flex-end;
		}
	}
	.add-games__list {
		grid-gap: 10px;
		.add-games__list-item {
			height: 210px;
		}
	}
}
</style>

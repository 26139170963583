<template>
	<form @submit.prevent="send" class="input-message">
		<input type="text" minlength="2" v-model="message.message" placeholder="Message">
<!--		<input type="file" id="attached" name="attached">-->
<!--		<label for="attached">-->
<!--			<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--				<path d="M20.1106 7.64791C19.768 7.30711 19.214 7.30862 18.8732 7.65124L8.35454 18.2267C6.98988 19.5914 4.77347 19.5914 3.40626 18.2249C2.04008 16.858 2.04008 14.6416 3.40643 13.2753L14.2364 2.3884C15.0884 1.53644 16.4736 1.53644 17.328 2.39024C18.1822 3.24445 18.1822 4.62946 17.3278 5.48392L8.35618 14.4555C8.35561 14.4561 8.35512 14.4567 8.35454 14.4573C8.01275 14.7972 7.46038 14.7967 7.11929 14.4556C6.77762 14.114 6.77762 13.5602 7.11929 13.2185L11.4498 8.88711C11.7915 8.54536 11.7914 7.9913 11.4497 7.64964C11.1079 7.30797 10.5539 7.30801 10.2122 7.64976L5.88174 11.9811C4.85669 13.0062 4.85669 14.668 5.88182 15.6931C6.90691 16.7182 8.56873 16.7182 9.59386 15.6931C9.59505 15.6919 9.59599 15.6906 9.59714 15.6894L18.5653 6.72131C20.1031 5.18343 20.1031 2.69036 18.5653 1.15249C17.0272 -0.384487 14.5343 -0.384487 12.9973 1.15249L2.16732 12.0394C0.119194 14.0876 0.119194 17.4117 2.16879 19.4624C4.21974 21.5123 7.54397 21.5123 9.5937 19.4625L20.114 8.88538C20.4548 8.54273 20.4532 7.98872 20.1106 7.64791Z" fill="#00E7DF"/>-->
<!--			</svg>-->
<!--		</label>-->
		<button type="submit">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M22.1046 10.1995L2.51647 1.16115C1.66624 0.768835 0.791436 1.65562 1.19528 2.50043L4.42912 9.26535C4.58577 9.59305 4.90778 9.81004 5.27032 9.8322L15.6928 10.4693C15.9751 10.4865 16.1951 10.7205 16.1951 11.0032C16.1951 11.2833 15.979 11.516 15.6997 11.5367L5.28249 12.3075C4.91355 12.3348 4.58983 12.5635 4.4409 12.9022L1.11097 20.4738C0.734557 21.3297 1.63402 22.188 2.47136 21.7719L22.1306 12.003C22.8826 11.6293 22.867 10.5513 22.1046 10.1995Z" fill="#00E7DF"/>
			</svg>
		</button>
	</form>
</template>
<script>
import { ChatService } from '@/services/chat.service'
import { TeamsService } from '../../services/teams.service'
import { mapGetters } from 'vuex'
import Message from '../../models/Message'
import Moment from 'moment'
export default {
	props: {
		team: {
			type: Object || null,
			required: false,
			default: null
		}
	},
	data () {
		return {
			message: {
				message: null,
				image: null
			}
		}
	},
	computed: {
		...mapGetters({
			activeChat: 'chat/activeChat',
			currentUser: 'user/getCurrentUser'
		})
	},
	methods: {
		send () {
			if (!this.message.message && !this.message.image) return
			const message = new Message({
				message: this.message.message,
				nickname: this.currentUser.nickname,
				image: this.currentUser.avatar,
				user_id: this.currentUser.id,
				created_at: new Moment()
			})
			this.$emit('sent', message)
			const data = JSON.parse(JSON.stringify(this.message))
			if (!data.image) {
				delete data.image
			}
			this.message.message = null
			if (!this.team) {
				ChatService.sendMessage({ tournamentId: this.activeChat.tournamentId, roundId: this.activeChat.id, message: data })
			} else {
				TeamsService.sendMessage({ roomId: this.team.chat_room, message: data })
			}
		}
	}
}
</script>
<style scoped lang="scss">
	.input-message {
		padding: 9px 25px 15px;
		display: flex;
		grid-gap: 10px;
		input[type="file"] {
			display: none;
		}
		input {
			flex-grow: 1;
			background: #2F2F2F;
			border: 1px solid #505050;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			padding: 14px;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: $primary-color;
			outline: none;
			&::placeholder {
				color: #8F8F8F;
			}
			overflow: hidden;
		}
		button, label {
			background: #2F2F2F;
			border: 1px solid #505050;
			display: flex;
			&:hover {
				opacity: 0.8;
			}
			transition: 0.2s;
			align-items: center;
			justify-content: center;
			width: 48px;
			outline: none;
			cursor: pointer;
			min-width: 48px;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}
	}
</style>

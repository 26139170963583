import BaseModel from '../BaseModel'

export default class Match extends BaseModel {
	get schema () {
		return {
			chatRoom: undefined,
			duration: undefined,
			gamesInRound: undefined,
			id: undefined,
			roomId: undefined,
			round: undefined,
			startedAt: undefined,
			status: undefined,
			roomRoundsCount: undefined,
			winnerId: undefined,
			results: undefined,
			firstParticipant: undefined,
			secondParticipant: undefined
		}
	}

	getStatus () {
		return this.status
	}

	getFirstParticipant () {
		return this.firstParticipant
	}

	getSecondParticipant () {
		return this.secondParticipant
	}

	prepareCreate () {
		this.baseClear()

		return this
	}

	prepareUpdate () {
		this.baseClear()

		return this
	}
}

<template>
	<div class="ui-platforms-list component">
		<div class="ui-platforms-list__item" v-for="item in items" :key="item.id">
			<UiBaseIcon
				class="ui-platforms-list__item__icon"
				width="20px"
				height="20px"
				:iconName="item.icon"
			/>
		</div>
	</div>
</template>

<script>
	import UiBaseIcon from './icons/UiBaseIcon'

	export default {
		name: 'UiPlatformsList',
		props: {
			platforms: {
				type: Array
			}
		},
		components: {
			UiBaseIcon
		},
		data () {
			return {
				platformsIcons: {
					1: 'platform-playstation',
					2: 'platform-xbox',
					3: 'platform-pc',
					4: 'platform-mobile'
				}
			}
		},

		computed: {
			items () {
				const items = []

				this.platforms.map(item => {
					items.push({
						id: item,
						icon: this.platformsIcons[item]
					})
				})
				return items
			}
		}
	}
</script>

<style lang="scss" scoped>
	.ui-platforms-list {
		display: flex;
		line-height: 0;

		&__item {
			color: $color-gray-400;
			+ * {
				margin-left: 10px;
			}
		}
	}
</style>

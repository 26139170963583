import Match from '../../../models/round_robin/Match'
import ParticipantModel from '../../../models/ParticipantModel'
import RoomParticipant from '../../../models/round_robin/RoomParticipant'
export function prepare (match) {
	const matchInstance = new Match(match)
	const firstParticipantModel = new RoomParticipant(matchInstance.firstParticipant)
	const secondParticipantModel = new RoomParticipant(matchInstance.secondParticipant)
	firstParticipantModel.participant = new ParticipantModel(firstParticipantModel.participant)
	secondParticipantModel.participant = new ParticipantModel(secondParticipantModel.participant)

	matchInstance.firstParticipant = firstParticipantModel
	matchInstance.secondParticipant = secondParticipantModel
	return matchInstance
}

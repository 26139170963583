<template>
	<div class="tournament page" v-if="Object.keys(tournament).length">
		<UiPageTopBar
			:backgroundImgUrl="currentGame.image"
			:title="tournament.title"
			v-if="currentGame && tournament"
			:withPrevButton="true"
		>
			<div class="status-badge">
				{{ tournament.status.replace('_', ' ') }}
			</div>
		</UiPageTopBar>
		<div class="menu-wrapper" v-if="hasNavigation">
			<ul class="menu">
				<li>
					<RouterLink :to="{ name: 'round' }" exact>{{ $t('Round 1') }}</RouterLink>
				</li>
			</ul>
		</div>
		<transition>
			<div class="match-result">
				<div class="shell">
					<div class="range">
						<div class="cell-sm-8">
							<div class="results-wrapper">
								<div class="results">
									<div class="results__item">
										<UiAvatar :src="''"/>
										<span class="results__item__nickname">mynickname</span>
										<span class="results__item__id">PSN 7643</span>
										<div class="results__item__input">
											<label for="result-input-1">
												<input
													id="result-input-1"
													type="number"
													min="0"
													max="9999"
													:value="results[0]"
													@change="resultChanged"
												>
											</label>
										</div>
									</div>
									<div class="results__item">
										<UiAvatar :src="''"/>
										<span class="results__item__nickname">mynickname</span>
										<span class="results__item__id">PSN 7643</span>
										<div class="results__item__input">
											<label for="result-input-2">
												<input
													id="result-input-2"
													type="number"
													min="0"
													max="9999"
													:value="results[1]"
													@change="resultChanged"
												>
											</label>
										</div>
									</div>
									<div class="results__middle-icon">
										<UiBaseIcon
											iconName="battle"
											width="22px"
											height="22px"
										/>
									</div>
								</div>
								<div class="results-button">
									<UiButton
										@click="sendResult()"
									>
										{{ $t('Send result') }}
									</UiButton>
								</div>
							</div>
							<div class="results-proofs">
								<div class="results-proofs__description">
									<h5>{{ $t('Proof your score') }}</h5>
									<p>{{ $t('You can take a picture of your result screen and send it to us to proove your result.') }}</p>
								</div>
								<div class="results-proofs__images">
									<UiUploadMulti
										entityId="1"
									/>
								</div>
							</div>
						</div>
						<div class="cell-sm-4">test</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex'

	import UiPageTopBar from '@/components/UiPageTopBar'
	import UiButton from '@/components/UiButton'
	import UiAvatar from '@/components/UiAvatar'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'
	import UiUploadMulti from '@/components/UiUploadMulti'

	export default {
		name: 'Tournament',
		props: {
			id: {
				type: [Number, String]
			}
		},
		data () {
			return {
				results: [0, 0],
				showNavigation: true
			}
		},
		components: {
			UiPageTopBar,
			UiButton,
			UiAvatar,
			UiBaseIcon,
			UiUploadMulti
		},
		watch: {
		},
		computed: {
			...mapGetters({
				currentGame: 'user/getCurrentGame',
				tournament: 'tournaments/getCurrentTournament'
			}),
			isLeaderBoard () {
				return Number(this.tournament.type) === 2
			},
			hasNavigation () {
				const hasNavigation = this.$route.meta.withPageNavigation
				return hasNavigation !== undefined ? hasNavigation : true
			}
		},
		methods: {
			...mapActions({
				getTournament: 'tournaments/getTournament'
			}),
			async init () {
				await this.getTournament(this.id)
			},
			sendResult () {

			},
			resultChanged () {

			}
		},
		mounted () {
			this.init()
		}
	}
</script>

<style lang="scss" scoped>
	.tournament {
		padding-bottom: 30px;
	}

	.status-badge {
		font-weight: 700;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: $color-gray-700;

		background: $primary-color;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
		border-radius: 4px;
		padding: 10px 15px;

		margin-right: 40px;
	}

	.menu {
		display: flex;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		padding-left: 40px;
		padding-right: 40px;
		border-bottom: 1px solid $color-gray-600;
		margin-bottom: 40px;

		a {
			@include navigation-item;
			font-weight: 700;
		}
	}

	.results-wrapper {
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 20px;

		.results {
			position: relative;
			display: flex;
			justify-content: space-between;

			&__item {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border: 1px solid $primary-color;
				border-radius: 5px;
				padding: 30px;
				z-index: 2;
				width: 100%;
				max-width: calc(50% - 10px);

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					border: 1px solid $primary-color;
					box-sizing: border-box;
					filter: blur(4px);
					border-radius: 5px;
					z-index: -1;
				}

				&__nickname{
					font-size: 18px;
					line-height: 20px;
					text-align: center;
					letter-spacing: 0.25px;

					color: $color-white;
					margin-top: 16px;
				}

				&__id {
					font-size: 10px;
					line-height: 16px;
					letter-spacing: 0.4px;
					text-transform: uppercase;
					color: $primary-color;
					margin-top: 10px;
				}

				&__input {
					margin-top: 25px;
				}

				input {
					appearance: none;
					-moz-appearance: none;
					-webkit-appearance: none;
					background: $color-gray-600;
					border: 1px solid $color-gray-500;
					border-radius: 5px;
					font-size: 36px;
					line-height: 62px;
					letter-spacing: -0.5px;
					color: $color-gray-400;
					text-align: center;

					&:focus {
						outline: none;
					}
				}

				input[type='number']::-webkit-inner-spin-button,
				input[type='number']::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}

			&__middle-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 60px;
				height: 60px;
				background: $color-gray-600;
				border: 1px solid $primary-color;
				box-sizing: border-box;
				border-radius: 50%;
				z-index: 2;

				.ui-icon-base {
					color: $primary-color;
				}
			}

			&-button {
				margin-top: 20px;

				.ui-button {
					width: 100%;
					padding: 15px;
				}
			}
		}

		.avatar {
			max-width: 66px;
			min-width: 66px;
			min-height: 66px;
		}
	}

	.results-proofs {
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 25px;
		margin-top: 20px;

		&__description {
			p {
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.25px;
				color: $color-white;
				margin-top: 10px;
			}
		}
	}
</style>

<template>
	<div class="news page">
		<UiPageTopBar
			:backgroundImgUrl="null"
			:title="'News'"
			:with-prev-button="true" />
		<div class="shell">
			<div class="range news__wrapper">
				<div class="cell-md-4" v-for="(item, i) in news" :key="i">
					<div class="news__item">
						<router-link :to="{ name: 'news.detail', params: { id: item.id } }" class="news__item-picture">
							<img :src="item.image" alt="">
						</router-link>
						<div class="news__item-description">
							<router-link :to="{ name: 'news.detail', params: { id: item.id } }">{{ item.title }}</router-link>
							<p>{{ truncate(item.description ? item.description : item.content, 110, '...') }}</p>
							<div class="news__item-description__info">
								<div class="news__item-description__info-date">
									<span>{{ item.created_at | formatMonthDayYear }}</span>
									<div class="separator"></div>
									<em>By {{ item.created_by.name }}</em>
								</div>
								<div @click.stop="openShare(item.id)" class="news__item-description__info-share">
									<div class="icon">
										<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.42857 7.5C8.8125 7.5 8.25 7.6875 7.82143 7.99219L5.0625 6.49219C5.11607 6.35156 5.11607 6.1875 5.11607 6C5.11607 5.83594 5.11607 5.67188 5.0625 5.53125L7.82143 4.03125C8.25 4.33594 8.8125 4.5 9.42857 4.5C10.8482 4.5 12 3.51562 12 2.25C12 1.00781 10.8482 0 9.42857 0C7.98214 0 6.85714 1.00781 6.85714 2.25C6.85714 2.4375 6.85714 2.60156 6.91071 2.74219L4.15179 4.24219C3.72321 3.9375 3.16071 3.75 2.57143 3.75C1.125 3.75 0 4.75781 0 6C0 7.26562 1.125 8.25 2.57143 8.25C3.16071 8.25 3.72321 8.08594 4.15179 7.78125L6.91071 9.28125C6.85714 9.42188 6.85714 9.58594 6.85714 9.77344V9.75C6.85714 11.0156 7.98214 12 9.42857 12C10.8482 12 12 11.0156 12 9.75C12 8.50781 10.8482 7.5 9.42857 7.5Z" fill="#00E7DF"/>
										</svg>
									</div>
									<transition name="fade">
										<div v-show="openShareBlockId === item.id" class="news__item-description__info-share-content">
											<a @click.stop.prevent="copyLink(item.id)">
											<span class="pre-icon">
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8.92962 5.07038C8.7654 4.90616 8.5738 4.74194 8.38221 4.60508C8.19062 4.46823 7.97165 4.4956 7.80743 4.63245L7.23265 5.20723C7.01369 5.45357 6.9042 5.75464 6.9042 6.02835C6.9042 6.19257 6.98631 6.30205 7.0958 6.38416C7.17791 6.43891 7.28739 6.52102 7.39687 6.60313C8.16325 7.39687 8.16325 8.62854 7.39687 9.39492L5.53568 11.2287C4.76931 12.0225 3.51026 12.0225 2.74389 11.2287C1.97752 10.4624 2.00489 9.20332 2.77126 8.43695L3.12708 8.08113C3.26393 7.97165 3.2913 7.78006 3.23656 7.61584C3.07234 7.15054 2.99022 6.65787 2.96285 6.19257C2.96285 5.80938 2.49756 5.61779 2.22385 5.8915L1.21114 6.9042C-0.403715 8.51906 -0.403715 11.174 1.21114 12.7889C2.826 14.4037 5.48094 14.4037 7.0958 12.7889L8.92962 10.955C8.92962 10.955 8.92962 10.955 8.92962 10.9277C10.5445 9.34018 10.5718 6.71261 8.92962 5.07038ZM12.7889 1.21114C11.174 -0.403715 8.51906 -0.403715 6.9042 1.21114L5.07038 3.04497C5.07038 3.04497 5.07038 3.04497 5.07038 3.07234C3.45552 4.65982 3.42815 7.28739 5.07038 8.92962C5.2346 9.09384 5.4262 9.25806 5.61779 9.39492C5.80938 9.53177 6.02835 9.5044 6.19257 9.36755L6.76735 8.79277C6.98631 8.54643 7.0958 8.24536 7.0958 7.97165C7.0958 7.80743 7.01369 7.69795 6.9042 7.61584C6.82209 7.56109 6.71261 7.47898 6.60313 7.39687C5.83675 6.60313 5.83675 5.37146 6.60313 4.60508L8.46432 2.77126C9.23069 1.97752 10.4897 1.97752 11.2561 2.77126C12.0225 3.53763 11.9951 4.79668 11.2287 5.56305L10.8729 5.91887C10.7361 6.02835 10.7087 6.21994 10.7634 6.38416C10.9277 6.84946 11.0098 7.34213 11.0371 7.80743C11.0371 8.19062 11.5024 8.38221 11.7761 8.1085L12.7889 7.0958C14.4037 5.48094 14.4037 2.826 12.7889 1.21114Z" fill="#00E7DF"/>
												</svg>
											</span>
												Share link
											</a>
											<a target="_blank" :href="`https://www.facebook.com/sharer/sharer.php?u=${currentDomain}/news/${item.id}`">
											<span class="pre-icon">
												<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M7.47636 7.875L7.88364 5.35938H5.29455V3.71875C5.29455 3.00781 5.64364 2.35156 6.80727 2.35156H8V0.191406C8 0.191406 6.92364 0 5.90545 0C3.78182 0 2.38545 1.23047 2.38545 3.41797V5.35938H0V7.875H2.38545V14H5.29455V7.875H7.47636Z" fill="#00E7DF"/>
											</svg>
											</span>

												Facebook
											</a>
											<a target="_blank" :href="`https://twitter.com/share?url=${currentDomain}/news/${item.id}`">
											<span class="pre-icon">
												<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M13.4736 2.97831C14.0607 2.54458 14.589 2.0241 15 1.41687C14.4716 1.64819 13.8552 1.82169 13.2387 1.87952C13.8845 1.50361 14.3542 0.925301 14.589 0.20241C14.002 0.549398 13.3268 0.809639 12.6517 0.954217C12.0646 0.346988 11.272 0 10.3914 0C8.68885 0 7.3092 1.35904 7.3092 3.03614C7.3092 3.26747 7.33855 3.4988 7.39726 3.73012C4.84344 3.58554 2.55382 2.37108 1.0274 0.549398C0.763209 0.983133 0.616438 1.50361 0.616438 2.08193C0.616438 3.12289 1.14481 4.04819 1.99609 4.59759C1.49706 4.56868 0.998043 4.45301 0.587084 4.22169V4.2506C0.587084 5.7253 1.64384 6.93976 3.05284 7.22892C2.818 7.28675 2.52446 7.34458 2.26027 7.34458C2.05479 7.34458 1.87867 7.31566 1.67319 7.28675C2.05479 8.50121 3.19961 9.36868 4.5499 9.39759C3.49315 10.2072 2.17221 10.6988 0.733855 10.6988C0.469667 10.6988 0.234834 10.6699 0 10.641C1.35029 11.5084 2.96478 12 4.72603 12C10.3914 12 13.4736 7.40241 13.4736 3.38313C13.4736 3.23855 13.4736 3.12289 13.4736 2.97831Z" fill="#00E7DF"/>
												</svg>
											</span>

												Twitter
											</a>
										</div>
									</transition>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { NewsService } from '@/services/news.service'
import UiPageTopBar from '../../components/UiPageTopBar'
import formatMonthDayYear from '@/filters/formatMonthDayYear'
import { mapMutations } from 'vuex'

export default {
	data () {
		return {
			news: [],
			currentPage: 0,
			limit: 15,
			lastPage: -1,
			openShareBlockId: null,
			disableScrollEvent: false
		}
	},
	filters: {
		formatMonthDayYear
	},
	components: {
		UiPageTopBar
	},
	computed: {
		currentDomain () {
			return window.location.origin
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		copyLink (id) {
			navigator.clipboard.writeText(`${this.currentDomain}/news/${id}`).then(() => {
				this.toast({ type: 'success', message: 'The link was successfully copied to the clipboard' })
			}, () => {
				this.toast({ type: 'error', message: 'Your browser does not support the copy feature' })
			})
		},
		openShare (id) {
			if (this.openShareBlockId === id) {
				this.openShareBlockId = null
			} else {
				this.openShareBlockId = id
			}
		},
		truncate (str, max, suffix) {
			return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`
		},
		async getNews () {
			this.currentPage++
			await NewsService.getNews({
				limit: this.limit,
				page: this.currentPage,
				games: this.$currentGame.id,
				withGeneral: true
			}).then(response => {
				const news = [...this.news]
				news.push(...response.data.data)
				this.$set(this, 'news', news)
				this.lastPage = response.data.meta.last_page
			})
		},
		async init () {
			this.amplitude.logEvent('screen_view', {
				screen_name: 'News'
			})
			await this.getNews()
			await this.getNextPosts()
		},
		getNextPosts () {
			window.onscroll = async () => {
				const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
				if (bottomOfWindow) {
					if (!this.disableScrollEvent && this.currentPage < this.lastPage) {
						this.disableScrollEvent = true
						await this.getNews()
						this.disableScrollEvent = false
					}
				}
			}
		}
	},
	mounted () {
		this.init()
		document.addEventListener('click', () => {
			this.openShareBlockId = null
		})
	}
}
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
.news {
	.news__wrapper {
		margin-top: 20px;
	}
	.news__item {
		margin-bottom: 20px;
		width: 100%;
		background: #2F2F2F;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		.news__item-picture {
			height: 180px;
			display: block;
			width: 100%;
			overflow: hidden;
			img {
				min-height: 100%;
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				max-width: 100%;
			}
		}
		.news__item-description {
			* {
				font-family: Montserrat;
			}
			padding: 10px 15px;
			padding-bottom: 15px;
			& > a {
				font-weight: bold;
				text-decoration: none;
				color: #fff;
				font-size: 16px;
				max-height: 48px;
				min-height: 48px;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 24px;
				display: block;
				width: 100%;
				letter-spacing: 0.15px;
				margin-bottom: 10px;
			}
			p {
				display: block;
				width: 100%;
				font-size: 12px;
				line-height: 20px;
				min-height: 60px;
				max-height: 60px;
				align-items: center;
				letter-spacing: 1px;
				overflow: hidden;
				text-overflow: ellipsis;

			}
			.news__item-description__info {
				display: flex;
				margin-top: 5px;
				align-items: flex-end;
				justify-content: space-between;
				.news__item-description__info-date {
					display: flex;
					align-items: center;
					span {
						font-size: 10px;
						line-height: 20px;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: #BDBDBD;
					}
					.separator {
						width: 4px;
						background: #8F8F8F;
						height: 4px;
						border-radius: 100%;
						margin: 0 10px;
					}
					em {
						font-size: 10px;
						line-height: 20px;
						letter-spacing: 2px;
						color: #00E7DF;
					}
				}
				.news__item-description__info-share {
					width: 32px;
					height: 32px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #2F2F2F;
					border: 1px solid #00E7DF;
					box-sizing: border-box;
					border-radius: 4px;
					transition: 0.2s;
					position: relative;
					&:hover {
						box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
					}
					.icon {
						display: flex;
						align-items: center;
						justify-content: center;

					}
					.news__item-description__info-share-content {
						width: 194px;
						right: 0;
						position: absolute;
						background: #2F2F2F;
						top: calc(100% + 4px);
						padding: 20px 25px;
						border: 1px solid #00E7DF;
						box-sizing: border-box;
						box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
						border-radius: 4px;
						a {
							text-decoration: none;
							width: 100%;
							display: flex;
							align-items: center;
							.pre-icon {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 15px;
								margin-right: 12px;
							}
							font-size: 14px;
							line-height: 24px;
							letter-spacing: 0.15px;
							text-transform: uppercase;
							color: #BDBDBD;
							transition: 0.2s;
							&:hover {
								color: #fff;
							}
						}
						a + a {
							margin-top: 14px;
						}
					}
				}
			}

		}
	}
}

</style>

<template>
	<div class="deposit-coins page">
		<div class="wrapper">
			<UiPageTopBar
				:backgroundImgUrl="null"
				:title="'Qlash coins'"
				:with-prev-button="true"
			>
				<template v-slot>
					<UiButton type="secondary" class="withdraw-button">
						<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 3.34375C1 3.72656 1.27344 4 1.65625 4H11.5V5.3125C11.5 5.91406 12.1836 6.1875 12.5938 5.77734L14.7812 3.58984C15.0547 3.34375 15.0547 2.93359 14.7812 2.6875L12.5938 0.5C12.1836 0.0625 11.5 0.363281 11.5 0.9375V2.25H1.65625C1.27344 2.25 1 2.55078 1 2.90625V3.34375ZM14.3438 7.5H4.5V6.1875C4.5 5.61328 3.78906 5.3125 3.37891 5.75L1.19141 7.9375C0.917969 8.18359 0.917969 8.59375 1.19141 8.83984L3.37891 11.0273C3.78906 11.4375 4.5 11.1641 4.5 10.5625V9.25H14.3438C14.6992 9.25 15 8.97656 15 8.59375V8.15625C15 7.80078 14.6992 7.5 14.3438 7.5Z" fill="#00E7DF"/>
						</svg>

						withdraw
					</UiButton>
				</template>
			</UiPageTopBar>

			<div class="shell coins-wrapper">
				<div class="range">
					<div class="cell-sm-4">
						<div class="coins-item">
							<div class="coins-item__match">
								<div class="coins-item__match-icon">
									<img src="/images/deposit/coin.svg" alt="">
								</div>
								<div class="coins-item__match-value">
									<strong>100</strong>
								</div>
							</div>
							<div class="coins-item__picture">
								<img src="/images/deposit/coin1.png" alt="">
							</div>
							<UiButton @click="pay(999)" type="primary" class="coins-item__button">
								<strong>9</strong>
								<span>.99 euro</span>
							</UiButton>
						</div>
					</div>
					<div class="cell-sm-4">
						<div class="coins-item">
							<div class="coins-item__match">
								<div class="coins-item__match-icon">
									<img src="/images/deposit/coin.svg" alt="">
								</div>
								<div class="coins-item__match-value">
									<strong>650</strong>
									<span>500</span>
								</div>
							</div>
							<div class="coins-item__picture">
								<img src="/images/deposit/coin2.png" alt="">
							</div>
							<UiButton @click="pay(4999)" type="primary" class="coins-item__button">
								<strong>49</strong>
								<span>.99 euro</span>
							</UiButton>
						</div>
					</div>
					<div class="cell-sm-4">
						<div class="coins-item">
							<div class="coins-item__match">
								<div class="coins-item__match-icon">
									<img src="/images/deposit/coin.svg" alt="">
								</div>
								<div class="coins-item__match-value">
									<strong>1200</strong>
									<span>1000</span>
								</div>
							</div>
							<div class="coins-item__picture">
								<img src="/images/deposit/coin3.png" alt="">
							</div>
							<UiButton @click="pay(9999)" type="primary" class="coins-item__button">
								<strong>99</strong>
								<span>.99 euro</span>
							</UiButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../../components/UiButton'
import UiPageTopBar from '../../components/UiPageTopBar'
import { mapMutations } from 'vuex'
import { DepositService } from '../../services/deposit.service'
export default {
	components: {
		UiPageTopBar,
		UiButton
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		pay (amount) {
			// eslint-disable-next-line no-undef
			paysafe.threedsecure.start('T1QtODAzMDcwOkItcWEyLTAtNjFkNTkwNTUtMC0zMDJjMDIxNDA1MDk4NzM4MjIwNTRiNGI1OWFjZDNhZWEwNDVjZDRlOTg1YzljOTUwMjE0N2Y1ODYzZGM0Y2JmMGRiYWQyOTg5OWZhNGNjN2U5ZDkwZDJiZTgxMA==', {
					accountId: '1002302630',
					environment: 'TEST',
					card: {
						cardBin: '411111'
					}
				}, (deviceFingerprintingId, error) => {
					if (error) {
						return this.toast({
							type: 'error',
							message: 'Incorrect card informations, please try again'
						})
					}

					// if all good, make request to take CC and fixed in server transaction
					this.payCards({
						accountId: '1002302630',
						currency: 'EUR',
						deviceFingerprintingId,
						amount: amount
					}, 'cards').then((data) => {
					})
			})
		},
		async payCards (data, type) {
			await DepositService.makePayment(data, type)
		},
		loadPaymentsSources () {
			// paysafe load
			const makePaymentScript = document.createElement('script')
			makePaymentScript.setAttribute('src', 'https://hosted.paysafe.com/threedsecure/js/latest/paysafe.threedsecure.min.js')
			document.head.appendChild(makePaymentScript)
		}
	},
	mounted () {
		this.loadPaymentsSources()
	}
}
</script>
<style scoped lang="scss">
	.withdraw-button {
		cursor: pointer;
		margin-right: 30px;
		background: #2F2F2F;
		padding: 15px 25px;
		display: flex;
		align-items: center;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		svg {
			margin-right: 10px;
		}
	}
	.coins-wrapper {
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.deposit-coins {
		.coins-item {
			height: 777px;
			width: 100%;
			border-radius: 5px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-color: #2F2F2F;
			background-blend-mode: overlay;
			background-image: url('~@/assets/coins-bg.png');
			background-repeat: repeat;
			background-size: contain;
			border: 1px solid #00E7DF;
			padding: 29px;
			box-sizing: border-box;
			.coins-item__match {
				display: flex;
				height: 84px;
				width: 174px;
				margin: 0 auto;
				position: relative;
				border: 1px solid #00E7DF;
				box-shadow: 0px 0px 3px 2px rgba(0, 231, 223, 0.5);
				border-radius: 4px;
				background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
				align-items: center;
				justify-content: center;
				.coins-item__match-icon {
					width: 36px;
					height: 36px;
					overflow-y: hidden;
					margin-right: 10px;
					img {
						width: 100%;
					}
				}
				.coins-item__match-value {
					strong {
						font-weight: bold;
						display: block;
						width: 100%;
						font-size: 36px;
						line-height: 36px;
						letter-spacing: 1px;
						color: #00E7DF;
					}
					span {
						font-weight: bold;
						font-size: 18px;
						line-height: 18px;
						letter-spacing: 1px;
						text-decoration-line: line-through;
						color: #018786;
					}
				}
				z-index: 9;
				&::after {
					position: absolute;
					box-shadow: 0px 0px 1px 1px rgba(0, 231, 223, 0.5);
					right: calc(100% + 1px);
					height: 1px;
					width: 100%;
					content: "";
					display: block;
					top: calc(50% - 0.5px);
					background: #00E7DF;
				}
				&::before {
					position: absolute;
					left: calc(100% + 1px);
					height: 1px;
					box-shadow: 0px 0px 1px 1px rgba(0, 231, 223, 0.5);
					width: 100%;
					content: "";
					display: block;
					top: calc(50% - 0.5px);
					background: #00E7DF;
				}
			}
			.coins-item__picture {
				margin-right: -29px;
				margin-left: -29px;
			}
			.coins-item__button {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				color: #121212;
				strong {
					font-weight: bold;
					font-size: 24px;
					line-height: 24px;
					letter-spacing: 1px;
					text-transform: uppercase;
					display: flex;
					align-items: flex-end;
				}
				span {
					font-weight: bold;
				}
			}
		}
	}
</style>

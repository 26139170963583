import { AuthService } from '@/services/auth.service'
export default {
  namespaced: true,
  state: {
    accessTokenExpDate: '',
		wsToken: localStorage.getItem('wsToken') || ''
  },
	getters: {
		wsToken: state => state.wsToken
	},
	actions: {
		login ({ commit }, data) {
			return AuthService.makeLogin(data)
		},
		register ({ commit }, data) {
			return AuthService.makeRegister(data).catch((err) => {
				commit('toast/NEW', { type: 'error', message: err ? err.response.data.errors[Object.keys(err.response.data.errors)[0]][0] : 'Invalid data' }, { root: true })
			})
		}
	},

  mutations: {
    SET_ATOKEN_EXP_DATE (state, expDate) {
      state.accessTokenExpDate = expDate
    }
  }
}

<template>
	<div class="left-sidebar">
		<div class="games">
			<template v-if="$currentGame">
				<div class="games__title">
					<p>Platform</p>
				</div>
				<div class="games__platform">
					<img :src="$currentGame.getPlatformIcon()" alt="">
				</div>
			</template>
			<div class="games__title">
				<p>{{ $t('Games') }}</p>
			</div>
			<ul class="games__list">
				<li
					v-for="game in games"
					:key="game.id"
					class="games__list__item"
					:class="{ 'active': currentGame && currentGame.id === game.id }"
					@click.prevent="onChangeGame(game.id)"
				>
					<UiImgLoader
						:src="game.icon"
						:animated="false"
					/>
				</li>
				<router-link :to="{ name: 'games.add' }" class="games__add">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 5.5H8.5V1C8.5 0.46875 8.03125 0 7.5 0H6.5C5.9375 0 5.5 0.46875 5.5 1V5.5H1C0.4375 5.5 0 5.96875 0 6.5V7.5C0 8.0625 0.4375 8.5 1 8.5H5.5V13C5.5 13.5625 5.9375 14 6.5 14H7.5C8.03125 14 8.5 13.5625 8.5 13V8.5H13C13.5313 8.5 14 8.0625 14 7.5V6.5C14 5.96875 13.5313 5.5 13 5.5Z" fill="#00E7DF"/>
					</svg>
				</router-link>
			</ul>
		</div>
		<div class="app-links">
			<p>{{ $t('Download app') }}</p>
			<a href="https://apps.apple.com/ua/app/qlash-community/id1420983263">
				<UiBaseIcon
					width="20px"
					iconName="app-store"
				/>
			</a>
			<a href="https://play.google.com/store/apps/details?id=gg.qlash.app&hl=bg&gl=US">
				<UiBaseIcon
					width="20px"
					iconName="play-store"
				/>
			</a>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapMutations } from 'vuex'
	import UiImgLoader from '@/components/UiImgLoader'
	import UiBaseIcon from '@/components/icons/UiBaseIcon'

	export default {
		name: 'LeftSidebar',
		components: {
			UiImgLoader,
			UiBaseIcon
		},
		data () {
			return {
				currentYear: new Date().getFullYear()
			}
		},
		computed: {
			...mapGetters({
				games: 'user/getGames',
				currentGame: 'user/getCurrentGame'
			})
		},
		methods: {
			...mapActions({
				getTournaments: 'tournaments/getTournaments',
				getAdvertisements: 'advertisements/getAdvertisements'
			}),
			...mapMutations({
				setGame: 'user/SET_CURRENT_GAME'
			}),
			async onChangeGame (id) {
				this.amplitude.logEvent('game_selected', {
					game: id
				})
				await this.setGame(id)
				await window.scrollTo(0, 0)

				if (this.$router.currentRoute.name !== 'index') {
					this.$router.push('/')
				}

				await this.getAdvertisements({
					games: id
				})

				await this.getTournaments({
					games: id,
					country_id: this.$currentUser && this.$currentUser.country_id ? this.$currentUser.country_id : 0,
					locale: 'en',
					page: 1,
					limit: 10
				})
			}
		}
	}
</script>

<style lang="scss">
	.left-sidebar {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: fixed;
		left: 0;
		top: 0;
		width: 95px;
		height: 100vh;
		padding-top: 71px;
		border-right: 1px solid $color-gray-600;

		.games {
			display: flex;
			flex-grow: 1;
			overflow: hidden;
			flex-direction: column;
			width: 100%;
			&__platform {
				border: 1px solid #00E7DF;
				width: 50px;
				height: 50px;
				margin: 0 auto;
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
			}
			&__title {
				text-align: center;
				font-size: 10px;
				line-height: 20px;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: $color-gray-400;
				margin-bottom: 5px;
			}
			&__list {
				&::-webkit-scrollbar {
					width: 2px;
				}
				&::-webkit-scrollbar-thumb {
					background-color: gray;
				}
				flex-grow: 1;
				overflow-y: auto;
				margin-right: -1px;

				&__item {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 70px;
					padding: 10px;
					border-right: 1px solid transparent;
					cursor: pointer;
					@include base-transition;

					@include hover-state {
						border-right-color: $primary-color;
						background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);

						img {
							border: 1px solid $primary-color;
						}
					}

					&.active {
						border-right-color: $primary-color;
						background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);

						img {
							border: 1px solid $primary-color;
						}
					}

					.img-container {
						text-align: center;
						width: 50px;
						height: 50px;
						max-width: 100%;
					}

					img {
						width: 50px;
						height: 50px;
						max-width: 50px;
						box-sizing: border-box;
						border: 1px solid transparent;
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
						border-radius: 4px;
						object-fit: cover;
						@include base-transition;
					}
				}
				.games__add {
					background: #2F2F2F;
					border: 1px solid #00E7DF;
					box-sizing: border-box;

					border-radius: 4px;
					width: 50px;
					height: 50px;
					display: flex;
					align-items: center;
					&:hover {
						box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					}
					transition: 0.2s;
					justify-content: center;
					margin: 10px auto;
				}
			}

		}

		.app-links {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			background: $color-gray-600;
			border: 1px dashed $color-gray-500;
			border-radius: 5px;

			padding: 10px 5px;
			margin-bottom: 15px;
			max-width: 77px;

			font-family: $secondary-font;
			font-size: 8px;
			line-height: 12px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: $color-gray-400;

			* + * {
				margin-top: 14px;
			}

			a {
				color: $color-gray-100;
				@include base-transition;

				@include hover-state {
					color: $primary-color;
				}

				@include focus-state {
					color: $secondary-color-1;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.left-sidebar {
			width: 80px;
			.games__title {
				font-size: 9px;
			}
			.app-links {
				margin-bottom: 6px;
				max-width: 65px;
				font-size: 7px;
			}
		}
	}
</style>

import { prepare as prepareMatch } from '@/model-preparing/tournaments/match.js'
export default {
	SET_ROUNDS (state, { rootState, rounds }) {
		state.rounds = prepareMatch(rootState.tournaments, rounds)
	},
	SET_ACTIVE_CHAT (state, chat) {
		state.activeChat = chat
	},
	CLOSE_CHAT (state) {
		state.activeChat = null
	},
	REMOVE_CHAT (state, id) {
		const index = state.rounds.findIndex(item => item.id === id)
		if (index > -1) {
			state.rounds.splice(index, 1)
		}
	}
}

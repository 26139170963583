<template>
	<div class="team__leaderboard leaderboard-table">
		<div class="leaderboard-table__head">
			<div class="leaderboard-table__head-name">
				Name
			</div>
			<div class="leaderboard-table__head-score">
				Score
			</div>
		</div>
		<div class="leaderboard-table__items">
			<div class="leaderboard-table__items-loader" v-if="!players.length && !load">
				No data available
			</div>
			<div class="leaderboard-table__items-loader" v-if="load">
				<UiLoader></UiLoader>
			</div>
			<div class="leaderboard-table__item" v-for="(player,i) in players" :key="i">
				<div class="leaderboard-table__item-main">
					<div class="leaderboard-table__item-main__team">
						<UiAvatar :src="player.user.avatar" :size="isMobile ? 40 : 50"></UiAvatar>
						<span>{{ player.user.nickname ? player.user.nickname : player.user.name }}</span>
					</div>
					<div class="leaderboard-table__item-main__score">
						{{ player.points }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import UiAvatar from '../UiAvatar'
import { mapGetters } from 'vuex'
import UiLoader from '../UiLoader'
import { UsersService } from '../../services/users.service'
export default {
	data () {
		return {
			open: null,
			load: false,
			players: []
		}
	},
	methods: {
		getLeaderboard () {
			this.load = true
			UsersService.getAllLeaderboard({
				game_id: this.$currentGame.id,
				season_id: this.$currentSeason.id,
				platform_id: this.$currentGame.platformId,
				limit: 20
			}).then(response => {
				this.players = response.data
			}).finally(() => {
				this.load = false
			})
		},
		setOpen (id) {
			if (this.open === id) {
				this.open = null
				return
			}
			this.open = id
		}
	},
	mounted () {
		this.getLeaderboard()
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		})
	},
	components: {
		UiLoader,
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.leaderboard-table {
	background: #2F2F2F;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	.leaderboard-table__head {
		padding: 5px 30px;
		padding-right: 0;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #505050;
		& > div {
			font-size: 10px;
			line-height: 20px;
			letter-spacing: 0.25px;
			color: #8F8F8F;
		}
		.leaderboard-table__head-name {
			flex-grow: 1;
			overflow: hidden;
		}
		.leaderboard-table__head-score {
			text-align: center;
			width: 140px;
			min-width: 140px;
		}
		.leaderboard-table__head-players {
			text-align: center;
			width: 220px;
			min-width: 220px;
		}
	}
	.leaderboard-table__items-loader {
		padding: 20px;
		display: flex;
		justify-content: center;
	}
	.leaderboard-table__items {
		.leaderboard-table__item {
			.leaderboard-table__item-main {
				border-bottom: 1px solid #505050;
				padding-left: 30px;
				display: flex;
				.leaderboard-table__item-main__team {
					flex-grow: 1;
					overflow: hidden;
					padding: 10px 0;
					display: flex;
					align-items: center;
					span {
						margin-left: 25px;
						font-weight: bold;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 0.25px;
					}
				}
				.leaderboard-table__item-main__score {
					border-left: 1px solid #505050;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 140px;
					min-width: 140px;
					text-align: center;
					font-weight: bold;
					font-size: 24px;
					line-height: 24px;
					letter-spacing: 1px;
					color: #FFFFFF;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.leaderboard-table {
		.leaderboard-table__head {
			.leaderboard-table__head-score {
				width: 60px;
				min-width: 60px;
			}
		}
		.leaderboard-table__items {
			.leaderboard-table__item {
				.leaderboard-table__item-main {
					padding-left: 10px;
					.leaderboard-table__item-main__players {
						width: 120px;
						min-width: 120px;
						.arrow {
							margin-right: 2px;
							margin-left: 2px;
						}
					}
					.leaderboard-table__item-main__score {
						width: 60px;
						min-width: 60px;
						font-size: 14px;
					}
					.leaderboard-table__item-main__team {
						span {
							font-size: 14px;
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 22 14">
    <g>
      <path d="M19.2175 11.9361C18.9412 11.8451 17.0988 11.2079 17.0988 11.2079C13.2297 9.52403 11.8479 8.43177 10.0515 6.70235C10.1897 9.43301 12.0321 10.5708 12.0321 10.5708H9.08428C5.16917 10.5708 5.21523 6.70235 6.45885 4.8364C8.30126 2.24228 11.157 2.7429 11.2031 2.7429C9.54489 2.7429 8.2552 3.51659 7.38006 4.47232C7.38006 4.47232 7.97884 4.15374 9.4067 4.06272C10.4661 3.9717 12.9994 4.01721 13.9667 4.10823C14.3351 4.15374 15.3024 4.33578 15.9472 4.79089C18.5727 6.61133 18.1121 9.56954 18.1121 9.56954C18.02 7.20297 16.8685 6.24724 16.8224 6.24724C17.4212 8.88687 15.8091 9.79709 15.8091 9.79709C15.8091 9.79709 18.3884 11.1624 19.5399 10.4342C22.626 8.24972 20.8296 3.1525 20.7836 3.01597C20.9678 3.9717 20.7375 5.20049 20.6914 5.20049C19.2175 0.239804 15.2563 0.103271 15.2563 0.103271C19.2175 2.01473 18.5727 5.42805 18.5727 5.47356C18.2963 0.603891 13.5982 0.285315 13.5982 0.285315C14.3351 0.649402 14.5654 0.785935 14.5654 0.785935C3.64918 -0.897968 3.87948 5.246 3.92554 5.74662C3.511 3.19801 5.26129 1.60513 5.26129 1.55962C0.471033 3.5621 1.80678 8.65932 1.80678 8.65932C1.11588 8.11319 0.701334 7.52155 0.655273 7.52155C2.72798 12.1637 6.55097 11.9361 6.50491 11.9361C4.15585 12.2092 2.5898 10.8438 2.5898 10.7983C4.80069 14.1206 9.54488 13.8931 9.54488 13.8931H21.2442C21.2442 13.8931 20.323 12.6643 20.0927 12.3457C19.8624 12.0271 19.4939 11.9816 19.2175 11.9361ZM14.7036 11.299L16.9606 12.0271C16.9606 12.0271 15.3024 12.4367 14.7036 11.299ZM18.5727 12.3457C19.3557 12.3912 19.7702 12.4822 19.7702 12.4822L20.0005 12.8008C20.0005 12.8008 19.0793 12.4822 18.5727 12.3457Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

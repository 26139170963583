<template>
	<div class="rr__bracket-stage">
		<RoundInfo></RoundInfo>
	</div>
</template>
<script>
import RoundInfo from '../RoundInfo'
export default {
	components: {
		RoundInfo
	}
}
</script>
<style scoped lang="scss">
.rr__bracket-stage {
	padding-top: 40px;
}
@media screen and (max-width: 768px) {
	.rr__bracket-stage {
		padding-top: 10px;
	}
}
</style>

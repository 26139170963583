<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 15 16">
    <g>
      <path d="M8 16C9.09375 16 9.96875 15.125 9.96875 14H6C6 15.125 6.875 16 8 16ZM14.7188 11.3438C14.125 10.6875 12.9688 9.71875 12.9688 6.5C12.9688 4.09375 11.2812 2.15625 8.96875 1.65625V1C8.96875 0.46875 8.53125 0 8 0C7.4375 0 7 0.46875 7 1V1.65625C4.6875 2.15625 3 4.09375 3 6.5C3 9.71875 1.84375 10.6875 1.25 11.3438C1.0625 11.5312 0.96875 11.7812 1 12C1 12.5312 1.375 13 2 13H13.9688C14.5938 13 14.9688 12.5312 15 12C15 11.7812 14.9062 11.5312 14.7188 11.3438Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

import CurrentUserModel from '@/models/CurrentUserModel'
export default {
	wsToken: state => state.wsToken,
	getCurrentUser: state => {
		return Object.keys(state.currentUser).length ? new CurrentUserModel(JSON.parse(state.currentUser)) : undefined
	},
	myTournaments: state => state.currentTournaments,
	getFavouriteGames: state => state.favouriteGames,
	getGames: state => state.games,
	allGames: state => state.allGames,
	currentSeason: state => state.currentSeason,
	getProfileForTournament: (state, getters, rootState, rootGetters) => {
		const tournament = rootGetters['tournaments/getCurrentTournament']
		if (!tournament) {
			return 'Unknown'
		}
		const profile = state.games.find(item => {
			return item.platforms.includes(tournament.platformId) && item.id === tournament.gameId
		})
		return profile ?? profile
	},
	getCurrentGame: (state) => {
		const currentGame = Number(state.currentGame)
		if (isNaN(currentGame)) return null

		if (!currentGame) {
			if (state.games.length !== 0) {
				localStorage.setItem('currentGame', state.games[0].id)
				return state.games[0]
			}
			return null
		}

		return state.games.find(item => item.id === currentGame)
	},
	getTournamentsParticipants: (state, getters) => {
		const tournaments = getters.myTournaments
		const data = {}
		for (const item in tournaments) {
			data[tournaments[item].id] = tournaments[item].participantId
		}
		return data
	},
	isAuth: (state, getters) => getters.getCurrentUser ? Object.keys(getters.getCurrentUser).length > 0 : false,
	currentTeams: state => state.currentTeams,
	getLeaderboard: state => state.leaderboard,
	discordUrl: () => process.env.VUE_APP_DISCORD_URL,
	twitchUrl: () => process.env.VUE_APP_TWITCH_URL,
	googleUrl: () => `${process.env.VUE_APP_GOOGLE_URL}`,
	appleUrl: () => `${process.env.VUE_APP_APPLE_URL}`
}

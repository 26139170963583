<template>
	<div class="team__info">
		<div class="team__info-main">
			<div class="team__item-avatar">
				<UiAvatar :src="team.logo" :size="isMobile ? 60 : 90"></UiAvatar>
			</div>
			<div class="team__item-information">
				<strong>{{ team.name }}</strong>
				<div class="team__item-information__indicators">
					<span>Team size: {{ playersCount }}/{{ team.max_players }}</span>
					<div class="separator"></div>
					<span>Substitutes: {{ team.substitute_count }}/{{ team.max_players }}</span>
				</div>
			</div>
			<div class="team__item-controls" v-if="editMode">
				<button class="edit__team" v-if="!isMobile" @click="showEditTeam = true">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.6101 3.89225C14.13 3.37237 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.419 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.419V11.581Z" fill="#00E7DF"/>
					</svg>

					Edit
				</button>
				<button class="edit__team simple" v-else @click="showEditTeam = true">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.6101 3.89225C14.13 3.37237 14.13 2.52415 13.6101 2.03163L11.9684 0.389909C11.4758 -0.12997 10.6276 -0.12997 10.1077 0.389909L8.84909 1.64856C8.73964 1.75801 8.73964 1.97691 8.84909 2.11372L11.8863 5.15091C12.0231 5.26036 12.242 5.26036 12.3514 5.15091L13.6101 3.89225ZM7.78197 2.71569L0.585746 9.91191L0.011143 13.2227C-0.0709432 13.6879 0.312126 14.0709 0.777281 13.9889L4.08809 13.4143L11.2843 6.21803C11.4211 6.10858 11.4211 5.88969 11.2843 5.75287L8.24713 2.71569C8.11032 2.60624 7.89142 2.60624 7.78197 2.71569ZM3.40404 9.28258C3.23987 9.14577 3.23987 8.89951 3.40404 8.7627L7.6178 4.54894C7.75461 4.38477 8.00087 4.38477 8.13768 4.54894C8.30185 4.68575 8.30185 4.93201 8.13768 5.06882L3.92392 9.28258C3.78711 9.44675 3.54085 9.44675 3.40404 9.28258ZM2.419 11.581H3.73238V12.5934L1.95385 12.8944L1.10563 12.0462L1.40661 10.2676H2.419V11.581Z" fill="#00E7DF"/>
					</svg>

				</button>
			</div>
		</div>
		<div class="team__info-description" v-urlify v-breaks v-html="team.description" v-if="team.description">
			{{ team.description }}
		</div>
		<transition name="modal">
			<CreateTeamModal @update="$emit('update')" :team="team" @close="showEditTeam = false" v-if="showEditTeam"></CreateTeamModal>
		</transition>
	</div>
</template>
<script>
import UiAvatar from '../UiAvatar'
import { mapGetters } from 'vuex'
import CreateTeamModal from './modals/CreateTeamModal'
import breaks from '../../directives/breaks'
export default {
	data () {
		return {
			showEditTeam: false
		}
	},
	directives: {
		breaks: breaks
	},
	props: {
		editMode: {
			type: Boolean,
			default: false
		},
		team: {
			required: true,
			type: Object
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile'
		}),
		getMyProfile () {
			const find = this.team.team_mates.find(item => item.profile.user.id === this.$currentUser.id)
			return find ?? false
		},
		isJoined () {
			if (!this.getMyProfile) {
				return false
			}
			return this.getMyProfile !== false
		},
		iRequest () {
			if (!this.isJoined) {
				return false
			}
			return !this.getMyProfile.role
		},
		iCaptain () {
			return this.captainId === this.$currentUser.id
		},
		captainId () {
			const find = this.team.team_mates.find(item => item.role === 'captain')
			return find ? find.user_id : null
		},
		playersCount () {
			return this.team.team_mates ? this.team.team_mates.filter(item => ['player', 'captain', 'co-captain'].includes(item.role)).length : 0
		}
	},
	components: {
		CreateTeamModal,
		UiAvatar
	}
}
</script>
<style scoped lang="scss">
.team__info {
	background: #2F2F2F;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25), 0px 4px 15px rgba(0, 0, 0, 0.25);
	margin-bottom: 20px;
	.team__info-main {
		border-radius: 5px;
		padding: 15px;
		display: flex;
		align-items: center;
		position: relative;
		text-decoration: none;
		.team__item-information {
			margin-left: 15px;
			flex-grow: 1;
			strong {
				width: 100%;
				display: block;
				font-weight: bold;
				font-size: 24px;
				color: #fff;
				line-height: 20px;
				letter-spacing: 0.25px;
			}
			.team__item-information__indicators {
				margin-top: 15px;
				align-items: center;
				display: flex;
				span {
					font-size: 12px;
					line-height: 20px;
					letter-spacing: 0.25px;
					color: #BDBDBD;
				}
				.separator {
					background: #8F8F8F;
					width: 3px;
					height: 3px;
					border-radius: 100%;
					margin: 0 10px;
				}
			}
		}
		.team__item-controls {
			align-self: flex-start;
			.edit__team {
				font-weight: bold;
				background: transparent;
				padding: 3px 15px;
				white-space: nowrap;
				font-size: 14px;
				line-height: 30px;
				align-items: center;
				display: flex;
				text-align: center;
				letter-spacing: 0.3px;
				cursor: pointer;
				text-transform: uppercase;
				border: 1px solid #505050;
				box-sizing: border-box;
				box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
				border-radius: 5px;
				color: #00E7DF;
				transition: 0.2s;
				&:hover {
					background-color: #505050;
					border-color: #505050;
				}
				svg {
					margin-right: 10px;
					margin-bottom: 2px;
				}
				&.simple {
					border: none;
					justify-content: center;
					text-align: center;
					svg {
						margin-right: 0;
					}
					background: transparent;
					padding: 5px 5px;
					box-shadow: none;
				}
			}
		}
	}
	.team__info-description {
		border-top: 1px solid #505050;
		padding: 20px;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.25px;
		color: #FFFFFF;
		::v-deep {
			a {
				color: #00E7DF;
			}
		}
	}

}
@media screen and (max-width: 575px) {
	.team__info {
		.team__info-main {
			padding: 10px;
			.team__item-information {
				margin-left: 8px;
				strong {
					font-size: 18px;
					line-height: 20px;
				}
				.team__item-information__indicators {
					margin-top: 10px;
				}
			}
		}
	}
}
</style>

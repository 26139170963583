import { ErrorWrapper, ResponseWrapper } from './util'
import { BaseService } from './base.service'

export class GamesService extends BaseService {
	static async getGames () {
		try {
			const response = await this.request({ auth: true }).get('games')
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getGame (id) {
		try {
			const response = await this.request({ auth: true }).get(`games/${id}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

<template>
  <span class="ui-icon-base component" v-on="$listeners">
    <component
			:is="iconName"
			:width="width"
			:height="height"
			:color="color">
    </component>
  </span>
</template>

<script>
	import write from './write'
	import moon from './moon'
	import done from './done'
	import arrowDown from './arrowDown'
	import sort from './sort'
	import sortDown from './sortDown'
	import sortUp from './sortUp'
	import appStore from './appStore'
	import appleBrand from './appleBrand'
	import playStore from './playStore'
	import bell from './bell'
	import arrowLeft from './arrowLeft'
	import platformMobile from './platformMobile'
	import platformPc from './platformPC'
	import platformPlaystation from './platformPlaystation'
	import platformXbox from './platformXbox'
	import clock from './clock'
	import cup from './cup'
	import prize from './prize'
	import logoSmall from './logoSmall'
	import user from './user'
	import logout from './logout'
	import calendar from './calendar'
	import users from './users'
	import totalScore from './totalScore'
	import crown from './crown'
	import discord from './discord'
	import share from './share'
	import error from './error'
	import battle from './battle'

	export default {
		name: 'UiIconBase',
		props: {
			iconName: {
				type: String,
				default: 'moon'
			},
			width: {
				type: String,
				default: '18px'
			},
			height: {
				type: String,
				default: '18px'
			},
			color: {
				type: String,
				default: 'currentColor'
			}
		},

		components: {
			write,
			moon,
			done,
			arrowDown,
			sort,
			sortDown,
			sortUp,
			playStore,
			appStore,
			appleBrand,
			bell,
			arrowLeft,
			platformMobile,
			platformPc,
			platformPlaystation,
			platformXbox,
			clock,
			cup,
			prize,
			logoSmall,
			user,
			logout,
			calendar,
			users,
			totalScore,
			discord,
			share,
			crown,
			error,
			battle
		}
	}
</script>

<style lang="scss" scoped>
	.ui-icon-base.component {
		display: inline-block;
		line-height: 1;
	}
</style>

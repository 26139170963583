/**
 * toast notification module
 */
export default {
	namespaced: true,
	state: {
		data: null,
		isOpen: false
	},

	getters: {
		modal: state => state.data,
		isOpenModal: state => state.isOpen
	},

	mutations: {
		SHOW (state, payload) {
			state.data = payload
			state.isOpen = true
		},
		REMOVE (state) {
			state.isOpen = false
			state.data = null
		}
	}
}

<template>
	<div class="app-loader">
		<QlashLoader></QlashLoader>
	</div>
</template>
<script>
import QlashLoader from '@/components/QlashLoader'
export default {
	components: {
		QlashLoader
	}
}
</script>
<style scoped lang="scss">
.app-loader {
	margin: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	::v-deep {
		.loader {
			img {
				width: 50px;
			}
		}
	}
}
</style>

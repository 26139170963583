<template>
	<div class="leader-board">
		<div class="leader-board__content">
			<div class="shell">
				<div class="range">
					<div class="cell-sm-8">
						<div class="table solo" v-if="round.participants">
							<div class="table__header">
								<div class="table__col">
									{{ $t('Position') }}
								</div>
								<div class="table__col">
									{{ $t('Name') }}
								</div>
								<div class="table__col">
									{{ $t('Points') }}
								</div>
							</div>
							<div class="table__body">
								<div class="table__row" v-for="(participant, index) in round.participants" :key="participant.id">
									<div class="table__col">
										<span>{{ Number(index) + 1 }}</span>
									</div>
									<div class="table__col">
										<UiAvatar
											:alt="getParticipantNickName(participant)"
											:src="getParticipantAvatar(participant)"
										/>
										<span>{{ getParticipantNickName(participant) }}</span>
									</div>
									<div class="table__col">
										{{ participant.points }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cell-sm-4">
						<SidebarContainer></SidebarContainer>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import UiAvatar from '@/components/UiAvatar'
import SidebarContainer from './containers/SidebarContainer'
export default {
	name: 'LeaderBoard',
	components: {
		UiAvatar,
		SidebarContainer
	},
	data () {
		return {
			roundsData: [],
			currentTab: 0,
			resultRound: null,
			isAvailableNextRound: false
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			participants: 'tournaments/getParticipants',
			rounds: 'tournaments/getLeaderBoard',
			currentRound: 'tournaments/getCurrentRoundLeaderboard'
		}),
		proxyRound () {
			return this.round
		},
		isTeam () {
			return this.tournament.isTeamType()
		},
		round () {
			if (this.rounds.length <= 0) return []

			if (this.currentTab === this.rounds.length) {
				const result = {}

				for (let j = 0; j <= this.rounds.length - 1; j++) {
					for (let i = 0; i <= this.rounds[j].participants.length - 1; i++) {
						const participant = this.rounds[j].participants[i]
						if (j === 0) {
							result[i] = participant
						} else {
							result[i].points = result[i].points + participant.points
						}
					}
				}

				return { participants: result }
			}

			return this.rounds[this.currentTab]
		}
	},
	methods: {
		...mapActions({
			getLeaderBoard: 'tournaments/getLeaderBoard',
			getParticipants: 'tournaments/getParticipants'
		}),
		getParticipantAvatar (participant) {
			if (!this.isTeam) return participant.profile.user.avatar

			return participant.team !== undefined ? participant.team.logo : ''
		},
		onTabClick (id) {
			this.currentTab = id
		},
		getParticipantNickName (participant) {
			if (!this.isTeam) return participant.profile.user.name ?? participant.profile.user.nickname

			return participant.team !== undefined ? participant.team.name : ''
		},
		async init () {
			this.resultRound = null
			await this.getParticipants(this.tournament.id)
			await this.getLeaderBoard({ id: this.tournament.id, commitToState: true })
			this.currentTab = this.rounds.length
		}
	},

	mounted () {
		this.init()
	}
}
</script>

<style lang="scss">
.leader-board {
	.leader-board__tabs {
		display: flex;
		justify-content: space-between;
		padding-left: 40px;

		.leader-board__tabs--left {
			overflow-x: auto;
			overflow-y: hidden;
			max-width: 100%;
			&::-webkit-scrollbar {
				height: 4px;
			}
			/* Track */
			&::-webkit-scrollbar-track {
				background: transparent;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #888;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #555;
			}
		}

		border-bottom: 1px solid $color-gray-600;

		&--left {
			display: flex;
			flex-wrap: nowrap;
		}

		&-wrapper {
			max-width: 100%;
			overflow-x: auto;
			margin-bottom: 30px;
			padding-bottom: 10px;
		}
	}

	&__content {
		padding-left: 30px;
		padding-right: 30px;
	}

	.leader-board__tab {
		@include navigation-item;
		outline: none;
		border: none;
		display: flex;
		align-items: center;
		svg {
			margin-right: 8px;
			margin-bottom: 2px;
			min-width: 13px;
		}
		white-space: nowrap;
		justify-content: center;
		background: transparent;
		cursor: pointer;
		font-weight: 700;
		font-size: 14px;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		color: $color-gray-400;
		text-align: center;
		border-bottom: 2px solid transparent;

		&.total {
			text-align: center;
			min-width: 205px;
			color: $primary-color;

			&.is-active,
			&:hover {
				color: #00E7DF;
			}

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			.ui-icon-base {
				margin-right: 15px;
			}
		}
		&.is-active {
			color: $primary-color;
			border-bottom: 2px solid #00E7DF;
		}
		padding-right: 22.5px;
		padding-left: 22.5px;
		&:first-child {
			padding-left: 10px;
		}
	}

	.avatar {
		max-width: 50px;
		min-width: 50px;
		min-height: 50px;
	}

	.table__row {
		padding: 0;
	}

	.table__header {
		padding-left: 0;
		padding-right: 0;

		> *:nth-child(2) {
			padding-left: 25px;
		}
	}

	.table__row,
	.table__header {
		> *:nth-child(1) {
			width: 10%;
			min-width: 70px;
			justify-content: center;
			text-align: center;
		}

		> *:nth-child(2) {
			width: 100%;
			justify-content: flex-start;
		}

		> *:nth-child(3) {
			min-width: 22%;
			justify-content: center;
			text-align: center;
		}
	}

	.table__body {
		.table__col {
			display: flex;
			align-items: center;
			padding: 10px 25px;
			font-size: 16px;
			line-height: 20px;
			font-weight: 700;
			letter-spacing: 0.25px;

			color: $color-white;

			&:first-child {
				font-size: 14px;
				line-height: 17px;
				letter-spacing: 2px;
				border-right: 1px solid $color-gray-500;
			}

			&:last-child {
				font-size: 18px;
				line-height: 16px;
				letter-spacing: 0.75px;

				border-left: 1px solid $color-gray-500;
			}
		}

		.avatar {
			margin-right: 25px;
		}
	}

}
</style>

import BaseModel from './BaseModel'

export default class RuleModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      creatorId: undefined,
      content: undefined,
      title: undefined
    }
  }

  prepareCreate () {
    this.baseClear()

    return this
  }

  prepareUpdate () {
    this.baseClear()

    return this
  }
}

<template>
	<div>
		<transition name="fade">
			<div class="leaderboard-table__item-players" v-if="team">
				<div class="leaderboard-table__item-player" v-for="(player, i) in team.team_mates" :key="i">
					<UiAvatar :src="player.profile.user.avatar" :size="40"></UiAvatar>
					<img src="@/assets/king.svg" alt="" v-if="player.role === 'captain'" class="king">
					<span>{{ player.profile.user.name ? player.profile.user.name : player.profile.user.nickname }}</span>
				</div>
			</div>
			<div v-else class="leaderboard-table__item-players with_loader">
				<UiLoader></UiLoader>
			</div>
		</transition>
	</div>
</template>
<script>
import UiLoader from '../UiLoader'
import UiAvatar from '../UiAvatar'
import { TeamsService } from '../../services/teams.service'
export default {
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	data () {
		return {
			team: null
		}
	},
	components: {
		UiLoader,
		UiAvatar
	},
	methods: {
		fetchTeam () {
				TeamsService.getTeam(this.id).then(response => {
					this.team = response.data.data
				})
		}
	},
	mounted () {
		this.fetchTeam()
	}
}
</script>
<style scoped lang="scss">
// routes transition animation
.fade-enter-active {
	transition: opacity .8s
}
.fade-enter, .fade-leave-to {
	opacity: 0
}
.leaderboard-table__item-players {
	background: #232323;
	width: 100%;
	padding: 16px 30px;
	&.with_loader {
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.leaderboard-table__item-player {
		display: flex;
		align-items: center;
		span {
			margin-left: 10px;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.25px;
		}
		.king {
			margin-left: 10px;
		}
	}
	.leaderboard-table__item-player + .leaderboard-table__item-player {
		margin-top: 10px;
	}
}

@media screen and (max-width: 575px) {
	.leaderboard-table__item-players {
		padding: 16px;
	}
}
</style>

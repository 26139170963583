<template>
	<div class="play__page">
		<TftPlay v-if="tournament.type === 6"></TftPlay>
		<RoundRobinPlay v-if="tournament.type === 4"></RoundRobinPlay>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TftPlay from './Tft/TftPlay'
import RoundRobinPlay from './RoundRobin/RoundRobinPlay'
export default {
	methods: {
		...mapActions({
			getTournament: 'tournaments/getTournament'
		}),
		init () {
			this.getTournament(this.$route.params.id)
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	mounted () {
		this.init()
	},
	components: {
		TftPlay,
		RoundRobinPlay
	}
}
</script>

<template>
	<div
		class="ui-accordion-item component"
		:class="{opened: opened}"
	>
		<div class="ui-accordion-item__button" @click="onClick" v-if="adv">
			<span class="ui-accordion-item__button__title">
				Sponsor
			</span>
			<span class="ui-accordion-item__button__divider"></span>
		</div>
		
		<div v-if="adv" class="ui-accordion-item__content">
			<div class="range">
				<div class="cell-sm-6 cell-md-4">
					<div class="ui-tournament-item component adv">
						<a :href="adv.link" target="_blank">
							<img :src="adv.image_full_url" :title="adv.title" :alt="adv.title" style="border-radius: 5px;">
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="ui-accordion-item__button" @click="onClick">
			<span class="ui-accordion-item__button__title">
				{{ title }}
			</span>
			<span class="ui-accordion-item__button__divider"></span>
			<span class="ui-accordion-item__button__icon">
				<UiBaseIcon width="8" height="5" iconName="arrow-down"/>
			</span>
		</div>
		<div class="ui-accordion-item__content" :class="{ opened: opened }">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import UiBaseIcon from './icons/UiBaseIcon'

	export default {
		name: 'UiAccordionItem',
		props: {
			title: {
				type: String,
				default: ''
			},
			isOpened: {
				type: Boolean,
				default: false
			},
			adv: undefined,
			onOpen: {
				type: Function,
				default: () => {}
			}
		},
		components: {
			UiBaseIcon
		},
		data () {
			return {
				opened: this.isOpened,
				onOpenFun: this.onOpen
			}
		},
		methods: {
			async onClick () {
				await this.onOpenFun()
				this.opened = !this.opened
			}
		}
	}
</script>

<style lang="scss">
	.ui-accordion-item {
		&__button {
			display: flex;
			align-items: flex-end;
			cursor: pointer;

			&__title {
				font-weight: 700;
				font-size: 14px;
				line-height: 22px;

				letter-spacing: 0.15px;
				text-transform: uppercase;
				flex-shrink: 0;
			}

			&__divider {
				width: 100%;
				margin-left: 15px;
				margin-right: 15px;
				margin-bottom: 5px;
				border-bottom: 1px solid $color-gray-600;
			}

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 10px;
				height: 10px;
				margin-bottom: 3px;
				transform: rotate(-180deg);
				transform-origin: center;
				@include base-transition;

				font-size: 0;
			}
		}

		.ui-accordion-item__content {
			height: 0;
			overflow: hidden;
		}

		&.opened {
			.ui-accordion-item__content {
				height: auto;
			}

			.ui-accordion-item__button__icon {
				transform: rotate(0);
			}
		}
	}

	.adv {
		padding-bottom: 5px;
	}
</style>

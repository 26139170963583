import BaseModel from './BaseModel'

export default class AdvertisementItemModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      games: undefined,
      image: undefined,
      image_full_url: undefined,
      link: undefined,
      platforms: undefined,
      title: undefined
    }
  }
}

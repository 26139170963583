<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20">
    <g>
      <path d="M6.11842 4.18768C6.11135 4.19312 6.10591 4.1991 6.09938 4.20454C6.1391 4.16265 6.19623 4.11749 6.23378 4.09301C7.32966 3.38084 8.59586 3 10.0008 3C11.3149 3 12.5457 3.36289 13.5964 3.99399C13.6726 4.03915 13.8805 4.18169 13.9877 4.31118V4.31172C12.9739 3.19423 9.99428 5.59189 9.99428 5.59189C8.96369 4.79756 8.01581 4.25623 7.31007 4.06363C6.72023 3.90205 6.31213 4.03697 6.11842 4.18768ZM15.1358 5.24424C15.1048 5.20996 15.071 5.17786 15.04 5.1425C14.7837 4.86122 14.4687 4.79321 14.1846 4.81171C13.9262 4.89277 12.7318 5.32312 11.2599 6.71265C11.2599 6.71265 12.9168 8.3236 13.9316 9.97046C14.9453 11.6173 15.5515 12.9116 15.1788 14.7081C16.31 13.4649 17 11.8132 17 9.99929C17 8.16364 16.2932 6.49285 15.1358 5.24424ZM12.6067 10.6739C12.1572 10.1696 11.4885 9.44708 10.6053 8.56897C10.4122 8.37692 10.2081 8.1767 9.99373 7.96942C9.99373 7.96942 9.67106 8.29204 9.25099 8.71641V8.71586C8.71339 9.25883 8.01636 9.96719 7.62948 10.3807C6.94061 11.1157 4.96704 13.4241 4.85604 14.7184C4.85604 14.7184 4.41692 13.6918 5.38058 11.3241C6.01014 9.77623 7.91243 7.45202 8.70686 6.69469C8.70686 6.69469 7.98099 5.89602 7.07011 5.34434L7.06467 5.34271C7.06467 5.34271 7.05433 5.33455 7.03801 5.32421C6.59998 5.06306 6.12169 4.86176 5.66135 4.8351C5.19122 4.86829 4.89358 5.21214 4.89358 5.21214C3.7188 6.46456 3 8.14787 3 9.99984C3 13.8654 6.1342 16.9997 10.0008 16.9997C12.0527 16.9997 13.899 16.115 15.1799 14.7087C15.1793 14.7065 15.0324 13.7838 14.0905 12.4601C13.869 12.15 13.0599 11.1821 12.6067 10.6739Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

<template>
	<div class="recovery-page">
		<Recovery></Recovery>
	</div>
</template>
<script>
import Recovery from '../../components/recovery/Recovery'
export default {
	components: {
		Recovery
	}
}
</script>

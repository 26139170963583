import { UsersService } from '@/services/users.service'
export default {
  getCurrent ({ commit }) {
    return UsersService.authWithToken()
      .then(user => commit('SET_CURRENT_USER', user.data))
      .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
  },
	getGames ({ commit }) {
		return UsersService.getProfiles()
			.then(response => commit('SET_GAMES', response.data.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
  getFavouriteGames ({ commit }) {
    return UsersService.getFavouriteGames()
      .then(response => commit('SET_FAVOURITE_GAMES', response.data))
      .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
  },
	subscribeTournaments ({ state, dispatch }, vm) {
		for (const key in state.currentTournaments) {
			vm.subscribeObserver(`tournament.${state.currentTournaments[key].id}`, () => {
				dispatch('chat/getRounds', {}, { root: true })
			})
		}
	},
	getCurrentTournaments ({ commit, dispatch }, vm) {
		return UsersService.getCurrentTournaments()
			.then(async response => {
				await commit('SET_CURRENT_TOURNAMENTS', response.data)
				await dispatch('subscribeTournaments', vm)
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async getCurrentTeams ({ commit }, params) {
		return await UsersService.getCurrentTeams(params)
			.then(async response => await commit('SET_CURRENT_TEAMS', response.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async getCurrentSeason ({ commit }, params) {
		return await UsersService.getSeasons(params)
			.then(async response => await commit('SET_CURRENT_SEASON', response.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async getLeaderboard ({ commit }) {
		return await UsersService.getLeaderboard()
			.then(async response => await commit('SET_LEADERBOARD', response.data))
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async createTeam ({ commit }, data) {
		return await UsersService.createTeam(data)
			.then(async response => {
				await commit('ADD_TEAM', response.data.data)
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	},
	async editTeam ({ commit }, { data, id }) {
		return await UsersService.editTeam(data, id)
			.then(async response => {
				return response
			})
			.catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
	}
}

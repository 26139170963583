<template>
	<div class="tournament-sidebar__footer">
		<div class="social-links">
			<a :target="tournament.chatLink ? '_blank' : '_self'" :href="tournament.chatLink ? getChatLink : '#'">
				<UiBaseIcon
					width="16px"
					height="12px"
					iconName="discord"
				/>
				<span>{{ $t('discord') }}</span>
			</a>
			<a href="#">
				<UiBaseIcon
					width="13px"
					height="14px"
					iconName="share"
				/>
				<span>{{ $t('share') }}</span>
			</a>
		</div>
	</div>

</template>
<script>
import { mapGetters } from 'vuex'
import UiBaseIcon from '@/components/icons/UiBaseIcon'
export default {
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		}),
		getChatLink () {
			const x = this.tournament.chatLink.replace(/(^\w+:|^)\/\//, '')
			const r = new URL(`https://${x}`)
			return r
		}
	},
	components: {
		UiBaseIcon
	}
}
</script>
<style scoped lang="scss">
.social-links {
	display: flex;
	justify-content: space-between;
	a {
		position: relative;
		display: inline-block;
		color: $primary-color;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 1px;
		background: $color-gray-600;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		text-decoration: none;
		text-transform: uppercase;
		padding: 13px 40px;

		text-align: center;

		.ui-icon-base {
			position: absolute;
			left: 15px;
			top: 50%;
			transform: translateY(-50%);
		}

		@include base-transition;

		@include hover-state {
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.14), 0 0 7px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.2);
		}

		@include focus-state {
			color: $color-gray-700;
			background-color: $primary-color;
			border-color: $primary-color;
		}
	}

	> * {
		width: calc(50% - 10px);
	}
}
</style>

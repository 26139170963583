import BaseModel from './BaseModel'

export default class ParticipantModel extends BaseModel {
  get schema () {
    return {
      id: undefined,
      profile: undefined,
      status: undefined,
      type: undefined,
      earnedPoints: undefined,
      points: undefined,
      team: undefined
    }
  }

	getAvatar () {
		const participant = this
		if (Number(participant.type) === 2) {
			return participant.team.logo
		} else if (Number(participant.type) === 1) {
			return participant.profile.user.avatar
		}

		return require('@/assets/logo.svg')
	}

	getId () {
		if (this.type === 2) {
			return this.team.id
		}
		return this.profile.user.id
	}

	getEarnedPoints () {
		return this.earnedPoints
	}

	getName () {
		if (this.type === 2) {
			return this.team !== null && this.team.name !== null ? this.team.name : 'TEAM DELETED'
		}

		return this.profile.user.nickname ?? this.profile.user.name
	}

	getTypeParticipant () {
		return this.type === 1 ? 'solo' : 'team'
	}

	isSelfMatch () {
		if (this.type === 2) {

		}
	}
}

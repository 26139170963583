<template>
	<div class="modal">
		<div class="modal__body">
			<div class="close" @click="$emit('close')">
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="#00E7DF"/>
				</svg>
			</div>
			<div class="upload__result">
				<h2>Prove picture</h2>
				<div class="upload__area">
					<input @change="select" accept="image/png, image/jpeg" type="file" id="area">
					<label :style="{'overflow': image ? 'hidden' : ''}" for="area">
						<svg v-if="!image" width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1813 24.817C16.2267 23.8051 15.7495 22.5655 15.7495 21.0982C15.7495 19.631 16.2519 18.3914 17.2566 17.3795C18.2614 16.3676 19.4671 15.8616 20.8737 15.8616C22.3306 15.8616 23.5614 16.3676 24.5662 17.3795C25.5709 18.3914 26.0733 19.631 26.0733 21.0982C26.0733 22.5149 25.5709 23.7292 24.5662 24.7411C23.5614 25.753 22.3306 26.2589 20.8737 26.2589C19.4168 26.2589 18.186 25.7783 17.1813 24.817ZM15.222 26.7902C16.7794 28.3586 18.6633 29.1429 20.8737 29.1429C23.0842 29.1429 24.9681 28.3586 26.5255 26.7902C28.1331 25.2217 28.9369 23.3244 28.9369 21.0982C28.9369 18.872 28.1331 16.9747 26.5255 15.4062C24.9681 13.7872 23.0842 12.9777 20.8737 12.9777C18.6633 12.9777 16.7794 13.7872 15.222 15.4062C13.6646 16.9747 12.8859 18.872 12.8859 21.0982C12.8859 23.3244 13.6646 25.2217 15.222 26.7902ZM9.64562 14.5714V9.71429H14.4684V4.85714H25.6965L28.6354 8.12054H33.7597C34.6137 8.12054 35.3673 8.4494 36.0204 9.10714C36.6735 9.76488 37 10.5238 37 11.3839V30.8125C37 31.6726 36.6735 32.4315 36.0204 33.0893C35.3673 33.6964 34.6137 34 33.7597 34H8.06314C7.2091 34 6.45553 33.6964 5.80244 33.0893C5.14935 32.4315 4.82281 31.6726 4.82281 30.8125V14.5714H9.64562ZM4.82281 4.85714V0H8.06314V4.85714H12.8859V8.12054H8.06314V12.9777H4.82281V8.12054H0V4.85714H4.82281Z" fill="#8F8F8F"/>
						</svg>
						<img v-else :src="imagePreview" alt="">
						<span v-if="!image" class="upload__area-label">
							Upload your result
						</span>
					</label>
				</div>
				<UiButton type="primary" @click="upload" :disabled="load">
					<template v-if="!load">
						Upload picture
					</template>
					<template v-else>
						<UiLoader></UiLoader>
					</template>
				</UiButton>
			</div>
		</div>
	</div>
</template>
<script>
import UiButton from '../../../components/UiButton'
import { TftService } from '../../../services/tft.service'
import { mapGetters, mapMutations } from 'vuex'
import UiLoader from '../../../components/UiLoader'
export default {
	props: {
		matchId: {
			required: true,
			type: Number
		}
	},
	data () {
		return {
			load: false,
			image: null,
			imagePreview: null
		}
	},
	methods: {
		...mapMutations({
			toast: 'toast/NEW'
		}),
		upload () {
			if (!(this.image instanceof File)) {
				this.toast({
					type: 'error',
					message: 'Please, select image'
				})
				return
			}
			this.load = true
			const data = new FormData()
			data.append('image', this.image)
			TftService.sendResult(this.tournament.id, this.matchId, data).then(response => {
				if (response.data.status === 200) {
					this.toast({
						type: 'success',
						message: 'Image delivered successfully'
					})
					this.$emit('upload')
					this.$emit('close')
				} else {
					this.toast({
						type: 'error',
						message: 'There was an error sending the image'
					})
				}
			}).catch(() => {
				this.toast({
					type: 'error',
					message: 'An unknown error has occurred, please try again later'
				})
			}).finally(() => {
				this.load = false
			})
		},
		select ($event) {
			this.image = $event.target.files[0]

			const reader = new FileReader()
			reader.onload = (e) => {
				this.imagePreview = e.target.result
			}
			reader.readAsDataURL(this.image)
			this.previewImageData = ''
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament'
		})
	},
	components: {
		UiButton,
		UiLoader
	}
}
</script>
<style scoped lang="scss">
.modal {
	.modal__body {
		padding: 30px 40px;
		width: 457px;
		.close {
			background: #2F2F2F;
			width: 40px;
			height: 40px;
			display: flex;
			border-bottom-left-radius: 5px;
			&:hover {
				background: rgba(255,255,255, 0.05);
			}
			transition: 0.2s;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid #505050;
			border-left: 1px solid #505050;
			position: absolute;
			right: 0;
			top: 0;
		}
		.upload__result {
			h2 {
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				text-align: left;
				letter-spacing: 1px;
				text-transform: uppercase;
			}
			.upload__area {
				margin-top: 20px;
				input {
					display: none;
				}
				label {
					width: 100%;
					cursor: pointer;
					border: 1px dashed #505050;
					border-radius: 5px;
					height: 300px;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					.upload__area-label {
						bottom: -11px;
						position: absolute;
						background: #2F2F2F;
						border-radius: 5px;
						font-weight: 400;
						font-size: 10px;
						padding: 0 5px;
						line-height: 22px;
						text-align: center;
						letter-spacing: 2px;
						text-transform: uppercase;
						color: #505050;
					}
				}
			}
			.ui-button {
				margin-top: 31px;
				width: 100%;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
</style>

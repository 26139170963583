<template>
	<div class="create-team-container">
		<div class="create-team-container__heading">
			Teams
		</div>
		<div class="create-team-container__separator"></div>
		<ul>
			<li>
				Create your own unique team
			</li>
			<li>
				Chat and find new friends
			</li>
			<li>
				Compete in your favorite games
			</li>
		</ul>
		<div class="create-team-container__controls">
			<button class="btn__create create-team-container__controls_button" @click="showCreateTeam = true">
				<img src="@/assets/swords.svg" alt="">
				Create Team
			</button>
			<router-link :to="{ name: 'teams.find' }" class="create-team-container__controls_button btn__find">
				<img src="@/assets/search.svg" alt="">
				Find team
			</router-link>
		</div>
		<transition name="modal">
			<CreateTeamModal @close="showCreateTeam = false" v-if="showCreateTeam"></CreateTeamModal>
		</transition>
	</div>
</template>
<script>
import CreateTeamModal from './modals/CreateTeamModal'
export default {
	data () {
		return {
			showCreateTeam: false
		}
	},
	components: {
		CreateTeamModal
	}
}
</script>
<style scoped lang="scss">
	.create-team-container {
		width: 100%;
		background: #2F2F2F;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 25px;
		.create-team-container__heading {
			font-weight: bold;
			text-align: center;
			display: block;
			width: 100%;
			font-size: 20px;
			line-height: 28px;
			letter-spacing: 0.15px;
			text-transform: uppercase;
		}
		.create-team-container__separator {
			height: 1px;
			width: 100%;
			background: #505050;
			margin: 20px 0;
		}
		ul {
			padding: 0;
			padding-left: 20px;
			margin: 0;
			list-style-type: disc;
			li {
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.25px;
				color: #FFFFFF;
				&::marker {
					color: #00E7DF;
				}
			}
			li + li {
				margin-top: 16px;
			}
		}
		.create-team-container__controls {
			margin-top: 25px;
			button, a {
				text-decoration: none;
				position: relative;
				display: flex;
				align-items: center;
				border-radius: 4px;
				outline: none;
				border: none;
				width: 100%;
				justify-content: center;
				cursor: pointer;
				img {
					position: absolute;
					left: 16px;
				}
				font-size: 14px;
				line-height: 17px;
				background: transparent;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: bold;
				padding: 16px;
				transition: 0.2s;
				&.btn__create {
					background: #00E7DF;
					color: #121212;
					box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
					&:hover {
						background: #00E7DF;
						box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
					}
				}
				&.btn__find {
					color: #FFFFFF;
					border: 1px solid #505050;
					box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
					&:hover {
						box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.2);
					}
				}
			}
			.create-team-container__controls_button + .create-team-container__controls_button {
				margin-top: 15px;
			}
		}
	}

	@media screen and (max-width: 768px) {

	}
</style>

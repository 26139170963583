<template>
	<div>
		<transition name="slide">
			<UiBrackets v-if="!loading" :matches="matches"></UiBrackets>
		</transition>
		<transition name="slide">
			<div class="loading" v-if="loading">
				<QlashLoader></QlashLoader>
			</div>
		</transition>
	</div>
</template>
<script>
import UiBrackets from '../../brackets/UiBrackets'
import QlashLoader from '../../QlashLoader'
import { mapActions, mapGetters } from 'vuex'
export default {
	data () {
		return {
			loading: true
		}
	},
	computed: {
		...mapGetters({
			tournament: 'tournaments/getCurrentTournament',
			matches: 'tournaments/getMatches'
		})
	},
	methods: {
		...mapActions({
			getMatches: 'tournaments/getMatches',
			getResults: 'tournaments/getResults'
		}),
		async init () {
			await this.getResults(this.tournament.id)
			await this.getMatches(this.tournament.id)

			this.subscribeObserver(`tournament.${this.tournament.id}.match.*`, () => {
				this.getMatches(this.tournament.id)
			})

			this.loading = false
		}
	},
	mounted () {
		this.init()
	},
	components: {
		QlashLoader,
		UiBrackets
	}
}
</script>
<style scoped lang="scss">
.loading {
	margin: 50px 0;
}
.slide-enter-active, .slide-leave-active {
	transition: all .15s ease-in;
	top: -10px;
}

.slide-enter, .slide-leave-to {
	opacity: 0;
}

.slide-enter-to, .slide-leave {
	opacity: 1;
	top: 0;
}

</style>

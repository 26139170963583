<template>
	<div class="chat-component" v-resizible :class="{'blur': isLoading}">
		<div class="chat__header">
			<div class="conversation__info" v-if="!activeChat.isTeam">
				<UiAvatar :size="50" :src="null"></UiAvatar>
				<div class="conversation__name">
					<strong>{{ activeChat.tournament.title }}</strong>
					<span>{{ activeChat.round }} round</span>
				</div>
			</div>
			<div class="conversation__info" v-else>
				<UiAvatar :size="50" :src="activeChat.logo"></UiAvatar>
				<div class="conversation__name">
					<strong>{{ activeChat.name }}</strong>
					<span>{{ activeChat.team_mates_count }} players</span>
				</div>
			</div>

			<div class="conversation__control">
				<button v-if="!activeChat.isTeam" class="call" @click="showCallAdminBlock = !showCallAdminBlock">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.5 10.125C9.5 9.46094 8.91406 8.875 8.25 8.875H7.625C6.21875 8.875 5.125 10.0078 5.125 11.375V13.25C5.125 14.6562 6.21875 15.75 7.625 15.75H8.25C8.91406 15.75 9.5 15.2031 9.5 14.5V10.125ZM16.375 15.75C17.7422 15.75 18.875 14.6562 18.875 13.25V11.375C18.875 10.0078 17.7422 8.875 16.375 8.875H15.75C15.0469 8.875 14.5 9.46094 14.5 10.125V14.5C14.5 15.2031 15.0469 15.75 15.75 15.75H16.375ZM12 2C6.41406 2 2.15625 6.64844 2 12V12.625C2 12.9766 2.27344 13.25 2.625 13.25H3.25C3.5625 13.25 3.875 12.9766 3.875 12.625V12C3.875 7.54688 7.50781 3.875 12 3.875C16.4531 3.875 20.125 7.54688 20.125 12H20.0859C20.0859 12.1172 20.125 18.4844 20.125 18.4844C20.125 19.4219 19.3828 20.125 18.4453 20.125H14.5C14.5 19.1094 13.6406 18.25 12.625 18.25H11.375C10.3203 18.25 9.5 19.1094 9.5 20.125C9.5 21.1797 10.3203 22 11.375 22H18.4453C20.3984 22 22 20.4375 22 18.4844V12C21.8047 6.64844 17.5469 2 12 2Z" fill="#121212"/>
					</svg>
					Call admin
				</button>
				<div @click="closeChat" v-if="!isMobile" class="close">
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.26383 5.98298L11.6681 2.57872C12.1106 2.17021 12.1106 1.48936 11.6681 1.08085L10.9191 0.331915C10.5106 -0.110638 9.82979 -0.110638 9.42128 0.331915L6.01702 3.73617L2.57872 0.331915C2.17021 -0.110638 1.48936 -0.110638 1.08085 0.331915L0.331915 1.08085C-0.110638 1.48936 -0.110638 2.17021 0.331915 2.57872L3.73617 5.98298L0.331915 9.42128C-0.110638 9.82979 -0.110638 10.5106 0.331915 10.9191L1.08085 11.6681C1.48936 12.1106 2.17021 12.1106 2.57872 11.6681L6.01702 8.26383L9.42128 11.6681C9.82979 12.1106 10.5106 12.1106 10.9191 11.6681L11.6681 10.9191C12.1106 10.5106 12.1106 9.82979 11.6681 9.42128L8.26383 5.98298Z" fill="white"/>
					</svg>
				</div>
			</div>
			<transition name="slide-fade">
				<CallAdmin @sent="addMessage" @cancel="showCallAdminBlock = false" v-show="showCallAdminBlock && !activeChat.isTeam"></CallAdmin>
			</transition>
		</div>

		<div class="chat__messages" v-if="!isLoading" ref="messages">
			<ChatMessage :team="team" v-for="(message, i) in messages" :key="i" :message="message"></ChatMessage>
		</div>
		<InputMessage :team="team" @sent="addMessage"></InputMessage>
	</div>
</template>
<script>
import { ChatService } from '@/services/chat.service'
import InputMessage from './InputMessage'
import UiAvatar from '../UiAvatar'
import ChatMessage from './ChatMessage'
import { mapActions, mapGetters } from 'vuex'
import { prepare as messagePrepare } from '@/model-preparing/chat/message.js'
import CallAdmin from './CallAdmin'
import { TeamsService } from '@/services/teams.service'
import resizible from '../../directives/resizible'
import { EmmitService } from '../../services/emmit.service'
export default {
	data () {
		return {
			showCallAdminBlock: false,
			isLoading: false,
			messages: [],
			team: null,
			emmitInterval: null
		}
	},
	directives: {
		resizible: resizible
	},
	methods: {
		...mapActions({
			closeChat: 'chat/closeChat'
		}),
		async initialize () {
			this.isLoading = true
			if (this.activeChat.isTeam) {
				await this.getTeam()
				if (!this.team) return
			}
			await this.getMessages().finally(() => {
				this.$nextTick(() => {
					this.isLoading = false
				})
			})
			await this.subscribeMessages()
			this.emmitInterval = setInterval(this.emmitInChatEvent, 5000)
		},
		emmitInChatEvent () {
			const id = this.activeChat.isTeam ? this.team.chat_room : this.activeChat.id
			EmmitService.emmitInChatEvent({
				id
			})
		},
		getTeam () {
			return TeamsService.getTeam(this.activeChat.id).then(response => {
				this.team = response.data.data
			})
		},
		getMessages () {
			if (!this.activeChat.isTeam) {
				return ChatService.getMessagesRound({ tournamentId: this.activeChat.tournamentId, roundId: this.activeChat.id }).then(response => {
					this.messages = messagePrepare(response.data.data)
				})
			} else {
				return ChatService.getMessagesTeam({ roomId: this.team.chat_room }).then(response => {
					this.messages = messagePrepare(response.data.data.reverse())
				})
			}
		},
		subscribeMessages () {
			if (!this.activeChat.isTeam) {
				this.$root.centrifugeInstance.subscribe(`chat.tournament.${this.activeChat.tournamentId}.match.${this.activeChat.id}`, async (data) => {
					if (data.data.user_id !== this.currentUser.id) {
						this.messages.push(...messagePrepare([data.data]))
					}
				})
			} else {
				this.$root.centrifugeInstance.subscribe(`${this.team.chat_room}`, async (data) => {
					if (data.data.user_id !== this.currentUser.id) {
						this.messages.push(...messagePrepare([data.data]))
					}
				})
			}
		},
		addMessage (message) {
			this.messages.push(message)
		}
	},
	beforeDestroy () {
		clearInterval(this.emmitInterval)
	},
	watch: {
		messages () {
			setTimeout(() => {
				const messages = this.$refs.messages
				messages.scrollTop = messages.scrollHeight
			}, 100)
		},
		async activeChat () {
			await this.initialize()
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile',
			currentUser: 'user/getCurrentUser',
			activeChat: 'chat/activeChat'
		})
	},
	async mounted () {
		await this.initialize()
		this.emmitInChatEvent()
	},
	components: {
		InputMessage,
		UiAvatar,
		ChatMessage,
		CallAdmin
	}
}
</script>
<style scoped lang="scss">
.chat-component {
	&.blur {
		filter: blur(2px);
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			content: "";
			display: block;
			height: 100%;
			background: rgba(0,0,0, 0.1);
			filter: blur(5px)
		}
	}
	position: absolute;
	right: 100%;
	width: 476px;
	top: 60px;
	height: calc(100vh - 60px);
	background: #232323;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 1px solid #2F2F2F;
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
	.chat__header {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		padding: 15px 15px 15px 25px;
		border-bottom: 1px solid #2F2F2F;
		.conversation__info {
			display: flex;
			align-items: center;
			.conversation__name {
				margin-left: 20px;
				strong {
					font-weight: bold;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 0.15px;
					text-transform: uppercase;
					color: #FFFFFF;
					margin-bottom: 2px;
					display: block;
				}
				span {
					font-size: 12px;
					line-height: 20px;
					letter-spacing: 0.15px;
					color: #BDBDBD;
				}
			}
		}
		.conversation__control {
			display: flex;
			align-items: center;
			grid-gap: 10px;
			.close {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 42px;
				height: 42px;
				cursor: pointer;
				transition: 0.2s;
				&:hover {
					background: rgba(255,255,255, 0.2);
				}
				background: #2F2F2F;
				border: 1px solid #505050;
				border-radius: 4px;
			}
			.call {
				background: #00E7DF;
				border-radius: 4px;
				padding: 9px 10px;
				outline: none;
				border: none;
				white-space: nowrap;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				font-family: Montserrat, sans-serif;
				cursor: pointer;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.25px;
				text-transform: uppercase;
				color: #121212;
				transition: 0.2s;
				&:hover {
					opacity: 0.9;
				}
				svg {
					margin-right: 7px;
				}
			}
		}
	}
	.chat__messages {
		flex-grow: 1;
		overflow-y: auto;
		background: url('~@/assets/chat-bg.png');
		background-repeat: repeat;
		background-size: contain;
		padding: 15px 25px;
		&::-webkit-scrollbar {
			width: 4px
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}

@media screen and (max-width: 768px) {
	.chat-component {
		position: static;
		width: 100%;
		border: none;
		height: calc(100% - 70px);
		background: url(/img/chat-bg.9934a9db.png);
		background-repeat: repeat;
		background-size: contain;
		.chat__header {
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 16px;
		}
		.chat__messages {
			background: none;
		}
	}
}

.slide-fade-enter-active {
	transition: all .1s ease;
}
.slide-fade-leave-active {
	transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
	opacity: 0;
}
</style>

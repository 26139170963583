import Message from '@/models/Message'
import { mapKeys, snakeCase } from 'lodash'
export function prepare (value) {
	const messages = []
	for (const key in value) {
		const formattedForModel = mapKeys(value[key], (value, key) => {
			return snakeCase(key)
		})
		messages.push(new Message(formattedForModel))
	}

	return messages
}

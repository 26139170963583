<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 12 14">
    <g>
      <path d="M6 7C7.875 7 9.42857 5.44141 9.42857 3.5C9.42857 1.58594 7.875 0 6 0C4.09821 0 2.57143 1.58594 2.57143 3.5C2.57143 5.44141 4.09821 7 6 7ZM8.38393 7.875H7.92857C7.33929 8.17578 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.63393 8.17578 4.04464 7.875H3.58929C1.60714 7.875 0 9.54297 0 11.5664V12.6875C0 13.4258 0.5625 14 1.28571 14H10.7143C11.4107 14 12 13.4258 12 12.6875V11.5664C12 9.54297 10.3661 7.875 8.38393 7.875Z" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: String },
    height: { type: String },
    color: { type: String }
  }
}
</script>

/**
 * import and init global mixins
 */

import Vue from 'vue'

import currentUser from '../mixins/currentUser'
import currentGame from '../mixins/currentGame'
import currentSeason from '../mixins/currentSeason'
import centrifugeInstance from '../mixins/centrifugeInstance'
import jumpTo from '../mixins/jumpTo'
import formatDateTime from '../mixins/formatDateTime'
import prepareTournamentsByDays from '../mixins/prepareTournamentsByDays'
Vue.mixin(currentUser)
Vue.mixin(centrifugeInstance)
Vue.mixin(currentGame)
Vue.mixin(currentSeason)
Vue.mixin(jumpTo)
Vue.mixin(formatDateTime)
Vue.mixin(prepareTournamentsByDays)

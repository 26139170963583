<template>
	<div class="app-initialize">
		<template v-if="isInitializeApp">
			<AppHeader v-if="$route.meta.withHeader"></AppHeader>
			<LeftSidebar v-if="$route.meta.withSidebar && !isMobile"></LeftSidebar>
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
			<Chat v-if="isAuth"></Chat>
			<CheckTerms v-if="isAuth"></CheckTerms>
			<SubscribeResultsMatches></SubscribeResultsMatches>
		</template>
		<AppLoader v-show="!isInitializeApp"></AppLoader>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CheckTerms from '../components/modals/CheckTerms'
import AppHeader from './Header.vue'
import LeftSidebar from './LeftSidebar.vue'
import SubscribeResultsMatches from './SubscribeResultsMatches'
import Chat from './Chat'
import AppLoader from './components/AppLoader'
export default {
	name: 'AppLayout',
	components: {
		AppHeader,
		LeftSidebar,
		Chat,
		CheckTerms,
		SubscribeResultsMatches,
		AppLoader
	},
	data () {
		return {
			isInitializeApp: false
		}
	},
	computed: {
		...mapGetters({
			isMobile: 'dom/isMobile',
			isAuth: 'user/isAuth',
			getCurrentUser: 'user/getCurrentUser'
		})
	},
	async mounted () {
		// initialize application
		await this.initializeApp()
	},
	methods: {
		async initializeApp () {
			// connect to websockets
			await this.connectToCentrifuge()

			// when connect to centrifuge, load based data for application
			this.$root.centrifugeInstance.on('connect', async () => {
				if (this.isInitializeApp) return
				await this.loadStartedData().then(() => {
					this.isInitializeApp = true
					// if dont find game, redirect to games page
					if (!this.$currentGame && this.$route.name !== 'games') {
						this.$router.push({ name: 'games' })
					}
				})
			})

			// disconnect, try again with other token
			this.$root.centrifugeInstance.on('disconnect', () => {
				// this.reconnectToCentrifuge()
			})
		},
		async loadStartedData () {
			return Promise.all([
				await this.getGames(),
				await this.getCurrentTeams({
					game_id: this.$currentGame ? this.$currentGame.id : null,
					platform_id: this.$currentGame ? this.$currentGame.platformId : null
				}),
				await this.getCurrentTournaments(this),
				await this.getCurrentSeason({
					is_active: true,
					games: this.$currentGame ? this.$currentGame.id : null
				}),
				await this.favouriteGames(),
				await this.getAllRounds(),
				await this.setUserAmplitude()
			])
		},
		async setUserAmplitude () {
			await this.amplitude.setUserId(this.$currentUser.id)
		},
		...mapActions({
			getAllRounds: 'chat/getRounds',
			getGames: 'user/getGames',
			favouriteGames: 'user/getFavouriteGames',
			getCurrentTournaments: 'user/getCurrentTournaments',
			getCurrentTeams: 'user/getCurrentTeams',
			getCurrentSeason: 'user/getCurrentSeason'
		})
	}
}
</script>
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>

<template>
	<div class="proove-score">
		<div class="info">
			<h4>Proove your score</h4>
			<p>You can take a picture of your result screen and send it to us to proove your result.</p>
		</div>
		<div class="pictures">
			<template v-if="isAvailableParticipants">
				<input v-if="!isFinishRound" type="file" multiple @change="addToImages" accept="image/png, image/jpeg" id="file1">
			</template>

			<div class="pictures-list">
				<label class="picture" v-for="(image, i) in images" :key="i" >
					<img :src="image.url" alt="">
				</label>

				<label class="picture" v-if="images.length > 1" for="file1">
					<img src="@/assets/picture-placeholder.svg" alt="">
				</label>

				<label class="picture" v-if="images.length < 1" for="file1">
					<img src="@/assets/picture-placeholder.svg" alt="">
				</label>
				<label class="picture" v-if="images.length < 2" for="file1">
					<img src="@/assets/picture-placeholder.svg" alt="">
				</label>
			</div>
			<UiButton @click="sendImages" :disabled="!availableSendImages || blockSendScore || isFinishRound" :type="'secondary'">
				Upload picture
			</UiButton>
		</div>
	</div>
</template>
<script>
import UiButton from '../../../../components/UiButton'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	data () {
		return {
			images: [],
			blockSendScore: false
		}
	},
	computed: {
		...mapGetters({
			results: 'tournaments/getResults',
			currentRound: 'tournaments/getCurrentMatch',
			tournament_id: 'tournaments/getCurrentTournamentId',
			user: 'user/getCurrentUser'
		}),
		isFinishRound () {
			return ['FINISHED', 'CLOSED'].includes(this.currentRound.match_status)
		},
		availableSendImages () {
			return this.images.filter(item => item instanceof File && !item.isLoaded).length > 0
		},
		isAvailableParticipants () {
			return this.currentRound.first_participant !== null && this.currentRound.second_participant !== null
		}
	},
	watch: {
		results () {
				this.setImages()
		}
	},
	methods: {
		...mapActions({
			setResults: 'tournaments/setResults'
		}),
		...mapMutations({
			toast: 'toast/NEW'
		}),
		setImages () {
			this.images = []
			const myImages = this.results.filter(item => item.reporter_id === this.user.id && item.type === 'IMAGE')
			for (const item in myImages) {
				this.images.push({
					url: myImages[item].value_state
				})
			}
		},
		async sendImages () {
			this.blockSendScore = true
			const formData = new FormData()
			const images = this.images
			for (const item in images) {
				if (!(images[item] instanceof File) || images[item].isLoaded) continue
				formData.append('image', images[item])
				await this.setResults({
					tournament_id: this.tournament_id,
					match_id: this.currentRound.id,
					data: formData
				}).finally(() => {
					images[item].isLoaded = true
				})
			}
			setTimeout(() => {
				this.blockSendScore = false
			}, 3000)
		},
		addToImages ($event) {
			const images = $event.target.files

			for (const image in images) {
				if (!(images[image] instanceof File)) continue
				if ((images[image].size / 1000) > 2048) {
					this.toast({
						message: 'The image may not be greater than 2048 kilobytes.',
						type: 'error'
					})
					return
				}

				const reader = new FileReader()
				reader.onload = e => {
					const instanceImage = images[image]
					instanceImage.url = e.target.result
					this.images.push(instanceImage)
				}
				reader.readAsDataURL(images[image])
			}
		}
	},
	components: {
		UiButton
	}
}
</script>
<style scoped lang="scss">
.proove-score {
	background: #2F2F2F;
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	padding: 25px;
	display: flex;
	overflow: hidden;
	.info {
		flex-grow: 1;
		margin-right: 20px;
		h4 {
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			align-items: center;
			letter-spacing: 0.15px;
			text-transform: uppercase;
			color: #FFFFFF;
			margin-bottom: 10px;
		}
		p {
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.25px;
		}
	}
	.pictures {
		width: fit-content;
		button {
			width: 100%;
			margin-top: 20px;
			background: #2F2F2F;
			border: 1px solid #505050;
			box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
		}
		input {
			display: none;
		}
		.pictures-list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 26px;
			flex-wrap: wrap;
			.picture {
				min-width: 212px;
				width: 212px;
				height: 200px;
				overflow: hidden;
				background: #2F2F2F;
				border: 1px dashed #505050;
				border-radius: 10px;
				display: flex;
				cursor: pointer;
				align-items: center;
				justify-content: center;
			}

		}
	}
}
@media screen and (max-width: 992px) {
	.proove-score {
		flex-wrap: wrap;
		.info {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}
@media screen and (max-width: 768px) {
	.proove-score {
		margin-top: 16px !important;
		padding: 16px;
		.pictures {
			width: 100%;
			.pictures-list {
				width: 100%;
				grid-gap: 16px;
				.picture {
					height: 140px;
					flex: 1;
					min-width: auto;
					width: auto;
					img {
						width: 26px;
					}
				}
			}
		}
	}
}
</style>

import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class NewsService extends BaseService {
	static get entity () {
		return 'news'
	}

	static async getNews (params) {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}?${this.querystring(params)}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}

	static async getNewsDetail (id) {
		try {
			const response = await this.request({ auth: true }).get(`${this.entity}/${id}`)
			return new ResponseWrapper(response, response.data.data)
		} catch (error) {
			const message = error.response.data ? error.response.data.error : error.response.statusText
			throw new ErrorWrapper(error, message)
		}
	}
}

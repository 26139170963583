<template>
	<div class="mobile__menu">
		<div @click="$emit('close')" class="mobile__menu-close">
			<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.50971 3.98865L7.77921 1.71915C8.07425 1.44681 8.07425 0.992908 7.77921 0.720567L7.27992 0.221277C7.00758 -0.0737589 6.55368 -0.0737589 6.28134 0.221277L4.01184 2.49078L1.71964 0.221277C1.4473 -0.0737589 0.993396 -0.0737589 0.721056 0.221277L0.221765 0.720567C-0.0732706 0.992908 -0.0732706 1.44681 0.221765 1.71915L2.49127 3.98865L0.221765 6.28085C-0.0732706 6.55319 -0.0732706 7.00709 0.221765 7.27943L0.721056 7.77872C0.993396 8.07376 1.4473 8.07376 1.71964 7.77872L4.01184 5.50922L6.28134 7.77872C6.55368 8.07376 7.00758 8.07376 7.27992 7.77872L7.77921 7.27943C8.07425 7.00709 8.07425 6.55319 7.77921 6.28085L5.50971 3.98865Z" fill="white"/>
			</svg>
		</div>
		<div class="menu__block">
			<div class="menu__block-nav">
				<router-link :to="{ name: 'games' }">Games</router-link>
				<router-link :to="{ name: 'index' }">Tournaments</router-link>
				<router-link :to="{ name: 'teams' }">Teams</router-link>
				<router-link :to="{ name: 'clans' }">Clans</router-link>
				<router-link :to="{ name: 'leaderboard' }">Leaderboard</router-link>
				<router-link :to="{ name: 'news' }">News</router-link>
			</div>
		</div>
		<div class="wallets__block">
			<div class="wallet__item">
				<div class="wallet__item-value">
					<img src="@/assets/self-coin.svg" alt="">
					<span>{{ $currentUser.getBalanceFromWallet('COINS') }}</span>
				</div>
				<button class="wallet__item-add">
					<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9.28571 3.92857H6.07143V0.714286C6.07143 0.334821 5.73661 0 5.35714 0H4.64286C4.24107 0 3.92857 0.334821 3.92857 0.714286V3.92857H0.714286C0.3125 3.92857 0 4.26339 0 4.64286V5.35714C0 5.75893 0.3125 6.07143 0.714286 6.07143H3.92857V9.28571C3.92857 9.6875 4.24107 10 4.64286 10H5.35714C5.73661 10 6.07143 9.6875 6.07143 9.28571V6.07143H9.28571C9.66518 6.07143 10 5.75893 10 5.35714V4.64286C10 4.26339 9.66518 3.92857 9.28571 3.92857Z" fill="white"/>
					</svg>
				</button>
			</div>
		</div>
		<div class="profile__block">
			<div class="profile__block-head">
				<UiAvatar :src="$currentUser.avatar" size="50"></UiAvatar>
				<span>{{ $currentUser.nickname }}</span>
			</div>
			<div class="profile__block-menu">
				<router-link :class="{'active': $route.name === 'profile'}" :to="{ name: 'profile' }">
					<span class="profile__block-menu__item-icon">
						<UiBaseIcon iconName="user"/>
					</span>
					<span>
						Profile
					</span>
				</router-link>
				<a href="" @click.prevent="logout">
					<span class="profile__block-menu__item-icon">
						<UiBaseIcon iconName="logout"/>
					</span>
					<span>
						Log out
					</span>
				</a>
			</div>
		</div>
	</div>
</template>
<script>
import UiAvatar from '../../../components/UiAvatar'
import { AuthService } from '../../../services/auth.service'
import UiBaseIcon from '../../../components/icons/UiBaseIcon'
export default {
	components: {
		UiAvatar,
		UiBaseIcon
	},
	methods: {
		async logout () {
			this.amplitude.logEvent('logout')
			await AuthService.makeLogout()
		}
	}
}
</script>
<style scoped lang="scss">
	.mobile__menu {
		height: calc(100vh - 60px);
		position: fixed;
		top: 60px;
		display: flex;
		flex-direction: column;
		left: 0;
		width: 100%;
		background: #232323;
		.mobile__menu-close {
			position: absolute;
			top: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			right: 16px;
			width: 28px;
			height: 28px;
			background: #2F2F2F;
			border: 1px solid #505050;
			border-radius: 4px;
		}
		border: 1px solid #2F2F2F;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
		.wallets__block {
			padding: 16px;
			border-top: 1px solid #2F2F2F;
			.wallet__item {
				display: flex;
				border-radius: 4px;
				overflow: hidden;
				background: linear-gradient(270deg, rgba(0, 231, 223, 0.15) 0%, rgba(0, 231, 223, 0.05) 100%);
				.wallet__item-value {
					flex-grow: 1;
					align-items: center;
					padding: 16px 0;
					padding-left: 15px;
					display: flex;
					span {
						margin-left: 8px;
						font-weight: bold;
						font-size: 14px;
						line-height: 18px;
					}
				}
				.wallet__item-add {
					padding: 0 15px;
					display: flex;
					background: transparent;
					border: none;
					outline: none;
					cursor: pointer;
					align-items: center;
					border-left: 1px solid rgba(35, 35, 35, 1);
				}
			}
		}
		.profile__block {
			padding-bottom: 22px;
			width: 100%;
			.profile__block-head {
				width: 100%;
				padding: 10px 16px;
				background: #2F2F2F;
				align-items: center;
				display: flex;
				span {
					font-weight: 600;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.15px;
					text-transform: uppercase;
					color: #FFFFFF;
					display: flex;
					margin-left: 10px;
				}
			}
			.profile__block-menu {
				margin-top: 20px;
				padding: 0 19px;
				a {
					display: flex;
					text-decoration: none;
					align-items: center;
					svg {
						path {
							transition: 0.2s;
						}
					}
					.profile__block-menu__item-icon {
						width: 20px;
						margin-right: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					span {
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 0.15px;
						text-transform: uppercase;
						color: #8F8F8F;
					}
					&:hover, &:focus, &.active {
						svg {
							path {
								fill: #FFFFFF
							}
						}
						span {
							color: #fff;
						}
					}
				}
				a + a {
					margin-top: 28px;
				}
			}
		}
		.menu__block {
			width: 100%;
			padding: 30px 16px;
			flex-grow: 1;
			overflow: hidden;
			.menu__block-nav {
				width: 100%;
				a {
					width: 100%;
					display: block;
					text-decoration: none;
					color: #fff;
					font-weight: 600;
					font-size: 20px;
					line-height: 20px;
					letter-spacing: 0.15px;
					text-transform: uppercase;
				}
				a + a {
					margin-top: 30px;
				}
			}
		}
	}
</style>
